import React, { FunctionComponent } from 'react';
import { ColorValue, StyleSheet, View, ViewProps } from 'react-native';

import Colors from '../colors';

interface Props extends ViewProps {
  active: boolean;
  progress: number;
  tintColor?: ColorValue;
}

const AudioBar: FunctionComponent<Props> = ({
  active,
  progress,
  style,
  tintColor = Colors.WHITE
}) => {
  const renderDot = (_: number, index: number) => (
    <View
      style={[styles.dot, { backgroundColor: tintColor }]}
      key={`point-${index}`}
    />
  );

  const renderBar = () => (
    <View
      style={[
        styles.bar,
        { backgroundColor: tintColor },
        { left: active ? `${progress}%` : 0 }
      ]}
    />
  );

  return (
    <View style={[styles.container, style]}>
      {[...Array(30)].map(renderDot)}
      {renderBar()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 20,
    flexDirection: 'row',
    alignItems: 'center'
  },
  dot: {
    height: 2,
    width: 2,
    borderRadius: 1,
    marginHorizontal: 1,
    backgroundColor: Colors.WHITE
  },
  bar: {
    position: 'absolute',
    height: 20,
    width: 2,
    borderRadius: 1,
    backgroundColor: Colors.WHITE
  }
});

export default AudioBar;
