import { MessageFormatElement } from 'react-intl';

const translations: { [lang: string]: Record<string, string> } = {
  en: require('./locales/en.json'),
  de: require('./locales/de.json'),
  ar: require('./locales/ar.json'),
  fa: require('./locales/fa.json')
};

export const isRtl = (locale: string) => {
  const rtl = [
    'ar',
    'arc',
    'dv',
    'fa',
    'ha',
    'he',
    'khw',
    'ks',
    'ku',
    'ps',
    'ur',
    'yi'
  ];
  return rtl.find((currLocale) => currLocale.match(locale)) !== undefined;
};

export const getCurrentTranslation: (
  locale: string
) =>
  | Record<string, string>
  | Record<string, MessageFormatElement[]>
  | undefined = (locale) => {
  const language = locale.split(/[-_]/)[0];
  const messages = translations[language] ?? translations['de']; //fallback
  return messages;
};
