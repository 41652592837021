import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet, Text, TextProps } from 'react-native';

import Colors from '../colors';
import { isRtl } from '../lang';
import { useAppSelector } from '../store/hooks';
import { selectActiveLanguage } from '../store/languages';

const MediumText: FunctionComponent<PropsWithChildren<TextProps>> = (props) => {
  const rtl = isRtl(useAppSelector(selectActiveLanguage).language);
  return (
    <Text
      {...props}
      style={[styles.textFont, props.style, rtl && { writingDirection: 'rtl' }]}
    >
      {props.children}
    </Text>
  );
};

export default MediumText;

const styles = StyleSheet.create({
  textFont: {
    fontFamily: 'NotoSans-Medium',
    fontSize: 16,
    color: Colors.GREY1
  }
});
