import AsyncStorage from '@react-native-async-storage/async-storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
  createTransform
} from 'redux-persist';

import assetsReducer from './assets';
import blocksReducer from './blocks';
import eventsReducer from './events';
import exercisesReducer from './exercises';
import faqReducer from './faq';
import flowReducer from './flow';
import languagesReducer from './languages';
import messagesReducer from './messages';
import modulesReducer from './modules';
import sessionsReducer from './sessions';
import substancesReducer from './substances';
import userReducer from './user';

const combinedReducer = combineReducers({
  flow: flowReducer,
  sessions: sessionsReducer,
  messages: messagesReducer,
  modules: modulesReducer,
  blocks: blocksReducer,
  exercises: exercisesReducer,
  assets: assetsReducer,
  substances: substancesReducer,
  user: userReducer,
  events: eventsReducer,
  languages: languagesReducer,
  faq: faqReducer
});

const rootReducer = (
  state: ReturnType<typeof combinedReducer> | undefined,
  action: AnyAction
) => {
  if (action.type === 'auth/logout' && state !== undefined) {
    state = {
      languages: state.languages
    } as any;
  }
  return combinedReducer(state, action);
};

const loadingTransform = createTransform(
  (inboundState) => {
    return { ...inboundState, loading: false };
  },
  (outboundState: any) => {
    return { ...outboundState, loading: false };
  },
  { whitelist: ['flow', 'sessions', 'modules', 'blocks'] }
);

const persistConfig = {
  key: 'root',
  version: 1,
  storage: AsyncStorage,
  transforms: [loadingTransform]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
