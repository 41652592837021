import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Image,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import Colors from '../../../../colors';
import Drawer from '../../../../components/Drawer';
import InputDrawer from '../../../../components/InputDrawer';
import ListSelect from '../../../../components/ListSelect';
import MediumText from '../../../../components/MediumText';
import Message from '../../../../components/Message';
import PillButton from '../../../../components/PillButton';
import Step from '../../../../components/Step';
import useAppWindowDimensions from '../../../../hooks/useAppWindowDimensions';
import { useBackHandler } from '../../../../hooks/useBackHandler';
import {
  saveExercise,
  selectListExercisesByTemplateId
} from '../../../../store/exercises';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  Option,
  ListExerciseTemplateItem,
  ExerciseType,
  ListExercise,
  ListExerciseBlock,
  ListExerciseItem
} from '../../../../store/types';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import { RootStackParamList } from '../../../RootNavigator';
import ComplexItemStep from './ComplexItemStep';

export enum ListSteps {
  LIST_SELECT,
  COMPLEX_ITEM
}

export interface ItemOptionAnswer {
  itemId: string;
  blockId: string;
  value: string | string[] | number | Option | Option[] | null;
}

const ListExerciseScreen: FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false);
  const [stepIndex, setStepIndex] = useState(ListSteps.LIST_SELECT);
  const [showInput, setShowInput] = useState(false);
  const [itemInFocus, setItemInFocus] = useState<string>('');
  const scrollViewRef = useRef<ScrollView>(null);

  const { height: windowHeight } = useAppWindowDimensions();
  const dispatch = useAppDispatch();

  const intl = useIntl();
  const navigation =
    useNavigation<
      StackNavigationProp<DrawerStackParamList & RootStackParamList>
    >();
  const { params } =
    useRoute<RouteProp<DrawerStackParamList, 'ListExercise'>>();

  const listExercise = useAppSelector(
    selectListExercisesByTemplateId(params.template.id)
  );

  // Prioritize ListExerciseItems passed through navigation params
  // Then check existing list exercises for one with the same template and apply user responses
  const externalItems = params.items ?? listExercise?.items ?? [];

  const initialAnswers: ItemOptionAnswer[] = (() => {
    // Convert saved blocks in all items of type ListExerciseBlock to ItemOptionAnswer
    const res: ItemOptionAnswer[] = [];
    externalItems.forEach((item) => {
      Array.isArray(item.response) &&
        item.response.forEach((block) => {
          res.push({
            itemId: item.id,
            blockId: block.id,
            value: block.response
          });
        });
    });
    return res;
  })();

  // Answers to blocks referenced by itemId and blockId
  const [answers, setAnswers] = useState<ItemOptionAnswer[]>(initialAnswers);

  const initialItems: ListExerciseTemplateItem[] = (() => {
    if (listExercise) {
      // Convert saved items of type ListExerciseItem[] to ListExerciseTemplateItem
      return listExercise.items.map((exercise) => {
        const blocks = params.template.items.find(
          (item) => item.id === exercise.id
        )?.blocks;
        return {
          id: exercise.id,
          text: exercise.text,
          blocks:
            !blocks || blocks.length === 0
              ? params.template.defaultBlocks
              : blocks
        };
      });
    }
    return params.template.items.map((item) =>
      item.blocks.length === 0
        ? { ...item, blocks: params.template.defaultBlocks }
        : item
    );
  })();

  // Items on the initial page of the exercise, user may add custom items
  const [items, setItems] = useState<ListExerciseTemplateItem[]>(initialItems);
  const [selectedIds, setSelectedIds] = useState<string[]>(
    externalItems.flatMap((item) => (item.selected ? item.id : []))
  );

  useEffect(() => {
    if (showInput) scrollViewRef.current?.scrollToEnd();
  }, [showInput]);

  const handleSetStepIndex = (val: number) => {
    setStepIndex(val);
    scrollViewRef.current?.scrollTo({ y: 0, animated: false });
  };

  const getProgress = (itemId: string | null) => {
    const item = items.find((val) => val.id === itemId);
    const itemAnswers = answers.filter((val) => val.itemId === itemId);

    if (item === undefined || item.blocks.length === 0) return 0;

    return (itemAnswers.length / item.blocks.length) * 100;
  };

  const renderItem = (item: ListExerciseTemplateItem) => {
    const onNavigation = () => {
      setItemInFocus(item.id);
      handleSetStepIndex(ListSteps.COMPLEX_ITEM);
      setShowInput(false);
    };

    const onSelect = () => {
      if (selectedIds.includes(item.id))
        setSelectedIds(selectedIds.filter((val) => val !== item.id));
      else setSelectedIds([...selectedIds, item.id]);
    };
    const onDelete = () => {
      navigation.navigate('ConfirmationQuestion', {
        onPressYes: () => setItems(items.filter((val) => val.id !== item.id)),
        question: intl.formatMessage({
          defaultMessage: 'Are you sure you want to delete this item?',
          description: 'Delete item confirmation'
        })
      });
    };
    return (
      <View style={{ marginBottom: 20 }} key={item.id}>
        <ListSelect
          progress={getProgress(item.id)}
          selected={selectedIds.includes(item.id)}
          editMode={editMode}
          onNavigation={onNavigation}
          onSelect={onSelect}
          onDelete={onDelete}
        >
          {item.text}
        </ListSelect>
      </View>
    );
  };

  const renderInputDrawer = () => {
    return (
      <InputDrawer
        onCancel={() => {
          setShowInput(false);
        }}
        onSave={(text) => {
          setShowInput(false);
          const newItem = {
            id: uuidv4(),
            text,
            blocks: params.template.defaultBlocks
          };
          setItems([...items, newItem]);
          setSelectedIds([...selectedIds, newItem.id]);
        }}
      />
    );
  };

  const renderListSelectStep = () => {
    const renderTapInstructions = () => {
      return (
        <View style={styles.instructionsContainer}>
          <View style={styles.bubbleContainer}>
            <View style={styles.handContainer}>
              <Image
                source={require('./assets/hand.png')}
                style={styles.hand}
              />
            </View>
            <MediumText style={styles.instruction}>
              <FormattedMessage defaultMessage="Please tap on the selected items to complete them." />
            </MediumText>
          </View>
          {params.template.scheduling ? (
            <View style={styles.calendarContainer}>
              <Image
                source={require('./assets/calendar.png')}
                style={styles.calendar}
              />
            </View>
          ) : null}
        </View>
      );
    };
    const renderAddValue = () => {
      return (
        <Pressable
          style={styles.addInputContainer}
          onPress={() => {
            setShowInput(true);
          }}
        >
          <MediumText style={styles.text}>
            <FormattedMessage
              defaultMessage="Add custom item"
              description="Add item in list exercise"
            />
          </MediumText>
          <View style={styles.plusButton}>
            <MediumText style={styles.text}>+</MediumText>
          </View>
        </Pressable>
      );
    };
    const renderSelectedItems = () =>
      items.filter((item) => selectedIds.includes(item.id)).map(renderItem);

    const renderUnselectedItems = () =>
      items.filter((item) => !selectedIds.includes(item.id)).map(renderItem);

    const allItemsFinished =
      selectedIds.length > 0 &&
      selectedIds.every((item) => getProgress(item) === 100);

    const exercise: ListExercise = {
      id: uuidv4(),
      type: ExerciseType.ListExercise,
      createdAt: new Date().toISOString(),
      templateId: params.template.id,
      items:
        // Convert items of type ListExerciseTemplateItem to saved type ListExerciseItem
        items.map(
          (item): ListExerciseItem => ({
            id: item.id,
            selected: selectedIds.includes(item.id),
            text: item.text,
            response: item.blocks.map(
              (block): ListExerciseBlock => ({
                id: block.id,
                question: block.text,
                response:
                  // Convert answers of type ItemOptionAnswer to saved type ListExerciseBlock
                  answers.find(
                    (answer) =>
                      answer.blockId === block.id && answer.itemId === item.id
                  )?.value ?? null
              })
            )
          })
        )
    };

    return (
      <>
        <ScrollView
          ref={scrollViewRef}
          keyboardShouldPersistTaps="always"
          style={[
            { paddingTop: 16 },
            Platform.OS === 'web' && {
              height: windowHeight - 125,
              paddingBottom: 50
            }
          ]}
        >
          <View
            style={{ paddingHorizontal: 16 }}
            pointerEvents={showInput ? 'none' : 'auto'}
          >
            {selectedIds.length !== 0 && renderTapInstructions()}
            {renderSelectedItems()}
            <Message
              text={
                params.instructionLinkedExercise ?? params.template.instruction
              }
              style={{ marginBottom: 16 }}
              containerStyle={{ shadowOpacity: 0 }}
            />
            {renderUnselectedItems()}
            {params.template.allowCustomItems && renderAddValue()}
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
              <PillButton
                style={{ marginBottom: 50 }}
                disabled={!allItemsFinished}
                onPress={() => {
                  !params.preventSaving && dispatch(saveExercise(exercise));
                  params.onFinish?.(exercise);
                  navigation.goBack();
                }}
              >
                <FormattedMessage
                  defaultMessage="Save"
                  description="Save button"
                />
              </PillButton>
            </View>
          </View>
        </ScrollView>
        {showInput && renderInputDrawer()}
      </>
    );
  };

  const isFirstScreen = () => {
    return stepIndex <= ListSteps.LIST_SELECT;
  };

  const regularBackwardNav = () => {
    handleSetStepIndex(stepIndex - 1);
  };

  const drawerCloseExerciseText = intl.formatMessage({
    defaultMessage: 'Are you sure you would like to exit this exercise?',
    description: 'Complex List exercise close exercise popup text'
  });

  const { getDrawerIcon } = useBackHandler(
    isFirstScreen,
    regularBackwardNav,
    [stepIndex],
    navigation,
    undefined,
    undefined,
    drawerCloseExerciseText
  );

  return (
    <Drawer
      title={params.template.title}
      onBackgroundPress={() =>
        navigation.navigate('DrawerCloseExercise', {
          closeExercise: () => navigation.goBack(),
          text: drawerCloseExerciseText
        })
      }
      leftComponent={getDrawerIcon()}
      rightComponent={
        stepIndex === ListSteps.LIST_SELECT ? (
          <TouchableOpacity onPress={() => setEditMode(!editMode)}>
            <MediumText style={{ color: Colors.BLUE }}>
              {editMode ? (
                <FormattedMessage
                  defaultMessage="Done"
                  description="Edit in List Exercise"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Edit"
                  description="Edit in List Exercise"
                />
              )}
            </MediumText>
          </TouchableOpacity>
        ) : (
          <></>
        )
      }
    >
      <View style={{ flex: 1 }}>
        <Step step={ListSteps.LIST_SELECT} current={stepIndex}>
          {renderListSelectStep()}
        </Step>
        <Step step={ListSteps.COMPLEX_ITEM} current={stepIndex}>
          <ComplexItemStep
            backwardsNav={regularBackwardNav}
            selectedItems={items.filter((item) =>
              selectedIds.includes(item.id)
            )}
            itemInFocus={itemInFocus}
            setItemInFocus={setItemInFocus}
            progress={getProgress(itemInFocus)}
            answers={answers.filter((val) => val.itemId === itemInFocus)}
            deleteAnswer={(id) => {
              setAnswers(answers.filter((ans) => ans.blockId !== id));
            }}
            setAnswer={(answer) => {
              const answerAlreadyExists = answers.some(
                (el) =>
                  el.itemId === answer.itemId && el.blockId === answer.blockId
              );
              if (answerAlreadyExists) {
                setAnswers(
                  answers.map((el) =>
                    el.itemId === answer.itemId && el.blockId === answer.blockId
                      ? answer
                      : el
                  )
                );
              } else setAnswers([...answers, answer]);
            }}
            defaultBlocks={params.template.defaultBlocks}
          />
        </Step>
      </View>
    </Drawer>
  );
};

export default ListExerciseScreen;

const styles = StyleSheet.create({
  arrow: {
    width: 30,
    height: 30,
    resizeMode: 'contain'
  },
  text: {
    color: Colors.BLUE
  },
  plusButton: {
    width: 30,
    height: 30,
    borderColor: Colors.GREY4,
    borderWidth: 1,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  addInputContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 10,
    paddingBottom: 40
  },
  calendar: {
    height: 22,
    width: 22,
    resizeMode: 'contain'
  },
  calendarContainer: {
    flexGrow: 0.05,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  instruction: {
    flex: 1,
    paddingVertical: 8
  },
  instructionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16
  },
  bubbleContainer: {
    flex: 1,
    backgroundColor: Colors.GREY6,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 12
  },
  handContainer: {
    width: 40,
    height: 20,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  hand: {
    width: 20,
    height: 20
  }
});
