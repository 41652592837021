import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { TextInputProps } from 'react-native';

import KeyboardInput from '../../../../../components/KeyboardInput';
import PillButton from '../../../../../components/PillButton';
import { QuestionBlock } from '../../../../../store/types';

interface KeyboardInputAnswerProps {
  block: QuestionBlock;
  completed: boolean;
  value: string;
  onValueChange: (value: string) => void;
  onComplete: () => void;
  validation?: { error: boolean; message: string };
  loading?: boolean;
}

const KeyboardInputAnswer: FunctionComponent<KeyboardInputAnswerProps> = (
  props
) => {
  const getKeyboardType: (
    block: QuestionBlock
  ) => TextInputProps['keyboardType'] = (block) => {
    switch (block.inputType) {
      case 'email':
        return 'email-address';
      case 'number':
        return 'numeric';
      case 'phone':
        return 'phone-pad';
      case 'text':
        return 'default';
      default:
        return 'default';
    }
  };
  const isMultiline = !('userConfig' in props.block);

  if (props.completed) {
    return (
      <PillButton disabled>
        {'userConfig' in props.block && props.block.userConfig === 'study'
          ? '*'.repeat(8)
          : props.block.answer}
      </PillButton>
    );
  }

  return (
    <>
      <KeyboardInput
        value={props.value as string}
        onChangeText={props.onValueChange}
        containerStyle={{ width: '100%' }}
        keyboardType={getKeyboardType(props.block)}
        hasValidation={props.validation?.error}
        errorText={props.validation?.message}
        multiline={isMultiline}
        onSubmitEditing={
          !isMultiline ? props.onComplete : undefined // NOTE(Mariana): If the block is user config, then pressing Enter should submit it
        }
      />
      <PillButton
        disabled={!props.value}
        onPress={() => props.onComplete()}
        style={{ marginTop: 8 }}
        loading={props.loading}
      >
        <FormattedMessage
          defaultMessage="Save"
          description="Save button text for blocks"
        />
      </PillButton>
    </>
  );
};

export default KeyboardInputAnswer;
