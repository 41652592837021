import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { MediaTypeOptions } from 'expo-image-picker';
import React, { FunctionComponent, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Image,
  Keyboard,
  Pressable,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  FlatList
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Colors from '../../../../colors';
import Drawer from '../../../../components/Drawer';
import KeyboardInput, {
  KeyboardInputRef
} from '../../../../components/KeyboardInput';
import MediumText from '../../../../components/MediumText';
import PillButton from '../../../../components/PillButton';
import RemovableImage from '../../../../components/RemovableImage';
import Slider from '../../../../components/Slider';
import Step from '../../../../components/Step';
import { useBackHandler } from '../../../../hooks/useBackHandler';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import { RootStackParamList } from '../../../RootNavigator';

export enum LetterSteps {
  LETTER_INTRO,
  LETTER_INPUT,
  LETTER_SLIDER,
  LETTER_THOUGHT_INPUT
}

interface Props {
  situation: string;
  stepIndex: number;
  setStepIndex: (val: number) => void;
}

const LetterToAFriendScreen: FunctionComponent<Props> = (props) => {
  const keyboardRef = useRef<KeyboardInputRef>(null);
  const route = useRoute<RouteProp<DrawerStackParamList, 'LetterToAFriend'>>();
  const [stepIndex, setStepIndex] = useState(
    route.params.stepIndex ?? LetterSteps.LETTER_INTRO
  );
  const [letter, setLetter] = useState<string>(
    route.params.values.letter ?? ''
  );
  const [pictureUris, setPictureUris] = useState<string[]>(
    route.params.values.files ?? []
  );
  const [thought, setThought] = useState(route.params.values?.thought ?? '');
  const [commitmentScale, setCommitmentScale] = useState<number | null>(
    route.params.values.commitmentScale ?? null
  );
  const intl = useIntl();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const insets = useSafeAreaInsets();

  const renderInstructionStep = () => {
    return (
      <ScrollView>
        <>
          <MediumText style={styles.situationText}>
            <MediumText style={styles.situationTitle}>Situation:</MediumText>{' '}
            {route.params.situation}
          </MediumText>
        </>
        <View style={styles.contentContainer}>
          <MediumText style={styles.title}>
            <FormattedMessage
              defaultMessage="Instruction:"
              description="The letter to a friend instruction"
            />{' '}
          </MediumText>
          <MediumText style={styles.instructionBody}>
            {route.params.instruction}
          </MediumText>
          <View style={styles.pillButtonContainer}>
            <PillButton
              onPress={() => {
                setStepIndex(stepIndex + 1);
              }}
            >
              <FormattedMessage defaultMessage="Next" description="Next" />
            </PillButton>
          </View>
        </View>
      </ScrollView>
    );
  };

  const renderInputStep = () => {
    return (
      <View style={styles.inputMainContainer}>
        <KeyboardAvoidingView
          style={styles.inputKeyboardAvoiding}
          behavior="padding"
          keyboardVerticalOffset={Platform.OS === 'ios' ? 130 : -200}
        >
          <Pressable
            style={styles.inputPressable}
            onPress={() => keyboardRef.current?.focus()}
          >
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={styles.inputScrollView}
            >
              {pictureUris.length > 0 && (
                <FlatList
                  data={pictureUris}
                  renderItem={({ item }) => (
                    <View style={styles.removableImageContainer}>
                      <RemovableImage
                        uri={item}
                        onDelete={() =>
                          setPictureUris(
                            pictureUris.filter((pic) => pic !== item)
                          )
                        }
                      />
                    </View>
                  )}
                />
              )}
              <KeyboardInput
                scrollEnabled={false}
                ref={keyboardRef}
                value={letter}
                onChangeText={setLetter}
                style={styles.keyboardInput}
                multiline
                noHeightLimit
                containerStyle={styles.inputKeyboardContainer}
                inputContainerStyle={styles.inputKeyboardSubContainer}
              />
            </ScrollView>
          </Pressable>
          <View
            style={[
              styles.bottomContainer,
              { marginBottom: insets.bottom + 10 }
            ]}
          >
            {Platform.OS !== 'web' && (
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('Drawer', {
                    screen: 'Camera',
                    params: {
                      setUri: (uri) => setPictureUris([...pictureUris, uri]),
                      mediaTypes: MediaTypeOptions.Images
                    }
                  });
                  Keyboard.dismiss();
                }}
              >
                <Image
                  source={require('./assets/camera.png')}
                  resizeMode="contain"
                  style={styles.cameraIcon}
                />
              </TouchableOpacity>
            )}
            <PillButton
              onPress={() => {
                Keyboard.dismiss();
                if (!route.params.rating) {
                  setStepIndex(stepIndex + 2);
                  return;
                }
                setStepIndex(stepIndex + 1);
              }}
              disabled={letter.length === 0 && pictureUris.length === 0}
            >
              <FormattedMessage defaultMessage="Next" description="Next" />
            </PillButton>
          </View>
        </KeyboardAvoidingView>
      </View>
    );
  };

  const renderSliderStep = () => {
    return (
      <View style={styles.sliderStepContainer}>
        <View />
        <View style={styles.sliderContainer}>
          <MediumText style={[styles.title, { marginBottom: 24 }]}>
            <FormattedMessage
              defaultMessage="Rate your credibility"
              description="Title of the credibility slider"
            />
          </MediumText>
          <Slider
            leftLabel={intl.formatMessage({
              defaultMessage: 'a little',
              description: 'Credibility slider left choice'
            })}
            rightLabel={intl.formatMessage({
              defaultMessage: 'a lot',
              description: 'Credibility slider right choice'
            })}
            minimumValue={0}
            maximumValue={100}
            step={1}
            value={commitmentScale ?? 50}
            onSlidingComplete={setCommitmentScale}
          />
        </View>
        <View style={styles.pillButtonContainer}>
          <PillButton
            disabled={commitmentScale === null}
            onPress={() => setStepIndex(stepIndex + 1)}
          >
            <FormattedMessage defaultMessage="Weiter" description="Next" />
          </PillButton>
        </View>
      </View>
    );
  };

  const renderThoughtStep = () => {
    return (
      <KeyboardAvoidingView
        style={styles.inputKeyboardAvoiding}
        behavior="padding"
        keyboardVerticalOffset={Platform.OS === 'ios' ? 130 : -200}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.inputScrollView}
          contentContainerStyle={[
            Platform.OS === 'web' && styles.webScrollViewContainer,
            { paddingTop: 12 }
          ]}
        >
          <MediumText style={styles.title}>
            <FormattedMessage
              defaultMessage="Instruction:"
              description="The letter to a friend instruction"
            />{' '}
          </MediumText>
          <MediumText style={styles.instructionBody}>
            {route.params.helpfulApproachInstruction}
          </MediumText>
          <KeyboardInput
            placeholder={intl.formatMessage({
              defaultMessage: 'Type in what you think',
              description: 'Input placeholder on cognitive triangle situation'
            })}
            onChangeText={setThought}
            value={thought}
          />
          <View style={styles.pillButtonContainer}>
            <PillButton
              onPress={() => {
                route.params.onFinish({
                  ...route.params.values,
                  thought,
                  commitmentScale: commitmentScale as number,
                  letter,
                  ...(pictureUris.length > 0 && { files: pictureUris })
                });
                navigation.goBack();
              }}
              disabled={thought?.length === 0}
            >
              <FormattedMessage defaultMessage="Next" description="Next" />
            </PillButton>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  };

  const isFirstScreen = () => {
    return stepIndex <= LetterSteps.LETTER_INTRO;
  };

  const regularBackwardNav = () => {
    if (
      !route.params.rating &&
      stepIndex === LetterSteps.LETTER_THOUGHT_INPUT
    ) {
      setStepIndex(stepIndex - 2);
      return;
    }
    setStepIndex(stepIndex - 1);
  };

  const { getDrawerIcon } = useBackHandler(
    isFirstScreen,
    regularBackwardNav,
    [stepIndex],
    navigation
  );

  return (
    <Drawer
      title={intl.formatMessage({
        defaultMessage: 'Letter to a friend',
        description: 'Letter to a friend drawer title'
      })}
      leftComponent={getDrawerIcon()}
      onBackgroundPress={() =>
        navigation.navigate('DrawerCloseExercise', {
          closeExercise: () => navigation.goBack()
        })
      }
    >
      <View style={styles.stepsContainer}>
        <Step current={stepIndex} step={LetterSteps.LETTER_INTRO}>
          {renderInstructionStep()}
        </Step>
        <Step current={stepIndex} step={LetterSteps.LETTER_INPUT}>
          {renderInputStep()}
        </Step>
        <Step current={stepIndex} step={LetterSteps.LETTER_SLIDER}>
          {renderSliderStep()}
        </Step>
        <Step current={stepIndex} step={LetterSteps.LETTER_THOUGHT_INPUT}>
          {renderThoughtStep()}
        </Step>
      </View>
    </Drawer>
  );
};

export default LetterToAFriendScreen;

const styles = StyleSheet.create({
  pillButtonContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginTop: 20
  },
  situationText: {
    marginTop: 34,
    marginHorizontal: 20,
    fontSize: 16
  },
  situationTitle: {
    fontSize: 16,
    color: Colors.GREY2
  },
  title: {
    fontSize: 18,
    marginBottom: 15
  },
  instructionBody: {
    fontSize: 16,
    marginBottom: 30,
    lineHeight: 26
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    marginTop: 20
  },
  bottomContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  arrow: {
    width: 30,
    resizeMode: 'contain'
  },
  sliderStepContainer: {
    height: '100%',
    justifyContent: 'center'
  },
  sliderContainer: {
    width: '100%',
    paddingHorizontal: 24,
    flex: 0.8,
    justifyContent: 'center'
  },
  picture: {
    marginHorizontal: 20,
    height: 200,
    resizeMode: 'contain',
    marginBottom: 10
  },
  cameraIcon: {
    width: 22,
    height: 22,
    marginRight: 24
  },
  keyboardInput: {
    borderWidth: 0,
    paddingHorizontal: 0
  },
  removableImageContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10
  },
  stepsContainer: {
    flex: 1,
    paddingHorizontal: 24
  },
  inputMainContainer: {
    flex: 1,
    marginTop: 12
  },
  inputScrollView: {
    flex: 1
  },
  inputKeyboardAvoiding: {
    flex: 1
  },
  inputPressable: {
    flex: 1
  },
  inputKeyboardContainer: {
    flex: 1
  },
  inputKeyboardSubContainer: {
    flex: 1
  },
  webScrollViewContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  }
});
