import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';

import Colors from '../colors';

interface Props {
  onSelect: () => void;
  selected: boolean;
}

const RadioButton = (props: Props) => {
  const handlePress = () => {
    props.onSelect();
  };

  return (
    <Pressable onPress={handlePress} style={styles.container}>
      <View
        style={[
          styles.radioButton,
          { borderColor: props.selected ? Colors.BLUE : Colors.GREY2 }
        ]}
      >
        {props.selected && <View style={styles.innerCircle} />}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  innerCircle: {
    backgroundColor: Colors.BLUE,
    borderRadius: 20,
    height: '70%',
    width: '70%'
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  radioButton: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 1,
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default RadioButton;
