import { NavigatorScreenParams } from '@react-navigation/native';
import {
  createStackNavigator,
  StackNavigationOptions
} from '@react-navigation/stack';
import React, { FunctionComponent, useEffect } from 'react';

import { useAppDispatch } from '../store/hooks';
import { fetchLanguages } from '../store/languages';
import AppNavigator, { AppStackParamList } from './AppNavigator';
import DrawerNavigator, { DrawerStackParamList } from './DrawerNavigator';
import Gallery from './Gallery';
import CalendarScreen from './Tab/Library/CalendarScreen';
import ConfirmationQuestionScreen from './Tab/Library/ConfirmationQuestionScreen';
import DrawerCloseExerciseScreen from './Tab/Library/DrawerCloseExerciseScreen';
import LifelineAddEventScreen from './Tab/Library/LifelineAddEventScreen';
import SliderPopupScreen from './Tab/Library/SliderPopupScreen';
import WrittenExposureWarningScreen from './Tab/Library/WrittenExposureWarningScreen';

export type RootStackParamList = {
  App: NavigatorScreenParams<AppStackParamList>;
  Module: undefined;
  Calendar: undefined;
  DrawerCloseExercise: {
    closeExercise: () => void;
    text?: string;
  };
  ConfirmationQuestion: {
    question?: string;
    affirmativeOption?: string;
    negativeOption?: string;
    onPressYes: () => void;
  };
  SliderPopup: { onConfirm: (val: number) => void; timeOut?: number };
  Drawer: NavigatorScreenParams<DrawerStackParamList>;
  Gallery: { uri: string };
  LifelineAddEvent: undefined;
  WrittenExposureWarning: {
    text: string;
    goToNextSlide: () => void;
  };
};

const RootStack = createStackNavigator<RootStackParamList>();

const RootNavigator: FunctionComponent = () => {
  const popupOptions: StackNavigationOptions = {
    animationEnabled: false
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchLanguages());
  }, []);

  return (
    <RootStack.Navigator
      initialRouteName="App"
      mode="modal"
      screenOptions={{
        headerShown: false,
        cardStyle: { backgroundColor: 'rgba(0,0,0,0.3)' }
      }}
    >
      <RootStack.Screen name="App" component={AppNavigator} />
      <RootStack.Screen
        name="Gallery"
        component={Gallery}
        options={{
          animationEnabled: false,
          cardStyle: {
            backgroundColor: 'transparent'
          }
        }}
      />
      <RootStack.Screen name="Drawer" component={DrawerNavigator} />
      <RootStack.Screen
        name="WrittenExposureWarning"
        component={WrittenExposureWarningScreen}
        options={popupOptions}
      />
      <RootStack.Screen
        name="LifelineAddEvent"
        component={LifelineAddEventScreen}
        options={popupOptions}
      />
      <RootStack.Screen
        name="Calendar"
        component={CalendarScreen}
        options={popupOptions}
      />
      <RootStack.Screen
        name="DrawerCloseExercise"
        component={DrawerCloseExerciseScreen}
        options={popupOptions}
      />
      <RootStack.Screen
        name="ConfirmationQuestion"
        component={ConfirmationQuestionScreen}
        options={popupOptions}
      />
      <RootStack.Screen
        name="SliderPopup"
        component={SliderPopupScreen}
        options={popupOptions}
      />
    </RootStack.Navigator>
  );
};

export default RootNavigator;
