import { Audio, AVPlaybackStatus } from 'expo-av';
import { useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';

import { useAppSelector } from '../store/hooks';

export enum StateType {
  Paused = 'paused',
  Playing = 'playing',
  Stopped = 'stopped'
}

type State = StateType.Paused | StateType.Playing | StateType.Stopped;

const useSound = (uri?: string) => {
  const [progress, setProgress] = useState<number>(0);
  const [sound, setSound] = useState<Audio.Sound>();
  const [state, setState] = useState<State>(StateType.Stopped);
  const [duration, setDuration] = useState(0);
  const userToken = useAppSelector((state) => state.user.token);
  const status = useRef<AVPlaybackStatus>();

  const setAudioMode = async () => {
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: true,
      playsInSilentModeIOS: true,
      staysActiveInBackground: false
    });
  };

  const enableRecording = async () => {
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: true
    });
  };

  const disableRecording = async () => {
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: false
    });
  };

  useEffect(() => {
    setAudioMode();
  }, []);

  // Initialise if URI changes.
  useEffect(() => {
    if (uri) {
      unloadSound().then(() =>
        Audio.Sound.createAsync(
          {
            uri,
            ...(!uri.includes('/cms/') && {
              headers: { Authorization: `Bearer ${userToken}` }
            })
          },
          undefined,
          onPlaybackStatusUpdate
        )
          .then(({ sound: createdSound, status }) => {
            setSound(createdSound);
            // Set duration
            if (Platform.OS === 'web') {
              // For web, status.durationMillis is broken and returns NaN, that's why we fallback to using the Web Audio API.
              const audioInstance = createdSound._key as HTMLMediaElement;
              audioInstance.addEventListener(
                'loadedmetadata',
                () => setDuration(audioInstance.duration * 1000),
                { once: true }
              );
            } else if (status.isLoaded && status.durationMillis) {
              setDuration(status.durationMillis);
            }
          })
          .catch((e) => console.error(e))
      );
    }
  }, [uri]);

  // Unload any existing sound.
  const unloadSound = async () => {
    if (status.current?.isLoaded) {
      await sound?.unloadAsync();
      setState(StateType.Stopped);
    }
  };

  const onPlaybackStatusUpdate = async (playbackStatus: AVPlaybackStatus) => {
    status.current = playbackStatus;
    if (playbackStatus.isLoaded) {
      setProgress(playbackStatus.positionMillis);
      // Handle audio finishing.
      if (playbackStatus.didJustFinish) {
        await enableRecording();
        setState(StateType.Stopped);
      }
    }
  };

  const playPause = async () => {
    if (status.current?.isLoaded) {
      if (status.current.isPlaying) {
        await enableRecording();
        sound?.pauseAsync();
        setState(StateType.Paused);
      } else if (state === StateType.Paused) {
        await disableRecording();
        sound?.playAsync();
        setState(StateType.Playing);
      } else {
        await disableRecording();
        sound?.replayAsync();
        setState(StateType.Playing);
      }
    }
  };

  const seek = async (seekTo: number) => {
    await sound?.setPositionAsync(seekTo);
  };

  return {
    state,
    playPause,
    progress,
    seek,
    duration,
    unloadSound
  };
};

export default useSound;
