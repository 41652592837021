import React, { FunctionComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Platform, I18nManager, Alert } from 'react-native';
import RNRestart from 'react-native-restart';

import usePrevious from './hooks/usePrevious';
import { isRtl } from './lang';
import { useAppSelector } from './store/hooks';
import { selectActiveLanguage } from './store/languages';

const RTL: FunctionComponent = () => {
  const { language } = useAppSelector(selectActiveLanguage);
  const prevLanguage = usePrevious(language);
  const intl = useIntl();

  useEffect(() => {
    I18nManager.forceRTL(isRtl(language));
    if (
      Platform.OS !== 'web' &&
      prevLanguage && // Check if this isn't the first render
      isRtl(prevLanguage) !== isRtl(language) // Check if there is a switch between writing directions
    ) {
      Alert.alert(
        intl.formatMessage({
          defaultMessage: 'App restart necessary',
          description: 'App restart notice title'
        }),
        intl.formatMessage({
          defaultMessage:
            'Since the writing direction will change, the app needs to be restarted',
          description: 'App restart notice text'
        }),
        [
          {
            text: intl.formatMessage({
              defaultMessage: 'Confirm',
              description: 'App restart notice confirm button'
            }),
            onPress: () => RNRestart.Restart()
          }
        ]
      );
    }
  }, [language]);

  return <></>;
};

export default RTL;
