import { useNavigation } from '@react-navigation/native';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, ScrollView } from 'react-native';

import Drawer from '../../components/Drawer';
import MediumText from '../../components/MediumText';
import SemiBoldText from '../../components/SemiBoldText';

const LegalDrawer: FunctionComponent<PropsWithChildren> = () => {
  const navigation = useNavigation();
  const intl = useIntl();

  const text = [
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Institution',
        description: 'Legal notice institution title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Arbeitsbereich Klinisch-Psychologische Intervention\nFreie Universität Berlin\nHabelschwerdter Allee 45\n14195 Berlin`,
        description: 'Legal notice institution text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Leitung',
        description: 'Legal notice leitung title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Prof. Dr. Christine Knaevelsrud\nTel: +49 (0)30 838-55736\nE-mail: klinpsy-int@psychologie.fu-berlin.de`,
        description: 'Legal notice leitung text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Umsatzsteueridentifikationsnummer',
        description: 'Legal notice Umsatzsteueridentifikationsnummer title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: 'DE 811304768',
        description: 'Legal notice Umsatzsteueridentifikationsnummer text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Kontakt bei Anfragen zu DIRECT',
        description: 'Legal notice direct contact title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Dipl.-Psych. Sebastian Burchert\nTel: +49 (0)30 838-57523\nE-mail: s.burchert@fu-berlin.de`,
        description: 'Legal notice direct contact text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Kontakt bei Anfragen zur Studie "ALMAMAR"',
        description: 'Legal notice study contact title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `M.Sc. Christina Wirz & M.Sc. Sophia Paskuy\nE-mail: almamar@ewi-psy.fu-berlin.de`,
        description: 'Legal notice study contact text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Technische Umsetzung',
        description: 'Legal notice technical implementation title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Hybrid Heroes GmbH\nAkazienstr. 3a\n10999 Berlin\nE-Mail: hello@hybridheroes.de`,
        description: 'Legal notice technical implementation text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Disclaimer',
        description: 'Legal notice disclaimer title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage:
          'Die Freie Universität Berlin ist eine Körperschaft des öffentlichen Rechts gem. §§ 1 und 2 Berliner Hochschulgesetz (BerlHG). Das Angebot auf der DIRECT-Plattform unterliegt dem geltenden Urheberrecht. Bitte beachten Sie, dass DIRECT nicht als Ersatz für professionelle medizinische oder psychologische Beratung, Diagnose oder Behandlung gedacht ist. DIRECT bietet Ihnen die Möglichkeit, an wissenschaftlichen Studien der Freien Universität Berlin teilzunehmen, in deren Rahmen Sie Strategien im Umgang mit psychischen Belastungen erlernen.',
        description: 'Legal notice disclaimer text'
      })
    }
  ];

  return (
    <Drawer
      onBackgroundPress={() => navigation.goBack()}
      title={intl.formatMessage({
        defaultMessage: 'Legal notice',
        description: 'Legal information drawer title'
      })}
    >
      <ScrollView style={styles.container}>
        {text.map(({ bold, msg }, idx) =>
          bold ? (
            <SemiBoldText key={`legal-policy-${idx}`}>{msg}</SemiBoldText>
          ) : (
            <MediumText key={`legal-policy-${idx}`} style={styles.text}>
              {msg}
            </MediumText>
          )
        )}
      </ScrollView>
    </Drawer>
  );
};

export default LegalDrawer;

const styles = StyleSheet.create({
  container: {
    padding: 16
  },
  arrow: {
    width: 30,
    height: 30,
    resizeMode: 'contain'
  },
  text: {
    marginTop: 6,
    marginBottom: 12
  }
});
