import React, { FunctionComponent } from 'react';
import { Image, StyleSheet, View, TouchableOpacity } from 'react-native';

import Colors from '../../../../colors';
import ProgressCircle from '../../../../components/ProgressCircle';
import { TriangleSteps } from './types';

interface Props {}

const WIDTH = 110;
const HEIGHT = (Math.sqrt(3) / 2) * WIDTH;

export enum Position {
  Top,
  Left,
  Right
}

interface Props {
  stepIndex: number;
  setStepIndex: (val: TriangleSteps) => void;
}

const Triangle: FunctionComponent<Props> = (props) => {
  const { stepIndex, setStepIndex } = props;
  const getPositions = () => {
    if (
      [
        TriangleSteps.UNHELPFUL_INTRO,
        TriangleSteps.UNHELPFUL_THOUGHT,
        TriangleSteps.HELPFUL_THOUGHT,
        TriangleSteps.HELPFUL_THOUGHT_REVIEW,
        TriangleSteps.THOUGHT_OVERVIEW
      ].includes(stepIndex)
    )
      return [Position.Top];
    if (
      [
        TriangleSteps.UNHELPFUL_FEELINGS,
        TriangleSteps.UNHELPFUL_FEELINGS_REVIEW,
        TriangleSteps.HELPFUL_FEELINGS,
        TriangleSteps.HELPFUL_FEELINGS_REVIEW,
        TriangleSteps.FEELINGS_OVERVIEW
      ].includes(stepIndex)
    )
      return [Position.Right];
    if (
      [
        TriangleSteps.UNHELPFUL_BEHAVIOUR,
        TriangleSteps.HELPFUL_BEHAVIOUR,
        TriangleSteps.BEHAVIOUR_OVERVIEW
      ].includes(stepIndex)
    )
      return [Position.Left];
    if (
      [
        TriangleSteps.UNHELPFUL_BEHAVIOUR_REVIEW,
        TriangleSteps.HELPFUL_BEHAVIOUR_REVIEW
      ].includes(stepIndex)
    )
      return [Position.Left, Position.Right, Position.Top];
    return [];
  };
  const getPercentage = () => {
    if (
      [
        TriangleSteps.UNHELPFUL_THOUGHT_REVIEW,
        TriangleSteps.UNHELPFUL_FEELINGS,
        TriangleSteps.HELPFUL_THOUGHT_REVIEW,
        TriangleSteps.HELPFUL_FEELINGS
      ].includes(stepIndex)
    )
      return 33;
    if (
      [
        TriangleSteps.UNHELPFUL_FEELINGS_REVIEW,
        TriangleSteps.UNHELPFUL_BEHAVIOUR,
        TriangleSteps.HELPFUL_FEELINGS_REVIEW,
        TriangleSteps.HELPFUL_BEHAVIOUR
      ].includes(stepIndex)
    )
      return 66;
    if (
      [
        TriangleSteps.HELPFUL_BEHAVIOUR_REVIEW,
        TriangleSteps.UNHELPFUL_BEHAVIOUR_REVIEW
      ].includes(stepIndex)
    )
      return 100;
    return 0;
  };
  const onIconPress = (position: Position) => {
    if (
      [
        TriangleSteps.THOUGHT_OVERVIEW,
        TriangleSteps.FEELINGS_OVERVIEW,
        TriangleSteps.BEHAVIOUR_OVERVIEW
      ].includes(stepIndex)
    ) {
      if (position === Position.Top)
        setStepIndex(TriangleSteps.THOUGHT_OVERVIEW);
      if (position === Position.Right)
        setStepIndex(TriangleSteps.FEELINGS_OVERVIEW);
      if (position === Position.Left)
        setStepIndex(TriangleSteps.BEHAVIOUR_OVERVIEW);
    }
  };

  const renderSmallCircle = (position: Position) => {
    const getCircleCoordinates = () => {
      if (position === Position.Top) {
        return styles.topCircle;
      }
      if (position === Position.Left) {
        return styles.leftCircle;
      }
      if (position === Position.Right) {
        return styles.rightCircle;
      }
    };
    const getIconCoordinates = () => {
      if (position === Position.Top) {
        return styles.topIcon;
      }
      if (position === Position.Left) {
        return styles.leftIcon;
      }
      if (position === Position.Right) {
        return styles.rightIcon;
      }
    };
    return (
      <>
        <View style={[styles.baseSmallCircle, getCircleCoordinates()]} />
        <TouchableOpacity
          hitSlop={{
            bottom: 25,
            top: 25,
            left: 25,
            right: 25
          }}
          activeOpacity={1}
          onPress={() => onIconPress(position)}
          style={[
            styles.baseSmallCircle,
            styles.smallCircleWhite,
            getCircleCoordinates()
          ]}
        />
        {getPositions().includes(position) && (
          <Image
            source={require('./statusIcon.png')}
            style={[styles.statusIcon, getIconCoordinates()]}
          />
        )}
      </>
    );
  };

  return (
    <View style={styles.container}>
      <ProgressCircle
        radius={WIDTH}
        activeColor={Colors.SURFGREEN}
        passiveColor={Colors.GREY5}
        percentage={getPercentage()}
      >
        <View style={styles.insideCircleContainer}>
          {renderSmallCircle(Position.Top)}
          {renderSmallCircle(Position.Left)}
          {renderSmallCircle(Position.Right)}
          <View style={styles.triangleContainer}>
            <View style={styles.triangle} />
            <View style={[styles.triangle, styles.triangleWhite]} />
          </View>
        </View>
      </ProgressCircle>
    </View>
  );
};

export default Triangle;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: WIDTH / 2,
    borderRightWidth: WIDTH / 2,
    borderBottomWidth: HEIGHT,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: Colors.SURFGREEN
  },
  insideCircleContainer: {
    height: WIDTH * 2,
    width: WIDTH * 2,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  triangleContainer: {
    top: -HEIGHT / 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  triangleWhite: {
    position: 'absolute',
    borderBottomColor: Colors.WHITE,
    top: 2.5,
    transform: [
      {
        scale: 0.85
      }
    ]
  },
  baseSmallCircle: {
    position: 'absolute',
    width: 30,
    height: 30,
    backgroundColor: Colors.GREY4,
    top: -30 / 2,
    borderRadius: 15
  },
  smallCircleWhite: {
    backgroundColor: Colors.WHITE,
    transform: [
      {
        scale: 0.75
      }
    ]
  },
  topCircle: {
    top: -20 / 2
  },
  leftCircle: {
    top: HEIGHT * 1.5,
    left: 2
  },
  rightCircle: {
    top: HEIGHT * 1.5,
    left: WIDTH * 2 - 35
  },
  statusIcon: {
    position: 'absolute',
    width: 20,
    height: 20,
    top: -20 / 2
  },
  topIcon: {
    top: -10 / 2
  },
  leftIcon: {
    top: HEIGHT * 1.5 + 5,
    left: 7
  },
  rightIcon: {
    top: HEIGHT * 1.5 + 5,
    left: WIDTH * 2 - 30
  }
});
