import React, { FunctionComponent } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Colors from '../colors';
import { Option } from '../store/types';
import MediumText from './MediumText';
import PillButton from './PillButton';

interface Props {
  // values, selected and onSelect parameter need to be of same type
  values: string[] | Option[];
  selected: string[] | Option[];
  onSelect: (val: string[] | Option[]) => void;
  style?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
}

const KeywordSelect: FunctionComponent<Props> = (props) => {
  const renderAnswerOption = (value: string | Option) => {
    if (typeof value === 'string') {
      const selected = props.selected as string[];
      const active = selected.includes(value);
      return (
        <PillButton
          key={value}
          style={[
            styles.baseButton,
            active ? styles.buttonActive : styles.buttonInactive,
            props.buttonStyle
          ]}
          onPress={() => {
            if (active) {
              props.onSelect(selected.filter((val) => val !== value));
            } else {
              props.onSelect([...selected, value]);
            }
          }}
        >
          <MediumText style={active ? styles.textActive : styles.textInactive}>
            {value}
          </MediumText>
        </PillButton>
      );
    } else {
      const selected = props.selected as Option[];
      const active = selected.map((option) => option.id).includes(value.id);
      return (
        <PillButton
          key={value.id}
          style={[
            styles.baseButton,
            active ? styles.buttonActive : styles.buttonInactive,
            props.buttonStyle
          ]}
          onPress={() => {
            if (active) {
              props.onSelect(
                selected.filter((option) => option.id !== value.id)
              );
            } else {
              props.onSelect([...selected, value]);
            }
          }}
        >
          <MediumText style={active ? styles.textActive : styles.textInactive}>
            {value.text}
          </MediumText>
        </PillButton>
      );
    }
  };

  return (
    <View style={[styles.container, props.style]}>
      {(props.values as any[]).map((value: string | Option) =>
        renderAnswerOption(value)
      )}
    </View>
  );
};

export default KeywordSelect;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  baseButton: {
    marginRight: 10,
    marginTop: 10
  },
  textInactive: {
    color: Colors.BLUE
  },
  textActive: {
    color: Colors.WHITE
  },
  buttonInactive: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: Colors.BLUE
  },
  buttonActive: {
    backgroundColor: Colors.BLUE,
    borderWidth: 1,
    borderColor: Colors.BLUE
  }
});
