import { ImageSourcePropType } from 'react-native';

export interface Country {
  flag: ImageSourcePropType;
  name: string;
  alpha2Code: string;
  callingCode: string;
}

const countries: Country[] = [
  {
    flag: require('./assets/AF.png'),
    name: 'Afghanistan',
    alpha2Code: 'AF',
    callingCode: '93'
  },
  {
    flag: require('./assets/AX.png'),
    name: 'Åland Islands',
    alpha2Code: 'AX',
    callingCode: '358'
  },
  {
    flag: require('./assets/AL.png'),
    name: 'Albania',
    alpha2Code: 'AL',
    callingCode: '355'
  },
  {
    flag: require('./assets/DZ.png'),
    name: 'Algeria',
    alpha2Code: 'DZ',
    callingCode: '213'
  },
  {
    flag: require('./assets/AD.png'),
    name: 'Andorra',
    alpha2Code: 'AD',
    callingCode: '376'
  },
  {
    flag: require('./assets/AO.png'),
    name: 'Angola',
    alpha2Code: 'AO',
    callingCode: '244'
  },
  {
    flag: require('./assets/AI.png'),
    name: 'Anguilla',
    alpha2Code: 'AI',
    callingCode: '1264'
  },
  {
    flag: require('./assets/AG.png'),
    name: 'Antigua and Barbuda',
    alpha2Code: 'AG',
    callingCode: '1268'
  },
  {
    flag: require('./assets/AR.png'),
    name: 'Argentina',
    alpha2Code: 'AR',
    callingCode: '54'
  },
  {
    flag: require('./assets/AM.png'),
    name: 'Armenia',
    alpha2Code: 'AM',
    callingCode: '374'
  },
  {
    flag: require('./assets/AW.png'),
    name: 'Aruba',
    alpha2Code: 'AW',
    callingCode: '297'
  },
  {
    flag: require('./assets/AU.png'),
    name: 'Australia',
    alpha2Code: 'AU',
    callingCode: '61'
  },
  {
    flag: require('./assets/AT.png'),
    name: 'Austria',
    alpha2Code: 'AT',
    callingCode: '43'
  },
  {
    flag: require('./assets/AZ.png'),
    name: 'Azerbaijan',
    alpha2Code: 'AZ',
    callingCode: '994'
  },
  {
    flag: require('./assets/BS.png'),
    name: 'Bahamas',
    alpha2Code: 'BS',
    callingCode: '1242'
  },
  {
    flag: require('./assets/BH.png'),
    name: 'Bahrain',
    alpha2Code: 'BH',
    callingCode: '973'
  },
  {
    flag: require('./assets/BD.png'),
    name: 'Bangladesh',
    alpha2Code: 'BD',
    callingCode: '880'
  },
  {
    flag: require('./assets/BB.png'),
    name: 'Barbados',
    alpha2Code: 'BB',
    callingCode: '1246'
  },
  {
    flag: require('./assets/BY.png'),
    name: 'Belarus',
    alpha2Code: 'BY',
    callingCode: '375'
  },
  {
    flag: require('./assets/BE.png'),
    name: 'Belgium',
    alpha2Code: 'BE',
    callingCode: '32'
  },
  {
    flag: require('./assets/BZ.png'),
    name: 'Belize',
    alpha2Code: 'BZ',
    callingCode: '501'
  },
  {
    flag: require('./assets/BJ.png'),
    name: 'Benin',
    alpha2Code: 'BJ',
    callingCode: '229'
  },
  {
    flag: require('./assets/BM.png'),
    name: 'Bermuda',
    alpha2Code: 'BM',
    callingCode: '1441'
  },
  {
    flag: require('./assets/BT.png'),
    name: 'Bhutan',
    alpha2Code: 'BT',
    callingCode: '975'
  },
  {
    flag: require('./assets/BO.png'),
    name: 'Bolivia',
    alpha2Code: 'BO',
    callingCode: '591'
  },
  {
    flag: require('./assets/BA.png'),
    name: 'Bosnia and Herzegovina',
    alpha2Code: 'BA',
    callingCode: '387'
  },
  {
    flag: require('./assets/BW.png'),
    name: 'Botswana',
    alpha2Code: 'BW',
    callingCode: '267'
  },
  {
    flag: require('./assets/BR.png'),
    name: 'Brazil',
    alpha2Code: 'BR',
    callingCode: '55'
  },
  {
    flag: require('./assets/VG.png'),
    name: 'British Virgin Islands',
    alpha2Code: 'VG',
    callingCode: '1284'
  },
  {
    flag: require('./assets/BN.png'),
    name: 'Brunei Darussalam',
    alpha2Code: 'BN',
    callingCode: '673'
  },
  {
    flag: require('./assets/BG.png'),
    name: 'Bulgaria',
    alpha2Code: 'BG',
    callingCode: '359'
  },
  {
    flag: require('./assets/BF.png'),
    name: 'Burkina Faso',
    alpha2Code: 'BF',
    callingCode: '226'
  },
  {
    flag: require('./assets/BI.png'),
    name: 'Burundi',
    alpha2Code: 'BI',
    callingCode: '257'
  },
  {
    flag: require('./assets/KH.png'),
    name: 'Cambodia',
    alpha2Code: 'KH',
    callingCode: '855'
  },
  {
    flag: require('./assets/CM.png'),
    name: 'Cameroon',
    alpha2Code: 'CM',
    callingCode: '237'
  },
  {
    flag: require('./assets/CA.png'),
    name: 'Canada',
    alpha2Code: 'CA',
    callingCode: '1'
  },
  {
    flag: require('./assets/CV.png'),
    name: 'Cabo Verde',
    alpha2Code: 'CV',
    callingCode: '238'
  },
  {
    flag: require('./assets/KY.png'),
    name: 'Cayman Islands',
    alpha2Code: 'KY',
    callingCode: '1345'
  },
  {
    flag: require('./assets/CF.png'),
    name: 'Central African Republic',
    alpha2Code: 'CF',
    callingCode: '236'
  },
  {
    flag: require('./assets/TD.png'),
    name: 'Chad',
    alpha2Code: 'TD',
    callingCode: '235'
  },
  {
    flag: require('./assets/CL.png'),
    name: 'Chile',
    alpha2Code: 'CL',
    callingCode: '56'
  },
  {
    flag: require('./assets/CN.png'),
    name: 'China',
    alpha2Code: 'CN',
    callingCode: '86'
  },
  {
    flag: require('./assets/CO.png'),
    name: 'Colombia',
    alpha2Code: 'CO',
    callingCode: '57'
  },
  {
    flag: require('./assets/KM.png'),
    name: 'Comoros',
    alpha2Code: 'KM',
    callingCode: '269'
  },
  {
    flag: require('./assets/CG.png'),
    name: 'Congo (Republic of)',
    alpha2Code: 'CG',
    callingCode: '242'
  },
  {
    flag: require('./assets/CD.png'),
    name: 'Congo (Democratic Republic)',
    alpha2Code: 'CD',
    callingCode: '243'
  },
  {
    flag: require('./assets/CR.png'),
    name: 'Costa Rica',
    alpha2Code: 'CR',
    callingCode: '506'
  },
  {
    flag: require('./assets/HR.png'),
    name: 'Croatia',
    alpha2Code: 'HR',
    callingCode: '385'
  },
  {
    flag: require('./assets/CU.png'),
    name: 'Cuba',
    alpha2Code: 'CU',
    callingCode: '53'
  },
  {
    flag: require('./assets/CY.png'),
    name: 'Cyprus',
    alpha2Code: 'CY',
    callingCode: '357'
  },
  {
    flag: require('./assets/CZ.png'),
    name: 'Czech Republic',
    alpha2Code: 'CZ',
    callingCode: '420'
  },
  {
    flag: require('./assets/DK.png'),
    name: 'Denmark',
    alpha2Code: 'DK',
    callingCode: '45'
  },
  {
    flag: require('./assets/DJ.png'),
    name: 'Djibouti',
    alpha2Code: 'DJ',
    callingCode: '253'
  },
  {
    flag: require('./assets/DM.png'),
    name: 'Dominica',
    alpha2Code: 'DM',
    callingCode: '1767'
  },
  {
    flag: require('./assets/DO.png'),
    name: 'Dominican Republic',
    alpha2Code: 'DO',
    callingCode: '1809'
  },
  {
    flag: require('./assets/EC.png'),
    name: 'Ecuador',
    alpha2Code: 'EC',
    callingCode: '593'
  },
  {
    flag: require('./assets/EG.png'),
    name: 'Egypt',
    alpha2Code: 'EG',
    callingCode: '20'
  },
  {
    flag: require('./assets/SV.png'),
    name: 'El Salvador',
    alpha2Code: 'SV',
    callingCode: '503'
  },
  {
    flag: require('./assets/GQ.png'),
    name: 'Equatorial Guinea',
    alpha2Code: 'GQ',
    callingCode: '240'
  },
  {
    flag: require('./assets/ER.png'),
    name: 'Eritrea',
    alpha2Code: 'ER',
    callingCode: '291'
  },
  {
    flag: require('./assets/EE.png'),
    name: 'Estonia',
    alpha2Code: 'EE',
    callingCode: '372'
  },
  {
    flag: require('./assets/ET.png'),
    name: 'Ethiopia',
    alpha2Code: 'ET',
    callingCode: '251'
  },
  {
    flag: require('./assets/FK.png'),
    name: 'Falkland Islands (Malvinas)',
    alpha2Code: 'FK',
    callingCode: '500'
  },
  {
    flag: require('./assets/FJ.png'),
    name: 'Fiji',
    alpha2Code: 'FJ',
    callingCode: '679'
  },
  {
    flag: require('./assets/FI.png'),
    name: 'Finland',
    alpha2Code: 'FI',
    callingCode: '358'
  },
  {
    flag: require('./assets/FR.png'),
    name: 'France',
    alpha2Code: 'FR',
    callingCode: '33'
  },
  {
    flag: require('./assets/PF.png'),
    name: 'French Polynesia',
    alpha2Code: 'PF',
    callingCode: '689'
  },
  {
    flag: require('./assets/GA.png'),
    name: 'Gabon',
    alpha2Code: 'GA',
    callingCode: '241'
  },
  {
    flag: require('./assets/GM.png'),
    name: 'Gambia',
    alpha2Code: 'GM',
    callingCode: '220'
  },
  {
    flag: require('./assets/GE.png'),
    name: 'Georgia',
    alpha2Code: 'GE',
    callingCode: '995'
  },
  {
    flag: require('./assets/DE.png'),
    name: 'Germany',
    alpha2Code: 'DE',
    callingCode: '49'
  },
  {
    flag: require('./assets/GH.png'),
    name: 'Ghana',
    alpha2Code: 'GH',
    callingCode: '233'
  },
  {
    flag: require('./assets/GI.png'),
    name: 'Gibraltar',
    alpha2Code: 'GI',
    callingCode: '350'
  },
  {
    flag: require('./assets/GR.png'),
    name: 'Greece',
    alpha2Code: 'GR',
    callingCode: '30'
  },
  {
    flag: require('./assets/GD.png'),
    name: 'Grenada',
    alpha2Code: 'GD',
    callingCode: '1473'
  },
  {
    flag: require('./assets/GT.png'),
    name: 'Guatemala',
    alpha2Code: 'GT',
    callingCode: '502'
  },
  {
    flag: require('./assets/GG.png'),
    name: 'Guernsey',
    alpha2Code: 'GG',
    callingCode: '44'
  },
  {
    flag: require('./assets/GN.png'),
    name: 'Guinea',
    alpha2Code: 'GN',
    callingCode: '224'
  },
  {
    flag: require('./assets/GW.png'),
    name: 'Guinea-Bissau',
    alpha2Code: 'GW',
    callingCode: '245'
  },
  {
    flag: require('./assets/GY.png'),
    name: 'Guyana',
    alpha2Code: 'GY',
    callingCode: '592'
  },
  {
    flag: require('./assets/HT.png'),
    name: 'Haiti',
    alpha2Code: 'HT',
    callingCode: '509'
  },
  {
    flag: require('./assets/HN.png'),
    name: 'Honduras',
    alpha2Code: 'HN',
    callingCode: '504'
  },
  {
    flag: require('./assets/HK.png'),
    name: 'Hong Kong',
    alpha2Code: 'HK',
    callingCode: '852'
  },
  {
    flag: require('./assets/HU.png'),
    name: 'Hungary',
    alpha2Code: 'HU',
    callingCode: '36'
  },
  {
    flag: require('./assets/IS.png'),
    name: 'Iceland',
    alpha2Code: 'IS',
    callingCode: '354'
  },
  {
    flag: require('./assets/IN.png'),
    name: 'India',
    alpha2Code: 'IN',
    callingCode: '91'
  },
  {
    flag: require('./assets/ID.png'),
    name: 'Indonesia',
    alpha2Code: 'ID',
    callingCode: '62'
  },
  {
    flag: require('./assets/CI.png'),
    name: "Côte d'Ivoire",
    alpha2Code: 'CI',
    callingCode: '225'
  },
  {
    flag: require('./assets/IR.png'),
    name: 'Iran',
    alpha2Code: 'IR',
    callingCode: '98'
  },
  {
    flag: require('./assets/IQ.png'),
    name: 'Iraq',
    alpha2Code: 'IQ',
    callingCode: '964'
  },
  {
    flag: require('./assets/IE.png'),
    name: 'Ireland',
    alpha2Code: 'IE',
    callingCode: '353'
  },
  {
    flag: require('./assets/IM.png'),
    name: 'Isle of Man',
    alpha2Code: 'IM',
    callingCode: '44'
  },
  {
    flag: require('./assets/IL.png'),
    name: 'Israel',
    alpha2Code: 'IL',
    callingCode: '972'
  },
  {
    flag: require('./assets/IT.png'),
    name: 'Italy',
    alpha2Code: 'IT',
    callingCode: '39'
  },
  {
    flag: require('./assets/JM.png'),
    name: 'Jamaica',
    alpha2Code: 'JM',
    callingCode: '1876'
  },
  {
    flag: require('./assets/JP.png'),
    name: 'Japan',
    alpha2Code: 'JP',
    callingCode: '81'
  },
  {
    flag: require('./assets/JE.png'),
    name: 'Jersey',
    alpha2Code: 'JE',
    callingCode: '44'
  },
  {
    flag: require('./assets/JO.png'),
    name: 'Jordan',
    alpha2Code: 'JO',
    callingCode: '962'
  },
  {
    flag: require('./assets/KZ.png'),
    name: 'Kazakhstan',
    alpha2Code: 'KZ',
    callingCode: '76'
  },
  {
    flag: require('./assets/KE.png'),
    name: 'Kenya',
    alpha2Code: 'KE',
    callingCode: '254'
  },
  {
    flag: require('./assets/KW.png'),
    name: 'Kuwait',
    alpha2Code: 'KW',
    callingCode: '965'
  },
  {
    flag: require('./assets/KG.png'),
    name: 'Kyrgyzstan',
    alpha2Code: 'KG',
    callingCode: '996'
  },
  {
    flag: require('./assets/LA.png'),
    name: 'Laos',
    alpha2Code: 'LA',
    callingCode: '856'
  },
  {
    flag: require('./assets/LV.png'),
    name: 'Latvia',
    alpha2Code: 'LV',
    callingCode: '371'
  },
  {
    flag: require('./assets/LB.png'),
    name: 'Lebanon',
    alpha2Code: 'LB',
    callingCode: '961'
  },
  {
    flag: require('./assets/LS.png'),
    name: 'Lesotho',
    alpha2Code: 'LS',
    callingCode: '266'
  },
  {
    flag: require('./assets/LR.png'),
    name: 'Liberia',
    alpha2Code: 'LR',
    callingCode: '231'
  },
  {
    flag: require('./assets/LY.png'),
    name: 'Libya',
    alpha2Code: 'LY',
    callingCode: '218'
  },
  {
    flag: require('./assets/LI.png'),
    name: 'Liechtenstein',
    alpha2Code: 'LI',
    callingCode: '423'
  },
  {
    flag: require('./assets/LT.png'),
    name: 'Lithuania',
    alpha2Code: 'LT',
    callingCode: '370'
  },
  {
    flag: require('./assets/LU.png'),
    name: 'Luxembourg',
    alpha2Code: 'LU',
    callingCode: '352'
  },
  {
    flag: require('./assets/MO.png'),
    name: 'Macao',
    alpha2Code: 'MO',
    callingCode: '853'
  },
  {
    flag: require('./assets/MK.png'),
    name: 'Macedonia',
    alpha2Code: 'MK',
    callingCode: '389'
  },
  {
    flag: require('./assets/MG.png'),
    name: 'Madagascar',
    alpha2Code: 'MG',
    callingCode: '261'
  },
  {
    flag: require('./assets/MW.png'),
    name: 'Malawi',
    alpha2Code: 'MW',
    callingCode: '265'
  },
  {
    flag: require('./assets/MY.png'),
    name: 'Malaysia',
    alpha2Code: 'MY',
    callingCode: '60'
  },
  {
    flag: require('./assets/MV.png'),
    name: 'Maldives',
    alpha2Code: 'MV',
    callingCode: '960'
  },
  {
    flag: require('./assets/ML.png'),
    name: 'Mali',
    alpha2Code: 'ML',
    callingCode: '223'
  },
  {
    flag: require('./assets/MT.png'),
    name: 'Malta',
    alpha2Code: 'MT',
    callingCode: '356'
  },
  {
    flag: require('./assets/MR.png'),
    name: 'Mauritania',
    alpha2Code: 'MR',
    callingCode: '222'
  },
  {
    flag: require('./assets/MU.png'),
    name: 'Mauritius',
    alpha2Code: 'MU',
    callingCode: '230'
  },
  {
    flag: require('./assets/MX.png'),
    name: 'Mexico',
    alpha2Code: 'MX',
    callingCode: '52'
  },
  {
    flag: require('./assets/FM.png'),
    name: 'Micronesia',
    alpha2Code: 'FM',
    callingCode: '691'
  },
  {
    flag: require('./assets/MD.png'),
    name: 'Moldova',
    alpha2Code: 'MD',
    callingCode: '373'
  },
  {
    flag: require('./assets/MC.png'),
    name: 'Monaco',
    alpha2Code: 'MC',
    callingCode: '377'
  },
  {
    flag: require('./assets/MN.png'),
    name: 'Mongolia',
    alpha2Code: 'MN',
    callingCode: '976'
  },
  {
    flag: require('./assets/ME.png'),
    name: 'Montenegro',
    alpha2Code: 'ME',
    callingCode: '382'
  },
  {
    flag: require('./assets/MA.png'),
    name: 'Morocco',
    alpha2Code: 'MA',
    callingCode: '212'
  },
  {
    flag: require('./assets/MZ.png'),
    name: 'Mozambique',
    alpha2Code: 'MZ',
    callingCode: '258'
  },
  {
    flag: require('./assets/MM.png'),
    name: 'Myanmar',
    alpha2Code: 'MM',
    callingCode: '95'
  },
  {
    flag: require('./assets/NA.png'),
    name: 'Namibia',
    alpha2Code: 'NA',
    callingCode: '264'
  },
  {
    flag: require('./assets/NP.png'),
    name: 'Nepal',
    alpha2Code: 'NP',
    callingCode: '977'
  },
  {
    flag: require('./assets/NL.png'),
    name: 'Netherlands',
    alpha2Code: 'NL',
    callingCode: '31'
  },
  {
    flag: require('./assets/NZ.png'),
    name: 'New Zealand',
    alpha2Code: 'NZ',
    callingCode: '64'
  },
  {
    flag: require('./assets/NI.png'),
    name: 'Nicaragua',
    alpha2Code: 'NI',
    callingCode: '505'
  },
  {
    flag: require('./assets/NE.png'),
    name: 'Niger',
    alpha2Code: 'NE',
    callingCode: '227'
  },
  {
    flag: require('./assets/NG.png'),
    name: 'Nigeria',
    alpha2Code: 'NG',
    callingCode: '234'
  },
  {
    flag: require('./assets/KP.png'),
    name: 'North Korea',
    alpha2Code: 'KP',
    callingCode: '850'
  },
  {
    flag: require('./assets/NO.png'),
    name: 'Norway',
    alpha2Code: 'NO',
    callingCode: '47'
  },
  {
    flag: require('./assets/OM.png'),
    name: 'Oman',
    alpha2Code: 'OM',
    callingCode: '968'
  },
  {
    flag: require('./assets/PK.png'),
    name: 'Pakistan',
    alpha2Code: 'PK',
    callingCode: '92'
  },
  {
    flag: require('./assets/PW.png'),
    name: 'Palau',
    alpha2Code: 'PW',
    callingCode: '680'
  },
  {
    flag: require('./assets/PA.png'),
    name: 'Panama',
    alpha2Code: 'PA',
    callingCode: '507'
  },
  {
    flag: require('./assets/PG.png'),
    name: 'Papua New Guinea',
    alpha2Code: 'PG',
    callingCode: '675'
  },
  {
    flag: require('./assets/PY.png'),
    name: 'Paraguay',
    alpha2Code: 'PY',
    callingCode: '595'
  },
  {
    flag: require('./assets/PE.png'),
    name: 'Peru',
    alpha2Code: 'PE',
    callingCode: '51'
  },
  {
    flag: require('./assets/PH.png'),
    name: 'Philippines',
    alpha2Code: 'PH',
    callingCode: '63'
  },
  {
    flag: require('./assets/PL.png'),
    name: 'Poland',
    alpha2Code: 'PL',
    callingCode: '48'
  },
  {
    flag: require('./assets/PT.png'),
    name: 'Portugal',
    alpha2Code: 'PT',
    callingCode: '351'
  },
  {
    flag: require('./assets/PR.png'),
    name: 'Puerto Rico',
    alpha2Code: 'PR',
    callingCode: '1787'
  },
  {
    flag: require('./assets/QA.png'),
    name: 'Qatar',
    alpha2Code: 'QA',
    callingCode: '974'
  },
  {
    flag: require('./assets/RO.png'),
    name: 'Romania',
    alpha2Code: 'RO',
    callingCode: '40'
  },
  {
    flag: require('./assets/RU.png'),
    name: 'Russian Federation',
    alpha2Code: 'RU',
    callingCode: '7'
  },
  {
    flag: require('./assets/RW.png'),
    name: 'Rwanda',
    alpha2Code: 'RW',
    callingCode: '250'
  },
  {
    flag: require('./assets/SH.png'),
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2Code: 'SH',
    callingCode: '290'
  },
  {
    flag: require('./assets/KN.png'),
    name: 'Saint Kitts and Nevis',
    alpha2Code: 'KN',
    callingCode: '1869'
  },
  {
    flag: require('./assets/LC.png'),
    name: 'Saint Lucia',
    alpha2Code: 'LC',
    callingCode: '1758'
  },
  {
    flag: require('./assets/VC.png'),
    name: 'Saint Vincent and the Grenadines',
    alpha2Code: 'VC',
    callingCode: '1784'
  },
  {
    flag: require('./assets/WS.png'),
    name: 'Samoa',
    alpha2Code: 'WS',
    callingCode: '685'
  },
  {
    flag: require('./assets/SM.png'),
    name: 'San Marino',
    alpha2Code: 'SM',
    callingCode: '378'
  },
  {
    flag: require('./assets/ST.png'),
    name: 'Sao Tome and Principe',
    alpha2Code: 'ST',
    callingCode: '239'
  },
  {
    flag: require('./assets/SA.png'),
    name: 'Saudi Arabia',
    alpha2Code: 'SA',
    callingCode: '966'
  },
  {
    flag: require('./assets/SN.png'),
    name: 'Senegal',
    alpha2Code: 'SN',
    callingCode: '221'
  },
  {
    flag: require('./assets/RS.png'),
    name: 'Serbia',
    alpha2Code: 'RS',
    callingCode: '381'
  },
  {
    flag: require('./assets/SC.png'),
    name: 'Seychelles',
    alpha2Code: 'SC',
    callingCode: '248'
  },
  {
    flag: require('./assets/SL.png'),
    name: 'Sierra Leone',
    alpha2Code: 'SL',
    callingCode: '232'
  },
  {
    flag: require('./assets/SG.png'),
    name: 'Singapore',
    alpha2Code: 'SG',
    callingCode: '65'
  },
  {
    flag: require('./assets/SK.png'),
    name: 'Slovakia',
    alpha2Code: 'SK',
    callingCode: '421'
  },
  {
    flag: require('./assets/SI.png'),
    name: 'Slovenia',
    alpha2Code: 'SI',
    callingCode: '386'
  },
  {
    flag: require('./assets/SB.png'),
    name: 'Solomon Islands',
    alpha2Code: 'SB',
    callingCode: '677'
  },
  {
    flag: require('./assets/SO.png'),
    name: 'Somalia',
    alpha2Code: 'SO',
    callingCode: '252'
  },
  {
    flag: require('./assets/ZA.png'),
    name: 'South Africa',
    alpha2Code: 'ZA',
    callingCode: '27'
  },
  {
    flag: require('./assets/KR.png'),
    name: 'South Korea',
    alpha2Code: 'KR',
    callingCode: '82'
  },
  {
    flag: require('./assets/ES.png'),
    name: 'Spain',
    alpha2Code: 'ES',
    callingCode: '34'
  },
  {
    flag: require('./assets/LK.png'),
    name: 'Sri Lanka',
    alpha2Code: 'LK',
    callingCode: '94'
  },
  {
    flag: require('./assets/SD.png'),
    name: 'Sudan',
    alpha2Code: 'SD',
    callingCode: '249'
  },
  {
    flag: require('./assets/SR.png'),
    name: 'Suriname',
    alpha2Code: 'SR',
    callingCode: '597'
  },
  {
    flag: require('./assets/SZ.png'),
    name: 'Swaziland',
    alpha2Code: 'SZ',
    callingCode: '268'
  },
  {
    flag: require('./assets/SE.png'),
    name: 'Sweden',
    alpha2Code: 'SE',
    callingCode: '46'
  },
  {
    flag: require('./assets/CH.png'),
    name: 'Switzerland',
    alpha2Code: 'CH',
    callingCode: '41'
  },
  {
    flag: require('./assets/SY.png'),
    name: 'Syrian Arab Republic',
    alpha2Code: 'SY',
    callingCode: '963'
  },
  {
    flag: require('./assets/TW.png'),
    name: 'Taiwan',
    alpha2Code: 'TW',
    callingCode: '886'
  },
  {
    flag: require('./assets/TJ.png'),
    name: 'Tajikistan',
    alpha2Code: 'TJ',
    callingCode: '992'
  },
  {
    flag: require('./assets/TZ.png'),
    name: 'Tanzania',
    alpha2Code: 'TZ',
    callingCode: '255'
  },
  {
    flag: require('./assets/TH.png'),
    name: 'Thailand',
    alpha2Code: 'TH',
    callingCode: '66'
  },
  {
    flag: require('./assets/TL.png'),
    name: 'Timor-Leste',
    alpha2Code: 'TL',
    callingCode: '670'
  },
  {
    flag: require('./assets/TG.png'),
    name: 'Togo',
    alpha2Code: 'TG',
    callingCode: '228'
  },
  {
    flag: require('./assets/TO.png'),
    name: 'Tonga',
    alpha2Code: 'TO',
    callingCode: '676'
  },
  {
    flag: require('./assets/TT.png'),
    name: 'Trinidad and Tobago',
    alpha2Code: 'TT',
    callingCode: '1868'
  },
  {
    flag: require('./assets/TN.png'),
    name: 'Tunisia',
    alpha2Code: 'TN',
    callingCode: '216'
  },
  {
    flag: require('./assets/TR.png'),
    name: 'Turkey',
    alpha2Code: 'TR',
    callingCode: '90'
  },
  {
    flag: require('./assets/TM.png'),
    name: 'Turkmenistan',
    alpha2Code: 'TM',
    callingCode: '993'
  },
  {
    flag: require('./assets/TC.png'),
    name: 'Turks and Caicos Islands',
    alpha2Code: 'TC',
    callingCode: '1649'
  },
  {
    flag: require('./assets/UG.png'),
    name: 'Uganda',
    alpha2Code: 'UG',
    callingCode: '256'
  },
  {
    flag: require('./assets/UA.png'),
    name: 'Ukraine',
    alpha2Code: 'UA',
    callingCode: '380'
  },
  {
    flag: require('./assets/AE.png'),
    name: 'United Arab Emirates',
    alpha2Code: 'AE',
    callingCode: '971'
  },
  {
    flag: require('./assets/GB.png'),
    name: 'United Kingdom',
    alpha2Code: 'GB',
    callingCode: '44'
  },
  {
    flag: require('./assets/US.png'),
    name: 'United States of America',
    alpha2Code: 'US',
    callingCode: '1'
  },
  {
    flag: require('./assets/UY.png'),
    name: 'Uruguay',
    alpha2Code: 'UY',
    callingCode: '598'
  },
  {
    flag: require('./assets/UZ.png'),
    name: 'Uzbekistan',
    alpha2Code: 'UZ',
    callingCode: '998'
  },
  {
    flag: require('./assets/VU.png'),
    name: 'Vanuatu',
    alpha2Code: 'VU',
    callingCode: '678'
  },
  {
    flag: require('./assets/VE.png'),
    name: 'Venezuela',
    alpha2Code: 'VE',
    callingCode: '58'
  },
  {
    flag: require('./assets/VN.png'),
    name: 'Viet Nam',
    alpha2Code: 'VN',
    callingCode: '84'
  },
  {
    flag: require('./assets/YE.png'),
    name: 'Yemen',
    alpha2Code: 'YE',
    callingCode: '967'
  },
  {
    flag: require('./assets/ZM.png'),
    name: 'Zambia',
    alpha2Code: 'ZM',
    callingCode: '260'
  },
  {
    flag: require('./assets/ZW.png'),
    name: 'Zimbabwe',
    alpha2Code: 'ZW',
    callingCode: '263'
  }
];

export default countries;
