import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Platform, ScrollView, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { v4 as uuidv4 } from 'uuid';

import { VictoryPie, VictoryContainer } from '../../../../../aux/victory';
import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import Message from '../../../../../components/Message';
import PillButton from '../../../../../components/PillButton';
import Slider from '../../../../../components/Slider';
import useAppWindowDimensions from '../../../../../hooks/useAppWindowDimensions';
import { ResponsibilityPieReason } from '../../../../../store/types';
import sharedStyles from './styles';

interface OwnResponsibilityPieProps {
  ownResponsibility: ResponsibilityPieReason;
  setOwnResponsibility: (responsibility: ResponsibilityPieReason) => void;
  onNext: () => void;
}

const OwnResponsibilityPie = ({
  ownResponsibility,
  setOwnResponsibility,
  onNext
}: OwnResponsibilityPieProps) => {
  const insets = useSafeAreaInsets();
  const { height: windowHeight } = useAppWindowDimensions();
  const intl = useIntl();

  const responsibilityValues = [
    intl.formatMessage({
      defaultMessage: 'Not at all',
      description: 'Not at all slider value'
    }),
    intl.formatMessage({
      defaultMessage: 'Very much',
      description: 'Very much slider value'
    })
  ];

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={[
        { paddingBottom: insets.bottom + 16 },
        sharedStyles.scrollViewContentContainer
      ]}
      style={[
        sharedStyles.scrollViewStyle,
        Platform.OS === 'web' && {
          height: windowHeight - 125
        }
      ]}
    >
      <Message
        component={
          <MediumText style={sharedStyles.messageInnerText}>
            <FormattedMessage
              description="Default initial instruction for the responsibility pie"
              defaultMessage="Initial instruction"
            />
          </MediumText>
        }
        style={sharedStyles.message}
        containerStyle={sharedStyles.messageContainer}
      />
      <View style={sharedStyles.pieContainer}>
        <VictoryPie
          data={[
            {
              x: ownResponsibility.reason,
              y: ownResponsibility.responsibility,
              fill: ownResponsibility.color
            },
            {
              x: 'Placeholder', // This label isn't displayed anywhere
              y: 100 - ownResponsibility.responsibility,
              fill: Colors.GREY5
            }
          ]}
          labels={() => null}
          // A fixed width and height limits the size of the pie chart in mobile (should be revisited)
          width={300}
          height={300}
          containerComponent={
            <VictoryContainer
              style={Platform.OS === 'web' ? { maxWidth: '40%' } : {}}
            />
          }
          style={{
            data: {
              fill: ({ datum }) => datum.fill
            }
          }}
        />
      </View>
      <ScrollView style={sharedStyles.slidersScroller}>
        <Slider
          key={uuidv4()}
          containerStyle={sharedStyles.slider}
          titleStyle={sharedStyles.sliderTitle}
          barStyle={sharedStyles.sliderBar}
          borderStyle={[
            sharedStyles.sliderBorder,
            { borderColor: ownResponsibility.color }
          ]}
          title={ownResponsibility.reason}
          maximumValue={ownResponsibility.maxResponsibility}
          minimumValue={0}
          onSlidingComplete={(responsibility) =>
            setOwnResponsibility({ ...ownResponsibility, responsibility })
          }
          value={ownResponsibility.responsibility}
          leftLabel={responsibilityValues[0]}
          rightLabel={responsibilityValues[1]}
          remainingPercentage={`${ownResponsibility.maxResponsibility}%`}
          minimumTrackTintColor="rgba(73, 85, 206, 0.25)" // Colors.BLUE at 25% opacity
          maximumTrackTintColor="rgba(73, 85, 206, 0.25)" // Colors.BLUE at 25% opacity
        />
      </ScrollView>
      <View style={sharedStyles.pillButtonContainer}>
        <PillButton onPress={onNext}>
          <FormattedMessage defaultMessage="Next" description="Next" />
        </PillButton>
      </View>
    </ScrollView>
  );
};

export default OwnResponsibilityPie;
