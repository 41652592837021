import React, { FunctionComponent } from 'react';
import { StyleSheet, TextProps } from 'react-native';

import Colors from '../colors';
import MediumText from '../components/MediumText';

interface Props extends TextProps {
  value: number;
}

// Displays milliseconds in digital format (Example: 02:32)
const TimeLabel: FunctionComponent<Props> = ({ value, ...props }) => {
  const minutes = Math.floor(value / 60000);
  const seconds = (value - minutes * 60000) / 1000;
  const secondsString = `0${Math.floor(seconds)}`.slice(-2); // Two-digit number.
  return (
    <MediumText
      {...props}
      style={[styles.text, props.style]}
    >{`${minutes}:${secondsString}`}</MediumText>
  );
};

const styles = StyleSheet.create({
  text: {
    color: Colors.WHITE
  }
});

export default TimeLabel;
