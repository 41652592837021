import { createStackNavigator } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import SettingsEmailScreen from './SettingsEmailScreen';
import SettingsLanguageScreen from './SettingsLanguageScreen';
import SettingsPasswordScreen from './SettingsPasswordScreen';
import SettingsPhoneScreen from './SettingsPhoneScreen';
import SettingsPinScreen from './SettingsPinScreen';
import SettingsScreen from './SettingsScreen';
import SettingsUserIdScreen from './SettingsUserIdScreen';
import SettingsHeader from './components/SettingsHeader';

export type SettingsStackParamList = {
  Settings: undefined;
  SettingsEmail: undefined;
  SettingsLanguage: undefined;
  SettingsPhone: undefined;
  SettingsPassword: undefined;
  SettingsPin: undefined;
  SettingsUserId: undefined;
};

const SettingsStack = createStackNavigator<SettingsStackParamList>();

const SettingsNavigator: FunctionComponent = () => {
  const intl = useIntl();

  return (
    <SettingsStack.Navigator
      initialRouteName="Settings"
      screenOptions={() => ({
        header: (props) => <SettingsHeader {...props} />
      })}
    >
      <SettingsStack.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          headerTitle: intl.formatMessage({
            defaultMessage: 'Settings',
            description: 'Settings header title'
          })
        }}
      />
      <SettingsStack.Screen
        name="SettingsEmail"
        component={SettingsEmailScreen}
        options={{
          headerTitle: intl.formatMessage({
            defaultMessage: 'Email address',
            description: 'Email address settings screen'
          })
        }}
      />
      <SettingsStack.Screen
        name="SettingsLanguage"
        component={SettingsLanguageScreen}
        options={{
          headerTitle: intl.formatMessage({
            defaultMessage: 'Language',
            description: 'Language settings screen'
          })
        }}
      />
      <SettingsStack.Screen
        name="SettingsPhone"
        component={SettingsPhoneScreen}
        options={{
          headerTitle: intl.formatMessage({
            defaultMessage: 'Phone number',
            description: 'Phone number settings screen'
          })
        }}
      />
      <SettingsStack.Screen
        name="SettingsPassword"
        component={SettingsPasswordScreen}
        options={{
          headerTitle: intl.formatMessage({
            defaultMessage: 'Password',
            description: 'Password settings screen'
          })
        }}
      />
      <SettingsStack.Screen
        name="SettingsPin"
        component={SettingsPinScreen}
        options={{
          headerTitle: intl.formatMessage({
            defaultMessage: 'Access PIN',
            description: 'Access PIN settings screen'
          })
        }}
      />
      <SettingsStack.Screen
        name="SettingsUserId"
        component={SettingsUserIdScreen}
        options={{
          headerTitle: intl.formatMessage({
            defaultMessage: 'User ID',
            description: 'User ID settings screen'
          })
        }}
      />
    </SettingsStack.Navigator>
  );
};

export default SettingsNavigator;
