import React, { FunctionComponent } from 'react';
import { Image, StyleSheet, View } from 'react-native';

import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import MessageComponent from '../../../../../components/Message';
import {
  DatedMessage,
  MessageOrigin,
  MessageType
} from '../../../../../store/types';

interface Props {
  message: DatedMessage;
  showDate: boolean;
}

const HelperMessage: FunctionComponent<Props> = ({ message, showDate }) => {
  const isUserMessage = message.origin === MessageOrigin.User;
  const dateString = message.date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  });
  const timeString = message.date.toLocaleTimeString('en', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });

  const renderMessage = () => {
    const content =
      message.type === MessageType.Text
        ? { text: message.text }
        : { file: message.file };
    return (
      <MessageComponent
        flipped={isUserMessage}
        style={isUserMessage ? styles.userMessage : styles.helperMessage}
        textStyle={isUserMessage ? styles.userText : {}}
        text={message.type === MessageType.Text ? message.text : undefined}
        audioBarTintColor={isUserMessage ? Colors.WHITE : Colors.BLUE}
        {...content}
      />
    );
  };

  return (
    <View style={[styles.container, isUserMessage && styles.alignRight]}>
      {showDate && (
        <MediumText style={[styles.label, styles.dateLabel]}>
          {dateString}
        </MediumText>
      )}
      <View style={styles.messageContainer}>
        {!isUserMessage && (
          <Image
            source={require('../assets/helper.png')}
            style={styles.helperIcon}
          />
        )}
        <View
          style={[styles.innerContainer, isUserMessage && styles.alignRight]}
        >
          {renderMessage()}
          <MediumText style={styles.label}>{timeString}</MediumText>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 13
  },
  messageContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  innerContainer: {
    flexShrink: 1
  },
  alignRight: {
    alignItems: 'flex-end',
    marginRight: 0
  },
  label: {
    color: Colors.GREY3,
    fontSize: 12,
    marginTop: 5
  },
  helperMessage: {
    backgroundColor: Colors.WHITE
  },
  helperIcon: {
    height: 24,
    width: 24,
    marginRight: 8,
    bottom: 24
  },
  userMessage: {
    backgroundColor: Colors.BLUE
  },
  userText: {
    color: Colors.WHITE
  },
  dateLabel: {
    alignSelf: 'center',
    marginBottom: 9
  }
});

export default HelperMessage;
