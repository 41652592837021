import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  StyleSheet,
  KeyboardAvoidingView,
  ScrollView,
  Keyboard,
  Platform,
  Pressable,
  InteractionManager
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import Colors from '../../../../colors';
import Drawer from '../../../../components/Drawer';
import { useBackHandler } from '../../../../hooks/useBackHandler';
import { saveExercise } from '../../../../store/exercises';
import { useAppDispatch } from '../../../../store/hooks';
import {
  CognitiveTriangleFlow,
  ExerciseType,
  LetterToAFriendExercise,
  ListExercise,
  ResponsibilityPieExercise
} from '../../../../store/types';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import { RootStackParamList } from '../../../RootNavigator';
import { LetterSteps } from '../LetterToAFriendScreen';
import { ResponsibilityPieSteps } from '../ResponsibilityPieScreen/ResponsibilityPieScreen';
import CognitiveTriangleStep from './CognitiveTriangleStep';
import { convertExerciseToLegacy, convertLegacyExerciseToNew } from './helpers';
import { CognitiveTriangleExerciseLegacy, TriangleSteps } from './types';

const CognitiveTriangleScreen: FunctionComponent = () => {
  const intl = useIntl();
  const route =
    useRoute<RouteProp<DrawerStackParamList, 'CognitiveTriangle'>>();
  const completedExercise = route.params?.completedExercise;
  const template = route.params?.template;

  const triangleFlow = (() => {
    if (completedExercise) {
      return CognitiveTriangleFlow.OVERVIEW;
    }
    if (
      !template ||
      !template.situations.length ||
      (template.situations.length === 1 &&
        template.situations[0]?.text.startsWith('MISSING TRANSLATION'))
    ) {
      return CognitiveTriangleFlow.OPEN;
    }
    if (template.situations.length === 1) {
      return CognitiveTriangleFlow.CLOSED;
    }
    if (template.situations.length > 1) {
      return CognitiveTriangleFlow.MIXED;
    }
    return CognitiveTriangleFlow.OPEN;
  })();

  const getClosedFlowInitialValues = (): CognitiveTriangleExerciseLegacy => {
    const missingDataText = intl.formatMessage({
      defaultMessage: 'Missing template data',
      description: 'Cognitive Triangle missing template data placeholder'
    });

    return {
      situation: template?.situations[0]?.text ?? missingDataText,
      helpful: {
        thought:
          template?.situations[0]?.helpful.thoughts[0]?.text ?? missingDataText,
        behavior:
          template?.situations[0]?.helpful.behaviors[0]?.text ??
          missingDataText,
        feelings: template?.situations[0]?.helpful.feelings[0]?.text
          ? [
              {
                name: template?.situations[0]?.helpful.feelings[0]?.text,
                scale: 50
              }
            ]
          : [
              {
                name: missingDataText,
                scale: 50
              }
            ]
      },
      unhelpful: {
        thought:
          template?.situations[0]?.unhelpful.thoughts[0]?.text ??
          missingDataText,
        behavior:
          template?.situations[0]?.unhelpful.behaviors[0]?.text ??
          missingDataText,
        feelings: template?.situations[0]?.unhelpful.feelings[0]?.text
          ? [
              {
                name: template?.situations[0]?.unhelpful.feelings[0]?.text,
                scale: 50
              }
            ]
          : [
              {
                name: missingDataText,
                scale: 50
              }
            ]
      }
    };
  };

  const getInitialValues = (): CognitiveTriangleExerciseLegacy => {
    if (triangleFlow === CognitiveTriangleFlow.OVERVIEW && completedExercise)
      return convertExerciseToLegacy(completedExercise);
    if (triangleFlow === CognitiveTriangleFlow.CLOSED)
      return getClosedFlowInitialValues();
    return {
      situation: '',
      helpful: {
        thought: '',
        feelings: [],
        behavior: ''
      },
      unhelpful: {
        thought: '',
        feelings: [],
        behavior: ''
      }
    } as CognitiveTriangleExerciseLegacy;
  };

  const [exerciseValues, setExerciseValues] =
    useState<CognitiveTriangleExerciseLegacy>({
      ...getInitialValues(),
      id: uuidv4()
    });
  const [letterToAFriendValues, setLetterToAFriendValues] =
    useState<LetterToAFriendExercise>({
      thought: exerciseValues.helpful.thought,
      commitmentScale: 0,
      letter: '',
      type: ExerciseType.LetterToAFriend,
      id: uuidv4(),
      createdAt: new Date().toISOString()
    });

  const [responsibilityPieExercise, setResponsibilityPieExercise] =
    useState<ResponsibilityPieExercise>({
      reasons: [],
      type: ExerciseType.ResponsibilityPie,
      id: uuidv4(),
      linkedCognitiveTriangleId: exerciseValues.id,
      createdAt: new Date().toISOString()
    });

  const [listExercise, setListExercise] = useState<ListExercise>({
    items: [],
    type: ExerciseType.ListExercise,
    id: uuidv4(),
    createdAt: new Date().toISOString(),
    templateId: template?.linkedListExerciseTemplate?.id ?? 0
  });

  const [stepIndex, setStepIndex] = useState<TriangleSteps>(
    triangleFlow === CognitiveTriangleFlow.OVERVIEW
      ? TriangleSteps.THOUGHT_OVERVIEW
      : TriangleSteps.UNHELPFUL_INTRO
  );

  const scrollViewRef = useRef<ScrollView>(null);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const dispatch = useAppDispatch();

  const handleSetStepIndex = (val: number) => {
    setStepIndex(val);
    scrollViewRef.current?.scrollTo({ y: 0, animated: false });
  };

  const getDrawerTitle = () => {
    if (stepIndex <= TriangleSteps.UNHELPFUL_BEHAVIOUR_REVIEW) {
      return intl.formatMessage({
        defaultMessage: 'Unhelpful thought',
        description: 'Unhelpful drawer title'
      });
    }
    if (stepIndex <= TriangleSteps.HELPFUL_BEHAVIOUR_REVIEW) {
      return intl.formatMessage({
        defaultMessage: 'Helpful thought',
        description: 'Helpful drawer title'
      });
    }
    return intl.formatMessage({
      defaultMessage: 'Summary',
      description: 'Summary drawer title'
    });
  };

  const isFirstScreen = () => {
    const isOverviewFlow = triangleFlow === CognitiveTriangleFlow.OVERVIEW;

    return isOverviewFlow
      ? stepIndex <= TriangleSteps.THOUGHT_OVERVIEW
      : stepIndex <= TriangleSteps.UNHELPFUL_INTRO;
  };

  const renderTriangleStep = () => (
    <CognitiveTriangleStep
      values={exerciseValues}
      stepIndex={stepIndex}
      flow={triangleFlow}
      template={template}
      setStepIndex={handleSetStepIndex}
      onSelect={(val) => setExerciseValues(val)}
      onSave={handleSave}
      onFinish={handleFinish}
      openExercise={(type: ExerciseType) => {
        if (type === ExerciseType.LetterToAFriend) {
          navigateToLetterToAFriendExercise();
        } else if (type === ExerciseType.ResponsibilityPie) {
          navigateToResponsibilityPieExercise();
        } else if (type === ExerciseType.ListExercise) {
          navigateToListExercise();
        }
      }}
    />
  );

  const handleSave = () => {
    dispatch(
      saveExercise(convertLegacyExerciseToNew(exerciseValues, template))
    );
    letterToAFriendValues.letter.length > 0 &&
      dispatch(saveExercise(letterToAFriendValues));
    responsibilityPieExercise.reasons.length !== 0 &&
      dispatch(saveExercise(responsibilityPieExercise));
    listExercise.items.length !== 0 && dispatch(saveExercise(listExercise));
  };

  const handleFinish = () => {
    navigation.goBack();
    route.params?.onFinish?.();
  };

  const navigateToResponsibilityPieExercise = () => {
    const backwardsNavigation = stepIndex === TriangleSteps.HELPFUL_INTRO;

    navigation.navigate('Drawer', {
      screen: 'ResponsibilityPie',
      params: {
        stepIndex: backwardsNavigation ? ResponsibilityPieSteps.SUMMARY : 0,
        linkedCognitiveTriangleId:
          responsibilityPieExercise.linkedCognitiveTriangleId,
        reasons: responsibilityPieExercise.reasons,
        instruction: template?.instructionLinkedExercise ?? '',
        onFinish: (exercise) => {
          setStepIndex(TriangleSteps.HELPFUL_INTRO);
          setResponsibilityPieExercise({
            ...exercise,
            reasons: exercise.reasons.map((reason) => ({ ...reason }))
          });
          setExerciseValues({
            ...exerciseValues,
            linkedExerciseId: exercise.id
          });
        },
        // The onBack handler takes care of saving the exercise if we go back from the pie to the first section of the Cognitive Triangle (e.g. complete the first section -> complete the pie -> go back to the first section)
        //                                                                                                                                                                                                       ^-- We want the values from the pie to be saved when travelling backwards
        onBack: (exercise) => {
          setResponsibilityPieExercise({
            ...exercise,
            reasons: exercise.reasons.map((reason) => ({ ...reason }))
          });
          setExerciseValues({
            ...exerciseValues,
            linkedExerciseId: exercise.id
          });
        }
      }
    });

    if (backwardsNavigation)
      InteractionManager.runAfterInteractions(() =>
        setStepIndex(TriangleSteps.UNHELPFUL_BEHAVIOUR_REVIEW)
      );
  };

  const navigateToLetterToAFriendExercise = () => {
    const backwardsNavigation =
      stepIndex === TriangleSteps.HELPFUL_THOUGHT_REVIEW;

    navigation.navigate('Drawer', {
      screen: 'LetterToAFriend',
      params: {
        stepIndex: backwardsNavigation ? LetterSteps.LETTER_THOUGHT_INPUT : 0,
        values: letterToAFriendValues,
        situation: exerciseValues.situation,
        instruction: template?.instructionLinkedExercise ?? '',
        helpfulApproachInstruction: template?.instructionHelpfulApproach ?? '',
        rating: template?.letterToAFriendRating ?? true,
        onFinish: (letterExercise) => {
          setStepIndex(TriangleSteps.HELPFUL_THOUGHT_REVIEW);
          setLetterToAFriendValues(letterExercise);
          setExerciseValues({
            ...exerciseValues,
            helpful: {
              ...exerciseValues.helpful,
              thought: letterExercise.thought
            },
            linkedExerciseId: letterExercise.id
          });
        }
      }
    });

    if (backwardsNavigation)
      InteractionManager.runAfterInteractions(() =>
        setStepIndex(TriangleSteps.UNHELPFUL_BEHAVIOUR_REVIEW)
      );
  };

  const navigateToListExercise = () => {
    const backwardsNavigation = stepIndex === TriangleSteps.HELPFUL_INTRO;

    if (!template?.linkedListExerciseTemplate) {
      alert('Linked list exercise template missing.');
      return;
    }

    navigation.navigate('Drawer', {
      screen: template.linkedListExerciseTemplate.complexList
        ? 'ListExercise'
        : 'SimpleListExercise',
      params: {
        template: template.linkedListExerciseTemplate,
        instructionLinkedExercise: template?.instructionLinkedExercise,
        items: listExercise.items.length ? listExercise.items : undefined,
        preventSaving: true,
        onFinish: (exercise: ListExercise) => {
          setStepIndex(TriangleSteps.HELPFUL_INTRO);
          setListExercise({
            ...exercise,
            items: exercise.items.map((item) => ({ ...item }))
          });
          setExerciseValues({
            ...exerciseValues,
            linkedExerciseId: exercise.id
          });
        },
        onBack: (exercise: ListExercise) => {
          setListExercise({
            ...exercise,
            items: exercise.items.map((item) => ({ ...item }))
          });
          setExerciseValues({
            ...exerciseValues,
            linkedExerciseId: exercise.id
          });
        }
      }
    });

    if (backwardsNavigation)
      InteractionManager.runAfterInteractions(() =>
        setStepIndex(TriangleSteps.UNHELPFUL_BEHAVIOUR_REVIEW)
      );
  };

  const renderExerciseContent = () => {
    return (
      <ScrollView
        keyboardShouldPersistTaps="always"
        automaticallyAdjustContentInsets={false}
        contentContainerStyle={styles.scrollViewContentContainer}
        style={styles.scrollViewContainer}
        ref={scrollViewRef}
      >
        <Pressable onPress={() => Platform.OS !== 'web' && Keyboard.dismiss()}>
          {renderTriangleStep()}
        </Pressable>
      </ScrollView>
    );
  };

  const regularBackwardNav = () => {
    if (
      stepIndex === TriangleSteps.HELPFUL_INTRO &&
      route.params?.template?.linkedExercise === ExerciseType.ResponsibilityPie
    ) {
      return navigateToResponsibilityPieExercise();
    } else if (
      stepIndex === TriangleSteps.HELPFUL_THOUGHT_REVIEW &&
      route.params?.template?.linkedExercise === ExerciseType.LetterToAFriend
    ) {
      return navigateToLetterToAFriendExercise();
    } else if (
      stepIndex === TriangleSteps.HELPFUL_INTRO &&
      route.params?.template?.linkedExercise === ExerciseType.ListExercise
    ) {
      return navigateToListExercise();
    }
    handleSetStepIndex(stepIndex - 1);
  };

  const { getDrawerIcon } = useBackHandler(
    isFirstScreen,
    regularBackwardNav,
    [stepIndex],
    navigation
  );

  return (
    <Drawer
      title={getDrawerTitle()}
      leftComponent={getDrawerIcon()}
      onBackgroundPress={() =>
        navigation.navigate('DrawerCloseExercise', {
          closeExercise: () => navigation.goBack()
        })
      }
    >
      {Platform.OS === 'web' ? (
        renderExerciseContent()
      ) : (
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'position' : undefined}
          keyboardVerticalOffset={Platform.OS === 'ios' ? 120 : 0}
        >
          {renderExerciseContent()}
        </KeyboardAvoidingView>
      )}
    </Drawer>
  );
};

const styles = StyleSheet.create({
  scrollViewContainer: {
    backgroundColor: Colors.WHITE,
    width: '100%',
    overflow: Platform.OS === 'android' ? 'scroll' : 'visible'
  },
  scrollViewContentContainer: {
    flexGrow: 1,
    paddingHorizontal: 24
  },
  arrow: {
    width: 30,
    height: 30,
    resizeMode: 'contain'
  }
});

export default CognitiveTriangleScreen;
