import React, { FunctionComponent } from 'react';
import { StyleSheet, View } from 'react-native';

import Colors from '../../../../colors';
import MediumText from '../../../../components/MediumText';
import Message from '../../../../components/Message';

interface Props {
  title: string;
  body: string;
  isHelpful?: boolean;
}

const ReviewMessage: FunctionComponent<Props> = ({
  title,
  body,
  isHelpful
}) => {
  return (
    <View style={[styles.container, isHelpful && { alignSelf: 'flex-end' }]}>
      <Message
        text={
          <>
            <MediumText style={{ color: Colors.GREY2, fontSize: 14 }}>
              {`${title}\n`}
            </MediumText>
            <MediumText style={{ fontSize: 16 }}>{body}</MediumText>
          </>
        }
        flipped={isHelpful}
        containerStyle={styles.messageContainer}
        style={isHelpful ? styles.reviewMessageRight : styles.reviewMessage}
      />
    </View>
  );
};

export default ReviewMessage;

const styles = StyleSheet.create({
  container: {
    marginBottom: 10
  },
  messageContainer: {
    shadowOpacity: 0
  },
  reviewMessage: {
    backgroundColor: Colors.MELONOPAQUE
  },
  reviewMessageRight: {
    backgroundColor: Colors.BLONDEOPAQUE
  }
});
