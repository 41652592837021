import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  View,
  StyleSheet,
  TextInputProps,
  KeyboardAvoidingView,
  Platform
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import Colors from '../colors';
import KeyboardInput from './KeyboardInput';
import MediumText from './MediumText';

interface InputDrawerProps extends TextInputProps {
  onSave?: (input: string) => void;
  onChangeText?: (input: string) => void;
  onCancel?: () => void;
}

const InputDrawer: FunctionComponent<InputDrawerProps> = (props) => {
  const [inputText, setInputText] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (text: string) => {
    setInputText(text);
    props.onChangeText?.(text);
    setIsDisabled(text.length === 0 || !/\S/.test(text));
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'position' : undefined}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 100 : 0}
    >
      <View style={styles.bottomInputAbsoluteContainer}>
        <View style={styles.bottomInputContainer}>
          <TouchableOpacity
            onPressIn={() => {
              props.onCancel?.();
              setInputText('');
            }}
          >
            <MediumText style={styles.text}>
              <FormattedMessage defaultMessage="Cancel" description="Cancel" />
            </MediumText>
          </TouchableOpacity>
          <TouchableOpacity
            disabled={isDisabled}
            onPress={() => {
              props.onSave?.(inputText);
              setInputText('');
            }}
          >
            <MediumText style={isDisabled ? styles.textDisabled : styles.text}>
              <FormattedMessage defaultMessage="Done" description="Done" />
            </MediumText>
          </TouchableOpacity>
        </View>
        <View style={styles.bottomInput}>
          <KeyboardInput
            multiline
            style={{ color: Colors.BLUE }}
            value={inputText}
            onChangeText={(t) => handleChange(t)}
            autoFocus
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default InputDrawer;

const styles = StyleSheet.create({
  pillButtonContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginTop: 20
  },
  plusButton: {
    width: 30,
    height: 30,
    borderColor: Colors.GREY4,
    borderWidth: 1,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12
  },
  text: {
    color: Colors.BLUE,
    lineHeight: 26
  },
  textDisabled: {
    color: Colors.GREY3,
    lineHeight: 26
  },
  bottomInputAbsoluteContainer: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: Colors.WHITE,
    minHeight: 110,
    width: '100%',
    borderColor: Colors.GREY5,
    shadowColor: Colors.GREY5,
    shadowRadius: 8,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    zIndex: 1
  },
  bottomInputContainer: {
    backgroundColor: Colors.WHITE,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    borderWidth: 1,
    borderColor: Colors.GREY5,
    // shadowColor: Colors.GREY5,
    // shadowRadius: 8,
    paddingVertical: 12,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingHorizontal: 14
  },
  bottomInput: {
    backgroundColor: Colors.WHITE,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 16
  }
});
