import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp, useHeaderHeight } from '@react-navigation/stack';
import React, { FunctionComponent, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  StyleSheet,
  View
} from 'react-native';

import Colors from '../../../colors';
import AlertBanner from '../../../components/AlertBanner';
import MediumText from '../../../components/MediumText';
import PillButton from '../../../components/PillButton';
import SemiBoldText from '../../../components/SemiBoldText';
import env from '../../../env';
import { AuthStackParamList } from '../AuthenticationNavigator';
import CodeField from './components/CodeField';

const VerificationCodeScreen: FunctionComponent = () => {
  const navigation = useNavigation<StackNavigationProp<AuthStackParamList>>();
  const route = useRoute<RouteProp<AuthStackParamList, 'VerificationCode'>>();
  const intl = useIntl();
  const headerHeight = useHeaderHeight();
  const [resendBannerVisible, setResendBannerVisible] = useState(false);

  const {
    control,
    watch,
    setError,
    formState: { errors }
  } = useForm<{ code: string }>();
  const code = useRef('');
  code.current = watch('code', '');

  const getContactOption = () => {
    if (route.params.contactOption === 'email') {
      return intl.formatMessage({ defaultMessage: 'Email' });
    }
    if (route.params.contactOption === 'sms') {
      return intl.formatMessage({ defaultMessage: 'SMS' });
    }
    return '';
  };

  const unknownErrorText = intl.formatMessage({
    description: 'VerificationCodeScreen unknown error',
    defaultMessage: 'Something went wrong. Please try again later.'
  });

  const showResendBanner = () => {
    setResendBannerVisible(true);
    const timer = setTimeout(() => setResendBannerVisible(false), 5000);
    return () => clearTimeout(timer);
  };

  const handleReceivePress = async () => {
    const res = await fetch(`${env.API_BASE_URL}/auth/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: code.current,
        password: ''
      })
    });

    const json = await res.json();

    if (json.code === 'ERR_TOKEN_INVALID') {
      setError('code', {
        message: intl.formatMessage({
          description: 'VerificationCodeScreen invalid input error',
          defaultMessage: 'Code is invalid. Please try again.'
        })
      });
    } else if (json.code === 'ERR_VALIDATION') {
      navigation.navigate('NewPassword', { code: code.current });
    } else {
      alert(unknownErrorText);
    }
  };

  const handleResendPress = async () => {
    const reqBody =
      route.params.contactOption === 'email'
        ? {
            email: route.params.contactInfo,
            type: 'reset-password-email'
          }
        : {
            phone: route.params.contactInfo,
            type: 'reset-password-sms'
          };

    const res = await fetch(`${env.API_BASE_URL}/tokens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqBody)
    });

    if (res.status === 201) {
      showResendBanner();
    } else {
      alert(unknownErrorText);
    }
  };

  const renderResendBanner = () => (
    <AlertBanner
      bannerVisible={resendBannerVisible}
      image={require('../../../assets/loginFailed.png')}
      headlineText={intl.formatMessage({
        defaultMessage: 'Code resent',
        description: 'Code resent banner headline'
      })}
      text={intl.formatMessage(
        {
          defaultMessage: 'We sent you a new code via {contactOption}.',
          description: 'Code resent banner text'
        },
        { contactOption: getContactOption() }
      )}
    />
  );

  return (
    <Pressable
      style={styles.container}
      onPress={Platform.OS === 'web' ? undefined : Keyboard.dismiss}
    >
      {renderResendBanner()}
      <KeyboardAvoidingView
        behavior="position"
        keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 80 + headerHeight}
      >
        <View style={styles.textContainer}>
          <SemiBoldText style={styles.headlineText}>
            <FormattedMessage
              defaultMessage="Enter Code"
              description="VerificationCodeScreen headline"
            />
          </SemiBoldText>
          <MediumText style={styles.text}>
            <FormattedMessage
              defaultMessage="Please enter your code, which you received by {contactOption} to {contactInfo}."
              description="VerificationCodeScreen prompt"
              values={{
                contactOption: getContactOption(),
                contactInfo: route.params.contactInfo
              }}
            />
          </MediumText>
        </View>

        <View style={{ marginTop: 125 }}>
          <Controller
            control={control}
            name="code"
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <CodeField
                value={value}
                onChange={onChange}
                hasValidation
                errorText={errors.code?.message}
                errorTextStyle={styles.errorTextStyle}
              />
            )}
          />
          <View style={styles.buttonContainer}>
            <PillButton
              style={styles.lineButton}
              textStyle={{ color: Colors.BLUE }}
              onPress={handleResendPress}
            >
              <FormattedMessage
                defaultMessage="Resend"
                description="VerificationCodeScreen Resend button label"
              />
            </PillButton>
            <PillButton style={styles.pillButton} onPress={handleReceivePress}>
              <FormattedMessage
                defaultMessage="Reset password"
                description="VerificationCodeScreen Reset password button label"
              />
            </PillButton>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Pressable>
  );
};

export default VerificationCodeScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  textContainer: {
    marginTop: 18,
    marginHorizontal: 24
  },
  headlineText: {
    lineHeight: 32,
    fontSize: 24,
    marginVertical: 14
  },
  text: {
    lineHeight: 26,
    minHeight: 80
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginHorizontal: 24,
    marginTop: 28
  },
  pillButton: {
    marginLeft: 12
  },
  lineButton: {
    backgroundColor: 'transparent'
  },
  errorTextStyle: {
    marginLeft: -10,
    marginTop: Platform.OS === 'web' ? 24 : 4
  }
});
