import React, { FunctionComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';

import Colors from '../../../colors';
import Picker from '../../../components/Picker';
import Popup from '../../../components/Popup';

const CalendarScreen: FunctionComponent = () => {
  return (
    <Popup>
      <View style={styles.container}>
        <Text>CalendarScreen</Text>
        <Picker />
      </View>
    </Popup>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.BACKGROUND
  }
});

export default CalendarScreen;
