import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Image,
  TouchableOpacity,
  View,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
  ScrollView
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import Colors from '../../../colors';
import KeyboardInput from '../../../components/KeyboardInput';
import MediumText from '../../../components/MediumText';
import Message from '../../../components/Message';
import PillButton from '../../../components/PillButton';
import { useAppDispatch } from '../../../store/hooks';
import { setFullname } from '../../../store/user';
import { AppStackParamList } from '../../AppNavigator';
import { RootStackParamList } from '../../RootNavigator';

const WelcomeScreen: FunctionComponent = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const navigation =
    useNavigation<
      StackNavigationProp<AppStackParamList & RootStackParamList>
    >();

  const handleSavePress = () => {
    if (name) {
      Keyboard.dismiss();
      dispatch(setFullname(name));
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          style={Platform.OS === 'web' ? { height: 1 } : undefined} // fixes web not scrolling
        >
          <View style={styles.contentContainer}>
            <View style={styles.contentWrapper}>
              <Image
                source={require('../../../assets/test_assets/small-activities.png')}
                style={styles.icon}
              />
              <View style={{ flex: 1 }}>
                <Message
                  file={{
                    type: 'image',
                    url: '',
                    localUrl: null,
                    source: require('../../../assets/welcomeScreenLogo.jpeg')
                  }}
                  text={intl.formatMessage(
                    {
                      defaultMessage:
                        'Welcome to I-Reach! {newLine}Please enter your name so that we know how to approach you.',
                      description: 'Welcome screen text'
                    },
                    { newLine: '\n\n' }
                  )}
                />

                <KeyboardInput
                  multiline={false}
                  placeholder="Name"
                  onChangeText={setName}
                  style={{ marginTop: 24, marginBottom: 12 }}
                  onSubmitEditing={handleSavePress}
                />
                <PillButton
                  style={{ alignSelf: 'flex-end' }}
                  onPress={handleSavePress}
                  disabled={!name}
                >
                  <FormattedMessage
                    defaultMessage="Save"
                    description="Welcome screen Save button"
                  />
                </PillButton>
              </View>
            </View>
          </View>
          <View style={styles.loginWrapper}>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate('Authentication', {
                  screen: 'Login',
                  params: { showRegistrationHint: true }
                })
              }
            >
              <MediumText style={styles.loginText}>
                <FormattedMessage
                  defaultMessage="Have an account? Log in"
                  description="Welcome screen log in hint"
                />
              </MediumText>
            </TouchableOpacity>
          </View>
          <View style={styles.technicalInfoLinks}>
            <View style={styles.privacyWrapper}>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate('Drawer', {
                    screen: 'PrivacyPolicyDrawer'
                  })
                }
              >
                <MediumText style={styles.technicalLink}>
                  <FormattedMessage
                    defaultMessage="Privacy policy"
                    description="Privacy policy link"
                  />
                </MediumText>
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate('Drawer', { screen: 'LegalDrawer' })
                }
              >
                <MediumText style={styles.technicalLink}>
                  <FormattedMessage
                    defaultMessage="Legal"
                    description="Legal information link"
                  />
                </MediumText>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default WelcomeScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center'
  },
  contentWrapper: {
    flexDirection: 'row',
    marginRight: 24
  },
  icon: {
    height: 48,
    width: 48,
    marginHorizontal: 16,
    marginBottom: 96,
    alignSelf: 'flex-end'
  },
  loginWrapper: {
    marginTop: 20,
    alignSelf: 'center'
  },
  loginText: {
    lineHeight: 26,
    color: Colors.BLUE,
    textAlign: 'center'
  },
  privacyWrapper: {
    marginEnd: 16
  },
  technicalInfoLinks: {
    marginTop: 12,
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  technicalLink: {
    color: Colors.GREY2
  }
});
