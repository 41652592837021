import React, { FunctionComponent, useState } from 'react';

import MediumText from '../../../../components/MediumText';
import useInterval from '../../../../hooks/useInterval';

const Cursor: FunctionComponent = () => {
  const [visible, setVisible] = useState(true);

  useInterval(() => {
    setVisible(!visible);
  }, 500);

  return <MediumText>{visible ? '|' : ''}</MediumText>;
};

export default Cursor;
