import { useNavigation } from '@react-navigation/native';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  Image,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  useWindowDimensions,
  View,
  ViewStyle
} from 'react-native';

import Colors from '../colors';
import MediumText from './MediumText';

interface Props {
  title: string;
  leftComponent?: JSX.Element;
  rightComponent?: JSX.Element;
  headerContainerStyle?: ViewStyle;
  titleStyle?: TextStyle;
  onBackgroundPress?: () => void;
  onArrowPress?: () => void;
}

// opens a Drawer sliding in from the bottom covering ~93% of the screen
const Drawer: FunctionComponent<PropsWithChildren<Props>> = (props) => {
  const windowHeight = useWindowDimensions().height;
  const navigation = useNavigation();
  return (
    <>
      <TouchableOpacity
        onPress={props.onBackgroundPress}
        style={{
          height: windowHeight * 0.07
        }}
      />
      <View style={styles.drawer}>
        <View style={[styles.headerContainer, props.headerContainerStyle]}>
          <View style={styles.leftContainer}>
            {props.leftComponent ?? (
              <TouchableOpacity
                onPress={props.onArrowPress ?? navigation.goBack}
              >
                <Image
                  source={require('../assets/arrow_down.png')}
                  style={styles.arrow}
                />
              </TouchableOpacity>
            )}
          </View>
          <MediumText style={[styles.title, props.titleStyle]}>
            {props.title}
          </MediumText>
          <View style={styles.rightContainer}>{props.rightComponent}</View>
        </View>
        <View style={{ flex: 1, overflow: 'hidden' }}>{props.children}</View>
      </View>
    </>
  );
};

export default Drawer;

const styles = StyleSheet.create({
  drawer: {
    flex: 1,
    backgroundColor: Colors.WHITE,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.GREY3,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: Colors.WHITE,
    height: 50,
    zIndex: 10
  },
  leftContainer: {
    position: 'absolute',
    left: 10
  },
  rightContainer: {
    position: 'absolute',
    right: 10
  },
  arrow: {
    width: 30,
    height: 30,
    resizeMode: 'contain'
  },
  title: {
    color: Colors.GREY2
  }
});
