import { MaterialTopTabView } from '@react-navigation/material-top-tabs';
import {
  useNavigationBuilder,
  DefaultNavigatorOptions,
  TabRouter,
  TabActions,
  TabActionHelpers,
  TabRouterOptions,
  TabNavigationState,
  createNavigatorFactory,
  ParamListBase,
  useNavigation
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import {
  StyleProp,
  View,
  ViewStyle,
  Platform,
  BackHandler
} from 'react-native';

import { ImageType } from '../../../../../components/HeaderSegmentButton';
import { HomeStackParamList } from '../../HomeNavigator';
import SessionHeader from './SessionHeader';

// Props accepted by the view
type TabNavigationConfig = {
  tabBarStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
};

// Supported screen options
type TabNavigationOptions = {
  title?: string;
  percentage?: number;
  tabLocked?: boolean;
  iconType?: keyof typeof ImageType;
  imageUri?: string;
};

// Map of event name and the type of data (in event.data)
//
// canPreventDefault: true adds the defaultPrevented property to the
// emitted events.
type TabNavigationEventMap = {
  tabPress: {
    data: { isAlreadyFocused: boolean };
    canPreventDefault: true;
  };
};

// The props accepted by the component is a combination of 3 things
type Props = DefaultNavigatorOptions<TabNavigationOptions> &
  TabRouterOptions &
  TabNavigationConfig;

const CustomTabNavigator: React.FunctionComponent<Props> = ({
  initialRouteName,
  children,
  screenOptions,
  tabBarStyle,
  contentStyle
}) => {
  const { state, navigation, descriptors } = useNavigationBuilder<
    TabNavigationState<ParamListBase>,
    TabRouterOptions,
    TabActionHelpers<ParamListBase>,
    TabNavigationOptions,
    TabNavigationEventMap
  >(TabRouter, {
    children,
    screenOptions,
    initialRouteName
  });

  const stackNavigation =
    useNavigation<StackNavigationProp<HomeStackParamList>>();

  useEffect(() => {
    const backAction = () => {
      if (state.index <= 0) {
        stackNavigation.pop();
      } else {
        navigation.navigate(state.routeNames[state.index - 1]);
      }
      return true;
    };

    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction
    );

    return () => backHandler.remove();
  }, [state.index]);

  const handleTabPress = (route: any) => {
    const event = navigation.emit({
      type: 'tabPress',
      target: route.key,
      data: {
        isAlreadyFocused: route.key === state.routes[state.index].key
      },
      canPreventDefault: true
    });

    if (!event.defaultPrevented) {
      navigation.dispatch({
        ...TabActions.jumpTo(route.name),
        target: state.key
      });
    }
  };

  const renderSessionHeader = () => (
    <View
      style={[
        {
          flexDirection: 'row',
          shadowOffset: { height: 0.5, width: 0 },
          shadowRadius: 0.75,
          shadowColor: 'rgba(145, 168, 184, 0.5)',
          shadowOpacity: 1,
          borderBottomColor: 'rgba(145, 168, 184, 0.5)',
          borderBottomWidth: Platform.OS === 'android' ? 0.75 : 0
        },
        tabBarStyle
      ]}
    >
      <SessionHeader
        title={
          descriptors[state.routes[state.index].key].options.title ??
          state.routeNames[state.index]
        }
        tabs={state.routes.map((route) => {
          return {
            percentage: descriptors[route.key].options.percentage ?? 0,
            locked: descriptors[route.key].options.tabLocked ?? false,
            imageUri: descriptors[route.key].options.imageUri,
            onPress: () => handleTabPress(route)
          };
        })}
        onClosePress={() => stackNavigation.pop()}
        selectedIndex={state.index}
      />
    </View>
  );

  return (
    <MaterialTopTabView
      style={contentStyle}
      state={state}
      navigation={navigation}
      descriptors={descriptors}
      tabBar={() => renderSessionHeader()}
      swipeEnabled={false}
      lazy
      timingConfig={{ duration: 350 }}
    />
  );
};

export const createCustomTabNavigator = createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap,
  typeof CustomTabNavigator
>(CustomTabNavigator);
