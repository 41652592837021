import { StyleSheet, Platform } from 'react-native';

import Colors from '../../../../../colors';

export default StyleSheet.create({
  victoryContainer: {
    ...(Platform.OS === 'web' && {
      maxWidth: '40%'
    })
  },
  pieContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  slidersScroller: {
    flexGrow: 1,
    marginTop: 12
  },
  scrollViewContentContainer: {
    flexGrow: 1
  },
  scrollViewStyle: {
    marginHorizontal: 16
  },
  pillButtonContainer: {
    width: '100%',
    paddingTop: 8,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: Colors.WHITE,
    // Android
    ...(Platform.OS === 'android' && {
      paddingBottom: 0,
      height: 42
    })
  },
  messageContainer: {
    shadowOpacity: 0,
    width: '100%'
  },
  slider: {
    paddingHorizontal: 12,
    paddingTop: 8,
    paddingBottom: 12,
    marginTop: 8
  },
  sliderTitle: {
    marginBottom: 8
  },
  sliderBar: {
    paddingHorizontal: 0
  },
  sliderBorder: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderLeftWidth: 8
  },
  message: {
    marginTop: 16,
    width: '100%',
    borderBottomLeftRadius: 16
  },
  messageInnerText: {
    paddingVertical: 12,
    paddingHorizontal: 16
  }
});
