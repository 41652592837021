import React, { FunctionComponent, useEffect, useState } from 'react';
import { Image, ImageProps, ImageURISource, Platform } from 'react-native';

import { useAppSelector } from '../store/hooks';

const AuthorizedImage: FunctionComponent<ImageProps> = (props) => {
  const [base64data, setBase64Data] = useState<string>();

  const token = useAppSelector((state) => state.user).token;

  const isWeb = Platform.OS === 'web';
  const isSourceObjectType = typeof props.source === 'object';

  useEffect(() => {
    /*
     * react-native-web currently doesn't support HTTP properties for source objects
     * https://github.com/necolas/react-native-web/issues/1019
     * This is a workaround to get the base64 data with fetch instead.
     */
    if (isWeb && isSourceObjectType) {
      const downloadBase64Data = async () => {
        const uri = (props.source as ImageURISource).uri;
        const response = await fetch(uri as string, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const blob = await response.blob();
        const fileReaderInstance = new FileReader();
        fileReaderInstance.readAsDataURL(blob);
        fileReaderInstance.onload = () =>
          setBase64Data(fileReaderInstance.result as string);
      };
      downloadBase64Data();
    }
  }, []);

  const getSourceProp = () => {
    if (!isSourceObjectType) return props.source;
    if (isWeb) return { uri: base64data };
    return {
      ...(props.source as ImageURISource),
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  };

  if (!token) return <Image {...props} />;
  return <Image {...props} source={getSourceProp()} />;
};

export default AuthorizedImage;
