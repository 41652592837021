import React, { FunctionComponent, useEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import Colors from '../colors';
import { Option } from '../store/types';
import MediumText from './MediumText';
import RadioButton from './RadioButton';

interface Props {
  values: Option[];
  value: Option | Option[] | null;
  onComplete: (val: Option | Option[]) => void;
}

const RadioSelect: FunctionComponent<Props> = (props) => {
  const [radioSelected, setRadioSelected] = useState<Option | Option[] | null>(
    props.value
  );

  useEffect(() => {
    setRadioSelected(props.value);
  }, [props.value]);

  const handleSelect = (value: Option) => {
    if (Array.isArray(radioSelected)) {
      if (radioSelected.some((selected) => selected.id === value.id)) {
        const newSelected = radioSelected.filter(
          (selected) => selected.id !== value.id
        );
        setRadioSelected(newSelected);
        props.onComplete(newSelected);
      } else {
        const newSelected = [...radioSelected, value];
        setRadioSelected(newSelected);
        props.onComplete(newSelected);
      }
    } else {
      setRadioSelected(value);
      props.onComplete(value);
    }
  };

  const renderAnswerOption = (value: Option) => {
    return (
      <Pressable
        key={value.id}
        style={styles.baseButton}
        onPress={() => handleSelect(value)}
      >
        <RadioButton
          onSelect={() => handleSelect(value)}
          selected={
            Array.isArray(radioSelected)
              ? radioSelected.some((selected) => selected.id === value.id)
              : radioSelected?.id === value.id
          }
        />
        <MediumText style={styles.text}>{value.text}</MediumText>
      </Pressable>
    );
  };

  return (
    <View style={styles.container}>
      {props.values.map((value: Option) => renderAnswerOption(value))}
    </View>
  );
};

export default RadioSelect;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: Colors.GREY6,
    padding: 16,
    borderRadius: 4
  },
  baseButton: {
    marginRight: 10,
    marginTop: 10,
    backgroundColor: 'transparent',
    flexDirection: 'row'
  },
  text: {
    color: Colors.GREY1
  }
});
