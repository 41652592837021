import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp, useHeaderHeight } from '@react-navigation/stack';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import Colors from '../../../colors';
import HeaderLine from '../../../components/HeaderLine';
import KeyboardInput from '../../../components/KeyboardInput';
import MediumText from '../../../components/MediumText';
import PillButton from '../../../components/PillButton';
import SemiBoldText from '../../../components/SemiBoldText';
import env from '../../../env';
import { AuthStackParamList } from '../AuthenticationNavigator';
import CountryCodeInput from './components/CountryCodeInput';
import countryData, { Country } from './components/CountryList/countryData';
export type ForgotPasswordOptions = 'sms' | 'email';

interface FormValues {
  contact: string;
}

const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const ForgotPasswordScreen: FunctionComponent = () => {
  const [contactOption, setContactOption] =
    useState<ForgotPasswordOptions>('email');
  const [selectedCountry, setSelectedCountry] = useState<Country>(
    countryData.find((country) => country.name === 'Germany') as Country
  );
  const navigation = useNavigation<StackNavigationProp<AuthStackParamList>>();
  const intl = useIntl();
  const headerHeight = useHeaderHeight();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors }
  } = useForm<FormValues>();
  const contact = useRef('');
  contact.current = watch('contact', '');

  useEffect(() => {
    reset({ contact: '' });
  }, [contactOption]);

  const getArrowOffset: () => number = () => {
    if (contactOption === 'email') {
      return 4;
    }
    if (contactOption === 'sms') {
      return 4 + 48;
    }
    return 4;
  };

  const handleButtonPress = async (data: FormValues) => {
    const phoneNumber = `+${selectedCountry.callingCode}${data.contact}`;
    const reqBody =
      contactOption === 'email'
        ? {
            email: contact.current,
            type: 'reset-password-email'
          }
        : {
            phone: phoneNumber,
            type: 'reset-password-sms'
          };

    const res = await fetch(`${env.API_BASE_URL}/tokens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqBody)
    });

    if (res.status === 201) {
      navigation.navigate('VerificationCode', {
        contactOption,
        contactInfo: contactOption === 'email' ? data.contact : phoneNumber
      });
    } else if (res.status === 404) {
      setError('contact', {
        message:
          contactOption === 'email'
            ? intl.formatMessage({
                description:
                  'ForgotPasswordScreen account with given email not found error',
                defaultMessage:
                  "Couldn't find an account with that email address"
              })
            : intl.formatMessage({
                description:
                  'ForgotPasswordScreen account with given phone number not found error',
                defaultMessage:
                  "Couldn't find an account with that phone number"
              })
      });
    } else {
      alert(
        intl.formatMessage({
          description: 'ForgotPasswordScreen unknown error',
          defaultMessage: 'Something went wrong. Please try again later.'
        })
      );
    }
  };

  const getInvalidInputError = () => {
    return contactOption === 'email'
      ? intl.formatMessage({
          defaultMessage: 'Please enter a valid email',
          description: 'ForgotPasswordScreen error label invalid email'
        })
      : intl.formatMessage({
          defaultMessage: 'Please enter a valid phone number',
          description: 'ForgotPasswordScreen error label invalid phone number'
        });
  };

  const renderKeyboardInput = () => {
    if (contactOption === 'email') {
      return (
        <Controller
          control={control}
          name="contact"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: getInvalidInputError()
            },
            pattern: {
              value: EMAIL_REGEXP,
              message: getInvalidInputError()
            }
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <KeyboardInput
              value={value}
              onChangeText={onChange}
              multiline={false}
              placeholder={intl.formatMessage({
                defaultMessage: 'Email address',
                description: 'ForgotPasswordScreen input placeholder'
              })}
              keyboardType="email-address"
              onSubmitEditing={handleSubmit(handleButtonPress)}
              autoComplete="email"
              textContentType="emailAddress"
              containerStyle={{ marginHorizontal: 24 }}
              style={{ height: 48 }}
              onBlur={onBlur}
              hasValidation
              errorText={errors.contact?.message ?? null}
            />
          )}
        />
      );
    } else {
      return (
        <Controller
          control={control}
          name="contact"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: getInvalidInputError()
            },
            minLength: {
              value: 6,
              message: getInvalidInputError()
            }
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <CountryCodeInput
              selectedCountry={selectedCountry}
              onChangeCounty={(country) => setSelectedCountry(country)}
              value={value}
              onChangeText={onChange}
              placeholder={intl.formatMessage({
                defaultMessage: 'Phone number',
                description: 'ForgotPasswordScreen input placeholder'
              })}
              onSubmitEditing={handleSubmit(handleButtonPress)}
              containerStyle={{ marginHorizontal: 24 }}
              onBlur={onBlur}
              hasValidation
              errorText={errors.contact?.message ?? null}
            />
          )}
        />
      );
    }
  };

  return (
    <Pressable
      style={styles.container}
      onPress={Platform.OS === 'web' ? undefined : Keyboard.dismiss}
    >
      <KeyboardAvoidingView
        behavior="position"
        keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 80 + headerHeight}
      >
        <View style={styles.textContainer}>
          <SemiBoldText style={styles.headlineText}>
            <FormattedMessage
              defaultMessage="Forgot Password?"
              description="ForgotPasswordScreen headline"
            />
          </SemiBoldText>
          <MediumText style={styles.text}>
            <FormattedMessage
              defaultMessage="Please select whether you would like to receive a reset code/link by SMS or Email."
              description="ForgotPasswordScreen prompt"
            />
          </MediumText>
        </View>

        <View style={styles.iconContainer}>
          <TouchableOpacity
            onPress={() => setContactOption('email')}
            style={{ marginRight: 24 }}
          >
            <Image
              source={
                contactOption === 'email'
                  ? require('../../../assets/Email_active.png')
                  : require('../../../assets/Email.png')
              }
              style={styles.icon}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setContactOption('sms')}>
            <Image
              source={
                contactOption === 'sms'
                  ? require('../../../assets/SMS_active.png')
                  : require('../../../assets/SMS.png')
              }
              style={styles.icon}
            />
          </TouchableOpacity>
        </View>
        <HeaderLine
          arrowPosition="start"
          lineContainerStyle={styles.line}
          arrowOffset={getArrowOffset()}
        />

        {renderKeyboardInput()}

        <PillButton
          disabled={!contact.current}
          style={styles.button}
          onPress={handleSubmit(handleButtonPress)}
        >
          {contactOption === 'email' && (
            <FormattedMessage
              defaultMessage="Receive link"
              description="ForgotPasswordScreen receive link button label"
            />
          )}
          {contactOption === 'sms' && (
            <FormattedMessage
              defaultMessage="Receive code"
              description="ForgotPasswordScreen receive code button label"
            />
          )}
        </PillButton>
      </KeyboardAvoidingView>
    </Pressable>
  );
};

export default ForgotPasswordScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  textContainer: {
    marginTop: 18,
    marginHorizontal: 24
  },
  headlineText: {
    lineHeight: 32,
    fontSize: 24,
    marginVertical: 14
  },
  text: {
    lineHeight: 26,
    minHeight: 80
  },
  iconContainer: {
    marginTop: 37,
    marginHorizontal: 28,
    flexDirection: 'row'
  },
  icon: {
    height: 24,
    width: 24
  },
  line: {
    flex: 0,
    height: 20,
    marginTop: 12,
    marginBottom: 32,
    marginHorizontal: 16
  },
  button: {
    alignSelf: 'flex-end',
    marginHorizontal: 24,
    marginTop: 13
  }
});
