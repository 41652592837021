import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';

import { RootState } from '.';
import env from '../env';
import {
  MessageOrigin,
  MessageType,
  TextMessage,
  VoiceMessage,
  Message,
  Asset
} from './types';

const messagesAdapter = createEntityAdapter<Message>({
  selectId: (message) => message.id
});

export const fetchMessages = createAsyncThunk<
  Message[],
  void,
  { state: RootState }
>('messages/fetchMessages', async (_, { getState }) => {
  const response = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/messages`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      }
    }
  );
  const messagesData = await response.json();

  if (!response.ok) {
    throw new Error(
      messagesData.code && messagesData.message
        ? `${messagesData.code}: ${messagesData.message}`
        : `${response.status} ${response.statusText}`
    );
  }

  return messagesData.items.map((message: any) => {
    if (message.files[0]) {
      return {
        id: message.id,
        date: message.createdAt,
        origin:
          message.sender.id === getState().user.userId
            ? MessageOrigin.User
            : MessageOrigin.Helper,
        file: {
          type: 'audio',
          url: message.files[0].url,
          localUrl: null
        } as Asset,
        type: MessageType.Voice
      };
    } else {
      return {
        id: message.id,
        date: message.createdAt,
        origin:
          message.sender.id === getState().user.userId
            ? MessageOrigin.User
            : MessageOrigin.Helper,
        text: message.text,
        type: MessageType.Text
      };
    }
  });
});

export const saveTextMessage = createAsyncThunk<
  TextMessage,
  string,
  { state: RootState }
>('messages/sendText', async (text, { getState }) => {
  const response = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/messages`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text
      })
    }
  );

  const messageData = await response.json();

  if (!response.ok) {
    throw new Error(
      messageData.code && messageData.message
        ? `${messageData.code}: ${messageData.message}`
        : `${response.status} ${response.statusText}`
    );
  }

  return {
    id: messageData.id,
    date: messageData.createdAt,
    origin: MessageOrigin.User,
    text: messageData.text,
    type: MessageType.Text // TODO: change once audio is supported
  };
});

export const saveVoiceMessage = createAsyncThunk<
  VoiceMessage,
  string,
  { state: RootState }
>('messages/sendVoice', async (uri, { getState }) => {
  const formData = new FormData();
  formData.append('file', { uri, name: 'xxx', type: 'audio/x-m4a' } as any);

  const response = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/messages`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        'Content-Type': 'multipart/form-data'
      },
      body: formData
    }
  );

  const messageData = await response.json();

  if (!response.ok) {
    throw new Error(
      messageData.code && messageData.message
        ? `${messageData.code}: ${messageData.message}`
        : `${response.status} ${response.statusText}`
    );
  }

  if (!messageData.files[0]) {
    throw new Error('No file found while saving voice message.');
  }

  return {
    id: messageData.id,
    file: {
      type: 'audio',
      url: messageData.files[0].url,
      localUrl: null
    },
    date: messageData.createdAt,
    origin: MessageOrigin.User,
    type: MessageType.Voice
  };
});

const initialState = messagesAdapter.getInitialState();

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addMessage: messagesAdapter.addOne
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessages.fulfilled, (state, { payload }) => {
      messagesAdapter.setAll(state, payload);
    });
    builder.addCase(saveTextMessage.fulfilled, (state, { payload }) => {
      messagesAdapter.addOne(state, payload);
    });
    builder.addCase(saveVoiceMessage.fulfilled, (state, { payload }) => {
      messagesAdapter.addOne(state, payload);
    });
  }
});

const messageSelectors = messagesAdapter.getSelectors<RootState>(
  (state) => state.messages
);

// Returns messages with date re-initialized.
export const selectAllMessages = (state: RootState) => {
  const entities = messageSelectors.selectAll(state);
  return entities
    .map((message) => ({
      ...message,
      date: new Date(message.date)
    }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());
};

export const { addMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
