import RNCSlider, { SliderProps } from '@react-native-community/slider';
import React, { FunctionComponent } from 'react';
import {
  StyleSheet,
  View,
  ViewStyle,
  TextStyle,
  I18nManager,
  Platform
} from 'react-native';

import Colors from '../colors';
import MediumText from './MediumText';

interface Props extends Omit<SliderProps, 'ref'> {
  title?: string;
  displayedValue?: string;
  leftLabel?: string;
  rightLabel?: string;
  containerStyle?: ViewStyle;
  titleStyle?: TextStyle;
  barStyle?: ViewStyle;
  borderStyle?: ViewStyle | ViewStyle[];
  remainingPercentage?: string;
}

const Slider: FunctionComponent<Props> = (props) => (
  <View style={[styles.container, props.containerStyle, props.borderStyle]}>
    {props.title && (
      <MediumText style={[styles.text, styles.title, props.titleStyle]}>
        {props.title}
      </MediumText>
    )}
    {props.displayedValue && (
      <MediumText style={[styles.text, styles.displayedValue]}>
        {props.displayedValue}
      </MediumText>
    )}
    <View>
      {/* This is a hack so that when we want to stop the slider artificially at any given point, the bar appears to still be full width. This effect is more apparent in the Responsibility Pie, where the value to which you can slide should be limited by the amount of "pie" left */}
      {/* 
        Longer explanation: In the responsibility pie, let's say you have three different sliders as reasons in the "OthersResponsibility section". You change the first one to occupy 80% of the pie. So now, the other 2 reasons can only occupy 20% of the pie. 
         However, the way the slider was implemented before, the width of the slider or the maximum position the circle could go to wouldn't reflect that. For example, if you wanted the second reason to occupy the rest of the pie, you had to slide the circle all the way to the right.
         Now, that's not really intuitive, this doesn't tell you in any way that you can go to the end of the slider, but in reality you're only in control of 20% of the pie (because the other 80% are already occupied by the first reason's slider).
         So, firstly, we changed the width of the slider so that it matches the remaining percentage of the pie. For example, if there's only 20% of the pie left, then the slider will only occupy 20% of the width of the container. However, this means that the slider tracks now have different lengths and it looks a bit off.
         To solve this, we've added an absolutely positioned track behind the actual slider. Now, all of the sliders seem to occupy the same width, but the circle only goes so far as the remaining percentage on the pie.
         Furthermore, the "fake" absolute track is colored in a lighter color to indicate that that percentage is already filled out by other reasons.
      */}
      <View style={styles.absoluteSliderPlaceholder} />
      <View
        style={[
          { paddingHorizontal: 16, width: props.remainingPercentage ?? '100%' },
          props.barStyle
        ]}
        // Disabling the slider doesn't correctly work in Web, this is a workaround https://github.com/callstack/react-native-slider/issues/344
        pointerEvents={props.disabled ? 'none' : undefined}
      >
        <RNCSlider
          inverted={
            (Platform.OS === 'web' || Platform.OS === 'android') &&
            I18nManager.getConstants().isRTL
          }
          minimumValue={0}
          maximumValue={1}
          minimumTrackTintColor={Colors.GREY5} // Colors.BLUE at 25% opacity
          maximumTrackTintColor={Colors.GREY5} // Colors.BLUE at 25% opacity
          thumbTintColor={Colors.BLUE}
          {...props}
          style={[{ height: 40 }, props.style]}
        />
      </View>
    </View>
    <View style={styles.labelsContainer}>
      <MediumText style={styles.text}>{props.leftLabel}</MediumText>
      <MediumText style={styles.text}>{props.rightLabel}</MediumText>
    </View>
  </View>
);

export default Slider;

const styles = StyleSheet.create({
  text: {
    color: Colors.GREY1,
    fontSize: 16
  },
  title: {
    marginBottom: 15
  },
  displayedValue: {
    marginBottom: 10,
    textAlign: 'center'
  },
  container: {
    backgroundColor: Colors.GREY6,
    paddingHorizontal: 16,
    borderRadius: 4,
    paddingVertical: 13
  },
  labelsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  absoluteSliderPlaceholder: {
    backgroundColor: Colors.GREY5,
    position: 'absolute',
    height: 4,
    left: 0,
    right: 0,
    top: 18,
    bottom: 0
  }
});
