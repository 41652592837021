import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  Pressable,
  View,
  Image,
  StyleSheet,
  TouchableOpacity
} from 'react-native';

import Colors from '../colors';
import MediumText from './MediumText';
import ProgressCircle from './ProgressCircle';

interface Props {
  selected: boolean;
  editMode?: boolean;
  progress?: number;
  onSelect?: () => void;
  onNavigation?: () => void;
  onDelete?: () => void;
}

const ListSelect: FunctionComponent<PropsWithChildren<Props>> = (props) => {
  const renderProgressCircle = (progress: number) => {
    return (
      <View style={styles.progressCircle}>
        {progress === 100 ? (
          <Image
            source={require('../assets/checkcircled.png')}
            style={styles.checkmark}
          />
        ) : (
          <ProgressCircle
            innerPadding={100}
            radius={12}
            strokeWidth={4}
            activeColor={Colors.BLUE}
            percentage={progress}
            passiveColor={Colors.LIGHTVIOLET}
          />
        )}
      </View>
    );
  };

  const renderSign = () => (
    <View style={styles.signContainer}>
      <MediumText>{props.selected ? '−' : '+'}</MediumText>
    </View>
  );

  const renderArrow = () => (
    <View style={styles.arrowContainer}>
      <Image
        source={require('../assets/arrow_right.png')}
        style={styles.arrow}
      />
    </View>
  );

  const handlePressContainer = () => {
    if (props.selected && props.onNavigation) return props.onNavigation?.();
    else props.onSelect?.();
  };

  const renderDeleteIcon = () => {
    if (props.editMode && !props.selected)
      return (
        <TouchableOpacity
          style={styles.arrowContainer}
          onPress={props.onDelete}
        >
          <Image source={require('../assets/binRed.png')} style={styles.bin} />
        </TouchableOpacity>
      );
  };

  const showComplexIcons = props.selected && props.onNavigation;

  return (
    <View style={styles.container}>
      {props.onSelect && (
        <Pressable style={{ flexDirection: 'row' }} onPress={props.onSelect}>
          {renderSign()}
        </Pressable>
      )}
      <Pressable
        onPress={handlePressContainer}
        style={[
          styles.textContainer,
          {
            borderColor: props.selected ? Colors.BLUE : Colors.GREY5,
            backgroundColor: props.selected
              ? Colors.SUPERLIGHTVIOLET
              : Colors.WHITE
          }
        ]}
      >
        {props.progress !== undefined &&
          props.selected &&
          renderProgressCircle(props.progress)}
        <MediumText
          style={[styles.text, { marginLeft: showComplexIcons ? 8 : 16 }]}
        >
          {props.children}
        </MediumText>
        {showComplexIcons && renderArrow()}
        {renderDeleteIcon()}
      </Pressable>
    </View>
  );
};

export default ListSelect;

const styles = StyleSheet.create({
  checkmark: {
    width: 24,
    height: 24
  },
  progressCircle: {
    alignSelf: 'center'
  },
  textContainer: {
    borderWidth: 1,
    borderRadius: 16,
    paddingRight: 16,
    paddingLeft: 3,
    paddingVertical: 5,
    flex: 1,
    flexDirection: 'row'
  },
  text: {
    flex: 1,
    marginRight: 16,
    color: Colors.BLUE
  },
  arrowContainer: {
    position: 'absolute',
    right: 10,
    alignSelf: 'center'
  },
  arrow: {
    tintColor: Colors.BLUE,
    width: 20,
    height: 20
  },
  bin: {
    width: 20,
    height: 20
  },
  container: {
    flexDirection: 'row',
    backgroundColor: Colors.GREY6,
    borderRadius: 6,
    flex: 1
  },
  signContainer: {
    width: 14,
    fontSize: 20,
    alignSelf: 'center',
    marginHorizontal: 15,
    color: Colors.GREY2
  }
});
