import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import Colors from '../../../../colors';
import Drawer from '../../../../components/Drawer';
import InputDrawer from '../../../../components/InputDrawer';
import KeyboardInput from '../../../../components/KeyboardInput';
import MediumText from '../../../../components/MediumText';
import Message from '../../../../components/Message';
import MultipleInputSelect from '../../../../components/MultipleInputSelect';
import PillButton from '../../../../components/PillButton';
import useAppWindowDimensions from '../../../../hooks/useAppWindowDimensions';
import { useBackHandler } from '../../../../hooks/useBackHandler';
import {
  saveExercise,
  selectSafetyItemsBySection
} from '../../../../store/exercises';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ExerciseType } from '../../../../store/types';
import { DrawerStackParamList } from '../../../DrawerNavigator';

const SafetyPlanInputScreen: FunctionComponent = () => {
  const [bottomInputText, setBottomInputText] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showInput, setShowInput] = useState(false);

  const { height: windowHeight } = useAppWindowDimensions();

  const intl = useIntl();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const route = useRoute<RouteProp<DrawerStackParamList, 'SafetyPlanInput'>>();
  const { type, onFinish } = route.params;

  const getDefaultTemplate = () => {
    if (type === ExerciseType.SafetyPlanAddressList) {
      return {
        title: intl.formatMessage({
          defaultMessage: 'Emergency contact',
          description: 'SafetyPlanInputScreen address list default title'
        }),
        instruction: intl.formatMessage({
          defaultMessage: 'Please add the information.',
          description: 'SafetyPlanInputScreen address list default instruction'
        })
      };
    }
    return {
      title: intl.formatMessage({
        defaultMessage: 'Default list',
        description: 'SafetyPlanInputScreen basic list default title'
      }),
      instruction: intl.formatMessage({
        defaultMessage: 'Add some items',
        description: 'SafetyPlanInputScreen basic list default instruction'
      })
    };
  };

  const template = route.params.template ?? getDefaultTemplate();
  const storedValues = useAppSelector(
    selectSafetyItemsBySection(template.title)
  );

  useEffect(() => {
    const handleKeyboardWillHide = () => {
      setShowInput(false);
    };
    const subscription = Keyboard.addListener(
      'keyboardWillHide',
      handleKeyboardWillHide
    );
    return () => {
      subscription.remove();
    };
  }, []);

  const handleSave = () => {
    const getValues = () => {
      if (type === ExerciseType.SafetyPlanAddressList) {
        return {
          cmsName: template.cmsName,
          name,
          ...(address.length !== 0 && { address }),
          ...(phoneNumber.length !== 0 && { phoneNumber })
        };
      }
      return { cmsName: template.cmsName, name: bottomInputText };
    };
    const values = getValues();

    dispatch(
      saveExercise({
        id: uuidv4(),
        createdAt: new Date().toISOString(),
        type,
        section: template.title,
        ...values
      })
    );
    if (type === ExerciseType.SafetyPlanAddressList) {
      handleCloseExercise();
    }
  };

  const handleCloseExercise = () => {
    onFinish?.();
    navigation.goBack();
  };

  const renderIntroductionMessage = () => {
    return (
      <View style={styles.introductionTextContainer}>
        <Message
          text={template.instruction}
          style={styles.introductionText}
          containerStyle={{ shadowOpacity: 0 }}
        />
      </View>
    );
  };

  const renderInputs = () => {
    if (type === ExerciseType.SafetyPlanAddressList) {
      return (
        <>
          <KeyboardInput
            placeholder={intl.formatMessage({
              defaultMessage: 'Name',
              description: 'Name placeholder on safety item input'
            })}
            multiline
            onChangeText={setName}
            value={name}
            style={{ marginBottom: 12 }}
          />
          <KeyboardInput
            placeholder={intl.formatMessage({
              defaultMessage: 'Phone',
              description: 'Phone placeholder on safety item input'
            })}
            onChangeText={setPhoneNumber}
            value={phoneNumber}
            keyboardType="numeric"
            style={{ marginBottom: 12 }}
          />

          <KeyboardInput
            placeholder={intl.formatMessage({
              defaultMessage: 'Address',
              description: 'Address placeholder on safety item input'
            })}
            multiline
            onChangeText={setAddress}
            value={address}
          />
        </>
      );
    }
    if (type === ExerciseType.SafetyPlanBasicList) {
      return (
        <>
          <MultipleInputSelect
            selectedKeys={storedValues.map((v) => v.name)}
            values={storedValues.map((val) => ({
              value: val.name,
              key: val.name,
              label: val.name
            }))}
            onSelect={(values) => {}}
            onChangeValues={(values) => {}}
            style={{ alignSelf: 'flex-end' }}
            addInputText={intl.formatMessage({
              defaultMessage: 'Add custom barrier',
              description: 'Add custom barrier text'
            })}
            allowInput={false}
          />
          <TouchableOpacity
            style={styles.addInputContainer}
            onPress={() => {
              setShowInput(!showInput);
            }}
          >
            <MediumText style={styles.text}>
              <FormattedMessage
                defaultMessage="Add custom barrier"
                description="add list item in safety plan input"
              />
            </MediumText>
            <View style={styles.plusButton}>
              <MediumText style={styles.text}>+</MediumText>
            </View>
          </TouchableOpacity>
        </>
      );
    }
  };

  const isButtonDisabled = () => {
    if (type === ExerciseType.SafetyPlanAddressList) {
      return name.length === 0;
    }
    return false;
  };

  const renderSaveButton = () => (
    <View style={styles.pillButtonContainer}>
      <PillButton disabled={isButtonDisabled()} onPress={handleSave}>
        <FormattedMessage defaultMessage="Save" description="Save" />
      </PillButton>
    </View>
  );

  const renderInputDrawer = () => {
    return (
      <InputDrawer
        onCancel={() => {
          setShowInput(false);
        }}
        onChangeText={(val) => {
          setBottomInputText(val);
        }}
        onSave={handleSave}
      />
    );
  };

  const firstScreenBackwardNav = () => {
    navigation.navigate('ConfirmationQuestion', {
      question: intl.formatMessage({
        defaultMessage: 'Are you sure you want to exit?',
        description: 'Exit text on input screen drawer'
      }),
      onPressYes: handleCloseExercise
    });
  };

  const { getDrawerIcon } = useBackHandler(
    () => true,
    () => null,
    [],
    navigation,
    handleCloseExercise,
    firstScreenBackwardNav
  );

  return (
    <Drawer
      title={template.title}
      leftComponent={getDrawerIcon()}
      onBackgroundPress={firstScreenBackwardNav}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'position' : undefined}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 100 : 20}
      >
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, paddingBottom: 300 }}
          style={
            Platform.OS === 'web'
              ? { height: windowHeight - 125, paddingBottom: 50 }
              : undefined
          } // Fixes web not scrolling
        >
          <View style={{ marginHorizontal: 14, flex: 1 }}>
            {renderIntroductionMessage()}
            {renderInputs()}
            {type === ExerciseType.SafetyPlanAddressList && renderSaveButton()}
          </View>
        </ScrollView>
        {type === ExerciseType.SafetyPlanBasicList &&
          showInput &&
          renderInputDrawer()}
      </KeyboardAvoidingView>
    </Drawer>
  );
};

export default SafetyPlanInputScreen;

const styles = StyleSheet.create({
  pillButtonContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginTop: 20
  },
  introductionText: {
    borderBottomLeftRadius: 16,
    borderWidth: 0
  },
  introductionTextContainer: { marginVertical: 20 },
  addInputContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 10
  },
  plusButton: {
    width: 30,
    height: 30,
    borderColor: Colors.GREY4,
    borderWidth: 1,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12
  },
  text: {
    color: Colors.BLUE
  },
  bottomInputAbsoluteContainer: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: Colors.WHITE,
    height: 150,
    width: '100%'
  },
  bottomInputContainer: {
    backgroundColor: Colors.WHITE,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 20,
    borderWidth: 1,
    borderColor: Colors.GREY5,
    paddingTop: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingHorizontal: 14
  },
  bottomInput: {
    backgroundColor: Colors.WHITE,
    flex: 1,
    width: '100%',
    paddingHorizontal: 14,
    marginTop: 20
  }
});
