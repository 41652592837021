import React, { FunctionComponent } from 'react';
import {
  Image,
  ImageBackground,
  ImageSourcePropType,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View
} from 'react-native';

import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import SemiBoldText from '../../../../../components/SemiBoldText';

export interface NotificationCardProps {
  title: string;
  text: string;
  iconSource: ImageSourcePropType;
  backgroundColor?: string;
  textColor?: string;
  backgroundImage?: ImageSourcePropType;
  textStyle?: TextStyle;
  onPress?: () => void;
}

const NotificationCard: FunctionComponent<NotificationCardProps> = ({
  title,
  text,
  iconSource,
  backgroundColor = Colors.WHITE,
  textColor = Colors.GREY1,
  backgroundImage,
  onPress
}) => {
  const renderSmallCard = () => (
    <View style={styles.smallCardContainer}>
      <View style={styles.smallCardTextContainer}>
        <SemiBoldText style={[{ color: textColor }, styles.title]}>
          {title}
        </SemiBoldText>
        <MediumText style={[{ color: textColor }, styles.text]}>
          {text}
        </MediumText>
      </View>

      <Image style={[styles.icon, { marginStart: 20 }]} source={iconSource} />
    </View>
  );

  const renderLargeCard = () => (
    <>
      <ImageBackground
        source={backgroundImage as ImageSourcePropType}
        style={{
          height: 133,
          width: '100%'
        }}
      >
        <SemiBoldText
          style={[
            { color: textColor, textAlign: 'center', marginTop: 13 },
            styles.title
          ]}
        >
          {title}
        </SemiBoldText>
        <Image
          style={[
            styles.icon,
            {
              alignSelf: 'center',
              marginTop: 15
            }
          ]}
          source={iconSource}
        />
      </ImageBackground>
      <View style={styles.largeCardTextContainer}>
        <MediumText style={[{ color: textColor }, styles.text]}>
          {text}
        </MediumText>
      </View>
    </>
  );

  return (
    <TouchableOpacity
      style={[{ backgroundColor }, styles.container]}
      onPress={onPress}
      activeOpacity={onPress ? 0 : 1}
    >
      {backgroundImage ? renderLargeCard() : renderSmallCard()}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 12,
    borderRadius: 4,
    shadowColor: Colors.GREY5,
    shadowRadius: 8,
    elevation: 6,
    shadowOpacity: 1
  },
  smallCardContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 15
  },
  smallCardTextContainer: {
    flex: 1
  },
  largeCardTextContainer: {
    paddingHorizontal: 16,
    paddingBottom: 15
  },
  title: {
    marginBottom: 2,
    lineHeight: 20
  },
  text: {
    fontSize: 14,
    lineHeight: 20
  },
  icon: {
    height: 50,
    width: 50
  }
});

export default NotificationCard;
