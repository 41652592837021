import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';

import Colors from '../../../../colors';
import KeyboardInput from '../../../../components/KeyboardInput';
import MediumText from '../../../../components/MediumText';
import Message from '../../../../components/Message';
import PillButton from '../../../../components/PillButton';
import SingleSelect from '../../../../components/SingleSelect';
import Step from '../../../../components/Step';
import {
  CognitiveTriangleFlow,
  CognitiveTriangleTemplate,
  ExerciseType
} from '../../../../store/types';
import FeelingsSelect from './FeelingsSelect';
import ReviewMessage from './ReviewMessage';
import Triangle from './Triangle';
import { convertSituationTemplateToLegacy } from './helpers';
import {
  CognitiveTriangleExerciseLegacy,
  SituationLegacy,
  TriangleSteps
} from './types';
import { useDefaultCognitiveTriangleContent } from './useDefaultCognitiveTriangleContent';

enum CognitiveType {
  HELPFUL,
  UNHELPFUL
}

interface Props {
  values: CognitiveTriangleExerciseLegacy;
  stepIndex: number;
  flow: CognitiveTriangleFlow;
  template?: CognitiveTriangleTemplate;
  setStepIndex: (n: number) => void;
  onSelect: (val: CognitiveTriangleExerciseLegacy) => void;
  onSave: () => void;
  onFinish: () => void;
  openExercise: (type: ExerciseType) => void;
}

const CognitiveTriangleStep: FunctionComponent<Props> = ({
  values,
  stepIndex,
  flow,
  template,
  setStepIndex,
  onSelect,
  onFinish,
  onSave,
  openExercise
}) => {
  const intl = useIntl();

  const { defaultInstructions, defaultSituations } =
    useDefaultCognitiveTriangleContent();

  const situations = template?.situations.length
    ? template?.situations.map((situation) =>
        convertSituationTemplateToLegacy(situation)
      )
    : defaultSituations;

  const [contentStrings, setContentStrings] = useState<SituationLegacy>(
    situations[0]
  );

  const closedFlow = flow === CognitiveTriangleFlow.CLOSED;
  const mixedFlow = flow === CognitiveTriangleFlow.MIXED;
  const openFlow = flow === CognitiveTriangleFlow.OPEN;

  const unhelpfulValues = values.unhelpful;
  const helpfulValues = values.helpful;
  const situation = values.situation;

  const getBackTranslation = () => {
    return <FormattedMessage defaultMessage="Back" description="Back" />;
  };

  const getNextTranslation = () => {
    return <FormattedMessage defaultMessage="Next" description="Next" />;
  };

  const getSituationTranslation = () => {
    return (
      <FormattedMessage
        defaultMessage="Situation"
        description="CognitiveTriangle Situation"
      />
    );
  };

  const formatFeelingsIntoSentence = (
    feelings: {
      name: string;
      scale: number;
    }[]
  ) => {
    const and = intl.formatMessage({
      defaultMessage: 'and',
      description: 'Conjuction and in the cognitive triangle'
    });

    const feelingsNames = feelings.map((f) => f.name);

    const feelingsWithConjunction = feelingsNames
      .map((feel, idx) => {
        if (idx === feelingsNames.length - 1 && feelingsNames.length > 1) {
          return ` ${and} ${feel}`;
        }
        if (idx === 0) return `${feel}`;
        return `, ${feel}`;
      })
      .join('');

    return feelingsWithConjunction;
  };

  const getStringWithoutEndSpaces = (text: string) =>
    text.replace(/(\r\n|\n|\r)/gm, '');

  const renderUnhelpfulIntroStep = () => {
    const setSituation = (val: string) => {
      return onSelect({ ...values, situation: val });
    };

    const instructionText =
      template?.instructionInitial ?? defaultInstructions.instructionInitial;

    const situationsData = situations.map((situation) => situation.text);

    return (
      <View style={{ flex: 1 }}>
        <View style={{ marginTop: 40 }}>
          <Message text={instructionText} style={styles.message} />
        </View>
        <MediumText style={styles.situationQuestion}>
          <FormattedMessage
            defaultMessage="Put yourself in this situation."
            description="Question about situation in the cognitive triangle"
          />
        </MediumText>
        <>
          {closedFlow && (
            <Message text={situationsData[0]} style={styles.message} />
          )}
          {mixedFlow && (
            <SingleSelect
              buttonStyle={styles.singleSelect}
              values={situationsData}
              selected={situation}
              onSelect={(val) => {
                setSituation(val as string);
                const situation = template?.situations.find(
                  (situation) => situation.text === val
                );
                situation &&
                  setContentStrings(
                    convertSituationTemplateToLegacy(situation)
                  );
              }}
            />
          )}
          {openFlow && (
            <KeyboardInput
              placeholder={intl.formatMessage({
                defaultMessage: 'Type in what you think',
                description: 'Input placeholder on cognitive triangle situation'
              })}
              onChangeText={setSituation}
              value={situation}
            />
          )}
          <View style={styles.pillButtonContainer}>
            <PillButton
              onPress={() => {
                setSituation(getStringWithoutEndSpaces(situation));
                setStepIndex(stepIndex + 1);
              }}
              disabled={situation?.length === 0}
            >
              <FormattedMessage
                defaultMessage="Begin exercise"
                description="Start of the cognitive triangle"
              />
            </PillButton>
          </View>
        </>
      </View>
    );
  };

  const renderHelpfulIntroStep = () => {
    const instructionText =
      template?.instructionHelpfulApproach ??
      defaultInstructions.instructionHelpfulApproach;

    return (
      <View style={{ flex: 1 }}>
        <MediumText style={styles.situationText}>
          <MediumText style={styles.situationTitle}>
            {getSituationTranslation()}:
          </MediumText>{' '}
          {values.situation}
        </MediumText>
        <View style={{ marginTop: 30 }}>
          <Message text={instructionText} style={styles.message} />
        </View>
        <View style={styles.pillButtonContainer}>
          <PillButton
            onPress={() => {
              setStepIndex(stepIndex + 1);
            }}
          >
            {getNextTranslation()}
          </PillButton>
        </View>
      </View>
    );
  };

  const renderOverviewStep = () => {
    const instructionText =
      template?.instructionSummary ?? defaultInstructions.instructionSummary;

    return (
      <View style={{ flex: 1 }}>
        <MediumText style={styles.situationText}>
          <MediumText style={styles.situationTitle}>
            {getSituationTranslation()}:
          </MediumText>{' '}
          {values.situation}
        </MediumText>
        <View style={{ marginTop: 30 }}>
          <Message text={instructionText} style={styles.message} />
        </View>
        <View style={styles.pillButtonContainer}>
          <PillButton
            onPress={() => {
              setStepIndex(stepIndex + 1);
            }}
          >
            <FormattedMessage
              defaultMessage="View summary"
              description="View Summary"
            />
          </PillButton>
        </View>
      </View>
    );
  };

  const renderThoughtStep = (cognitiveType: CognitiveType) => {
    const thought =
      cognitiveType === CognitiveType.UNHELPFUL
        ? unhelpfulValues.thought
        : helpfulValues.thought;
    const thoughtsData =
      cognitiveType === CognitiveType.UNHELPFUL
        ? contentStrings.unhelpful.thoughts
        : contentStrings.helpful.thoughts;

    const setThought = (val: string) => {
      if (cognitiveType === CognitiveType.UNHELPFUL) {
        return onSelect({
          ...values,
          unhelpful: { ...unhelpfulValues, thought: val }
        });
      } else
        return onSelect({
          ...values,
          helpful: { ...helpfulValues, thought: val }
        });
    };
    return (
      <>
        <MediumText style={styles.title}>
          <FormattedMessage
            defaultMessage="What is going through your mind?"
            description="Question about thought in the cognitive triangle"
          />
        </MediumText>
        {closedFlow && <Message text={thought} style={styles.message} />}
        {mixedFlow && (
          <SingleSelect
            buttonStyle={styles.singleSelect}
            values={thoughtsData}
            selected={thought}
            onSelect={(val) => setThought(val as string)}
          />
        )}
        {openFlow && (
          <KeyboardInput
            placeholder={intl.formatMessage({
              defaultMessage: 'Type in what you think',
              description: 'Input placeholder on cognitive triangle situation'
            })}
            onChangeText={setThought}
            value={thought}
          />
        )}
        <View style={styles.pillButtonContainer}>
          <PillButton
            onPress={() => {
              setThought(getStringWithoutEndSpaces(thought));
              setStepIndex(stepIndex + 1);
            }}
            disabled={thought?.length === 0}
          >
            {getNextTranslation()}
          </PillButton>
        </View>
      </>
    );
  };

  const renderThoughtReviewStep = (cognitiveType: CognitiveType) => {
    const thought =
      cognitiveType === CognitiveType.UNHELPFUL
        ? unhelpfulValues.thought
        : helpfulValues.thought;
    return (
      <>
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'What you think:',
            description: 'What you think review in the cognitive triangle'
          })}
          isHelpful={cognitiveType === CognitiveType.HELPFUL}
          body={thought}
        />
        <View style={styles.pillButtonContainer}>
          <PillButton onPress={() => setStepIndex(stepIndex + 1)}>
            {getNextTranslation()}
          </PillButton>
        </View>
      </>
    );
  };

  const renderFeelingsStep = (cognitiveType: CognitiveType) => {
    const feelings =
      cognitiveType === CognitiveType.UNHELPFUL
        ? unhelpfulValues.feelings
        : helpfulValues.feelings;

    const feelingsData =
      cognitiveType === CognitiveType.UNHELPFUL
        ? contentStrings.unhelpful.feelings
        : contentStrings.helpful.feelings;

    return (
      <>
        <MediumText style={styles.title}>
          <FormattedMessage
            defaultMessage="How do you feel ?"
            description="Question about feeling in the cognitive triangle"
          />
        </MediumText>
        {closedFlow && (
          <Message text={feelings[0].name} style={styles.message} />
        )}
        {!closedFlow && (
          <FeelingsSelect
            feelingsData={feelingsData}
            selected={feelings}
            onSelect={(val) => {
              if (cognitiveType === CognitiveType.UNHELPFUL) {
                onSelect({
                  ...values,
                  unhelpful: { ...unhelpfulValues, feelings: val }
                });
              } else
                onSelect({
                  ...values,
                  helpful: { ...helpfulValues, feelings: val }
                });
            }}
          />
        )}
        <View
          style={[
            styles.pillButtonContainer,
            {
              marginBottom: 20
            }
          ]}
        >
          <PillButton
            onPress={() => setStepIndex(stepIndex + 1)}
            disabled={
              feelings.some((item) => item.scale === -1) ||
              feelings.length === 0
            }
          >
            {getNextTranslation()}
          </PillButton>
        </View>
      </>
    );
  };

  const renderFeelingsReviewStep = (cognitiveType: CognitiveType) => {
    const currentValues =
      cognitiveType === CognitiveType.UNHELPFUL
        ? unhelpfulValues
        : helpfulValues;
    return (
      <>
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'What you think:',
            description: 'What you think review in the cognitive triangle'
          })}
          isHelpful={cognitiveType === CognitiveType.HELPFUL}
          body={currentValues.thought}
        />
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'What you feel:',
            description: 'What you feel review in the cognitive triangle'
          })}
          isHelpful={cognitiveType === CognitiveType.HELPFUL}
          body={formatFeelingsIntoSentence(currentValues.feelings)}
        />
        <View
          style={[
            styles.pillButtonContainer,
            {
              marginBottom: 20
            }
          ]}
        >
          <PillButton onPress={() => setStepIndex(stepIndex + 1)}>
            {getNextTranslation()}
          </PillButton>
        </View>
      </>
    );
  };

  const renderBehaviourStep = (cognitiveType: CognitiveType) => {
    const behavior =
      cognitiveType === CognitiveType.UNHELPFUL
        ? unhelpfulValues.behavior
        : helpfulValues.behavior;

    const behaviorsData =
      cognitiveType === CognitiveType.UNHELPFUL
        ? contentStrings.unhelpful.behaviors
        : contentStrings.helpful.behaviors;

    const setBehavior = (val: string) => {
      if (cognitiveType === CognitiveType.UNHELPFUL) {
        onSelect({
          ...values,
          unhelpful: { ...unhelpfulValues, behavior: val }
        });
      } else
        onSelect({
          ...values,
          helpful: { ...helpfulValues, behavior: val }
        });
    };
    return (
      <>
        <MediumText style={styles.title}>
          <FormattedMessage
            defaultMessage="How do you behave?"
            description="Question about behavior in the cognitive triangle"
          />
        </MediumText>
        {closedFlow && <Message text={behavior} style={styles.message} />}
        {mixedFlow && (
          <SingleSelect
            buttonStyle={styles.singleSelect}
            selected={behavior}
            values={behaviorsData}
            onSelect={(val) => setBehavior(val as string)}
          />
        )}
        {openFlow && (
          <KeyboardInput
            placeholder={intl.formatMessage({
              defaultMessage: 'Type in what you do',
              description: 'Input placeholder on cognitive triangle behavior'
            })}
            onChangeText={setBehavior}
            value={behavior}
          />
        )}
        <View style={styles.pillButtonContainer}>
          <PillButton
            onPress={() => {
              setBehavior(getStringWithoutEndSpaces(behavior));
              setStepIndex(stepIndex + 1);
            }}
            disabled={behavior.length === 0}
          >
            {getNextTranslation()}
          </PillButton>
        </View>
      </>
    );
  };

  const renderFinalStep = (cognitiveType: CognitiveType) => {
    const currentValues =
      cognitiveType === CognitiveType.UNHELPFUL
        ? values.unhelpful
        : values.helpful;
    return (
      <>
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'What you think:',
            description: 'What you think review in the cognitive triangle'
          })}
          body={currentValues.thought}
          isHelpful={cognitiveType === CognitiveType.HELPFUL}
        />
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'What you feel:',
            description: 'What you feel review in the cognitive triangle'
          })}
          body={formatFeelingsIntoSentence(currentValues.feelings)}
          isHelpful={cognitiveType === CognitiveType.HELPFUL}
        />
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'What you do:',
            description: 'What you do review in the cognitive triangle'
          })}
          body={currentValues.behavior}
          isHelpful={cognitiveType === CognitiveType.HELPFUL}
        />
        <View style={styles.pillButtonContainer}>
          <PillButton
            onPress={() => {
              if (cognitiveType === CognitiveType.UNHELPFUL) {
                if (template?.linkedExercise) {
                  openExercise(template.linkedExercise);
                } else setStepIndex(TriangleSteps.HELPFUL_INTRO);
              } else {
                onSave();
                setStepIndex(stepIndex + 1);
              }
            }}
          >
            <FormattedMessage
              defaultMessage="Save"
              description="CognitiveTriangle final step button"
            />
          </PillButton>
        </View>
      </>
    );
  };

  const renderThoughtOverview = () => {
    return (
      <>
        <MediumText style={styles.overviewTitle}>
          <FormattedMessage
            defaultMessage="Thought"
            description="Title of the thought overview on the cognitive triangle"
          />
        </MediumText>
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'You do not think:',
            description:
              'What you do not think in the final review of the cognitive triangle'
          })}
          body={values.unhelpful.thought}
        />
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'You think:',
            description:
              'What you do think in the final review of the cognitive triangle'
          })}
          body={values.helpful.thought}
          isHelpful
        />
        <View style={styles.pillButtonContainer}>
          <PillButton onPress={() => setStepIndex(stepIndex + 1)}>
            {getNextTranslation()}
          </PillButton>
        </View>
      </>
    );
  };
  const renderFeelingsOverview = () => {
    return (
      <>
        <MediumText style={styles.overviewTitle}>
          <FormattedMessage
            defaultMessage="Feeling"
            description="Title of the feeling overview on the cognitive triangle"
          />
        </MediumText>
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'You do not feel:',
            description:
              'What you do not feel in the final review of the cognitive triangle'
          })}
          body={formatFeelingsIntoSentence(values.unhelpful.feelings)}
        />
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'You feel:',
            description:
              'What you do feel in the final review of the cognitive triangle'
          })}
          body={formatFeelingsIntoSentence(values.helpful.feelings)}
          isHelpful
        />
        <View
          style={[
            styles.pillButtonContainer,
            { justifyContent: 'space-between' }
          ]}
        >
          <PillButton onPress={() => setStepIndex(stepIndex - 1)}>
            {getBackTranslation()}
          </PillButton>
          <PillButton onPress={() => setStepIndex(stepIndex + 1)}>
            {getNextTranslation()}
          </PillButton>
        </View>
      </>
    );
  };
  const renderBehaviorOverview = () => {
    return (
      <>
        <MediumText style={styles.overviewTitle}>
          <FormattedMessage
            defaultMessage="Behavior"
            description="Title of the behavior overview on the cognitive triangle"
          />
        </MediumText>
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'You do not do:',
            description:
              'What you do not do in the final review of the cognitive triangle'
          })}
          body={values.unhelpful.behavior}
        />
        <ReviewMessage
          title={intl.formatMessage({
            defaultMessage: 'You do:',
            description:
              'What you do in the final review of the cognitive triangle'
          })}
          body={values.helpful.behavior}
          isHelpful
        />
        <View
          style={[
            styles.pillButtonContainer,
            { justifyContent: 'space-between' }
          ]}
        >
          <PillButton onPress={() => setStepIndex(stepIndex - 1)}>
            {getBackTranslation()}
          </PillButton>
          <PillButton onPress={onFinish}>{getNextTranslation()}</PillButton>
        </View>
      </>
    );
  };

  const renderTopContainer = () => {
    const indexesWithoutTriangle = [
      TriangleSteps.UNHELPFUL_INTRO,
      TriangleSteps.HELPFUL_INTRO,
      TriangleSteps.OVERVIEW
    ];

    if (indexesWithoutTriangle.includes(stepIndex)) return;
    return (
      <View style={styles.topContainer}>
        <>
          <MediumText style={styles.situationText}>
            <MediumText style={styles.situationTitle}>
              {getSituationTranslation()}:
            </MediumText>{' '}
            {typeof values.situation === 'object'
              ? (values.situation as SituationLegacy).text
              : values.situation}
          </MediumText>
          <View style={styles.triangleContainer}>
            <Triangle stepIndex={stepIndex} setStepIndex={setStepIndex} />
          </View>
        </>
      </View>
    );
  };

  return (
    <>
      {renderTopContainer()}
      <View style={styles.bottomContainer}>
        <Step current={stepIndex} step={TriangleSteps.UNHELPFUL_INTRO}>
          {renderUnhelpfulIntroStep()}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.UNHELPFUL_THOUGHT}>
          {renderThoughtStep(CognitiveType.UNHELPFUL)}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.UNHELPFUL_THOUGHT_REVIEW}>
          {renderThoughtReviewStep(CognitiveType.UNHELPFUL)}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.UNHELPFUL_FEELINGS}>
          {renderFeelingsStep(CognitiveType.UNHELPFUL)}
        </Step>
        <Step
          current={stepIndex}
          step={TriangleSteps.UNHELPFUL_FEELINGS_REVIEW}
        >
          {renderFeelingsReviewStep(CognitiveType.UNHELPFUL)}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.UNHELPFUL_BEHAVIOUR}>
          {renderBehaviourStep(CognitiveType.UNHELPFUL)}
        </Step>
        <Step
          current={stepIndex}
          step={TriangleSteps.UNHELPFUL_BEHAVIOUR_REVIEW}
        >
          {renderFinalStep(CognitiveType.UNHELPFUL)}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.HELPFUL_INTRO}>
          {renderHelpfulIntroStep()}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.HELPFUL_THOUGHT}>
          {renderThoughtStep(CognitiveType.HELPFUL)}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.HELPFUL_THOUGHT_REVIEW}>
          {renderThoughtReviewStep(CognitiveType.HELPFUL)}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.HELPFUL_FEELINGS}>
          {renderFeelingsStep(CognitiveType.HELPFUL)}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.HELPFUL_FEELINGS_REVIEW}>
          {renderFeelingsReviewStep(CognitiveType.HELPFUL)}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.HELPFUL_BEHAVIOUR}>
          {renderBehaviourStep(CognitiveType.HELPFUL)}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.HELPFUL_BEHAVIOUR_REVIEW}>
          {renderFinalStep(CognitiveType.HELPFUL)}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.OVERVIEW}>
          {renderOverviewStep()}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.THOUGHT_OVERVIEW}>
          {renderThoughtOverview()}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.FEELINGS_OVERVIEW}>
          {renderFeelingsOverview()}
        </Step>
        <Step current={stepIndex} step={TriangleSteps.BEHAVIOUR_OVERVIEW}>
          {renderBehaviorOverview()}
        </Step>
      </View>
    </>
  );
};

export default CognitiveTriangleStep;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.WHITE
  },
  topContainer: {
    width: '100%',
    flexDirection: 'column'
  },
  scrollViewContainer: {
    backgroundColor: Colors.WHITE,
    width: '100%'
  },
  scrollViewContentContainer: {
    flexGrow: 1,
    marginHorizontal: 24
  },
  bottomContainer: {
    width: '100%',
    marginBottom: 20
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 40
  },
  sliderText: { fontSize: 16 },
  situationText: {
    marginTop: 34,
    marginHorizontal: 20,
    fontSize: 16
  },
  situationTitle: {
    fontSize: 16,
    color: Colors.GREY2
  },
  pillButtonContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginTop: 20
  },
  triangleContainer: {
    marginVertical: 50
  },
  overviewTitle: {
    fontSize: 20,
    alignSelf: 'center',
    marginBottom: 30
  },
  situationQuestion: {
    marginTop: 55,
    marginBottom: 41,
    fontSize: 20,
    paddingHorizontal: '20%',
    textAlign: 'center'
  },
  singleSelect: { borderRadius: 15 },
  message: {
    borderBottomLeftRadius: 16
  }
});
