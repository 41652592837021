import { useNavigation, useRoute, RouteProp } from '@react-navigation/core';
import { StackNavigationProp, useHeaderHeight } from '@react-navigation/stack';
import React, { FunctionComponent, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  StyleSheet,
  TextInput,
  View
} from 'react-native';

import Colors from '../../../colors';
import KeyboardInput from '../../../components/KeyboardInput';
import MediumText from '../../../components/MediumText';
import PillButton from '../../../components/PillButton';
import SemiBoldText from '../../../components/SemiBoldText';
import env from '../../../env';
import { AuthStackParamList } from '../AuthenticationNavigator';

interface FormValues {
  password: string;
  repeatedPassword: string;
}

const NewPasswordScreen: FunctionComponent = () => {
  const intl = useIntl();
  const repeatPasswordInput = useRef<TextInput>(null);
  const navigation = useNavigation<StackNavigationProp<AuthStackParamList>>();
  const headerHeight = useHeaderHeight();
  const { params } = useRoute<RouteProp<AuthStackParamList, 'NewPassword'>>();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<FormValues>();
  const password = useRef('');
  const repeatedPassword = useRef('');
  password.current = watch('password', '');
  repeatedPassword.current = watch('repeatedPassword', '');

  const handleButtonPress = async (data: FormValues) => {
    const res = await fetch(`${env.API_BASE_URL}/auth/password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: params.code,
        password: password.current
      })
    });

    if (res.status === 200) {
      navigation.navigate('Login');
    } else {
      alert(
        intl.formatMessage({
          description: 'NewPasswordScreen unknown error',
          defaultMessage: 'Something went wrong. Please try again later.'
        })
      );
    }
  };

  return (
    <Pressable
      style={styles.container}
      onPress={Platform.OS === 'web' ? undefined : Keyboard.dismiss}
    >
      <KeyboardAvoidingView
        behavior="position"
        keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 80 + headerHeight}
      >
        <View style={styles.textContainer}>
          <SemiBoldText style={styles.headlineText}>
            <FormattedMessage
              defaultMessage="New Password"
              description="NewPasswordScreen title"
            />
          </SemiBoldText>
          <MediumText style={styles.text}>
            <FormattedMessage
              defaultMessage="Please enter your new password."
              description="NewPasswordScreen prompt"
            />
          </MediumText>
        </View>

        <View style={{ marginTop: 125 }}>
          <Controller
            control={control}
            name="password"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  defaultMessage: 'Please enter a password',
                  description: 'NewPasswordScreen error label no password'
                })
              },
              minLength: {
                value: 8,
                message: intl.formatMessage({
                  defaultMessage: 'Please use at least 8 characters',
                  description: 'NewPasswordScreen error label password length'
                })
              },
              pattern: {
                value:
                  /^(?=.*?[a-zA-Z\u0621-\u064A])(?=.*?[0-9\u0660-\u0669]).{8,}$/,
                message: intl.formatMessage({
                  defaultMessage:
                    'Password not complex enough. Please include at least one letter and one digit.',
                  description: 'NewPasswordScreen error label failed validation'
                })
              }
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <KeyboardInput
                value={value}
                onChangeText={onChange}
                secureTextEntry
                autoCorrect={false}
                autoCapitalize="none"
                returnKeyType="next"
                autoComplete="password"
                textContentType="password"
                multiline={false}
                placeholder={intl.formatMessage({
                  defaultMessage: 'New password',
                  description: 'New password input placeholder'
                })}
                style={{ height: 48 }}
                containerStyle={{ marginHorizontal: 24 }}
                onBlur={onBlur}
                onSubmitEditing={() => repeatPasswordInput.current?.focus()}
                hasValidation
                errorText={errors.password ? errors.password.message : null}
              />
            )}
          />

          <Controller
            control={control}
            name="repeatedPassword"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  defaultMessage: 'Please confirm your password',
                  description:
                    'NewPasswordScreen error label no confirm password'
                })
              },
              validate: (val) =>
                val === password.current ||
                intl.formatMessage({
                  defaultMessage: "Passwords don't match",
                  description: 'NewPasswordScreen error label not matching'
                })
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <KeyboardInput
                ref={repeatPasswordInput}
                value={value}
                onChangeText={onChange}
                secureTextEntry
                autoCorrect={false}
                autoCapitalize="none"
                returnKeyType="done"
                autoComplete="password"
                textContentType="password"
                multiline={false}
                placeholder={intl.formatMessage({
                  defaultMessage: 'Confirm new password',
                  description: 'New password confirmation input placeholder'
                })}
                style={{ height: 48 }}
                containerStyle={{ marginHorizontal: 24, marginTop: 16 }}
                onBlur={onBlur}
                onSubmitEditing={handleSubmit(handleButtonPress)}
                hasValidation
                errorText={
                  errors.repeatedPassword
                    ? errors.repeatedPassword.message
                    : null
                }
              />
            )}
          />
        </View>
        <PillButton
          disabled={!password.current || !repeatedPassword.current}
          style={styles.button}
          onPress={handleSubmit(handleButtonPress)}
        >
          <FormattedMessage
            defaultMessage="Save Password"
            description="NewPasswordScreen button label"
          />
        </PillButton>
      </KeyboardAvoidingView>
    </Pressable>
  );
};

export default NewPasswordScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  textContainer: {
    marginTop: 18,
    marginHorizontal: 24
  },
  headlineText: {
    lineHeight: 32,
    fontSize: 24,
    marginVertical: 14
  },
  text: {
    lineHeight: 26,
    minHeight: 80
  },
  button: {
    alignSelf: 'flex-end',
    marginHorizontal: 24,
    marginTop: 16
  }
});
