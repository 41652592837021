import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React, { FunctionComponent } from 'react';

import IconTopTabHeader from '../IconTopTabHeader';
import MoodScreen from './MoodScreen';
import SubstanceScreen from './SubstanceScreen';

type TrackerTabParamList = {
  Mood: undefined;
  Substance: undefined;
};

const TrackerTab = createMaterialTopTabNavigator<TrackerTabParamList>();

const TrackerNavigator: FunctionComponent = () => {
  return (
    <TrackerTab.Navigator
      tabBar={(props) => (
        <IconTopTabHeader
          {...props}
          icons={{ Substance: 'SUBSTANCE', Mood: 'MOODCIRCLE' }}
        />
      )}
    >
      <TrackerTab.Screen
        name="Substance"
        component={SubstanceScreen}
        options={{
          title: 'Substance'
        }}
      />
      {/* Hidden for Blended version */}
      {/* <TrackerTab.Screen
        name="Mood"
        component={MoodScreen}
        options={{
          title: 'Mood',
        }}
      /> */}
    </TrackerTab.Navigator>
  );
};

export default TrackerNavigator;
