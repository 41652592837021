import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';

import { RootStackParamList } from '../screens/RootNavigator';
import AuthorizedImage from './AuthorizedImage';

interface Props {
  uri: string;
  /**
   * Provide a function to display a button to remove the image.
   */
  onDelete?: () => void;
}

const RemovableImage: FunctionComponent<Props> = (props) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  return (
    <View style={styles.image}>
      <AuthorizedImage source={{ uri: props.uri }} style={styles.image} />
      {props.onDelete ? (
        <TouchableOpacity
          style={{ position: 'absolute', left: 5, bottom: 5 }}
          onPress={() => {
            navigation.navigate('ConfirmationQuestion', {
              onPressYes: props.onDelete!
            });
          }}
        >
          <Image
            style={styles.icon}
            source={require('../assets/binWithBackground.png')}
          />
        </TouchableOpacity>
      ) : null}
      <TouchableOpacity
        style={{ position: 'absolute', right: 5, bottom: 5 }}
        onPress={() => navigation.navigate('Gallery', { uri: props.uri })}
      >
        <Image style={styles.icon} source={require('../assets/zoom.png')} />
      </TouchableOpacity>
    </View>
  );
};

export default RemovableImage;

const styles = StyleSheet.create({
  image: {
    width: 250,
    aspectRatio: 1
  },
  icon: {
    width: 32,
    height: 32,
    backgroundColor: 'white',
    borderRadius: 32 / 2
  }
});
