import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useCallback,
  useState
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';
import RenderHtml from 'react-native-render-html';

import Colors from '../../../../colors';
import Header from '../../../../components/Header';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import MediumText from '../../../../components/MediumText';
import Message from '../../../../components/Message';
import SemiBoldText from '../../../../components/SemiBoldText';
import Swiper, { SwiperRef } from '../../../../components/Swiper';
import useInterval from '../../../../hooks/useInterval';
import { selectSafetyItems } from '../../../../store/exercises';
import {
  getOnboardingFlow,
  getUserFlow,
  updateFlow
} from '../../../../store/flow';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectActiveLanguage } from '../../../../store/languages';
import {
  selectAllSessions,
  selectSessionProgresses,
  upsertSession
} from '../../../../store/sessions';
import { ExclusionBlock, FlowState, Session } from '../../../../store/types';
import { getExclusionData, getUserData } from '../../../../store/user';
import { AppStackParamList } from '../../../AppNavigator';
import { HomeStackParamList } from '../HomeNavigator';
import NotificationCard, {
  NotificationCardProps
} from './components/NotificationCard';
import SessionCard from './components/SessionCard';

const HomeScreen: FunctionComponent = () => {
  const intl = useIntl();
  const navigation =
    useNavigation<
      StackNavigationProp<AppStackParamList & HomeStackParamList>
    >();
  const swiper = useRef<SwiperRef>(null);
  const user = useAppSelector((state) => state.user);
  const flow = useAppSelector((state) => state.flow);
  const sessions = useAppSelector(selectAllSessions);
  const sessionProgresses = useAppSelector(selectSessionProgresses);
  const activeLanguage = useAppSelector(selectActiveLanguage);
  const flowStartDate = useAppSelector((state) => state.flow.startedAt);
  const dispatch = useAppDispatch();
  const safetyItemsEmpty = useAppSelector(selectSafetyItems).length === 0;
  const loading = useAppSelector(
    (state) => state.flow.loading || state.sessions.loading
  );

  const [currentDate, setCurrentDate] = useState(new Date());

  const notificationCardProps: NotificationCardProps[] = [];

  useInterval(() => {
    setCurrentDate(new Date());
  }, 60000);

  useFocusEffect(
    useCallback(() => {
      setCurrentDate(new Date());
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      if (user.status === 'excluded') {
        dispatch(getExclusionData(activeLanguage.code));
        return;
      }
      getFlow();
    }, [activeLanguage.code, user.userId, user.token, user.status]) // NOTE: Use the language code to avoid comparing object references
  );

  const getFlow = async () => {
    let userFlow = null;
    if (user.userId && user.token) {
      const resultAction = await dispatch(getUserData());
      userFlow = (resultAction.payload as { flow: FlowState }).flow;
    }
    if (!userFlow) {
      await dispatch(getOnboardingFlow(activeLanguage.code));
    } else {
      await dispatch(getUserFlow(activeLanguage.code));
    }
    if (flow.id && !flow.started) {
      await dispatch(
        updateFlow({ started: true, startedAt: new Date().toISOString() })
      );
    }

    const sessionsToUnlock = sessions.filter(
      (session) =>
        session.waitFrom === 'start' && session.wait === 0 && !session.unlocked
    );

    if (sessionsToUnlock.length) {
      const unlockedAt = new Date().toISOString();
      sessionsToUnlock.map((session) =>
        dispatch(
          upsertSession({
            entityId: session.id,
            changes: { unlocked: true, unlockedAt }
          })
        )
      );
    }
  };

  useEffect(() => {
    const initialIndex = sessions
      .map((session) => session.unlocked)
      .lastIndexOf(true);
    swiper.current?.scrollToIndex(initialIndex, false);
  }, []);

  const unlockSession = (session: Session) => {
    if (!session.unlocked) {
      dispatch(
        upsertSession({
          entityId: session.id,
          changes: { unlocked: true, unlockedAt: new Date().toISOString() }
        })
      );
    }
  };

  const renderExcludedMessage = (data: ExclusionBlock) => (
    <Message
      wysiwyg
      text={data.text}
      file={data.file}
      containerStyle={styles.excludedMessageContainer}
      style={styles.excludedMessage}
    />
  );

  return (
    <View style={styles.container}>
      <Header
        arrowPosition="center"
        headerRight={
          <TouchableOpacity
            style={{ marginTop: 10 }}
            onPress={() => {
              navigation.navigate('Settings');
            }}
          >
            <Image
              style={styles.settingsIcon}
              source={require('./assets/Settings.png')}
            />
          </TouchableOpacity>
        }
      >
        <MediumText style={styles.greetingText}>
          <FormattedMessage
            defaultMessage="Hello"
            description="HomeScreen header greeting"
          />
        </MediumText>
        <SemiBoldText style={styles.nameText}>{user.fullname}</SemiBoldText>
      </Header>

      {user.exclusionData ? (
        renderExcludedMessage(user.exclusionData)
      ) : (
        <ScrollView style={{ flex: 1 }}>
          <View style={styles.swiperContainer}>
            {loading && (
              <LoadingOverlay
                style={{
                  backgroundColor: '#fff',
                  borderRadius: 100,
                  padding: 4
                }}
              />
            )}
            <Swiper ref={swiper} elementWidth={184}>
              {sessions.map((session, idx) => (
                <SessionCard
                  key={session.id.toString()}
                  onButtonPress={() =>
                    navigation.navigate('Session', {
                      sessionEntityId: session.id
                    })
                  }
                  progress={sessionProgresses[session.id]}
                  session={session}
                  currentDate={currentDate}
                  flowStartDate={flowStartDate}
                  previousSessionCompleteDate={
                    idx === 0
                      ? new Date(0).toISOString()
                      : sessions[idx - 1].completedAt
                  }
                  onCountdownZero={() => unlockSession(session)}
                />
              ))}
            </Swiper>
          </View>
          {!safetyItemsEmpty && (
            <NotificationCard
              title={intl.formatMessage({
                defaultMessage: 'Safety Plan',
                description: 'Safety Plan title'
              })}
              text={intl.formatMessage({
                defaultMessage: 'In case of emergency click here.',
                description: 'Safety plan emergency text'
              })}
              iconSource={require('../../../../assets/safetyplan.png')}
              backgroundColor={Colors.ICEBLUE}
              onPress={() => navigation.navigate('SafetyPlan')}
            />
          )}
          {notificationCardProps.map((data) => (
            <NotificationCard key={data.title} {...data} />
          ))}
        </ScrollView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  greetingText: {
    fontSize: 18,
    color: Colors.GREY1
  },
  nameText: {
    fontSize: 24,
    lineHeight: 32,
    color: Colors.BLUE
  },
  settingsIcon: {
    height: 24,
    width: 24
  },
  swiperContainer: {
    minHeight: 232,
    marginVertical: 28
  },
  excludedMessageContainer: {
    borderBottomLeftRadius: 16,
    width: '80%',
    alignSelf: 'center',
    marginTop: 30
  },
  excludedMessage: {
    width: '100%',
    borderBottomLeftRadius: 16,
    borderColor: Colors.RED,
    borderWidth: 1.5,
    backgroundColor: Colors.WHITE
  }
});

export default HomeScreen;
