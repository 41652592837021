import React, { FunctionComponent } from 'react';
import {
  ActivityIndicator,
  ActivityIndicatorProps,
  Platform,
  StyleSheet,
  View
} from 'react-native';

import Colors from '../colors';

const LoadingOverlay: FunctionComponent<ActivityIndicatorProps> = ({
  color = Platform.select({
    ios: '#999999',
    android: Colors.BLUE,
    default: undefined
  }),
  ...props
}) => {
  return (
    <View style={styles.overlay}>
      <ActivityIndicator color={color} {...props} />
    </View>
  );
};

export default LoadingOverlay;

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999
  }
});
