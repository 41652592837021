import { useIntl } from 'react-intl';

import { SituationLegacy } from './types';

export const useDefaultCognitiveTriangleContent = () => {
  const intl = useIntl();

  const missingDataText = intl.formatMessage({
    defaultMessage: 'Missing template data',
    description: 'Cognitive Triangle missing template data placeholder'
  });

  const feelingsData = [
    intl.formatMessage({
      defaultMessage: 'scared',
      description: 'Scared Feeling'
    }),
    intl.formatMessage({
      defaultMessage: 'concerned',
      description: 'Concerned Feeling'
    }),
    intl.formatMessage({
      defaultMessage: 'unsafe',
      description: 'Unsafe Feeling'
    }),
    intl.formatMessage({
      defaultMessage: 'annoyed',
      description: 'Annoyed Feeling'
    }),
    intl.formatMessage({
      defaultMessage: 'nervous',
      description: 'Nervous Feeling'
    }),
    intl.formatMessage({
      defaultMessage: 'sad',
      description: 'Sad Feeling'
    }),
    intl.formatMessage({
      defaultMessage: 'relaxed',
      description: 'Relaxed Feeling'
    }),
    intl.formatMessage({
      defaultMessage: 'happy',
      description: 'Happy Feeling'
    }),
    intl.formatMessage({
      defaultMessage: 'grateful',
      description: 'Grateful Feeling'
    }),
    intl.formatMessage({
      defaultMessage: 'relieved',
      description: 'Relieved Feeling'
    }),
    intl.formatMessage({
      defaultMessage: 'normal',
      description: 'Normal Feeling'
    })
  ];

  const instructionInitial = intl.formatMessage({
    defaultMessage: 'Default initial instruction.',
    description: 'Cognitive Triangle default initial instruction.'
  });
  const instructionHelpfulApproach = intl.formatMessage({
    defaultMessage: 'Default helpful approach instruction.',
    description: 'Cognitive Triangle default helpful approach instruction.'
  });
  const instructionSummary = intl.formatMessage({
    defaultMessage: 'Default summary instruction.',
    description: 'Cognitive Triangle default summary instruction.'
  });

  const defaultSituations: SituationLegacy[] = [
    {
      text: missingDataText,
      unhelpful: {
        thoughts: [missingDataText],
        behaviors: [missingDataText],
        feelings: feelingsData
      },
      helpful: {
        thoughts: [missingDataText],
        behaviors: [missingDataText],
        feelings: feelingsData
      }
    },
    {
      text: missingDataText,
      unhelpful: {
        thoughts: [missingDataText],
        behaviors: [missingDataText],
        feelings: feelingsData
      },
      helpful: {
        thoughts: [missingDataText],
        behaviors: [missingDataText],
        feelings: feelingsData
      }
    }
  ];

  const defaultInstructions = {
    instructionInitial,
    instructionHelpfulApproach,
    instructionSummary
  };

  return { defaultInstructions, defaultSituations };
};
