import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { StyleSheet, BackHandler, TouchableOpacity, Image } from 'react-native';

import { isRtl } from '../lang';
import { useAppSelector } from '../store/hooks';
import { selectActiveLanguage } from '../store/languages';

// https://reactnavigation.org/docs/custom-android-back-button-handling/

/**
 *
 * @param isFirstScreen Method that determines whether or not the component that is using the hook is on the first screen of the drawer
 * @param regularBackwardNav Method that describes what should happen when navigating backwards from any screen OTHER THAN THE FIRST
 * @param deps Dependency array for the useCallback called by the useFocusEffect (usually should include the stepIndex if we use it)
 * @param navigation Navigation prop for the component that is using the hook
 * @param closeExercise What should happen when the screen closes
 * @param firstScreenBackwardNav Override for when you don't want the first screen backward navigation to trigger the "Close exercise" confirmation dialog before applying the closeExercise method
 *
 * @returns
 */
export const useBackHandler = (
  isFirstScreen: () => boolean,
  regularBackwardNav: () => void,
  deps: any[],
  navigation: any,
  closeExercise?: () => void,
  firstScreenBackwardNav?: () => void,
  drawerCloseExerciseText?: string
): {
  getDrawerIcon: () => JSX.Element;
} => {
  const rtl = isRtl(useAppSelector(selectActiveLanguage).language);

  const defaultCloseExercise = () => {
    navigation.goBack();
  };

  const defaultFirstScreenBackwardNav = () => {
    navigation.navigate('DrawerCloseExercise', {
      closeExercise: closeExercise ?? defaultCloseExercise,
      text: drawerCloseExerciseText
    });
  };

  const handleBackwardNavigation = () => {
    if (isFirstScreen()) {
      firstScreenBackwardNav
        ? firstScreenBackwardNav()
        : defaultFirstScreenBackwardNav();
    } else {
      regularBackwardNav();
    }
    return true; // Should return `true` if you DON'T want the event to bubble up for React Navigation to handle it
  };

  /**
   * Attaches the backward navigation handler to Android's back button press event
   */
  useFocusEffect(
    useCallback(() => {
      BackHandler.addEventListener(
        'hardwareBackPress',
        handleBackwardNavigation
      );

      return () =>
        BackHandler.removeEventListener(
          'hardwareBackPress',
          handleBackwardNavigation
        );
    }, deps)
  );

  /**
   * Returns the component to be used on the left side of the exercise drawers
   */
  const getDrawerIcon = () => {
    const first = isFirstScreen();

    return (
      <TouchableOpacity onPress={handleBackwardNavigation}>
        <Image
          source={
            first
              ? require('../assets/arrow_down.png')
              : require('../assets/arrow_left.png')
          }
          style={[styles.arrow, rtl && !first && styles.rtlArrow]}
        />
      </TouchableOpacity>
    );
  };

  return { getDrawerIcon };
};

const styles = StyleSheet.create({
  arrow: {
    width: 30,
    height: 30,
    resizeMode: 'contain'
  },
  rtlArrow: {
    transform: [{ scaleX: -1 }]
  }
});
