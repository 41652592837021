import { useNavigation } from '@react-navigation/native';
import { StackHeaderProps } from '@react-navigation/stack';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import { isRtl } from '../../../../../lang';
import { useAppSelector } from '../../../../../store/hooks';
import { selectActiveLanguage } from '../../../../../store/languages';

const SettingsHeader: FunctionComponent<PropsWithChildren<StackHeaderProps>> = (
  props
) => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const rtl = isRtl(useAppSelector(selectActiveLanguage).language);
  const { headerTitle, headerRight } = props.scene?.descriptor?.options;

  const renderLeftComponent = () => {
    return (
      <TouchableOpacity
        style={styles.iconContainer}
        onPress={() => {
          navigation.goBack();
        }}
        hitSlop={{ bottom: 20, left: 20, right: 20, top: 20 }}
      >
        <Image
          style={[styles.icon, rtl && { transform: [{ scaleX: -1 }] }]}
          source={
            props.scene.route.name === 'Settings'
              ? require('../../../../../assets/Close.png')
              : require('../../../../../assets/arrow_left.png')
          }
        />
      </TouchableOpacity>
    );
  };

  const renderRightComponent = () => {
    if (headerRight) {
      return <View>{headerRight({})}</View>;
    }
    return <View style={{ width: 86 }} />;
  };
  return (
    <View style={[styles.container, { paddingTop: insets.top + 9 }]}>
      <View style={styles.upperContainer}>
        {renderLeftComponent()}
        <MediumText
          style={{
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          {headerTitle as string}
        </MediumText>
        {renderRightComponent()}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
    paddingHorizontal: 24,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.GREY5,
    backgroundColor: Colors.WHITE
  },
  upperContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingVertical: 8
  },
  iconContainer: {
    height: 24,
    width: 86,
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  icon: {
    height: 24,
    width: 24
  }
});

export default SettingsHeader;
