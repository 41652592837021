import { RouteProp, useRoute } from '@react-navigation/native';
import React from 'react';
import { useIntl } from 'react-intl';

import Drawer from '../../../../components/Drawer';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import OthersResponsibilityPie from '../ResponsibilityPieScreen/components/OthersResponsibilityPie';

const ResponsibilityPieArchiveItemScreen = () => {
  const { params } =
    useRoute<RouteProp<DrawerStackParamList, 'ResponsibilityPieArchiveItem'>>();
  const intl = useIntl();
  const reasons = params.reasons.slice(1); // It's expected that the first reason will always be the user's own fault, whcih we don't want to include in this archive item

  return (
    <Drawer
      title={intl.formatMessage({
        defaultMessage: 'Responsibility Pie',
        description: 'ResponsibilityPie drawer title'
      })}
    >
      <OthersResponsibilityPie
        archived
        othersResponsibility={reasons.slice()}
        setOthersResponsibility={() => {}}
        onNext={() => {}}
      />
    </Drawer>
  );
};

export default ResponsibilityPieArchiveItemScreen;
