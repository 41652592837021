import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-datetimeformat/locale-data/ar';
import '@formatjs/intl-datetimeformat/locale-data/de';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-datetimeformat/add-all-tz';
import {
  addMonths,
  addWeeks,
  endOfWeek,
  startOfWeek,
  subWeeks,
  subMonths
} from 'date-fns';
import React, { FunctionComponent, useState } from 'react';
import { FormattedDate, FormattedDateTimeRange } from 'react-intl';
import { Platform, useWindowDimensions } from 'react-native';
import { ExpandableCalendar } from 'react-native-calendars';

import MediumText from '../../MediumText';
import CalendarArrow from '../components/CalendarArrow';
import { ArrowDirection, Direction, DateObject } from '../types';

const WeeklyCalendar: FunctionComponent = ({ ...props }) => {
  const { width: screenWidth } = useWindowDimensions();
  const [pageDate, setPageDate] = useState<Date>(new Date());
  const [arrowsActive, setArrowsActive] = useState(true);
  const isWeb = Platform.OS === 'web';

  // Avoid rapid pressing edge-cases.
  const debounceArrows = () => {
    setArrowsActive(false);
    setTimeout(() => setArrowsActive(true), 500);
  };

  // Update title's reference to the current week.
  const onArrowPress = (direction: ArrowDirection) => {
    if (arrowsActive) {
      debounceArrows();
      if (direction === Direction.Left) {
        const previous = isWeb ? subMonths(pageDate, 1) : subWeeks(pageDate, 1);
        setPageDate(previous);
      } else {
        const next = isWeb ? addMonths(pageDate, 1) : addWeeks(pageDate, 1);
        setPageDate(next);
      }
    }
  };

  const renderRangeHeader = () => (
    <MediumText>
      <FormattedDateTimeRange
        from={startOfWeek(pageDate, { weekStartsOn: 1 })}
        to={endOfWeek(pageDate, { weekStartsOn: 1 })}
        month="short"
        day="numeric"
      />
    </MediumText>
  );

  const renderMonthHeader = () => (
    <MediumText>
      <FormattedDate value={pageDate} month="long" />
    </MediumText>
  );

  const renderArrow = (direction: ArrowDirection) => (
    <CalendarArrow direction={direction} />
  );

  return (
    <ExpandableCalendar
      {...props}
      // @ts-ignore: Missing library type.
      calendarWidth={screenWidth - 32}
      renderHeader={isWeb ? renderMonthHeader : renderRangeHeader}
      hideKnob
      disablePan
      renderArrow={renderArrow}
      disableArrowLeft={!arrowsActive}
      disableArrowRight={!arrowsActive}
      onPressArrowLeft={() => onArrowPress(Direction.Left)}
      onPressArrowRight={() => onArrowPress(Direction.Right)}
    />
  );
};

export default WeeklyCalendar;
