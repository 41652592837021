import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { FlatList, ListRenderItem, StyleSheet } from 'react-native';
import {
  SafeAreaView,
  useSafeAreaInsets
} from 'react-native-safe-area-context';

import Colors from '../../../../colors';
import { selectLetterToAFriendExercises } from '../../../../store/exercises';
import { useAppSelector } from '../../../../store/hooks';
import { LetterToAFriendExercise } from '../../../../store/types';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import { RootStackParamList } from '../../../RootNavigator';
import LetterToAFriendArchiveItem from './components/LetterToAFriendArchiveItem';

const LetterToAFriendArchiveScreen: FunctionComponent = () => {
  const letterToAFriendExercises = useAppSelector(
    selectLetterToAFriendExercises
  );
  const navigation =
    useNavigation<
      StackNavigationProp<RootStackParamList & DrawerStackParamList>
    >();
  const insets = useSafeAreaInsets();

  const renderItem: ListRenderItem<LetterToAFriendExercise> = ({
    item: exercise
  }) => {
    const onPress = () => {
      navigation.navigate('Drawer', {
        screen: 'LetterToAFriendArchiveItem',
        params: {
          letter: exercise.letter,
          helpfulThought: exercise.thought,
          date: exercise.createdAt,
          files: exercise.files
        }
      });
    };

    return (
      <LetterToAFriendArchiveItem
        helpfulThought={exercise.thought}
        date={exercise.createdAt}
        text={exercise.letter}
        onPress={onPress}
      />
    );
  };

  return (
    <SafeAreaView style={styles.container} edges={['left', 'right']}>
      <FlatList
        data={letterToAFriendExercises}
        renderItem={renderItem}
        contentContainerStyle={{
          paddingTop: 16,
          paddingBottom: insets.bottom + 8
        }}
      />
    </SafeAreaView>
  );
};

export default LetterToAFriendArchiveScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  fabContainer: {
    position: 'absolute',
    right: 20
  }
});
