import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Update
} from '@reduxjs/toolkit';

import env from '../env';
import {
  nullishCoalesce,
  extractFileTypeFromMimeType,
  filterTranslations,
  parseIfJson
} from '../helpers';
import { getOnboardingFlow, getUserFlow } from './flow';
import {
  getAssetFromFile,
  getCognitiveTriangleTemplateFromApiObject,
  getListExerciseTemplateFromApiObject,
  getTemplateFromM2A,
  getWrittenExposureTemplateFromApiObject
} from './helpers';
import { RootState } from './index';
import { selectModulesByMultipleIds } from './modules';
import { Block, Module, Session, UnlockableFeature } from './types';

const sessionsAdapter = createEntityAdapter<Session>({
  selectId: (session) => session.id
});

const initialState = sessionsAdapter.getInitialState({ loading: false });

export const fetchSession = createAsyncThunk<
  { session: Session; modules: Module[]; blocks: Block[] },
  { entityId: string; languageCode: string },
  { state: RootState }
>('sessions/fetchSession', async ({ entityId, languageCode }, { getState }) => {
  let apiData: Partial<Session & { prototypicalSession: number }> = {};
  const sessionState = getState().sessions.entities[entityId] as Session;

  if (getState().user.userId) {
    const apiRes = await fetch(
      `${env.API_BASE_URL}/users/${getState().user.userId}/sessions/${
        sessionState.sessionId
      }`,
      {
        headers: {
          Authorization: `Bearer ${getState().user.token}`
        }
      }
    );

    apiData = await apiRes.json();
  }

  const cmsRes = await fetch(
    `${env.API_BASE_URL}/sessions/${sessionState.sessionId}`
  );

  const data = extractFileTypeFromMimeType(
    filterTranslations(await cmsRes.json(), languageCode)
  );

  const session = {
    ...sessionState,
    sessionId: sessionState.sessionId,
    flowId: getState().flow.id,
    title: data.translations[0]?.title ?? data.translations[0].placeholder,
    file: {
      id: data.file?.id,
      type: data.file?.type,
      url: data.file?.id ? `${env.CMS_BASE_URL}/assets/${data.file?.id}` : '',
      localUrl: null
    },
    theme: {
      primary: data.theme?.primary_color,
      secondary: data.theme?.secondary_color
    },
    moduleIds: data.modules.map(
      (module: any) => `${sessionState.id}-${module.id}`
    ),
    unlocked: nullishCoalesce(apiData.unlocked, sessionState.unlocked, false),
    started: nullishCoalesce(apiData.started, sessionState.started, false),
    completed: nullishCoalesce(
      apiData.completed,
      sessionState.completed,
      false
    ),
    unlockedAt: nullishCoalesce(
      apiData.unlockedAt,
      sessionState.unlockedAt,
      null
    ),
    startedAt: nullishCoalesce(apiData.startedAt, sessionState.startedAt, null),
    completedAt: nullishCoalesce(
      apiData.completedAt,
      sessionState.completedAt,
      null
    ),
    wait: data.wait,
    waitFrom: data.wait_from
  };

  let apiModules: { [id: number]: Partial<Module> } = {};

  if (getState().user.userId) {
    const apiModulesRes = await fetch(
      `${env.API_BASE_URL}/users/${getState().user.userId}/sessions/${
        sessionState.sessionId
      }/modules`,
      {
        headers: {
          Authorization: `Bearer ${getState().user.token}`
        }
      }
    );

    const apiModulesData = (await apiModulesRes.json()).items;

    apiModules = apiModulesData.reduce(
      (
        res: typeof apiModules,
        module: Partial<Module> & { prototypicalModule: number }
      ) => {
        return {
          ...res,
          [module.prototypicalModule]: module
        };
      },
      apiModules
    );
  }

  const modules: Module[] = data.modules.map((module: any) => {
    const apiModule = apiModules[module.id];
    const entityId = `${sessionState.id}-${module.id}`;
    const moduleState = getState().modules.entities[entityId];

    return {
      id: entityId,
      moduleId: module.id,
      sessionId: sessionState.sessionId,
      flowId: getState().flow.id,
      file: {
        id: module.file?.id,
        type: module.file?.type,
        url: module.file?.id
          ? `${env.CMS_BASE_URL}/assets/${module.file.id}`
          : ''
      },
      narrator: module.narrator
        ? {
            id: module.narrator.id,
            file: {
              id: module.narrator.file?.id,
              type: module.narrator.file?.type,
              url: module.narrator.file?.id
                ? `${env.CMS_BASE_URL}/assets/${module.narrator.file?.id}`
                : null
            }
          }
        : null,
      blockIds: module.blocks.map(
        (block: any) =>
          `${sessionState.id}-${module.id}-${block.item.id}-${block.collection}`
      ),
      started: nullishCoalesce(apiModule?.started, moduleState?.started, false),
      completed: nullishCoalesce(
        apiModule?.completed,
        moduleState?.completed,
        false
      ),
      startedAt: nullishCoalesce(
        apiModule?.startedAt,
        moduleState?.startedAt,
        null
      ),
      completedAt: nullishCoalesce(
        apiModule?.completedAt,
        moduleState?.completedAt,
        null
      )
    };
  });

  let apiBlocks: {
    [id: number]: { [id: string]: Partial<Block> & { answer: string } };
  } = {};

  if (getState().user.userId) {
    const apiBlocksRes = await Promise.all(
      data.modules.map((module: any) => {
        return fetch(
          `${env.API_BASE_URL}/users/${getState().user.userId}/sessions/${
            sessionState.sessionId
          }/modules/${module.id}/blocks`,
          {
            headers: {
              Authorization: `Bearer ${getState().user.token}`
            }
          }
        ).catch(() => null);
      })
    );

    const apiBlocksData = await Promise.all(
      apiBlocksRes.map((res: any) => res.json())
    );

    apiBlocks = data.modules.reduce((res: any, module: any, idx: number) => {
      return {
        ...res,
        [module.id]: (apiBlocksData[idx]?.items ?? []).reduce(
          (
            blocksRes: any,
            block: Partial<Block> & { prototypicalBlock: number }
          ) => {
            return {
              ...blocksRes,
              [`${block.prototypicalBlock}-${block.collection}`]: block
            };
          },
          {}
        )
      };
    }, apiBlocks);
  }

  const blocks: Block[] = data.modules.reduce(
    (blocksAcc: Block[], module: any) => {
      const getExerciseBlockProps = (block: any) => {
        const cabTemplate = block.item.concerns_and_barriers_template;
        const audioExercise = block.item.audio_exercise;
        const weTemplate = block.item.written_exposure_template;
        const listTemplate = block.item.list_exercise_template;
        return {
          exercise: block.item.exercise,
          cognitiveTriangleTemplate:
            block.item.cognitive_triangle_template &&
            getCognitiveTriangleTemplateFromApiObject(
              block.item.cognitive_triangle_template
            ),
          concernsAndBarriersTemplate: cabTemplate && {
            instruction: cabTemplate.translations[0]?.instruction,
            userInput: cabTemplate.user_input,
            items: cabTemplate.items.map((item: any) => {
              const tr = item.concerns_and_barriers_items_id.translations[0];
              return {
                problem: nullishCoalesce(tr?.problem, tr?.placeholder),
                response: nullishCoalesce(tr?.response, tr?.placeholder)
              };
            })
          },
          safetyPlanListInputTemplate: block.item.safety_plan_list_item && {
            cmsName: block.item.safety_plan_list_item.name,
            title: nullishCoalesce(
              block.item.safety_plan_list_item.translations[0]?.title,
              block.item.safety_plan_list_item.translations[0]?.placeholder
            ),
            instruction: nullishCoalesce(
              block.item.safety_plan_list_item.translations[0]?.instruction,
              block.item.safety_plan_list_item.translations[0]?.placeholder
            )
          },
          audioExercise: audioExercise && {
            title: audioExercise.translations[0]?.title,
            audioName: audioExercise.translations[0]?.audio_name,
            file: audioExercise.translations[0]?.file
              ? {
                  id: audioExercise.translations[0]?.file?.id,
                  type: audioExercise.translations[0]?.file?.type,
                  url: audioExercise.translations[0]?.file?.id
                    ? `${env.CMS_BASE_URL}/assets/${audioExercise.translations[0]?.file?.id}`
                    : null
                }
              : null
          },
          writtenExposureTemplate:
            weTemplate && getWrittenExposureTemplateFromApiObject(weTemplate),
          listExerciseTemplate:
            listTemplate && getListExerciseTemplateFromApiObject(listTemplate)
        };
      };

      const getQuestionBlockProps = (
        block: any,
        apiBlock: any,
        blockState?: Block
      ) => ({
        skippable: block.item.skippable,
        type: block.item.type,
        options: block.item.options.map((opt: any) => ({
          ...opt.answer_options_id,
          text:
            opt.answer_options_id.translations[0]?.text ??
            opt.answer_options_id.translations[0]?.placeholder
        })),
        inputType: block.item.input_type,
        min: block.item.min,
        max: block.item.max,
        interval: block.item.interval,
        exclusionCheck: block.item.exclusion_check,
        userConfig: block.item.user_config
      });

      const getNarrativeBlockProps = (block: any) => ({
        messengerLink: block.item.messenger_link,
        buttonText:
          block.item.translations[0]?.button_text ??
          block.item.translations[0]?.placeholder,
        buttonAudio: {
          id: block.item.translations[0]?.button_audio?.id,
          type: block.item.translations[0]?.button_audio?.type,
          url: block.item.translations[0]?.button_audio?.id
            ? `${env.CMS_BASE_URL}/assets/${block.item.translations[0]?.button_audio?.id}`
            : null
        }
      });

      const getCollectionExtraProps = (
        block: any,
        apiBlock: any,
        blockState?: Block
      ) => {
        switch (block.collection) {
          case 'exercise_blocks':
            return getExerciseBlockProps(block);
          case 'question_blocks':
            return getQuestionBlockProps(block, apiBlock, blockState);
          case 'narrative_blocks':
            return getNarrativeBlockProps(block);
          default:
            return {};
        }
      };
      return [
        ...blocksAcc,
        ...module.blocks.map((block: any) => {
          const entityId = `${sessionState.id}-${module.id}-${block.item.id}-${block.collection}`;
          const blockState = getState().blocks.entities[entityId];
          const narrator = block.item.narrator ?? module.narrator;

          const apiBlock = apiBlocks[module.id]
            ? apiBlocks[module.id][`${block.item.id}-${block.collection}`]
            : null;

          const extraProps = getCollectionExtraProps(
            block,
            apiBlock,
            blockState
          );

          return {
            id: entityId,
            unlockFeatures: block.item.unlock_features
              .map((feature: any) => feature.unlockable_features_id)
              .map(
                (feature: any) =>
                  ({
                    id: feature.id,
                    unlockedFeature: feature.unlocked_feature,
                    file: getAssetFromFile(feature.file),
                    template: getTemplateFromM2A(feature.template)
                  } as UnlockableFeature)
              ),
            blockId: block.item.id,
            moduleId: module.id,
            sessionId: sessionState.sessionId,
            flowId: getState().flow.id,
            collection: block.collection,
            narrator: narrator
              ? {
                  id: narrator.id,
                  file: {
                    id: narrator.file?.id,
                    type: narrator.file?.type,
                    url: narrator.file?.id
                      ? `${env.CMS_BASE_URL}/assets/${narrator.file?.id}`
                      : null
                  }
                }
              : null,
            typing: block.item.typing,
            file: block.item.translations[0]?.file
              ? {
                  id: block.item.translations[0]?.file?.id,
                  type: block.item.translations[0]?.file?.type,
                  url: block.item.translations[0]?.file?.id
                    ? `${env.CMS_BASE_URL}/assets/${block.item.translations[0]?.file?.id}`
                    : null
                }
              : null,
            text:
              block.item.translations[0]?.text ??
              block.item.translations[0]?.placeholder,
            condition: [
              (block.item.condition_questions as any[]).reduce(
                (prev, questionBlock: any) => {
                  const questionBlockId =
                    questionBlock.related_question_blocks_id ??
                    questionBlock.question_blocks_id;
                  const conditionBlockEntityId = `${sessionState.id}-${
                    module.id
                  }-${questionBlockId}-${'question_blocks'}`;
                  prev.push(conditionBlockEntityId);
                  return prev;
                },
                [] as any[]
              ),
              block.item.condition_operator,
              block.item.condition_value
            ],
            ...extraProps,
            completed: nullishCoalesce(
              apiBlock?.completed,
              blockState?.completed,
              false
            ),
            completedAt: nullishCoalesce(
              apiBlock?.completedAt,
              blockState?.completedAt,
              null
            ),
            answer: nullishCoalesce(
              apiBlock?.answer
                ? parseIfJson(apiBlock?.answer)
                : blockState?.answer,
              ''
            )
          };
        })
      ];
    },
    [] as Block[]
  );

  return {
    session,
    modules,
    blocks
  };
});

export const upsertSession = createAsyncThunk<
  Update<Session>,
  { entityId: string; changes: Partial<Session> },
  { state: RootState }
>('sessions/upsertSession', async ({ entityId, changes }, { getState }) => {
  if (!getState().user.userId) {
    return {
      id: entityId,
      changes
    };
  }

  const flowState = getState().flow;
  const sessionState = getState().sessions.entities[entityId] as Session;

  const apiRes = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/sessions/${
      sessionState.sessionId
    }`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...changes, flowId: flowState?.id ?? null })
    }
  );

  const apiData = await apiRes.json();

  if (!apiRes.ok) {
    throw new Error(
      apiData.code && apiData.message
        ? `${apiData.code}: ${apiData.message}`
        : `${apiRes.status} ${apiRes.statusText}`
    );
  }

  return {
    id: entityId,
    changes: {
      unlocked: nullishCoalesce(apiData.unlocked, sessionState.unlocked),
      started: nullishCoalesce(apiData.started, sessionState.started),
      completed: nullishCoalesce(apiData.completed, sessionState.completed),
      unlockedAt: nullishCoalesce(apiData.unlockedAt, sessionState.unlockedAt),
      startedAt: nullishCoalesce(apiData.startedAt, sessionState.startedAt),
      completedAt: nullishCoalesce(
        apiData.completedAt,
        sessionState.completedAt
      )
    }
  };
});

export const upsertAllSessions = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('sessions/upsertAllSessions', async (_, { getState, dispatch }) => {
  await Promise.allSettled(
    selectAllSessions(getState()).map((session) =>
      dispatch(
        upsertSession({
          entityId: session.id,
          changes: {
            unlocked: session.unlocked,
            unlockedAt: session.unlockedAt ?? undefined,
            started: session.started,
            startedAt: session.startedAt ?? undefined,
            completed: session.completed,
            completedAt: session.completedAt ?? undefined
          }
        })
      )
    )
  );
});

export const sessionsSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    addSession: sessionsAdapter.addOne,
    updateSession: sessionsAdapter.updateOne
  },
  extraReducers: (builder) => {
    builder.addCase(getOnboardingFlow.fulfilled, (state, action) => {
      sessionsAdapter.setAll(state, action.payload.sessions);
    });
    builder.addCase(getUserFlow.fulfilled, (state, action) => {
      sessionsAdapter.setAll(state, action.payload.sessions);
    });
    builder.addCase(fetchSession.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchSession.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchSession.fulfilled, (state, action) => {
      state.loading = false;
      sessionsAdapter.upsertOne(state, action.payload.session);
    });
    builder.addCase(upsertSession.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(upsertSession.fulfilled, (state, action) => {
      state.loading = false;
      sessionsAdapter.updateOne(state, action.payload);
    });
    builder.addCase(upsertSession.rejected, (state, action) => {
      state.loading = false;
    });
  }
});

export const { selectAll: selectAllSessions, selectById: selectSessionById } =
  sessionsAdapter.getSelectors<RootState>((state) => state.sessions);

export const selectCompletedSessions = createSelector(
  [selectAllSessions],
  (sessions) => sessions.filter((session) => !!session.completedAt)
);

export const selectAllSessionModules = (state: RootState) =>
  selectAllSessions(state).reduce(
    (prev, session) => ({
      ...prev,
      [session.id]: selectModulesByMultipleIds(state, session.moduleIds)
    }),
    {} as { [sessionId: string]: Module[] }
  );

export const selectSessionProgresses = createSelector(
  [selectAllSessionModules],
  (modules) => {
    return Object.keys(modules).reduce((prev, curr) => {
      const progress = modules[curr].length
        ? (modules[curr].filter((module) => module.completed).length /
            modules[curr].length) *
          100
        : 0;
      return {
        ...prev,
        [curr]: progress
      };
    }, {} as { [id: string]: number });
  }
);

export const { addSession, updateSession } = sessionsSlice.actions;
export default sessionsSlice.reducer;
