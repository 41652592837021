import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import { RootState } from '.';
import env from '../env';
import { Gender, Language } from './types';

const languagesAdapter = createEntityAdapter<Language>({
  selectId: (language) => language.code
});

const initialState = languagesAdapter.getInitialState();

export const fetchLanguages = createAsyncThunk<
  Language[],
  void,
  { state: RootState }
>('languages/fetchLanguages', async (_, { getState }) => {
  const response = await fetch(`${env.API_BASE_URL}/languages`);

  const languages = await response.json();

  if (!response.ok) {
    throw new Error(
      languages.code && languages.message
        ? `${languages.code}: ${languages.message}`
        : `${response.status} ${response.statusText}`
    );
  }

  return languages.map((language: any) => {
    const {
      language_code,
      country_code,
      displayed_name,
      ...formattedLanguage
    } = language;

    return {
      ...formattedLanguage,
      language: language_code,
      country: country_code,
      displayedName: displayed_name,
      selected: getState().languages.entities[language.code]?.selected ?? false
    };
  });
});

export const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    updateLanguage: languagesAdapter.updateOne,
    setLanguage: (state, action: PayloadAction<string>) => {
      languagesAdapter.updateMany(
        state,
        state.ids.map((id) => ({ id, changes: { selected: false } }))
      );
      languagesAdapter.updateOne(state, {
        id: action.payload,
        changes: { selected: true }
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLanguages.fulfilled, (state, action) => {
      languagesAdapter.setAll(state, action.payload);
    });
  }
});

export const { selectAll: selectAllLanguages } =
  languagesAdapter.getSelectors<RootState>((state) => state.languages);

// We use this selector to get languages that either have no gender OR have our current gender.
// If we have no gender selected, we select male as fallback.
export const selectGenderCompatibleLanguages = (state: RootState) => {
  const gender = state.user.gender;

  const hasOurGenderOrNone = (lan: Language) =>
    lan.gender === gender || !lan.gender;
  const hasMaleFallbackOrNone = (lan: Language) =>
    lan.gender === Gender.Male || !lan.gender;

  return selectAllLanguages(state).filter(
    (lan) =>
      (gender !== null && hasOurGenderOrNone(lan)) ||
      (gender === null && hasMaleFallbackOrNone(lan))
  );
};

export const selectActiveLanguage = (state: RootState) => {
  const allLanguages = selectAllLanguages(state);

  let activeLanguage = allLanguages.find((lang) => lang.selected);
  if (!activeLanguage) {
    activeLanguage = allLanguages.find((lang) => lang.country?.match('de')) ??
      allLanguages[0] ?? {
        code: 'de',
        language: 'de',
        country: 'de'
      };
  }
  return activeLanguage;
};

export const { updateLanguage, setLanguage } = languagesSlice.actions;
export default languagesSlice.reducer;
