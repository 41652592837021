import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  FlatList,
  Image,
  Platform,
  LayoutChangeEvent,
  Linking,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import SemiBoldText from '../../../../../components/SemiBoldText';
import {
  BaseSafetyItem,
  ContactSafetyItem,
  ExerciseType,
  SafetyItem
} from '../../../../../store/types';
import { RootStackParamList } from '../../../../RootNavigator';

interface Props {
  section: string;
  addItemTitle?: string;
  items: SafetyItem[];
  editMode: boolean;
  onAdd: () => void;
  onDelete: (id: string) => void;
}

const SafetyItemCard: FunctionComponent<Props> = ({
  section,
  addItemTitle,
  items,
  editMode,
  onAdd,
  onDelete
}) => {
  const offset = useRef(0);
  const [expanded, setExpanded] = useState(false);

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const intl = useIntl();

  const onPress = () => {
    setExpanded(!expanded);
  };

  // Keep reference of offset for scrolling.
  const onLayout = ({ nativeEvent: { layout } }: LayoutChangeEvent) => {
    offset.current = layout.y;
  };

  const renderItem = (item: SafetyItem) => {
    if (item.type === ExerciseType.SafetyPlanAddressList) {
      return renderContactItem(item as ContactSafetyItem);
    }

    return renderTextItem(item as BaseSafetyItem);
  };

  const renderTitle = (title: string) => {
    return (
      <View style={{ flex: 1 }}>
        <SemiBoldText style={[styles.name, { marginBottom: 10 }]}>
          {title}
        </SemiBoldText>
      </View>
    );
  };

  const renderTextItem = (item: BaseSafetyItem) => {
    return (
      <>
        {renderTitle(item.name)}
        {editMode && renderEditSection(item)}
      </>
    );
  };

  const renderContactItem = (item: ContactSafetyItem) => {
    const renderPhoneNumber = () => {
      const isDesktopBrowser = () => {
        return ((a) => {
          if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
              a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
              a.substr(0, 4)
            )
          ) {
            return false;
          }
          return true;
        })(navigator.userAgent || navigator.vendor);
      };
      const { phoneNumber } = item;
      return (
        <TouchableOpacity
          style={styles.infoContainer}
          disabled={Platform.OS === 'web' && isDesktopBrowser()}
          onPress={() => {
            navigation.navigate('ConfirmationQuestion', {
              question: intl.formatMessage(
                {
                  defaultMessage: `Do you really want to call {phoneNumber}?`,
                  description: 'Confirmation calling phone number'
                },
                { phoneNumber }
              ),
              onPressYes: () => {
                const numberWithoutSpaces = phoneNumber?.replace(/\s/g, '');
                if (Platform.OS === 'web') {
                  return window.open(`tel://${numberWithoutSpaces}`, '_self');
                }
                Linking.openURL(`tel://${numberWithoutSpaces}`);
              }
            });
          }}
        >
          <Image
            source={require('../../../../../assets/phone.png')}
            style={styles.infoIcon}
          />
          <MediumText style={styles.info}>{item.phoneNumber}</MediumText>
        </TouchableOpacity>
      );
    };
    return (
      <>
        <View style={{ flex: 1 }}>
          {renderTitle(item.name)}
          {item.phoneNumber && renderPhoneNumber()}
          {item.address && (
            <View style={styles.infoContainer}>
              <Image
                source={require('../../../../../assets/house.png')}
                style={styles.infoIcon}
              />
              <MediumText style={styles.info}>{item.address}</MediumText>
            </View>
          )}
        </View>
        {editMode && renderEditSection(item)}
      </>
    );
  };

  const renderEditSection = (item: SafetyItem) => {
    return (
      <TouchableOpacity
        onPress={() => onDelete(item.id)}
        style={{ marginTop: 2 }}
      >
        <Image
          source={require('../../../../../assets/binRed.png')}
          style={styles.bin}
        />
      </TouchableOpacity>
    );
  };

  const renderAddItem = () => {
    if (editMode)
      return (
        <View
          style={[
            styles.addListItemContainer,
            { borderTopWidth: items.length > 0 ? 1 : 0 }
          ]}
        >
          <TouchableOpacity onPress={onAdd} style={{ flexDirection: 'row' }}>
            <Image
              source={require('../../../../../assets/plusTransparent.png')}
              style={styles.plusTransparent}
            />
            <SemiBoldText style={styles.addListItemText}>
              {addItemTitle ?? (
                <FormattedMessage
                  defaultMessage="Add list item"
                  description="Add new safety item"
                />
              )}
            </SemiBoldText>
          </TouchableOpacity>
        </View>
      );
  };

  const renderExpandedContent = () => {
    return (
      <View style={styles.contentContainer}>
        <FlatList
          data={items}
          ListFooterComponent={renderAddItem()}
          renderItem={(listItem) => {
            const isLastItem = listItem.index === items.length - 1;
            return (
              <View
                style={[
                  styles.contentListItem,
                  { borderBottomWidth: isLastItem ? 0 : 1 }
                ]}
              >
                {renderItem(listItem.item)}
              </View>
            );
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container} onLayout={onLayout}>
      <Pressable style={styles.sectionContainer} onPress={onPress}>
        <SemiBoldText style={styles.name}>{section}</SemiBoldText>
        <Image
          source={require('../../../../../assets/arrow_down.png')}
          style={[
            styles.icon,
            expanded && { transform: [{ rotate: '180deg' }] }
          ]}
        />
      </Pressable>
      {expanded && renderExpandedContent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 24,
    borderRadius: 4,
    backgroundColor: Colors.WHITE
  },
  sectionContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 15,
    width: '100%'
  },
  name: {
    flex: 1,
    fontSize: 16,
    lineHeight: 26
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  info: {
    flex: 1,
    fontSize: 14,
    lineHeight: 26
  },
  infoIcon: {
    width: 20,
    height: 20,
    marginRight: 16
  },
  icon: {
    marginLeft: 24,
    height: 24,
    width: 24
  },
  contentContainer: {
    borderTopColor: Colors.GREY5,
    borderTopWidth: 1
  },
  bin: { width: 20, height: 20, tintColor: Colors.RED },
  plusTransparent: {
    width: 24,
    height: 24,
    marginHorizontal: 8
  },
  addListItemContainer: {
    marginHorizontal: 8,
    paddingVertical: 11,
    borderColor: Colors.GREY5
  },
  addListItemText: { color: Colors.BLUE },
  contentListItem: {
    marginHorizontal: 16,
    flexDirection: 'row',
    borderColor: Colors.GREY5,
    paddingVertical: 11
  }
});

export default SafetyItemCard;
