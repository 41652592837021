import { useNavigation } from '@react-navigation/native';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  View,
  StyleSheet,
  Pressable,
  Keyboard,
  Platform,
  TouchableOpacity
} from 'react-native';

import Colors from '../../../../colors';
import MediumText from '../../../../components/MediumText';
import SettingsMenuInput from './components/SettingsMenuInput';
import SettingsMenuItem from './components/SettingsMenuItem';
import { UserStateFormatter, useSetting } from './hooks/useSetting';

const SettingsPhoneScreen: FunctionComponent = () => {
  const intl = useIntl();
  const navigation = useNavigation();

  const phoneFormatter = (phone: string | null) => {
    return typeof phone === 'string' && phone.length > 0 ? phone : null;
  };

  const {
    value,
    editMode,
    control,
    errors,
    handleFormSubmit,
    handleSave,
    handleDelete
  } = useSetting('phone', phoneFormatter as UserStateFormatter);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={styles.editContainer}>
          <TouchableOpacity onPress={handleFormSubmit(handleSave)}>
            <MediumText style={{ color: Colors.BLUE }}>
              {editMode ? (
                <FormattedMessage defaultMessage="Save" description="Save" />
              ) : (
                <FormattedMessage defaultMessage="Edit" description="Edit" />
              )}
            </MediumText>
          </TouchableOpacity>
        </View>
      )
    });
  }, [editMode]);

  return (
    <>
      <Pressable
        style={styles.container}
        onPress={() => (Platform.OS === 'web' ? undefined : Keyboard.dismiss())}
      >
        <View style={styles.itemsContainer}>
          {editMode ? (
            <Controller
              defaultValue={value.current}
              control={control}
              name="value"
              render={({ field: { value, onChange } }) => (
                <SettingsMenuInput
                  value={value as string}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'No phone number set',
                    description: 'Email input placeholder'
                  })}
                  hideBorder
                  onChangeText={onChange}
                  hasValidation={!!(errors.value as any)?.message}
                  errorText={(errors.value as any)?.message ?? null}
                />
              )}
            />
          ) : (
            <SettingsMenuItem
              title={
                value.current && (value.current as string).length > 0
                  ? (value.current as string)
                  : intl.formatMessage({
                      defaultMessage: 'No phone number set',
                      description: 'Phone input placeholder'
                    })
              }
              hideBorder
              type="none"
            />
          )}
        </View>

        <View style={[styles.itemsContainer, { marginTop: 53 }]}>
          <SettingsMenuItem
            title={intl.formatMessage({
              defaultMessage: 'Delete phone number',
              description: 'Delete phone number button'
            })}
            hideBorder
            type="danger"
            onPress={() => handleDelete()}
          />
        </View>
      </Pressable>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND,
    paddingTop: 56
  },
  itemsContainer: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.GREY5,
    backgroundColor: Colors.WHITE,
    paddingLeft: 24
  },
  editContainer: {
    width: 86,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
});

export default SettingsPhoneScreen;
