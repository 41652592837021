import { createStackNavigator } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { Image, StyleSheet, View } from 'react-native';

import Colors from '../../../colors';
import HomeScreen from './HomeScreen';

export type HomeStackParamList = {
  Home: undefined;
};

const HomeStack = createStackNavigator<HomeStackParamList>();

const HomeNavigator: FunctionComponent = () => {
  return (
    <HomeStack.Navigator
      initialRouteName="Home"
      screenOptions={{
        headerTitleStyle: {
          fontFamily: 'NotoSans-Medium',
          color: Colors.GREY2,
          alignSelf: 'center'
        },
        headerBackTitleVisible: false,
        headerBackImage: () => (
          <View style={styles.iconContainer}>
            <Image
              style={styles.icon}
              source={require('../../../assets/Close.png')}
            />
          </View>
        )
      }}
    >
      <HomeStack.Screen
        name="Home"
        component={HomeScreen}
        options={{ headerShown: false }}
      />
    </HomeStack.Navigator>
  );
};

export default HomeNavigator;

const styles = StyleSheet.create({
  iconContainer: {
    height: 45,
    width: 45,
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    height: 24,
    width: 24
  }
});
