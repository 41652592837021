import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import KeyboardInput from '../../../../../components/KeyboardInput';
import PillButton from '../../../../../components/PillButton';
import { QuestionBlock } from '../../../../../store/types';

export interface UsernameAnswerProps {
  block: QuestionBlock;
  completed: boolean;
  value: string;
  onValueChange: (value: string) => void;
  onComplete: () => void;
  validation?: { error: boolean; message: string };
  loading?: boolean;
}

// NOTE(Mariana): Ignore case in the app validation
const USERNAME_REGEX = /^[a-z\u0621-\u064A0-9\u0660-\u0669]{3,19}$/i;

const UsernameAnswer: FunctionComponent<UsernameAnswerProps> = (props) => {
  const intl = useIntl();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<{ username: string }>();
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (props.validation?.error) {
      setError('username', {
        type: 'available',
        message: props.validation.message
      });
    }
  }, [props.validation]);

  const getLengthError = () => {
    return intl.formatMessage({
      description: 'Username block validation error length',
      defaultMessage: 'Please use a username with 3-20 characters.'
    });
  };

  if (props.completed) {
    return (
      <PillButton disabled>{props.block.answer ?? '*'.repeat(8)}</PillButton>
    );
  }

  return (
    <>
      <Controller
        control={control}
        name="username"
        rules={{
          minLength: { value: 3, message: getLengthError() },
          maxLength: { value: 20, message: getLengthError() },
          pattern: {
            value: USERNAME_REGEX,
            message: intl.formatMessage({
              description: 'Username block validation error invalid characters',
              defaultMessage: 'Please use alpha-numeric characters.'
            })
          }
        }}
        render={({ field: { value, onChange, onBlur } }) => (
          <KeyboardInput
            multiline={false}
            value={value}
            onChangeText={(text) => {
              onChange(text);
              props.onValueChange(text);
            }}
            onBlur={onBlur}
            onSubmitEditing={handleSubmit(props.onComplete)}
            containerStyle={{ width: '100%' }}
            style={{ marginBottom: 8 }}
            hasValidation={!!errors.username?.message}
            errorText={errors.username?.message ?? null}
          />
        )}
      />
      <PillButton
        style={{ marginTop: 12 }}
        disabled={!props.value}
        onPress={handleSubmit(props.onComplete)}
        loading={props.loading}
      >
        <FormattedMessage
          defaultMessage="Save"
          description="Save button text for blocks"
        />
      </PillButton>
    </>
  );
};

export default UsernameAnswer;
