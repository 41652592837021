import 'react-native-gesture-handler';
import 'react-native-get-random-values';
import { NavigationContainer } from '@react-navigation/native';
import { useFonts } from 'expo-font';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import { Platform, StatusBar } from 'react-native';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import PushNotifications from './PushNotifications';
import RTL from './RTL';
import Colors from './colors';
import { getCurrentTranslation } from './lang/index';
import RootNavigator from './screens/RootNavigator';
import { store, persistor } from './store';
import { useAppSelector } from './store/hooks';
import { selectActiveLanguage } from './store/languages';

const IntlProviderWrapper: FunctionComponent<PropsWithChildren<unknown>> = (
  props
) => {
  const { language } = useAppSelector(selectActiveLanguage);

  return (
    <IntlProvider messages={getCurrentTranslation(language)} locale={language}>
      {props.children}
    </IntlProvider>
  );
};

const App: FunctionComponent = () => {
  const [loaded] = useFonts({
    'NotoSans-Medium': require('./assets/fonts/NotoSans-Medium.ttf'),
    'NotoSans-SemiBold': require('./assets/fonts/NotoSans-SemiBold.ttf')
  });

  if (!loaded) {
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProviderWrapper>
          <NavigationContainer>
            <StatusBar
              barStyle="dark-content"
              backgroundColor={Colors.BACKGROUND}
            />
            <RootNavigator />
            <RTL />
            {Platform.OS !== 'web' && <PushNotifications />}
          </NavigationContainer>
        </IntlProviderWrapper>
      </PersistGate>
    </Provider>
  );
};

export default App;
