import React, { FunctionComponent } from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import Colors from '../colors';
import MediumText from './MediumText';
import SemiBoldText from './SemiBoldText';

interface AlertBannerProps {
  bannerVisible: boolean;
  image: ImageSourcePropType;
  headlineText: string;
  text?: string;
  linkText?: string;
  onLinkPress?: () => void;
}

const AlertBanner: FunctionComponent<AlertBannerProps> = (props) => {
  if (!props.bannerVisible) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Image source={props.image} style={styles.image} />
      <View style={styles.textContainer}>
        <SemiBoldText style={styles.headlineText}>
          {props.headlineText}
        </SemiBoldText>
        <MediumText style={styles.text}>{props.text}</MediumText>
        <TouchableOpacity onPress={props.onLinkPress}>
          <MediumText style={styles.linkText}>{props.linkText}</MediumText>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default AlertBanner;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    flexDirection: 'row',
    top: 10,
    alignSelf: 'center',
    marginHorizontal: 16,
    paddingTop: 13,
    paddingBottom: 15,
    paddingHorizontal: 16,
    backgroundColor: Colors.WHITE,
    borderRadius: 4,
    shadowColor: Colors.GREY5,
    shadowRadius: 8,
    elevation: 6,
    shadowOpacity: 1,
    zIndex: 999
  },
  image: {
    height: 48,
    width: 48,
    alignSelf: 'center'
  },
  textContainer: {
    flex: 1,
    paddingLeft: 16
  },
  headlineText: {
    lineHeight: 26,
    marginBottom: 2
  },
  text: {
    fontSize: 14,
    lineHeight: 20
  },
  linkText: {
    fontSize: 14,
    lineHeight: 20,
    color: Colors.BLUE
  }
});
