import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { View, StyleSheet } from 'react-native';

import Colors from '../../../colors';
import PillButton from '../../../components/PillButton';
import Popup from '../../../components/Popup';
import { RootStackParamList } from '../../RootNavigator';

const ConfirmationQuestionScreen: FunctionComponent = () => {
  const intl = useIntl();

  const route =
    useRoute<RouteProp<RootStackParamList, 'ConfirmationQuestion'>>();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const affirmativeOption = route.params.affirmativeOption;
  const negativeOption = route.params.negativeOption;
  const onPressYes = route.params.onPressYes;

  const handleSelect = (choice: boolean) => {
    if (choice) onPressYes();
    navigation.goBack();
  };

  return (
    <Popup
      title={
        route.params.question ??
        intl.formatMessage({
          defaultMessage: 'Are you sure?',
          description: 'Confirmation screen question'
        })
      }
    >
      <View style={styles.container}>
        <PillButton
          onPress={() => handleSelect(true)}
          style={styles.yesPillButton}
        >
          {affirmativeOption ?? (
            <FormattedMessage defaultMessage="Yes" description="Yes" />
          )}
        </PillButton>
        <PillButton
          onPress={() => handleSelect(false)}
          style={styles.noPillButton}
          textStyle={styles.noButtonText}
        >
          {negativeOption ?? (
            <FormattedMessage defaultMessage="No" description="No" />
          )}
        </PillButton>
      </View>
    </Popup>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.WHITE,
    flexDirection: 'row-reverse',
    padding: 16
  },
  yesPillButton: { marginLeft: 20 },
  noPillButton: {
    backgroundColor: Colors.WHITE
  },
  noButtonText: {
    color: Colors.BLUE
  }
});

export default ConfirmationQuestionScreen;
