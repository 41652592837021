import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { View, StyleSheet, FlatList, TouchableOpacity } from 'react-native';

import Colors from '../../../colors';
import FAB from '../../../components/FAB';
import MediumText from '../../../components/MediumText';
import SemiBoldText from '../../../components/SemiBoldText';
import { selectCognitiveTriangleExercises } from '../../../store/exercises';
import { useAppSelector } from '../../../store/hooks';
import {
  CognitiveTriangleExercise
  // CognitiveTriangleTemplate
} from '../../../store/types';
import { AppStackParamList } from '../../AppNavigator';
import { RootStackParamList } from '../../RootNavigator';
// import { cognitiveTriangleWithLetterDebug } from './LibraryScreen/debug';

const CognitiveTriangleArchiveScreen: FunctionComponent = (props) => {
  const navigation =
    useNavigation<
      StackNavigationProp<AppStackParamList & RootStackParamList>
    >();

  const exercises = useAppSelector(selectCognitiveTriangleExercises);

  const renderCard = (item: CognitiveTriangleExercise) => {
    return (
      <TouchableOpacity
        style={styles.cardContainer}
        onPress={() => {
          navigation.navigate('Drawer', {
            screen: 'CognitiveTriangle',
            params: {
              completedExercise: item
            }
          });
        }}
      >
        <View style={{ flexDirection: 'column', flex: 1 }}>
          <View style={styles.situationContainer}>
            <SemiBoldText style={styles.title}>
              <FormattedMessage
                defaultMessage="Thought in a certain situation:"
                description="Title of the cognitive triangle archive card"
              />
            </SemiBoldText>
            <MediumText style={styles.text} numberOfLines={1}>
              {typeof item.situation === 'object'
                ? item.situation.text
                : item.situation}
            </MediumText>
          </View>
          <View style={styles.unhelpfulThoughtContainer}>
            <MediumText>{item.unhelpful.thought?.text}</MediumText>
          </View>
          <View style={styles.helpfulThoughtContainer}>
            <MediumText>{item.helpful.thought?.text}</MediumText>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      <FlatList
        data={exercises}
        renderItem={({ item }) => renderCard(item)}
        keyExtractor={(item) => item.id}
      />
      <View style={styles.fabContainer}>
        <FAB
          onPress={() =>
            navigation.navigate('Drawer', {
              screen: 'CognitiveTriangle'
              // If you want to test the Letter to a Friend
              // params: {
              //  template: cognitiveTriangleWithLetterDebug.template as CognitiveTriangleTemplate
              // }
            })
          }
        />
      </View>
    </View>
  );
};

export default CognitiveTriangleArchiveScreen;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.GREY5,
    flex: 1,
    paddingTop: 16
  },
  cardContainer: {
    marginHorizontal: 16,
    marginBottom: 12,
    borderRadius: 4,
    shadowColor: Colors.GREY5,
    backgroundColor: Colors.WHITE,
    shadowRadius: 8,
    elevation: 6,
    shadowOpacity: 1,
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 15
  },

  title: {
    marginBottom: 2,
    fontSize: 14,
    color: Colors.GREY2
  },
  text: {
    fontSize: 16,
    color: Colors.GREY1
  },
  fabContainer: {
    position: 'absolute',
    bottom: 50,
    right: 20
  },
  situationContainer: {
    paddingLeft: 8,
    paddingBottom: 13
  },
  unhelpfulThoughtContainer: {
    backgroundColor: Colors.MELONOPAQUE,
    paddingHorizontal: 8,
    paddingVertical: 10,
    borderRadius: 4,
    marginBottom: 8
  },
  helpfulThoughtContainer: {
    backgroundColor: Colors.BLONDEOPAQUE,
    paddingHorizontal: 8,
    paddingVertical: 10,
    borderRadius: 4
  }
});
