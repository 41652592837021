import React, { FunctionComponent } from 'react';
import { Image, ImageSourcePropType, ImageProps } from 'react-native';

export type Mood = 'very-sad' | 'sad' | 'meh' | 'happy' | 'very-happy';

interface MoodIconProps extends Omit<ImageProps, 'source'> {
  mood: Mood;
}

const MoodIcon: FunctionComponent<MoodIconProps> = (props) => {
  const getImage: () => ImageSourcePropType = () => {
    switch (props.mood) {
      case 'very-sad':
        return require('./assets/very-sad.png');
      case 'sad':
        return require('./assets/sad.png');
      case 'meh':
        return require('./assets/meh.png');
      case 'happy':
        return require('./assets/happy.png');
      case 'very-happy':
        return require('./assets/very-happy.png');
    }
  };

  return (
    <Image
      source={getImage()}
      style={[{ height: 64, width: 64 }, props.style]}
    />
  );
};

export default MoodIcon;
