import React, {
  forwardRef,
  ForwardRefRenderFunction,
  SyntheticEvent,
  useEffect,
  useState
} from 'react';
import {
  StyleSheet,
  TextInputProps,
  TextInput,
  Platform,
  StyleProp,
  View,
  ViewStyle,
  NativeSyntheticEvent,
  TextInputChangeEventData,
  LayoutChangeEvent,
  I18nManager
} from 'react-native';

import Colors from '../colors';
import ViewPasswordToggle from '../screens/Authentication/LoginScreen/components/ViewPasswordToggle';
import MediumText from './MediumText';

export interface KeyboardInputRef extends TextInput {
  focus: () => void;
}

export interface KeyboardInputProps extends TextInputProps {
  hasValidation?: boolean;
  /**
   *  Error text that gets displayed under input field.
   *  Only takes effect if hasValidation is true.
   *  Setting this to a falsy value hides the error.
   */
  errorText?: string | null;
  errorTextStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  inputContainerStyle?: StyleProp<ViewStyle>;
  showPasswordToggleButton?: boolean;
  passwordVisible?: boolean;
  onPressPasswordToggleButton?: () => void;
  noHeightLimit?: boolean;
}

const KeyboardInput: ForwardRefRenderFunction<
  TextInput & KeyboardInputRef,
  KeyboardInputProps
> = (props, ref) => {
  const defaultMaxHeight = 200;
  const [customMaxHeight, setCustomMaxHeight] =
    useState<number>(defaultMaxHeight);

  // The max height needs to be available to the component so that the workaround for setting an automatically expanding text area in web works
  useEffect(() => {
    if (!props.style) {
      return;
    }

    const { maxHeight } = StyleSheet.flatten(props.style);

    if (!maxHeight) {
      return;
    }

    let parsedMaxHeight;
    if (typeof maxHeight === 'string') {
      parsedMaxHeight = parseInt(maxHeight);
    } else {
      parsedMaxHeight = maxHeight;
    }

    setCustomMaxHeight(parsedMaxHeight);
  }, [props.style]);

  const rtl = I18nManager.getConstants().isRTL;
  const textAlign = rtl ? 'right' : 'left';

  /**
   * Workaround for expanding the textinput on react-native-web when set to multiline
   * https://github.com/necolas/react-native-web/issues/795
   */
  const forceSetHeight = (textArea: HTMLTextAreaElement) => {
    const currentHeight = Number(textArea.style.height.slice(0, -2));

    if (textArea.scrollHeight < currentHeight) {
      textArea.style.height = '0px';
      textArea.style.height = `${textArea.scrollHeight}px`;
    }

    if (textArea.scrollHeight > currentHeight) {
      if (!props.noHeightLimit && textArea.scrollHeight > customMaxHeight) {
        textArea.style.height = '0px';
        textArea.style.height = `${customMaxHeight}px`;
        return;
      }

      textArea.style.height = '0px';
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  };

  const onSizeChange = (
    e: NativeSyntheticEvent<TextInputChangeEventData> | LayoutChangeEvent
  ) => {
    const event = e as unknown as SyntheticEvent<HTMLTextAreaElement, Event>;
    const textAreaElement = event.nativeEvent.target as HTMLTextAreaElement;
    forceSetHeight(textAreaElement);
  };

  const renderShowPasswordButton = () => (
    <View style={styles.inputButtonWrapper}>
      <ViewPasswordToggle
        visible={!props.secureTextEntry}
        onPress={() => props.onPressPasswordToggleButton?.()}
      />
    </View>
  );

  const shouldCallOnSizeChange = (
    e: LayoutChangeEvent | NativeSyntheticEvent<TextInputChangeEventData>
  ) => {
    props.multiline && Platform.OS === 'web' && onSizeChange(e);
  };

  const shouldRenderPasswordButton = () => {
    return (
      Platform.OS !== 'web' &&
      props.showPasswordToggleButton &&
      renderShowPasswordButton()
    );
  };

  return (
    <View style={props.containerStyle}>
      <View style={props.inputContainerStyle}>
        <TextInput
          placeholderTextColor={Colors.GREY3}
          {...props}
          onLayout={(e) => {
            shouldCallOnSizeChange(e);
            props.onLayout?.(e);
          }}
          onChange={(e) => {
            shouldCallOnSizeChange(e);
            props.onChange?.(e);
          }}
          style={[
            props.multiline &&
              !props.noHeightLimit && { maxHeight: customMaxHeight }, // This guarantees that, even if the user didn't specificy a max height, the height will be capped by the default value. If they did, then this will just get overwritten
            styles.textInput,
            {
              borderColor:
                props.hasValidation && props.errorText
                  ? Colors.RED
                  : Colors.BLUE,
              textAlign
            },
            props.style
          ]}
          ref={ref}
        />
        {shouldRenderPasswordButton()}
      </View>
      {props.hasValidation && (
        <View style={[styles.errorTextWrapper, props.errorTextStyle]}>
          {props.errorText ? (
            <MediumText style={styles.errorText}>{props.errorText}</MediumText>
          ) : null}
        </View>
      )}
    </View>
  );
};

export default forwardRef(KeyboardInput);

const styles = StyleSheet.create({
  textInput: {
    borderWidth: 1.5,
    borderRadius: 20,
    borderColor: Colors.BLUE,
    minHeight: 32,
    paddingHorizontal: 16,
    paddingBottom: Platform.OS === 'android' ? 2 : 3,
    paddingTop: Platform.OS === 'android' ? 2 : 3,
    fontSize: 16,
    fontFamily: 'NotoSans-Medium',
    ...(Platform.OS === 'web' && ({ outlineStyle: 'none' } as ViewStyle))
  },
  errorTextWrapper: {
    minHeight: 16,
    marginTop: 3
  },
  errorText: {
    color: Colors.RED,
    marginLeft: 16,
    fontSize: 12,
    lineHeight: 16
  },
  inputButtonWrapper: {
    height: '100%',
    position: 'absolute',
    right: 16,
    justifyContent: 'center'
  }
});
