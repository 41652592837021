import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';
import { startOfDay } from 'date-fns';

import { RootState } from '.';
import env from '../env';
import { SubstanceEntry } from './types';

const entityAdapter = createEntityAdapter<SubstanceEntry>({
  selectId: (entry) => `${entry.date}-${entry.substance}`
});

const initialState = entityAdapter.getInitialState();

export const getSubstanceEntries = createAsyncThunk<
  SubstanceEntry[],
  void,
  { state: RootState }
>('substances/getSubstanceEntries', async (_, { getState }) => {
  const res = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/substance-entries`,
    {
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        'Content-Type': 'application/json'
      }
    }
  );

  const data = await res.json();

  if (!res.ok) {
    throw new Error(
      data.code && data.message
        ? `${data.code}: ${data.message}`
        : `${res.status} ${res.statusText}`
    );
  }

  return data.items.map((entry: SubstanceEntry) => ({
    substance: entry.substance,
    date: entry.date,
    count: entry.count
  }));
});

export const upsertSubstanceEntry = createAsyncThunk<
  SubstanceEntry,
  SubstanceEntry,
  { state: RootState }
>(
  'substances/upsertSubstanceEntry',
  async ({ substance, date, count }, { getState }) => {
    if (!getState().user.userId) {
      return { substance, date, count };
    }

    const apiRes = await fetch(
      `${env.API_BASE_URL}/users/${getState().user.userId}/substance-entries`,
      {
        method: 'post',
        headers: {
          Authorization: `Bearer ${getState().user.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          substance,
          date,
          count
        })
      }
    );

    const apiData = await apiRes.json();

    if (!apiRes.ok) {
      throw new Error(
        apiData.code && apiData.message
          ? `${apiData.code}: ${apiData.message}`
          : `${apiRes.status} ${apiRes.statusText}`
      );
    }

    return { substance, date, count };
  }
);

const substancesSlice = createSlice({
  name: 'substances',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubstanceEntries.fulfilled, (state, action) => {
      entityAdapter.setAll(state, action.payload);
    });
    builder.addCase(upsertSubstanceEntry.fulfilled, (state, action) => {
      entityAdapter.upsertOne(state, action.payload);
    });
  }
});

const substancesSelectors = entityAdapter.getSelectors<RootState>(
  (state) => state.substances
);

// Get the entries of a given day.
const selectDaysEntries = (state: RootState, date: Date) => {
  const entries = substancesSelectors.selectAll(state);
  return entries.filter(
    (entry) => startOfDay(new Date(entry.date)).getTime() === date.getTime()
  );
};

// Get a list of days that have entries.
const selectedLoggedDays = (state: RootState) =>
  substancesSelectors
    .selectAll(state)
    .map((entry) => startOfDay(new Date(entry.date)).getTime())
    .filter((date, index, entries) => entries.indexOf(date) === index);

export const actions = substancesSlice.actions;
export const selectors = {
  selectDaysEntries,
  selectedLoggedDays
};

export default substancesSlice.reducer;
