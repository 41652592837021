import React, { FunctionComponent } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import Colors from '../colors';

export interface HeaderLineProps {
  arrowOffset?: number;
  arrowPosition?: 'start' | 'center' | 'end';
  lineContainerStyle?: ViewStyle;
}

const HeaderLine: FunctionComponent<HeaderLineProps> = (props) => {
  const getJustifyContent = () => {
    switch (props.arrowPosition) {
      case 'start':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'end':
        return 'flex-end';
    }
  };
  return (
    <View
      style={[
        styles.container,
        { justifyContent: getJustifyContent() },
        props.lineContainerStyle
      ]}
    >
      <View
        style={[
          styles.triangleContainer,
          props.arrowPosition === 'end'
            ? { right: props.arrowOffset }
            : { left: props.arrowOffset }
        ]}
      >
        <View style={styles.triangleLayer} />
        <View style={styles.triangleUpper} />
      </View>
      <View style={styles.line} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'transparent',
    marginBottom: 1
  },
  line: {
    flex: 1,
    borderTopWidth: 1,
    borderTopColor: Colors.GREY3
  },
  triangleContainer: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'transparent'
  },
  triangleUpper: {
    position: 'absolute',
    left: 2,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 16,
    borderRightWidth: 16,
    borderTopWidth: 16,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: Colors.BACKGROUND
  },
  triangleLayer: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 18,
    borderRightWidth: 18,
    borderTopWidth: 18,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: Colors.GREY3
  }
});

export default HeaderLine;
