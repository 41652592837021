import { createStackNavigator } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { View, Image, StyleSheet } from 'react-native';

import Colors from '../../../colors';
import LibraryScreen from './LibraryScreen';

export type LibraryStackParamList = {
  Library: undefined;
  ExerciseSummary: undefined;
};

const LibraryStack = createStackNavigator<LibraryStackParamList>();

const LibraryNavigator: FunctionComponent = () => {
  return (
    <LibraryStack.Navigator
      initialRouteName="Library"
      screenOptions={{
        headerTitleStyle: {
          fontFamily: 'NotoSans-Medium',
          color: Colors.GREY2,
          alignSelf: 'center'
        },
        headerBackTitleVisible: false,
        headerBackImage: () => (
          <View style={styles.iconContainer}>
            <Image
              style={styles.icon}
              source={require('../../../assets/Close.png')}
            />
          </View>
        )
      }}
    >
      <LibraryStack.Screen
        name="Library"
        component={LibraryScreen}
        options={{ headerShown: false }}
      />
    </LibraryStack.Navigator>
  );
};

export default LibraryNavigator;

const styles = StyleSheet.create({
  iconContainer: {
    height: 45,
    width: 45,
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    height: 24,
    width: 24
  }
});
