import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Image,
  ImageBackground,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import Message from '../../../../../components/Message';
import useAppWindowDimensions from '../../../../../hooks/useAppWindowDimensions';
import { LifelineExercise } from '../../../../../store/types';
import { RootStackParamList } from '../../../../RootNavigator';

interface Props {
  editMode: boolean;
  isEven: boolean;
  isLast: boolean;
  event: LifelineExercise;
  handleDelete: () => void;
}

const LifelineItem: FunctionComponent<Props> = ({
  isEven,
  isLast,
  event,
  editMode,
  handleDelete
}) => {
  const dimensions = useAppWindowDimensions();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const containerHeight = 100;
  const imageMargins = 120;
  const containerWidth = dimensions.width - imageMargins;

  const renderInfluenceIcon = () => (
    <View
      style={[
        {
          bottom: 5,
          flexDirection: 'row'
        },
        !isEven && {
          justifyContent: 'flex-start',
          left: 20
        },
        isEven && {
          justifyContent: 'flex-end',
          right: 20
        }
      ]}
    >
      {event.influenceScale <= 3 && (
        <Image
          source={require('./../../../../../assets/rockSmall.png')}
          style={styles.influenceIcon}
        />
      )}
      {event.influenceScale > 3 && event.influenceScale <= 6 && (
        <Image
          source={require('./../../../../../assets/rockMedium.png')}
          style={styles.influenceIcon}
        />
      )}
      {event.influenceScale > 6 && (
        <Image
          source={require('./../../../../../assets/rockBig.png')}
          style={styles.influenceIcon}
        />
      )}
    </View>
  );

  return (
    <>
      <ImageBackground
        source={
          isEven
            ? require('../assets/leftToRightCurve.png')
            : require('../assets/rightToLeftCurve.png')
        }
        resizeMode="stretch"
        imageStyle={[styles.imageBackground, { height: containerHeight }]}
        style={[
          styles.imageBackgroundContainer,
          {
            width: containerWidth
          }
        ]}
      >
        <View
          style={[
            styles.eventContainer,
            {
              flexDirection: isEven ? 'row' : 'row-reverse',
              height: containerHeight - 10
            }
          ]}
        >
          <View
            style={{
              flex: 0.7,
              flexDirection: !isEven ? 'row' : 'row-reverse',
              justifyContent: 'flex-end'
            }}
          >
            <Message
              component={
                <View style={styles.messageContentContainer}>
                  <View style={styles.cardContainer}>
                    <MediumText style={styles.ageText}>
                      <FormattedMessage
                        defaultMessage="{n} years old"
                        description="Age on lifeline item cards"
                        values={{ n: event.age }}
                      />
                    </MediumText>
                    <View>
                      <MediumText
                        style={styles.descriptionText}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                      >
                        {event.description}
                      </MediumText>
                    </View>
                  </View>
                  {editMode && (
                    <TouchableOpacity
                      onPress={() => {
                        navigation.navigate('ConfirmationQuestion', {
                          onPressYes: handleDelete
                        });
                      }}
                      style={[
                        styles.editSlotContainer,
                        {
                          ...(isEven
                            ? { borderBottomRightRadius: 5 }
                            : { borderBottomRightRadius: 16 })
                        }
                      ]}
                    >
                      <Image
                        source={require('../../../../../assets/bin.png')}
                        style={{ width: 25, height: 25 }}
                      />
                    </TouchableOpacity>
                  )}
                </View>
              }
              flipped={isEven}
              style={[
                styles.messageContainer,
                { maxWidth: containerWidth - imageMargins }
              ]}
            />
          </View>
          <View style={{ flex: 0.3 }}>{renderInfluenceIcon()}</View>
        </View>
      </ImageBackground>
      {isLast && isEven && (
        <View
          style={{
            height: containerHeight
          }}
        >
          <ImageBackground
            source={require('../assets/rightToLeftCurve.png')}
            resizeMode="stretch"
            imageStyle={[styles.imageBackground]}
            style={[styles.imageBackgroundContainer, { width: containerWidth }]}
          />
        </View>
      )}
    </>
  );
};

export default LifelineItem;

const styles = StyleSheet.create({
  ageText: { fontSize: 14, color: Colors.GREY3, marginBottom: 5 },
  descriptionText: { fontSize: 14, color: Colors.GREY1 },
  eventContainer: {
    marginTop: 10,
    alignItems: 'flex-end',
    bottom: 4
  },
  cardContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    marginHorizontal: 16,
    marginVertical: 13,
    flex: 1
  },
  messageContainer: {
    backgroundColor: Colors.WHITE,
    minWidth: '100%'
  },
  influenceIcon: { width: 40, height: 40 },
  imageBackground: {
    left: 10,
    top: -1
  },
  imageBackgroundContainer: {
    flex: 1,
    left: 60
  },
  messageContentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  editSlotContainer: {
    backgroundColor: Colors.RED,
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: 16
  }
});
