import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import { TabActions } from '@react-navigation/native';
import React, { FunctionComponent } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import Header from '../../components/Header';
import HeaderSegmentButton, {
  ImageType
} from '../../components/HeaderSegmentButton';
import SemiBoldText from '../../components/SemiBoldText';

interface IconTopTabHeaderProps extends MaterialTopTabBarProps {
  /**
   * Object key `routeName` refers to the `name` prop of the the <Screen> component.
   */
  icons: { [routeName: string]: keyof typeof ImageType };
}

const IconTopTabHeader: FunctionComponent<IconTopTabHeaderProps> = (props) => {
  const { state, descriptors } = props;

  const handleTabPress = (route: any) => {
    const event = props.navigation.emit({
      type: 'tabPress',
      target: route.key,
      canPreventDefault: true
    });

    if (!event.defaultPrevented) {
      props.navigation.dispatch({
        ...TabActions.jumpTo(route.name),
        target: state.key
      });
    }
  };

  return (
    <Header
      arrowPosition="end"
      arrowOffset={4 + (state.routes.length - 1 - state.index) * 60}
      headerRight={
        <View style={{ flexDirection: 'row' }}>
          {state.routes.map((route) => (
            <TouchableOpacity
              key={route.key}
              style={{ marginStart: 12 }}
              onPress={() => handleTabPress(route)}
            >
              <HeaderSegmentButton
                type={props.icons[route.name]}
                size={40}
                selected={route.key === state.routes[state.index].key}
              />
            </TouchableOpacity>
          ))}
        </View>
      }
    >
      <SemiBoldText style={styles.titleText}>
        {descriptors[state.routes[state.index].key].options.title}
      </SemiBoldText>
    </Header>
  );
};

export default IconTopTabHeader;

const styles = StyleSheet.create({
  titleText: {
    fontSize: 24,
    lineHeight: 32
  }
});
