import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { View, StyleSheet } from 'react-native';

import Colors from '../../../colors';
import PillButton from '../../../components/PillButton';
import Popup from '../../../components/Popup';
import Slider from '../../../components/Slider';
import useInterval from '../../../hooks/useInterval';
import { RootStackParamList } from '../../RootNavigator';

const SliderPopupScreen: FunctionComponent = () => {
  const [sliderValue, setSliderValue] = useState<number>(-1);
  const [elapsedTime, setElapsedTime] = useState<number>(0);

  const intl = useIntl();
  const route = useRoute<RouteProp<RootStackParamList, 'SliderPopup'>>();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const handleConfirm = () => {
    route.params.onConfirm(sliderValue);
    navigation.goBack();
  };

  useInterval(() => {
    if (elapsedTime + 1 === route.params.timeOut) {
      return navigation.goBack();
    }
    setElapsedTime(elapsedTime + 1);
  }, 1000);

  const sliderLeftLabel = intl.formatMessage({
    defaultMessage: 'a little',
    description: 'Anxiety tracker slider left choice'
  });
  const sliderRightLabel = intl.formatMessage({
    defaultMessage: 'a lot',
    description: 'Anxiety tracker slider right choice'
  });

  return (
    <Popup
      title={intl.formatMessage({
        defaultMessage: 'How anxious do you feel?',
        description: 'Title on the written exposure first anxiety screen'
      })}
    >
      <View style={styles.sliderContainer}>
        <Slider
          leftLabel={sliderLeftLabel}
          rightLabel={sliderRightLabel}
          minimumValue={0}
          maximumValue={100}
          step={1}
          value={sliderValue === -1 ? 50 : sliderValue}
          onSlidingComplete={setSliderValue}
        />
      </View>
      <View style={styles.container}>
        <PillButton
          onPress={() => handleConfirm()}
          style={styles.yesPillButton}
        >
          <FormattedMessage defaultMessage="Confirm" description="Confirm" />
        </PillButton>
      </View>
    </Popup>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.WHITE,
    flexDirection: 'row-reverse',
    padding: 16
  },
  yesPillButton: { marginLeft: 20 },
  sliderContainer: {
    width: '100%',
    justifyContent: 'center'
  }
});

export default SliderPopupScreen;
