import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';
import Svg, { Circle, G } from 'react-native-svg';

interface ProgressCircleProps {
  percentage?: number;
  strokeWidth?: number;
  radius?: number;
  imageSource?: ImageSourcePropType | string;
  activeColor?: string;
  passiveColor?: string;
  innerPadding?: number;
}

const ProgressCircle: FunctionComponent<
  PropsWithChildren<ProgressCircleProps>
> = ({
  percentage = 0,
  strokeWidth = 5,
  radius = 50,
  imageSource,
  activeColor = 'black',
  passiveColor = 'rgba(0,0,0,0.3)',
  innerPadding = 5,
  ...props
}) => {
  if (percentage > 100) percentage = 100;
  if (percentage < 0) percentage = 0;

  const circumference = 2 * Math.PI * radius;
  const halfCircle = radius + strokeWidth;
  const strokeLength = circumference - (percentage * circumference) / 100;

  const innerStyle = {
    top: strokeWidth + innerPadding,
    left: strokeWidth + innerPadding,
    height: (radius - strokeWidth - innerPadding) * 2,
    width: (radius - strokeWidth - innerPadding) * 2
  };

  const { top, left, ...imageStyle } = innerStyle;

  return (
    <View>
      <Svg
        height={radius * 2}
        width={radius * 2}
        viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}
      >
        <G
          fill="transparent"
          transform={`rotate(-90, ${halfCircle}, ${halfCircle})`}
        >
          <Circle
            cx="50%"
            cy="50%"
            r={radius}
            stroke={passiveColor}
            strokeWidth={strokeWidth}
          />
          <Circle
            cx="50%"
            cy="50%"
            r={radius}
            stroke={activeColor}
            strokeWidth={strokeWidth}
            strokeDashoffset={strokeLength}
            strokeDasharray={circumference}
            strokeLinecap="round"
          />
        </G>
      </Svg>
      <View style={[styles.innerContainer, innerStyle]}>
        {imageSource ? (
          <Image
            source={
              typeof imageSource === 'string'
                ? { uri: imageSource }
                : imageSource
            }
            style={[{ borderRadius: 90 }, imageStyle]}
            resizeMode="contain"
          />
        ) : null}
        {props.children}
      </View>
    </View>
  );
};

export default ProgressCircle;

const styles = StyleSheet.create({
  image: {
    position: 'absolute',
    borderRadius: 90
  },
  innerContainer: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 90
  }
});
