import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigatorScreenParams } from '@react-navigation/native';
import React, { FunctionComponent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Image, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Colors from '../../colors';
import { selectUnlockedFeatures } from '../../store/blocks';
import { getExercises } from '../../store/exercises';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectActiveLanguage } from '../../store/languages';
import { getSubstanceEntries } from '../../store/substances';
import HomeNavigator, { HomeStackParamList } from './Home/HomeNavigator';
import LibraryNavigator, {
  LibraryStackParamList
} from './Library/LibraryNavigator';
import MyJourneyScreen from './MyJourneyScreen';
import TrackerNavigator from './Tracker/TrackerNavigator';
import EHelperNavigator, {
  TrackerTabParamList
} from './eHelper/eHelperNavigator';

export type TabParamList = {
  Home: NavigatorScreenParams<HomeStackParamList>;
  Library: NavigatorScreenParams<LibraryStackParamList>;
  Tracker: undefined;
  'My Journey': undefined;
  eHelper: NavigatorScreenParams<TrackerTabParamList>;
};

const Tab = createBottomTabNavigator<TabParamList>();

const TabNavigator: FunctionComponent = () => {
  const userId = useAppSelector((state) => state.user.userId);
  const unlockedFeatures = useAppSelector(selectUnlockedFeatures);
  const { language } = useAppSelector(selectActiveLanguage);

  const insets = useSafeAreaInsets();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const substanceTrackerUnlocked = unlockedFeatures.some(
    (feature) => feature.unlockedFeature === 'substance-tracker'
  );

  useEffect(() => {
    dispatch(getExercises());
    dispatch(getSubstanceEntries());
  }, [userId]);

  return (
    <Tab.Navigator
      key={language} // Needed for the web version to deal with writing direction changes without having to restart the app (check the wiki for more info)
      initialRouteName="Home"
      tabBarOptions={{
        activeTintColor: Colors.BLUE,
        inactiveTintColor: Colors.GREY3,
        labelStyle: {
          fontFamily: 'NotoSans-Medium',
          fontSize: 11,
          marginVertical: 5
        },
        tabStyle: { paddingTop: 8 },
        style: { height: 56 + insets.bottom }
      }}
    >
      <Tab.Screen
        name="Home"
        component={HomeNavigator}
        options={() => ({
          title: intl.formatMessage({
            defaultMessage: 'Home',
            description: 'Tab bar label'
          }),
          tabBarIcon: ({ focused }) =>
            focused ? (
              <Image
                source={require('./assets/Home-selected.png')}
                style={styles.iconStyle}
              />
            ) : (
              <Image
                source={require('./assets/Home.png')}
                style={styles.iconStyle}
              />
            )
        })}
      />
      <Tab.Screen
        name="Library"
        component={LibraryNavigator}
        options={{
          title: intl.formatMessage({
            defaultMessage: 'Library',
            description: 'Tab bar label'
          }),
          tabBarIcon: ({ focused }) =>
            focused ? (
              <Image
                source={require('./assets/Library-selected.png')}
                style={styles.iconStyle}
              />
            ) : (
              <Image
                source={require('./assets/Library.png')}
                style={styles.iconStyle}
              />
            )
        }}
      />
      {substanceTrackerUnlocked ? (
        <Tab.Screen
          name="Tracker"
          component={TrackerNavigator}
          options={{
            title: intl.formatMessage({
              defaultMessage: 'Tracker',
              description: 'Tab bar label'
            }),
            tabBarIcon: ({ focused }) =>
              focused ? (
                <Image
                  source={require('./assets/Mood-selected.png')}
                  style={styles.iconStyle}
                />
              ) : (
                <Image
                  source={require('./assets/Mood.png')}
                  style={styles.iconStyle}
                />
              )
          }}
        />
      ) : null}
      {/* Hidden for Blended version */}
      {/* <Tab.Screen
        name="My Journey"
        component={MyJourneyScreen}
        options={{
          title: intl.formatMessage({
            defaultMessage: 'My Journey',
            description: 'Tab bar label'
          }),
          tabBarIcon: ({ focused }) =>
            focused ? (
              <Image
                source={require('./assets/MyJourney-selected.png')}
                style={styles.iconStyle}
              />
            ) : (
              <Image
                source={require('./assets/MyJourney.png')}
                style={styles.iconStyle}
              />
            )
        }}
      /> */}
      <Tab.Screen
        name="eHelper"
        component={EHelperNavigator}
        options={{
          title: intl.formatMessage({
            defaultMessage: 'eHelper',
            description: 'Tab bar label'
          }),
          tabBarIcon: ({ focused }) =>
            focused ? (
              <Image
                source={require('./assets/eHelper-selected.png')}
                style={styles.iconStyle}
              />
            ) : (
              <Image
                source={require('./assets/eHelper.png')}
                style={styles.iconStyle}
              />
            )
        }}
      />
    </Tab.Navigator>
  );
};

export default TabNavigator;

const styles = StyleSheet.create({
  iconStyle: {
    height: 24,
    width: 24
  }
});
