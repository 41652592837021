import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import Colors from '../../../../colors';
import Drawer from '../../../../components/Drawer';
import SemiBoldText from '../../../../components/SemiBoldText';
import { useBackHandler } from '../../../../hooks/useBackHandler';
import useSound, { StateType } from '../../../../hooks/useSound';
import { saveExercise } from '../../../../store/exercises';
import { useAppDispatch } from '../../../../store/hooks';
import { ExerciseType } from '../../../../store/types';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import { RootStackParamList } from '../../../RootNavigator';
import SeekBar from './SeekBar';

const AudioPlayerScreen: FunctionComponent = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<DrawerStackParamList, 'AudioPlayer'>>();
  const sound = useSound(route.params.template?.file?.url);
  const dispatch = useAppDispatch();

  const soundRef = useRef(sound);
  soundRef.current = sound;

  useEffect(() => {
    return () => {
      soundRef.current.unloadSound();
    };
  }, []);

  const handleCloseExercise = () => {
    dispatch(
      saveExercise({
        id: uuidv4(),
        type: ExerciseType.Audio,
        createdAt: new Date().toISOString(),
        timePlayed: sound.progress
      })
    );
    route.params.onFinish?.();
    navigation.goBack();
  };

  const { getDrawerIcon } = useBackHandler(
    () => true,
    () => null,
    [sound.progress],
    navigation,
    handleCloseExercise
  );

  return (
    <Drawer
      title={route.params.template?.title}
      headerContainerStyle={{
        backgroundColor: Colors.DARKBLUE
      }}
      titleStyle={{
        color: Colors.WHITE
      }}
      leftComponent={getDrawerIcon()}
      onBackgroundPress={() =>
        navigation.navigate('DrawerCloseExercise', {
          closeExercise: handleCloseExercise
        })
      }
    >
      <View style={styles.upSection}>
        <View style={styles.imagesContainer}>
          <Image
            style={{
              width: '100%',
              height: '100%'
            }}
            resizeMode="contain"
            source={require('./assets/ellipses.png')}
          />
          <Image
            style={styles.microphoneImage}
            resizeMode="contain"
            source={require('./assets/microphone.png')}
          />
        </View>
        <View style={styles.downSection}>
          <SemiBoldText
            style={{
              color: Colors.WHITE,
              fontSize: 20
            }}
          >
            {route.params.template?.audioName}
          </SemiBoldText>
          <SeekBar
            trackLength={sound.duration}
            currentPosition={sound.progress}
            onSeek={(val) => {
              sound.seek(val);
            }}
          />

          <View style={styles.buttonsContainer}>
            <TouchableOpacity activeOpacity={0.5}>
              <Image
                source={require('./assets/previous.png')}
                style={{ width: 28, height: 28 }}
                resizeMode="contain"
              />
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => {
                sound.playPause();
              }}
              style={styles.playPauseContainer}
            >
              {sound.state === StateType.Playing ? (
                <Image
                  source={require('../../../../assets/pause.png')}
                  style={{ width: 40, height: 40 }}
                  resizeMode="contain"
                />
              ) : (
                <Image
                  source={require('../../../../assets/play.png')}
                  style={{ width: 40, height: 40 }}
                  resizeMode="contain"
                />
              )}
            </TouchableOpacity>
            <TouchableOpacity activeOpacity={0.5}>
              <Image
                source={require('./assets/next.png')}
                style={{ width: 28, height: 28 }}
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Drawer>
  );
};

export default AudioPlayerScreen;

const styles = StyleSheet.create({
  upSection: {
    backgroundColor: Colors.DARKBLUE,
    height: '100%',
    flexDirection: 'column'
  },
  downSection: {
    width: '100%',
    flex: 0.45,
    justifyContent: 'center',
    paddingHorizontal: 24
  },
  buttonsContainer: {
    width: '60%',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  playPauseContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    width: 80,
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 60
  },
  microphoneImage: {
    width: 80,
    height: 80,
    position: 'absolute'
  },
  imagesContainer: {
    marginTop: 40,
    width: '100%',
    flex: 0.55,
    justifyContent: 'center',
    alignItems: 'center'
  }
});
