import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  View,
  StyleSheet,
  Image,
  ScrollView,
  AppState,
  AppStateStatus,
  Pressable,
  FlatList,
  Platform
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import Colors from '../../../../colors';
import Drawer from '../../../../components/Drawer';
import KeyboardInput from '../../../../components/KeyboardInput';
import MediumText from '../../../../components/MediumText';
import PillButton from '../../../../components/PillButton';
import ProgressCircle from '../../../../components/ProgressCircle';
import Slider from '../../../../components/Slider';
import Step from '../../../../components/Step';
import { useBackHandler } from '../../../../hooks/useBackHandler';
import useInterval from '../../../../hooks/useInterval';
import { writtenExposureDefaultTemplate } from '../../../../store/blocks';
import {
  saveExercise,
  selectLifelineExercises
} from '../../../../store/exercises';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ExerciseType, WrittenExposureTemplate } from '../../../../store/types';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import { RootStackParamList } from '../../../RootNavigator';
import ExposureStep from './components/ExposureStep';

interface MoodScale {
  before: number;
  tenMinutesIn: number;
  twentyMinutesIn: number;
  after: number;
}

enum Steps {
  TOPIC_LIST,
  TIMER,
  FIRST_ANXIETY,
  EXPOSURE,
  LAST_ANXIETY,
  FINAL_THOUGHT
}

// needs to be same as Steps enum
const stepsArr = [
  Steps.TOPIC_LIST,
  Steps.TIMER,
  Steps.FIRST_ANXIETY,
  Steps.EXPOSURE,
  Steps.LAST_ANXIETY,
  Steps.FINAL_THOUGHT
];

const ratingSteps = [
  Steps.FIRST_ANXIETY,
  Steps.LAST_ANXIETY,
  Steps.FINAL_THOUGHT
];

const WrittenExposureScreen: FunctionComponent = () => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isTimerRunning, toggleTimer] = useState(false);
  const [selectedEventId, setSelectedTopic] = useState<string>('');
  const [timeAppWentBackground, setTimeAppWentBackground] = useState<number>();
  const [text, setText] = useState('');
  const [pictureUris, setPictureUris] = useState<string[]>([]);
  const [moodScale, setMoodScale] = useState<MoodScale>({
    before: -1,
    tenMinutesIn: -1,
    twentyMinutesIn: -1,
    after: -1
  });
  const [finalThought, setFinalThought] = useState<string>('');

  const sortedLifelineEvents = useAppSelector(selectLifelineExercises).sort(
    (a, b) => b.influenceScale - a.influenceScale
  );

  const dispatch = useAppDispatch();

  const intl = useIntl();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<DrawerStackParamList, 'WrittenExposure'>>();
  const template: WrittenExposureTemplate =
    route.params?.template ?? writtenExposureDefaultTemplate;

  const initialStep = template.skipTopicSelection
    ? Steps.TIMER
    : Steps.TOPIC_LIST;
  const [stepIndex, setStepIndex] = useState(initialStep);

  const timeLimit = template.time * 60;

  const stepIndexRef = useRef<number>();
  const timeAppWentBackgroundRef = useRef<number>();
  stepIndexRef.current = stepIndex;
  timeAppWentBackgroundRef.current = timeAppWentBackground;

  const sliderLeftLabel = intl.formatMessage({
    defaultMessage: 'a little',
    description: 'Anxiety tracker slider left choice'
  });
  const sliderRightLabel = intl.formatMessage({
    defaultMessage: 'a lot',
    description: 'Anxiety tracker slider right choice'
  });

  const getSecondsAsMmSs = (secondsValue: number) =>
    new Date(secondsValue * 1000).toISOString().substr(14, 5);

  // We store the current time when the app goes background
  // We calculate the difference with the new current time and add it back when the app goes active again
  const handleAppStateChange = (appState: AppStateStatus) => {
    if (stepIndexRef.current !== Steps.EXPOSURE) return;
    if (appState === 'background') {
      toggleTimer(false);
      setTimeAppWentBackground(new Date().getTime());
    } else if (appState === 'active') {
      if (timeAppWentBackgroundRef.current) {
        const now = new Date().getTime();
        const timeSpentBackground =
          (now - timeAppWentBackgroundRef?.current) / 1000;
        setElapsedTime((elapsed) =>
          elapsed + timeSpentBackground < timeLimit
            ? elapsed + timeSpentBackground
            : timeLimit
        );
        setTimeAppWentBackground(0);
        toggleTimer(true);
      }
    }
  };

  useEffect(() => {
    const subscription = AppState.addEventListener(
      'change',
      handleAppStateChange
    );
    return () => subscription.remove();
  }, []);

  useEffect(() => {
    const fiveMinutes = 60 * 5;
    const tenMinutes = 60 * 10;
    const twentyMinutes = 60 * 20;

    if (elapsedTime === tenMinutes && template.rating) {
      return navigation.navigate('SliderPopup', {
        onConfirm: (val) => {
          setMoodScale({ ...moodScale, tenMinutesIn: val });
        },
        timeOut: fiveMinutes
      });
    }
    if (elapsedTime === twentyMinutes && template.rating) {
      return navigation.navigate('SliderPopup', {
        onConfirm: (val) => {
          setMoodScale({ ...moodScale, twentyMinutesIn: val });
        },
        timeOut: fiveMinutes
      });
    }
  }, [elapsedTime]);

  useInterval(
    () => {
      if (elapsedTime < timeLimit) setElapsedTime(elapsedTime + 1);
      else toggleTimer(false);
    },
    isTimerRunning ? 1000 : null
  );

  const getNextTranslation = () => {
    return <FormattedMessage defaultMessage="Weiter" description="Next" />;
  };

  const getTopic = () => {
    if (template.skipTopicSelection) {
      return template.defaultTopic;
    }
    return sortedLifelineEvents.find(
      (lifelineEvent) => lifelineEvent.id === selectedEventId
    )?.description;
  };

  const handleSaveExercise = async () => {
    await dispatch(
      saveExercise({
        id: uuidv4(),
        createdAt: new Date().toISOString(),
        type: ExerciseType.WrittenExposure,
        lifelineEventId: selectedEventId,
        moodScale,
        text,
        finalThought,
        templateId: template.id,
        ...(pictureUris && {
          files: pictureUris
        })
      })
    );
    route.params?.onFinish?.();
    navigation.goBack();
  };

  const goToNextStep = () => {
    if (!template.rating && ratingSteps.includes(stepIndex + 1)) {
      const nextStep = stepsArr.find(
        (step) => step > stepIndex && !ratingSteps.includes(step)
      );
      if (nextStep) {
        setStepIndex(nextStep);
      } else {
        handleSaveExercise();
      }
      return;
    }

    setStepIndex(stepIndex + 1);
  };

  const goToPreviousStep = () => {
    if (!template.rating && ratingSteps.includes(stepIndex - 1)) {
      const prevStep = [...stepsArr]
        .reverse()
        .find((step) => step < stepIndex && !ratingSteps.includes(step));
      if (prevStep) {
        setStepIndex(prevStep);
      }
      // going back from first screen is handled by useBackHandler
      return;
    }

    setStepIndex(stepIndex - 1);
  };

  const renderSmallTimer = () => {
    if (stepIndex === Steps.FIRST_ANXIETY || stepIndex === Steps.EXPOSURE)
      return (
        <Pressable
          delayLongPress={10000}
          onLongPress={() => {
            // Cheat code to end the timer during testing
            setElapsedTime(timeLimit - 5);
          }}
        >
          <View style={styles.smallTimerContainer}>
            <ProgressCircle
              activeColor={Colors.BLONDE}
              passiveColor={Colors.GREY5}
              strokeWidth={5}
              radius={15}
              percentage={(elapsedTime / timeLimit) * 100}
            />
            <MediumText style={styles.timerFormatted}>
              {getSecondsAsMmSs(timeLimit - elapsedTime)}
            </MediumText>
          </View>
        </Pressable>
      );
  };

  const renderTopicListStep = () => {
    const renderImpactRock = (scale: number) => {
      const getImageSource = () => {
        if (scale < 7 && scale > 4)
          return require('../../../../assets/rockMedium.png');
        if (scale <= 4) return require('../../../../assets/rockSmall.png');
        return require('../../../../assets/rockBig.png');
      };

      return <Image source={getImageSource()} style={styles.rockImage} />;
    };
    return (
      <>
        <FlatList
          contentContainerStyle={styles.eventTopicsContainer}
          style={{
            width: '100%'
          }}
          ListHeaderComponent={
            <MediumText
              style={[
                styles.title,
                {
                  marginTop: '20%',
                  marginBottom: Platform.OS === 'web' ? 140 : '38%'
                }
              ]}
            >
              <FormattedMessage
                defaultMessage="Please choose a topic!"
                description="Title on the written exposure topic screen"
              />
            </MediumText>
          }
          data={[...sortedLifelineEvents]}
          ListEmptyComponent={() => (
            <>
              <MediumText>Please create lifeline events</MediumText>
              <PillButton
                onPress={() => {
                  navigation.navigate('Drawer', {
                    screen: 'Lifeline'
                  });
                }}
              >
                Create Lifeline
              </PillButton>
            </>
          )}
          renderItem={({ item }) => {
            return (
              <View style={styles.pillbuttonTopicContainer} key={item.id}>
                <View>{renderImpactRock(item.influenceScale)}</View>
                <View style={styles.itemDescriptionContainer}>
                  <PillButton
                    key={item.id}
                    style={
                      selectedEventId === item.id
                        ? styles.buttonActive
                        : styles.buttonInactive
                    }
                    textStyle={styles.pillButtonText}
                    onPress={() => setSelectedTopic(item.id)}
                    numberOfLines={1}
                  >
                    {item.description}
                  </PillButton>
                </View>
              </View>
            );
          }}
        />
        <View style={styles.pillButtonContainer}>
          <PillButton
            disabled={!selectedEventId}
            onPress={() => {
              if (selectedEventId !== sortedLifelineEvents[0].id) {
                const warningText = intl.formatMessage({
                  defaultMessage:
                    'We recommend to write about the event that had the strongest impact on your life. Do you still want to go on?',
                  description:
                    'Warning about strongest impact in written exposure'
                });

                navigation.navigate('WrittenExposureWarning', {
                  text: warningText,
                  goToNextSlide: goToNextStep
                });
              } else {
                goToNextStep();
              }
            }}
          >
            {getNextTranslation()}
          </PillButton>
        </View>
      </>
    );
  };

  const renderFirstAnxietyTrackerStep = () => {
    return (
      <>
        <MediumText style={styles.title}>
          <FormattedMessage
            defaultMessage="How anxious do you feel?"
            description="Title on the written exposure first anxiety screen"
          />
        </MediumText>
        <View style={styles.sliderContainer}>
          <Slider
            leftLabel={sliderLeftLabel}
            rightLabel={sliderRightLabel}
            minimumValue={0}
            maximumValue={100}
            step={1}
            value={moodScale.before === -1 ? 50 : moodScale.before}
            onSlidingComplete={(before) =>
              setMoodScale((val) => ({ ...val, before }))
            }
          />
        </View>
        <View style={styles.pillButtonContainer}>
          <PillButton disabled={moodScale.before === -1} onPress={goToNextStep}>
            {getNextTranslation()}
          </PillButton>
        </View>
      </>
    );
  };

  const renderInstructionsTimerStep = () => {
    return (
      <>
        <ScrollView
          contentContainerStyle={styles.scrollView}
          showsVerticalScrollIndicator={false}
        >
          <View style={{ alignItems: 'center' }}>
            <MediumText style={styles.title}>
              <FormattedMessage
                defaultMessage="When you are ready to write, please start the timer."
                description="Title on the written exposure timer screen"
              />
            </MediumText>
            <View style={styles.bigTimerContainer}>
              <MediumText style={styles.bigTimerText}>
                {getSecondsAsMmSs(timeLimit)}
              </MediumText>
            </View>
            <View style={styles.textsContainer}>
              <MediumText style={styles.topicsContainer}>
                <MediumText style={styles.categoryText}>
                  <FormattedMessage
                    defaultMessage="Your topic:"
                    description="Your written exposure topic"
                  />{' '}
                </MediumText>
                <MediumText style={styles.text}>{getTopic()}</MediumText>
              </MediumText>
              <MediumText style={styles.timerInstructionsContainer}>
                <MediumText style={styles.categoryText}>
                  <FormattedMessage
                    defaultMessage="Instruction:"
                    description="The written exposure instruction"
                  />{' '}
                </MediumText>
                <MediumText style={styles.text}>
                  {template.instruction}
                </MediumText>{' '}
              </MediumText>
            </View>
          </View>
          <View style={styles.pillButton}>
            <PillButton
              onPress={() => {
                goToNextStep();
                setElapsedTime(0);
                toggleTimer(true);
              }}
            >
              <FormattedMessage defaultMessage="Start" description="Start" />
            </PillButton>
          </View>
        </ScrollView>
      </>
    );
  };

  const renderExposureScreenStep = () => (
    <ExposureStep
      lifelineId={selectedEventId}
      onSave={goToNextStep}
      exerciseTopic={`${
        sortedLifelineEvents.find(
          (lifelineEvent) => lifelineEvent.id === selectedEventId
        )?.description
      }`}
      instruction={template.instruction}
      isTimeLimitReached={elapsedTime < timeLimit}
      text={text}
      pictureUris={pictureUris}
      onChangeText={setText}
      onChangePictureUris={setPictureUris}
    />
  );

  const renderLastAnxietyTrackerStep = () => {
    return (
      <>
        <MediumText style={styles.title}>
          <FormattedMessage
            defaultMessage="How anxious do you feel now?"
            description="Title on the written exposure last anxiety screen"
          />
        </MediumText>
        <View style={styles.sliderContainer}>
          <Slider
            leftLabel={sliderLeftLabel}
            rightLabel={sliderRightLabel}
            minimumValue={0}
            maximumValue={100}
            step={1}
            value={moodScale.after === -1 ? 50 : moodScale.after}
            onSlidingComplete={(after) =>
              setMoodScale((val) => ({ ...val, after }))
            }
          />
        </View>
        <View style={styles.pillButtonContainer}>
          <PillButton disabled={moodScale.after === -1} onPress={goToNextStep}>
            {getNextTranslation()}
          </PillButton>
        </View>
      </>
    );
  };

  const renderFinalThoughtStep = () => {
    return (
      <>
        <MediumText style={styles.questionTitle}>
          <FormattedMessage
            defaultMessage="What have you learned while writing?"
            description="Last input slide on the written exposure"
          />
        </MediumText>
        <View style={{ paddingHorizontal: '10%' }}>
          <KeyboardInput
            placeholder={intl.formatMessage({
              defaultMessage: 'Type in what you think',
              description: 'Input placeholder on the written exposure'
            })}
            onChangeText={setFinalThought}
            value={finalThought}
          />
        </View>
        <View
          style={{
            justifyContent: 'flex-end',
            marginRight: 24,
            marginTop: 20,
            flexDirection: 'row'
          }}
        >
          <PillButton
            disabled={finalThought.length === 0}
            onPress={handleSaveExercise}
          >
            {getNextTranslation()}
          </PillButton>
        </View>
      </>
    );
  };

  const isFirstScreen = () => {
    return stepIndex <= initialStep;
  };

  const regularBackwardNav = () => {
    if (stepIndex === Steps.EXPOSURE) {
      const warningText = intl.formatMessage({
        defaultMessage:
          'Do you really want to go back? You will lose your progress.',
        description: 'WrittenExposureWarning text leaving writing screen'
      });

      navigation.navigate('WrittenExposureWarning', {
        text: warningText,
        goToNextSlide: () => {
          setText('');
          setPictureUris([]);
          goToPreviousStep();
        }
      });
    } else {
      goToPreviousStep();
    }
  };

  const { getDrawerIcon } = useBackHandler(
    isFirstScreen,
    regularBackwardNav,
    [stepIndex],
    navigation
  );

  return (
    <Drawer
      title={intl.formatMessage({
        defaultMessage: 'Written Exposure',
        description: 'Title of the written exposure drawer'
      })}
      leftComponent={getDrawerIcon()}
      rightComponent={renderSmallTimer()}
      onBackgroundPress={() =>
        navigation.navigate('DrawerCloseExercise', {
          closeExercise: () => navigation.goBack()
        })
      }
    >
      <Step step={Steps.TOPIC_LIST} current={stepIndex}>
        {renderTopicListStep()}
      </Step>
      <Step step={Steps.TIMER} current={stepIndex}>
        {renderInstructionsTimerStep()}
      </Step>
      <Step step={Steps.FIRST_ANXIETY} current={stepIndex}>
        {renderFirstAnxietyTrackerStep()}
      </Step>
      <Step step={Steps.EXPOSURE} current={stepIndex}>
        {renderExposureScreenStep()}
      </Step>
      <Step step={Steps.LAST_ANXIETY} current={stepIndex}>
        {renderLastAnxietyTrackerStep()}
      </Step>
      <Step step={Steps.FINAL_THOUGHT} current={stepIndex}>
        {renderFinalThoughtStep()}
      </Step>
    </Drawer>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: Colors.WHITE
  },
  arrow: {
    width: 30,
    height: 30,
    resizeMode: 'contain'
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    marginTop: 75,
    paddingHorizontal: 50
  },
  startContainer: {
    marginTop: 60,
    paddingHorizontal: 50
  },
  pillButtonContainer: {
    position: 'absolute',
    bottom: 50,
    right: 24
  },
  eventTopicsContainer: {
    width: '100%',
    justifyContent: 'center',
    paddingHorizontal: 24,
    paddingBottom: 100
  },
  pillbuttonTopicContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 24,
    marginBottom: 10
  },
  pillButtonText: {
    color: Colors.BLUE
  },
  buttonInactive: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: Colors.GREY4
  },
  buttonActive: {
    backgroundColor: Colors.WHITE,
    borderWidth: 1,
    borderColor: Colors.BLUE
  },
  rockImage: {
    width: 48,
    height: 48
  },
  sliderContainer: {
    width: '100%',
    paddingHorizontal: 24,
    flex: 0.8,
    justifyContent: 'center'
  },
  bigTimerContainer: {
    borderWidth: 4,
    borderColor: Colors.GREY5,
    width: 160,
    height: 160,
    borderRadius: 80,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 43
  },
  bigTimerText: {
    fontSize: 36
  },
  textsContainer: {
    paddingHorizontal: 24
  },
  topicsContainer: {
    flexDirection: 'row',
    marginBottom: 6
  },
  categoryText: {
    fontSize: 16,
    color: Colors.GREY2
  },
  text: {
    fontSize: 16
  },
  pillButton: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
    right: 24,
    marginBottom: 50
  },
  smallTimerContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  timerFormatted: {
    fontSize: 16,
    marginLeft: 4,
    color: Colors.GREY2
  },
  timerInstructionsContainer: {
    flexDirection: 'row',
    lineHeight: 26
  },
  scrollView: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  questionTitle: {
    marginTop: 55,
    marginBottom: 41,
    fontSize: 20,
    paddingHorizontal: '20%',
    textAlign: 'center'
  },
  itemDescriptionContainer: {
    paddingLeft: 16,
    paddingRight: 24,
    maxWidth: '100%'
  }
});

export default WrittenExposureScreen;
