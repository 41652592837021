import { useRoute, RouteProp } from '@react-navigation/core';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  View,
  StyleSheet,
  FlatList,
  Image,
  Platform,
  TouchableOpacity
} from 'react-native';
import { Popover, usePopover } from 'react-native-modal-popover';

import Colors from '../../../../colors';
import Drawer from '../../../../components/Drawer';
import FAB from '../../../../components/FAB';
import MediumText from '../../../../components/MediumText';
import PillButton from '../../../../components/PillButton';
import { useBackHandler } from '../../../../hooks/useBackHandler';
import {
  removeExercise,
  selectLifelineExercises
} from '../../../../store/exercises';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { LifelineExercise } from '../../../../store/types';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import { RootStackParamList } from '../../../RootNavigator';
import LifelineItem from './components/LifelineItem';

const LifelineScreen: FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false);

  const events: LifelineExercise[] = useAppSelector(selectLifelineExercises);
  const rightIconImage = useRef<Image>(null);

  const {
    openPopover,
    closePopover,
    popoverVisible,
    touchableRef,
    popoverAnchorRect
  } = usePopover(true);

  const intl = useIntl();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<DrawerStackParamList, 'Lifeline'>>();
  const dispatch = useAppDispatch();

  const handleCloseExercise = () => {
    route.params?.onFinish();
    navigation.goBack();
  };

  const renderPopover = () => {
    return (
      <Popover
        fromRect={popoverAnchorRect}
        visible={popoverVisible}
        onClose={closePopover}
        arrowStyle={styles.popoverArrow}
        popoverStyle={styles.popover}
        backgroundStyle={{ backgroundColor: `${Colors.GREY1}40` }}
        contentStyle={styles.popoverContent}
        placement="bottom"
        supportedOrientations={['portrait']}
        duration={0}
        useNativeDriver
      >
        <View style={styles.popoverContent}>
          <TouchableOpacity
            onPress={() => {
              closePopover();
              setEditMode(!editMode);
            }}
          >
            <MediumText style={styles.menuText}>
              <FormattedMessage
                defaultMessage="Edit"
                description="Lifeline popover button"
              />
            </MediumText>
          </TouchableOpacity>
        </View>
      </Popover>
    );
  };

  const { getDrawerIcon } = useBackHandler(
    () => true,
    () => null,
    [events],
    navigation,
    handleCloseExercise
  );

  return (
    <Drawer
      title={intl.formatMessage({
        defaultMessage: 'Lifeline',
        description: 'Lifeline drawer title'
      })}
      leftComponent={getDrawerIcon()}
      onBackgroundPress={() =>
        navigation.navigate('DrawerCloseExercise', {
          closeExercise: handleCloseExercise
        })
      }
      rightComponent={
        <TouchableOpacity onPress={() => openPopover()} ref={touchableRef}>
          <Image
            ref={rightIconImage}
            source={require('../../../../assets/Submenu.png')}
            style={{
              height: 24,
              width: 24
            }}
          />
        </TouchableOpacity>
      }
    >
      <View style={styles.container}>
        {renderPopover()}
        {events.length > 0 && (
          <FlatList
            style={styles.flatListContainer}
            ListHeaderComponent={
              <PillButton
                disabled
                textStyle={styles.pillButtonText}
                style={[styles.pillButton, { marginTop: 20 }]}
              >
                <FormattedMessage
                  defaultMessage="Birth"
                  description="Birth button on the lifeline screen"
                />
              </PillButton>
            }
            ListFooterComponent={
              <>
                <PillButton
                  disabled
                  textStyle={styles.pillButtonText}
                  style={[styles.pillButton, { top: -5, marginBottom: 20 }]}
                >
                  <FormattedMessage
                    defaultMessage="Now"
                    description="Now button on the lifeline screen"
                  />
                </PillButton>
              </>
            }
            data={events}
            renderItem={({ item, index }) => (
              <LifelineItem
                event={item}
                isEven={index % 2 === 0}
                isLast={index === events.length - 1}
                editMode={editMode}
                handleDelete={() => {
                  dispatch(removeExercise(item.id));
                  setEditMode(false);
                }}
                key={item.id}
              />
            )}
            keyExtractor={(item) => item.id}
          />
        )}
        <View style={styles.fabContainer}>
          <FAB
            onPress={() => {
              setEditMode(false);
              navigation.navigate('LifelineAddEvent');
            }}
          />
        </View>
      </View>
    </Drawer>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.WHITE
  },
  pillButton: {
    backgroundColor: Colors.GREY5,
    width: 75,
    paddingVertical: 2,
    marginHorizontal: 50
  },
  pillButtonText: { color: Colors.GREY1, fontSize: 14 },
  flatListContainer: {
    width: '100%'
  },
  fabContainer: {
    position: 'absolute',
    bottom: 80,
    right: 40
  },
  menuText: {
    lineHeight: 26
  },
  popover: {
    backgroundColor: Colors.WHITE,
    width: Platform.OS === 'web' ? 200 : undefined,
    borderRadius: 4,
    shadowColor: Colors.GREY5,
    shadowRadius: 8,
    shadowOpacity: 1
  },
  popoverArrow: {
    width: 24,
    height: 12,
    backgroundColor: Colors.WHITE
  },
  popoverContent: {
    paddingHorizontal: 16,
    paddingVertical: 13
  }
});

export default LifelineScreen;
