import { addHours, differenceInSeconds } from 'date-fns';
import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import ProgressCircle from '../../../../../components/ProgressCircle';
import { Session, Theme } from '../../../../../store/types';

export interface SessionCardProps {
  progress: number;
  onButtonPress: () => void;
  session: Session;
  currentDate: Date;
  flowStartDate: string | null;
  previousSessionCompleteDate: string | null;
  onCountdownZero: () => void;
}

const defaultTheme: Theme = {
  primary: Colors.BLUE,
  secondary: Colors.GREY6
};

const SessionCard: FunctionComponent<SessionCardProps> = ({
  progress,
  onButtonPress,
  session,
  currentDate,
  flowStartDate,
  previousSessionCompleteDate,
  onCountdownZero
}) => {
  const { title, file, theme, unlocked, wait } = session;
  const primaryColor = theme.primary ?? defaultTheme.primary;
  const secondaryColor = theme.secondary ?? defaultTheme.secondary;
  const intl = useIntl();

  useEffect(() => {
    const waitTime = calculateWaitTimeInSeconds(currentDate);
    if (waitTime === null) return;
    if (waitTime <= 0 && !session.unlocked) {
      onCountdownZero();
    }
  }, [currentDate]);

  const calculateWaitTimeInSeconds: (date: Date) => number | null = (date) => {
    const referenceDate = getReferenceDate();
    if (!referenceDate) {
      return null;
    }
    return differenceInSeconds(addHours(referenceDate, wait), date);
  };

  const getReferenceDate = () => {
    if (session.waitFrom === 'start') {
      return flowStartDate ? new Date(flowStartDate) : new Date(0);
    } else {
      return previousSessionCompleteDate
        ? new Date(previousSessionCompleteDate)
        : null;
    }
  };

  const getWaitTimeString = () => {
    const waitTime = calculateWaitTimeInSeconds(currentDate);
    if (waitTime === null) {
      return (
        <FormattedMessage
          defaultMessage="after previous Session"
          description="SessionCard label for session waiting on previous session"
        />
      );
    }
    if (waitTime < 0) {
      return (
        <FormattedMessage
          description="SessionCard locked text minutes"
          defaultMessage="shortly"
        />
      );
    } else if (waitTime < 3600) {
      return (
        <FormattedMessage
          description="SessionCard locked text minutes"
          defaultMessage="in {time} minutes"
          values={{ time: Math.ceil(waitTime / 60) }}
        />
      );
    } else {
      return (
        <FormattedMessage
          description="SessionCard locked text hours"
          defaultMessage="in {time} hours"
          values={{ time: Math.ceil(waitTime / 3600) }}
        />
      );
    }
  };

  const renderButton = () => {
    let buttonText = '';
    if (progress <= 0) {
      buttonText = intl.formatMessage({
        defaultMessage: 'Start',
        description: 'SessionCard button for 0% progress'
      });
    } else if (progress >= 100) {
      buttonText = intl.formatMessage({
        defaultMessage: 'View',
        description: 'SessionCard button for 100% progress'
      });
    } else {
      buttonText = intl.formatMessage({
        defaultMessage: 'Continue',
        description: 'SessionCard button for progress between 0% and 100%'
      });
    }
    if (unlocked) {
      return (
        <TouchableOpacity
          style={[
            styles.button,
            {
              borderColor: secondaryColor,
              borderWidth: 1
            }
          ]}
          onPress={() => onButtonPress()}
        >
          <MediumText
            style={{
              color: secondaryColor
            }}
          >
            {buttonText}
          </MediumText>
        </TouchableOpacity>
      );
    } else {
      return (
        <View style={styles.lockedTextContainer}>
          <MediumText style={styles.lockedText}>
            <FormattedMessage
              defaultMessage="Unlocked"
              description="SessionCard label for locked sessions"
            />
            {'\n'}
            {getWaitTimeString()}
          </MediumText>
        </View>
      );
    }
  };

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: unlocked ? primaryColor : Colors.GREY5
        }
      ]}
    >
      <View style={styles.textContainer}>
        <MediumText
          numberOfLines={2}
          style={[
            styles.titleText,
            {
              color: unlocked ? secondaryColor : Colors.GREY3
            }
          ]}
        >
          {title}
        </MediumText>
      </View>
      <View style={{ position: 'absolute', marginTop: 64 }}>
        <ProgressCircle
          activeColor={unlocked ? secondaryColor : 'transparent'}
          passiveColor="rgba(255, 255, 255, 0.25)"
          strokeWidth={4}
          radius={52}
          percentage={progress}
          imageSource={
            unlocked ? file?.url : require('../../../../../assets/locked.png')
          }
          innerPadding={6}
        />
      </View>
      {renderButton()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 232,
    width: 160,
    borderRadius: 16,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-between'
  },
  textContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 13,
    height: 44,
    lineHeight: 22
  },
  titleText: {
    textAlign: 'center'
  },
  image: {
    height: 88,
    width: 88,
    borderRadius: 100 / 2
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    height: 32,
    width: 120,
    marginBottom: 16
  },
  lockedTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 64,
    width: 117
  },
  lockedText: {
    color: Colors.GREY3,
    fontSize: 14,
    textAlign: 'center'
  }
});

export default SessionCard;
