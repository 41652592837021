import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import {
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
  Platform,
  StatusBar
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import ProgressCircle from '../../../../../components/ProgressCircle';
import SpeakerToggle from '../../../../../components/SpeakerToggle';
import Swiper, { SwiperRef } from '../../../../../components/Swiper';

export const SESSION_HEADER_HEIGHT = Platform.OS === 'web' ? 145 : 125;

interface SessionHeaderTab {
  percentage: number;
  locked: boolean;
  imageUri?: string;
  onPress: () => void;
}

interface SessionHeaderProps {
  title: string;
  tabs: SessionHeaderTab[];
  onClosePress: () => void;
  selectedIndex: number;
}

const SessionHeader: FunctionComponent<SessionHeaderProps> = (props) => {
  const insets = useSafeAreaInsets();
  const swiper = useRef<SwiperRef>(null);
  const [muted, setMuted] = useState(false);

  const scrollToIndex = (idx: number) => {
    swiper.current?.scrollToIndex(idx);
  };

  useEffect(() => {
    // initial scrolling workaround: https://github.com/facebook/react-native/issues/24531
    setTimeout(() => scrollToIndex(props.selectedIndex), 1);
  }, [props.selectedIndex]);

  const renderLockedIcon = (tab: SessionHeaderTab, idx: number) => (
    <View style={{ alignItems: 'center' }}>
      {tab.imageUri ? (
        <Image
          source={{ uri: tab.imageUri }}
          style={{ height: 52, width: 52, borderRadius: 90 }}
          resizeMode="contain"
        />
      ) : (
        <View
          style={{
            height: 52,
            width: 52,
            borderWidth: 1,
            borderColor: 'black',
            borderRadius: 90
          }}
        />
      )}
    </View>
  );

  const renderUnlockedIcon = (tab: SessionHeaderTab, idx: number) => (
    <TouchableOpacity
      style={{ marginHorizontal: 2 }}
      onPress={() => {
        tab.onPress?.();
      }}
    >
      <ProgressCircle
        imageSource={tab.imageUri}
        strokeWidth={4}
        radius={36}
        innerPadding={6}
        percentage={tab.percentage}
        activeColor={Colors.TOURQUOISEBLUE}
        passiveColor={`${Colors.ICEBLUE}40`}
      />
    </TouchableOpacity>
  );

  return (
    <View
      style={[
        {
          paddingTop: insets.top,
          height: SESSION_HEADER_HEIGHT + insets.top
        },
        styles.container
      ]}
    >
      <StatusBar backgroundColor={Colors.WHITE} />
      <View style={styles.upperContainer}>
        <TouchableOpacity onPress={props.onClosePress} style={styles.iconLeft}>
          <Image
            style={styles.icon}
            source={require('../../../../../assets/Close.png')}
          />
        </TouchableOpacity>
        <View style={styles.headerTitleWrapper}>
          <MediumText style={styles.headerTitle}>{props.title}</MediumText>
        </View>
        <View style={styles.iconRight}>
          {/* <SpeakerToggle muted={muted} onPress={() => setMuted(!muted)} /> */}
        </View>
      </View>

      <Swiper
        ref={swiper}
        containerStyle={styles.swiperContainer}
        elementWidth={76}
        scrollEnabled={false}
      >
        {props.tabs.map((tab, idx) =>
          tab.locked ? renderLockedIcon(tab, idx) : renderUnlockedIcon(tab, idx)
        )}
      </Swiper>
    </View>
  );
};

export default SessionHeader;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.WHITE,
    paddingBottom: 6,
    width: '100%',
    paddingHorizontal: 15
  },
  upperContainer: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? 64 : 44,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  iconLeft: {
    height: 34,
    width: 34,
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconRight: {
    height: 34,
    width: 34,
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    height: 24,
    width: 24
  },
  headerTitleWrapper: {
    marginVertical: 10,
    width: 260
  },
  headerTitle: {
    lineHeight: 26,
    textAlign: 'center',
    color: Colors.GREY2
  },
  swiperContainer: {
    justifyContent: 'flex-start'
  }
});
