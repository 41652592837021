import React, { ForwardRefRenderFunction, useState } from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  TextInput,
  View,
  ViewStyle
} from 'react-native';

import Colors from '../../../../colors';
import KeyboardInput from '../../../../components/KeyboardInput';
import MediumText from '../../../../components/MediumText';
import Cursor from './Cursor';

const CELL_COUNT = 6;

interface CodeFieldProps {
  value: string;
  onChange?: (text: string) => void;
  hasValidation?: boolean;
  errorText?: string | null;
  errorTextStyle?: StyleProp<ViewStyle>;
}

const CodeField: ForwardRefRenderFunction<TextInput, CodeFieldProps> = (
  props,
  ref
) => {
  const [focused, setFocused] = useState(false);

  const renderCell = (index: number) => (
    <View style={styles.cellContainer}>
      {props.value.length === index && focused ? (
        <Cursor />
      ) : (
        <MediumText>{props.value.charAt(index)}</MediumText>
      )}
    </View>
  );

  return (
    <View style={[{ height: props.hasValidation ? 67 : 48 }, styles.container]}>
      <View>
        <View style={{ flexDirection: 'row' }}>
          {new Array(CELL_COUNT).fill(0).map((_, idx) => renderCell(idx))}
        </View>
        <KeyboardInput
          ref={ref}
          containerStyle={styles.textInputContainer}
          style={styles.textInput}
          disableFullscreenUI
          caretHidden
          spellCheck={false}
          autoCorrect={false}
          blurOnSubmit={false}
          clearButtonMode="never"
          autoCapitalize="characters"
          underlineColorAndroid="transparent"
          maxLength={CELL_COUNT}
          keyboardType="numeric"
          value={props.value}
          onChangeText={props.onChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          hasValidation={props.hasValidation}
          errorText={props.errorText}
          errorTextStyle={props.errorTextStyle}
        />
      </View>
    </View>
  );
};

export default React.forwardRef(CodeField);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center'
  },
  cellContainer: {
    height: 48,
    width: 42,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: Colors.BLUE,
    borderRadius: 16,
    borderWidth: 1.5,
    marginHorizontal: 4
  },
  textInputContainer: {
    ...StyleSheet.absoluteFillObject
  },
  textInput: {
    height: 48,
    opacity: 0.01,
    fontSize: 1,
    ...Platform.select({
      web: {
        width: '100%',
        fontSize: 16
      }
    })
  }
});
