import RNSlider from '@react-native-community/slider';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';

import Colors from '../../../../../colors';
import PillButton from '../../../../../components/PillButton';
import Slider from '../../../../../components/Slider';
import { parseValue } from '../../../../../helpers';
import { QuestionBlock } from '../../../../../store/types';

interface SliderAnswerProps {
  block: QuestionBlock;
  completed: boolean;
  value: string | null; // type of Option value
  onValueChange: (value: string) => void;
  onComplete: () => void;
  loading?: boolean;
}

const SliderAnswer: FunctionComponent<SliderAnswerProps> = (props) => {
  // choose Slider index based on Option value
  const optionsIndex = props.block.options.findIndex(
    (option) => option.value === props.value
  );
  const selected = optionsIndex !== -1 ? optionsIndex : parseValue(props.value);

  const getLabels = (block: QuestionBlock) => {
    // Use labels if they exist, else use first and last entry of options.
    let leftLabel, rightLabel;
    if (block.options && block.options.length > 0) {
      leftLabel = block.options[0].text;
      rightLabel = block.options[block.options.length - 1].text;
    }
    return { leftLabel, rightLabel };
  };

  // get range for slider min/max and keyboard input validation
  const getRange: (block: QuestionBlock) => {
    min: number | undefined;
    max: number | undefined;
    interval: number | undefined;
  } = (block) => {
    // if options exist use indeces else use min/max
    const hasOptions = block.options && block.options.length > 0;
    const min = hasOptions ? 0 : block.min;
    const max = hasOptions ? block.options.length - 1 : block.max;
    const interval = hasOptions ? 1 : block.interval ?? 0;
    return { min, max, interval };
  };

  // get current value label displayed above Slider
  const getSliderDisplayedValue: (
    block: QuestionBlock
  ) => string | undefined = (block) => {
    return typeof selected === 'number' && block.options.length > 0
      ? block.options[Math.round(selected)]?.text
      : selected?.toString() ?? '-';
  };

  const handleValueChange = (val: number) => {
    const chosenOption = props.block.options[val];
    props.onValueChange(
      chosenOption?.value ? chosenOption.value : val.toString()
    );
  };

  const { leftLabel, rightLabel } = getLabels(props.block);
  const { min, max, interval } = getRange(props.block);
  if (props.completed) {
    const optionsIndex = props.block.options.findIndex(
      (option) => option.value === props.block.answer
    );
    const answer = optionsIndex !== -1 ? optionsIndex : props.block.answer;
    return (
      <View style={{ paddingHorizontal: 32, width: '100%' }}>
        <RNSlider
          value={typeof answer === 'number' ? answer : undefined}
          disabled
          thumbTintColor={Colors.GREY1}
          style={{ height: 32 }}
          minimumValue={min}
          maximumValue={max}
          minimumTrackTintColor={Colors.GREY5}
          maximumTrackTintColor={Colors.GREY5}
        />
      </View>
    );
  }
  return (
    <>
      <View style={{ width: '100%' }}>
        <Slider
          displayedValue={
            props.completed ? undefined : getSliderDisplayedValue(props.block)
          } // TODO: design
          minimumValue={min}
          maximumValue={max}
          step={interval}
          leftLabel={leftLabel}
          rightLabel={rightLabel}
          value={typeof selected === 'number' ? selected : undefined}
          onValueChange={handleValueChange}
          style={{ marginBottom: 4, width: '100%' }}
          barStyle={{ paddingHorizontal: 0 }}
          thumbTintColor={Colors.BLUE}
        />
      </View>
      <PillButton
        disabled={props.value === undefined}
        onPress={() => props.onComplete()}
        loading={props.loading}
      >
        <FormattedMessage
          defaultMessage="Save"
          description="Save button text for blocks"
        />
      </PillButton>
    </>
  );
};

export default SliderAnswer;
