import React, { FocusEvent, FunctionComponent, useState } from 'react';
import {
  Image,
  StyleSheet,
  TextStyle,
  View,
  TouchableOpacity,
  TextInputProps,
  Platform
} from 'react-native';

import Colors from '../../../../../colors';
import KeyboardInput from '../../../../../components/KeyboardInput';
import useAppWindowDimensions from '../../../../../hooks/useAppWindowDimensions';

interface Props extends TextInputProps {
  onPress?: () => void;
  hideBorder?: boolean;
  textStyle?: TextStyle | TextStyle[];
  hasValidation?: boolean;
  errorText?: string | null;
}

const SettingsMenuInput: FunctionComponent<Props> = (props) => {
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const dimensions = useAppWindowDimensions();

  return (
    <View style={[styles.container, !props.hideBorder && styles.border]}>
      <KeyboardInput
        style={[styles.input, { width: dimensions.width - 48 }]}
        onChangeText={props.onChangeText}
        value={props.value}
        multiline={false}
        {...props}
        onFocus={(e) => {
          setInputIsFocused(true);
          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          /*  
          Workaround to avoid onBlur blocking the onPress function from the close button to fire
          https://github.com/facebook/react/issues/4210 
          */
          if (Platform.OS === 'web') {
            const evt = e as unknown as FocusEvent<HTMLInputElement>;
            (evt?.relatedTarget as any)?.click();
          }
          setInputIsFocused(false);
          props.onBlur?.(e);
        }}
      />
      {inputIsFocused && (
        <TouchableOpacity
          onPress={() => {
            if (props.onChangeText) props.onChangeText('');
          }}
          hitSlop={{
            left: 20,
            right: 20,
            top: 20,
            bottom: 20
          }}
          style={styles.closeButtonContainer}
        >
          <Image
            source={require('./../../../../../assets/close_button_filled.png')}
            style={styles.closeButton}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default SettingsMenuInput;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.WHITE,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10
  },
  border: {
    borderBottomWidth: 1,
    borderColor: Colors.GREY5
  },
  input: {
    borderWidth: 0,
    borderRadius: 0,
    margin: 0,
    paddingHorizontal: 0
  },
  closeButtonContainer: {
    position: 'absolute',
    right: 10,
    top: 15
  },
  closeButton: {
    width: 20,
    height: 20
  }
});
