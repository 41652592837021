import { useNavigation, useRoute, RouteProp } from '@react-navigation/core';
import React, { FunctionComponent, useEffect } from 'react';
import {
  Animated,
  BackHandler,
  Image,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity
} from 'react-native';
import { PinchGestureHandler } from 'react-native-gesture-handler';

import { RootStackParamList } from '../RootNavigator';

const Gallery: FunctionComponent = () => {
  const navigation = useNavigation();
  const { params } = useRoute<RouteProp<RootStackParamList, 'Gallery'>>();

  const close = () => {
    navigation.goBack();
    return true;
  };

  const scale = new Animated.Value(1);

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', close);
    return () => BackHandler.removeEventListener('hardwareBackPress', close);
  }, []);

  const onZoom = Animated.event([{ nativeEvent: { scale } }], {
    useNativeDriver: true
  });

  return (
    <SafeAreaView style={styles.container}>
      <PinchGestureHandler onGestureEvent={onZoom}>
        <Animated.View
          style={[styles.imageContainer, { transform: [{ scale }] }]}
        >
          <Image style={styles.image} source={{ uri: params.uri }} />
        </Animated.View>
      </PinchGestureHandler>
      <TouchableOpacity style={styles.closeButton} onPress={close}>
        <Image
          style={styles.closeIcon}
          source={require('../../assets/close_white.png')}
        />
      </TouchableOpacity>
    </SafeAreaView>
  );
};

export default Gallery;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%',
    paddingBottom: 60,
    backgroundColor: 'black'
  },
  closeButton: {
    position: 'absolute',
    top: 32,
    right: 32
  },
  closeIcon: {
    height: 30,
    width: 30
  },
  imageContainer: {
    width: '100%'
  },
  image: {
    height: '100%',
    width: '100%',
    resizeMode: 'contain'
  }
});
