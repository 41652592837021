import messaging from '@react-native-firebase/messaging';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useIntl } from 'react-intl';
import {
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  BackHandler,
  NativeEventSubscription
} from 'react-native';

import Colors from '../../../../colors';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import MediumText from '../../../../components/MediumText';
import env from '../../../../env';
import {
  selectCurrentFilteredBlocksByModuleId,
  upsertAllBlocks,
  upsertBlock
} from '../../../../store/blocks';
import { updateFlow } from '../../../../store/flow';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectActiveLanguage,
  selectAllLanguages,
  selectGenderCompatibleLanguages,
  setLanguage
} from '../../../../store/languages';
import {
  selectModuleById,
  selectModulesByMultipleIds,
  upsertAllModules,
  upsertModule
} from '../../../../store/modules';
import {
  selectAllSessions,
  selectSessionById,
  upsertAllSessions,
  upsertSession
} from '../../../../store/sessions';
import {
  Answer,
  Block,
  Module,
  Option,
  QuestionBlock,
  Session
} from '../../../../store/types';
import {
  activateUser,
  assignToStudy,
  authenticate,
  cacheInfo,
  getUserData,
  register,
  saveFcmToken,
  updateUserData
} from '../../../../store/user';
import { AppStackParamList } from '../../../AppNavigator';
import { RootStackParamList } from '../../../RootNavigator';
import BlocksList from './components/BlocksList';

interface ModuleScreenProps {
  moduleEntityId: string;
  sessionEntityId: string;
}

const ModuleScreen: FunctionComponent<ModuleScreenProps> = ({
  sessionEntityId,
  moduleEntityId
}) => {
  const flow = useAppSelector((state) => state.flow);
  const flowLoading = useAppSelector(
    (state) => state.flow.loading || state.sessions.loading
  );
  const blocksLoading = useAppSelector(
    (state) => state.modules.loading || state.blocks.loading
  );
  const sessions = useAppSelector(selectAllSessions);
  const session = useAppSelector((state) =>
    selectSessionById(state, sessionEntityId)
  ) as Session;
  const modules = useAppSelector((state) =>
    selectModulesByMultipleIds(state, session?.moduleIds ?? [])
  );
  const currentBlocks = useAppSelector((state) =>
    selectCurrentFilteredBlocksByModuleId(state, moduleEntityId)
  );
  const module = useAppSelector(
    (state) => selectModuleById(state, moduleEntityId) as Module
  );
  const user = useAppSelector((state) => state.user);
  const userStatus = useAppSelector((state) => state.user.status);
  const fullname = useAppSelector((state) => state.user.fullname);
  const [currentIndex, setCurrentIndex] = useState(currentBlocks.length - 1);
  const [error, setError] = useState<{ error: boolean; message: string }>({
    error: false,
    message: ''
  });
  const allLanguages = useAppSelector(selectAllLanguages);
  const genderCompatibleLanguages = useAppSelector(
    selectGenderCompatibleLanguages
  );
  const activeLanguage = useAppSelector(selectActiveLanguage);
  const navigation = useNavigation();
  const stackNavigation =
    useNavigation<
      StackNavigationProp<AppStackParamList & RootStackParamList>
    >();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const unknownErrorText = intl.formatMessage({
    defaultMessage: 'An unknown error occurred. Please try again later.',
    description: 'Block validation unknown error'
  });

  useEffect(() => {
    const syncModule = async () => {
      if (!session?.started) {
        await dispatch(
          upsertSession({
            entityId: sessionEntityId,
            changes: {
              unlocked: true,
              unlockedAt: session?.unlockedAt,
              started: true,
              startedAt: new Date().toISOString()
            }
          })
        );
      }
      if (!module.started) {
        await dispatch(
          upsertModule({
            entityId: moduleEntityId,
            changes: { started: true, startedAt: new Date().toISOString() }
          })
        );
      }
    };
    syncModule();
  }, []);

  useEffect(() => {
    if (
      !module.completed &&
      currentBlocks.length &&
      currentBlocks.every((block) => block.completed)
    ) {
      saveModuleProgress();
      goToNextModule();
    } else {
      goToNextBlock();
    }
  }, [
    currentBlocks.length,
    currentBlocks[currentBlocks.length - 1]?.id,
    currentBlocks[currentBlocks.length - 1]?.completed
  ]);

  useEffect(() => {
    if (user.status === 'excluded') {
      stackNavigation.navigate('Tab', {
        screen: 'Home',
        params: { screen: 'Home' }
      });
    }
  }, [user.status]);

  useFocusEffect(
    useCallback(() => {
      const backAction = () => {
        if (currentIndex === 0) {
          // call navigator back action to switch module
          return false;
        } else {
          goToPreviousBlock();
          return true;
        }
      };

      let backHandler: NativeEventSubscription;

      // workaround to get BackHandler to correctly listen to new event
      setTimeout(() => {
        backHandler = BackHandler.addEventListener(
          'hardwareBackPress',
          backAction
        );
      }, 1);

      return () => backHandler?.remove();
    }, [currentIndex])
  );

  const goToPreviousBlock = () => {
    if (currentIndex > 0) {
      if (!module.completed) {
        dispatch(
          upsertBlock({
            entityId: currentBlocks[currentIndex - 1].id,
            changes: { completed: false, completedAt: null, answer: '' }
          })
        );
      }
      setCurrentIndex(currentIndex - 1);
    }
  };

  const goToNextBlock = () => {
    if (currentIndex + 1 < currentBlocks.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const goToNextModule = async () => {
    const navState = navigation.dangerouslyGetState();
    const nextRouteName = navState.routeNames[navState.index + 1];
    if (nextRouteName) {
      navigation.navigate(nextRouteName);
    } else {
      stackNavigation.pop();
    }
  };

  const handleBlockComplete = (
    answer: string | Option | Option[] | null | undefined
  ) => {
    const currentBlock = currentBlocks[currentIndex];
    const valToBeSaved = (() => {
      if (typeof answer === 'string') {
        return answer;
      }
      if (typeof answer === 'object' && answer !== null && 'value' in answer) {
        return answer.value;
      }
      if (Array.isArray(answer)) {
        return answer.map((option) => option.value);
      }
      return '';
    })();

    if ('userConfig' in currentBlock && !!currentBlock.userConfig && answer) {
      handleUserConfigBlock(valToBeSaved, currentBlock);
    } else {
      saveBlockProgress(currentBlock, valToBeSaved);
    }
  };

  const saveBlockProgress = async (block: Block, answer: string | string[]) => {
    const completedAt = new Date().toISOString();
    const blockResultAction = await dispatch(
      upsertBlock({
        entityId: block.id,
        changes: {
          completed: true,
          completedAt,
          answer
        }
      })
    );
    if (
      upsertBlock.rejected.match(blockResultAction) &&
      blockResultAction.error.message?.match(/(Session|Module) does not exist/)
    ) {
      // If block's parent session or module don't exist they are created here
      await dispatch(
        upsertSession({
          entityId: sessionEntityId,
          changes: {
            unlocked: true,
            unlockedAt: session.unlockedAt,
            started: true,
            startedAt: session.startedAt
          }
        })
      );
      await dispatch(
        upsertModule({
          entityId: moduleEntityId,
          changes: {
            started: true,
            startedAt: module.startedAt ?? new Date().toISOString()
          }
        })
      );
      // Try again to save block progress with session and module created
      saveBlockProgress(block, answer);
    }
  };

  const saveModuleProgress = async () => {
    const completedAt = new Date().toISOString();

    const moduleResultAction = await dispatch(
      upsertModule({
        entityId: moduleEntityId,
        changes: {
          started: true,
          startedAt: module.startedAt,
          completed: true,
          completedAt
        }
      })
    );

    if (
      !session.completed &&
      upsertModule.fulfilled.match(moduleResultAction) &&
      modules.filter((m) => m.id !== module.id).every((m) => m.completed)
    ) {
      const sessionResultAction = await dispatch(
        upsertSession({
          entityId: sessionEntityId,
          changes: {
            unlocked: true,
            unlockedAt: session.unlockedAt,
            started: true,
            startedAt: session.startedAt,
            completed: true,
            completedAt
          }
        })
      );
      if (
        upsertSession.fulfilled.match(sessionResultAction) &&
        sessions.filter((s) => s.id !== session.id).every((s) => s.completed)
      ) {
        await dispatch(
          updateFlow({
            started: true,
            startedAt: flow.startedAt,
            completed: true,
            completedAt
          })
        );
      }
    }

    if (!upsertModule.fulfilled.match(moduleResultAction)) {
      // Undo the last block
      goToPreviousBlock();
      // Dont close the module and show an error to user
      alert('Oops! Something went wrong, please try again later.');
    }

    // check if user is excluded
    await dispatch(getUserData());
  };

  const handleUserConfigBlock: (
    value: Answer,
    block: QuestionBlock
  ) => void = async (value, block) => {
    let error = false;

    // Gender check
    if (block.userConfig === 'gender') {
      error = await handleGenderUserConfigBlock(value, block);
    }

    // Language check
    else if (block.userConfig === 'language') {
      error = await handleLanguageUserConfigBlock(value, block);
    }

    // Username check
    else if (block.userConfig === 'username') {
      error = await handleUsernameUserConfigBlock(value, block);
    }

    // Token check
    else if (block.userConfig === 'participant') {
      error = await handleParticipantUserConfigBlock(value, block);
    }

    // User Registration
    else if (block.userConfig === 'password') {
      error = await handlePasswordUserConfigBlock(value, block);

      // Unassign value so that it doesn't get stored as plain text
      value = '';
    }

    // Study assignment
    else if (block.userConfig === 'study') {
      error = await handleStudyUserConfigBlock(value, block);
      // Unassign value so that it doesn't get stored as plain text
      value = '';
    }

    // Set generic user config
    else {
      error = await handleGenericUserConfigBlock(value, block);
    }
    if (error) {
      return;
    }
    saveBlockProgress(block, value);
  };

  // These functions return true or false depending on if there was an error
  // when handling the userConfig block or not.
  const handleGenderUserConfigBlock = (
    value: Answer,
    block: QuestionBlock
  ): Promise<boolean> => {
    if (activeLanguage.gender !== null && activeLanguage.gender !== value) {
      const genderSpecificLanguage = allLanguages.find(
        (lan) =>
          lan.language === activeLanguage.language && lan.gender === value
      );
      if (genderSpecificLanguage) {
        dispatch(setLanguage(genderSpecificLanguage.code));
      }
    }

    return handleGenericUserConfigBlock(value, block);
  };

  const handleLanguageUserConfigBlock = (
    value: Answer,
    block: QuestionBlock
  ): Promise<boolean> => {
    const eligibleLanguage = genderCompatibleLanguages.find(
      (lan) => lan.language === value
    );
    if (eligibleLanguage?.code) {
      dispatch(setLanguage(eligibleLanguage.code));
    }
    return handleGenericUserConfigBlock(value, block);
  };

  const handleUsernameUserConfigBlock = async (
    value: Answer,
    block: QuestionBlock
  ): Promise<boolean> => {
    try {
      const response = await fetch(`${env.API_BASE_URL}/users/${value}`);
      if (response.status === 404) {
        setError({ error: false, message: '' });
        dispatch(cacheInfo({ [block.userConfig as string]: value }));
      } else if (response.status === 200) {
        setError({
          error: true,
          message: intl.formatMessage({
            defaultMessage: 'This username is already taken.',
            description: 'Username block username taken error'
          })
        });
        return true;
      } else {
        setError({
          error: true,
          message: unknownErrorText
        });
        return true;
      }
    } catch {
      setError({
        error: true,
        message: unknownErrorText
      });
      return true;
    }
    return false;
  };

  const handleParticipantUserConfigBlock = async (
    value: Answer,
    block: Block
  ): Promise<boolean> => {
    try {
      const response = await fetch(`${env.API_BASE_URL}/auth/password`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: value,
          password: ''
        })
      });
      const data = await response.json();
      if (data.code === 'ERR_TOKEN_INVALID') {
        setError({
          error: true,
          message: intl.formatMessage({
            defaultMessage: 'The entered token is invalid. Please try again.',
            description: 'Block validation invalid token error'
          })
        });
        return true;
      } else {
        setError({ error: false, message: '' });
      }
    } catch {
      setError({ error: true, message: unknownErrorText });
      return true;
    }
    return false;
  };

  const handlePasswordUserConfigBlock = async (
    value: Answer,
    block: Block
  ): Promise<boolean> => {
    const username = currentBlocks.find(
      (block) => 'userConfig' in block && block.userConfig === 'username'
    )?.answer;
    const participantId = currentBlocks.find(
      (block) => 'userConfig' in block && block.userConfig === 'participant'
    )?.answer;

    if (username) {
      const registerResult = await dispatch(
        register({
          blockId: block.id,
          username: username as string,
          password: value as string
        })
      );

      if (register.fulfilled.match(registerResult)) {
        const authenticateResult = await dispatch(
          authenticate({
            username: username as string,
            password: value as string
          })
        );

        if (authenticate.fulfilled.match(authenticateResult)) {
          await syncUserInfoAndBlocks();
          Platform.OS !== 'web' &&
            (await dispatch(saveFcmToken(await messaging().getToken())));
        }
      } else {
        setError({ error: true, message: unknownErrorText });
        return true;
      }
    } else if (participantId) {
      const activateUserResult = await dispatch(
        activateUser({
          blockId: block.id,
          token: participantId as string,
          newPassword: value as string
        })
      );
      if (activateUser.fulfilled.match(activateUserResult)) {
        stackNavigation.navigate('Drawer', {
          screen: 'LoginDrawer',
          params: {
            onFinish: async () => {
              await saveBlockProgress(block, '');
              await syncUserInfoAndBlocks();
            }
          }
        });
        return true;
      } else {
        setError({ error: true, message: unknownErrorText });
        return true;
      }
    } else {
      alert(
        "Registration not possible as block with userConfig 'username' or 'participant' could not be found in this module."
      );
      return true;
    }
    return false;
  };

  // NOTE(Mariana): The following two methods call API endpoints that require a user to be logged in
  // If they're not, the methods will return an error, and the saveBlockProgress() at the end of handleUserConfigBlocks() won't be reached
  // So for these two, if the user isn't logged in, just cache the information and sync it later, once the user registration is complete
  // (which will happen in handlePasswordUserConfigBlock())

  const handleStudyUserConfigBlock = async (
    value: Answer,
    block: QuestionBlock
  ): Promise<boolean> => {
    const isUserLoggedIn = user.userId && user.token;

    if (isUserLoggedIn) {
      const resultAction = await dispatch(
        assignToStudy({ blockId: block.id, code: value as string })
      );

      if (assignToStudy.fulfilled.match(resultAction)) {
        setError({ error: false, message: '' });
        await dispatch(getUserData());
      } else {
        setError({
          error: true,
          message: intl.formatMessage({
            defaultMessage:
              'The study assignment failed. Please check your input.',
            description: 'Study assignment error.'
          })
        });
        return true;
      }
    } else {
      dispatch(cacheInfo({ [block.userConfig as string]: value }));
    }
    return false;
  };

  const handleGenericUserConfigBlock = async (
    value: Answer,
    block: QuestionBlock
  ): Promise<boolean> => {
    const isUserLoggedIn = user.userId && user.token;

    if (isUserLoggedIn) {
      try {
        const response = await dispatch(
          updateUserData({ [block.userConfig as string]: value })
        );
        if (response.payload && 'error' in response.payload) {
          setError({
            error: true,
            message: getValidationText(block.userConfig)
          });
          return true;
        } else if (response.payload) {
          setError({ error: false, message: '' });
          dispatch(
            upsertBlock({
              entityId: block.id,
              changes: {
                completed: true,
                completedAt: new Date().toISOString(),
                answer: value
              }
            })
          );
        } else {
          setError({ error: true, message: unknownErrorText });
          return true;
        }
      } catch (e) {
        setError({
          error: true,
          message: unknownErrorText
        });
        return true;
      }
    } else {
      dispatch(cacheInfo({ [block.userConfig as string]: value }));
    }
    return false;
  };

  const syncUserInfoAndBlocks = async () => {
    const { userId, token, ...userInfoBeforeRegistering } = user;

    await dispatch(updateUserData(userInfoBeforeRegistering));
    await dispatch(upsertAllSessions());
    await dispatch(upsertAllModules());
    await dispatch(upsertAllBlocks());
  };

  const getValidationText = (userConfig: string | undefined) => {
    switch (userConfig) {
      case 'email':
        return intl.formatMessage({
          defaultMessage: 'Please enter a valid email address.',
          description: 'Email validation error text'
        });
      case 'phone':
        return intl.formatMessage({
          defaultMessage: 'Please enter a valid phone number.',
          description: 'Phone number validation error text'
        });
      case 'study':
        return intl.formatMessage({
          defaultMessage: 'Please enter a valid study ID.',
          description: 'Study ID validation error text'
        });
      case 'participant':
        return intl.formatMessage({
          defaultMessage: 'Please enter a valid participant ID.',
          description: 'Participant ID validation error text'
        });
      default:
        return intl.formatMessage({
          defaultMessage: 'Please enter a valid value.',
          description: 'Default validation error text'
        });
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'position' : undefined}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 130 : 0}
      style={styles.container}
      contentContainerStyle={{ flex: 1 }}
    >
      {flowLoading && <LoadingOverlay />}
      <BlocksList
        blocks={currentBlocks}
        goToPrevious={goToPreviousBlock}
        disabled={module.completed}
        initialIndex={currentBlocks.length - 1}
        currentIndex={currentIndex}
        onBlockComplete={handleBlockComplete}
        validation={error}
        loading={blocksLoading}
      />
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  }
});

export default ModuleScreen;
