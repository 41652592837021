import React, { FunctionComponent } from 'react';
import { Image, View } from 'react-native';

interface HeaderSegmentButtonProps {
  selected?: boolean;
  size?: number;
  type?: keyof typeof ImageType;
}

export enum ImageType {
  ALL = require('./assets/all.png'),
  ASSESSMENT = require('./assets/assessment.png'),
  DIARY = require('./assets/diary.png'),
  EHELPER = require('./assets/eHelper.png'),
  EXERCISE = require('./assets/exercise.png'),
  FAQ = require('./assets/FAQ.png'),
  FEEDBACK = require('./assets/feedback.png'),
  HOMEWORK = require('./assets/homework.png'),
  MOODCIRCLE = require('./assets/moodcircle.png'),
  SESSIONS = require('./assets/sessions.png'),
  SUBSTANCE = require('./assets/substance.png'),
  TASK = require('./assets/task.png')
}

const HeaderSegmentButton: FunctionComponent<HeaderSegmentButtonProps> = ({
  size = 40,
  selected = false,
  type = 'ALL'
}) => {
  return (
    <View
      style={{
        height: size + 8,
        width: size + 8,
        backgroundColor: selected ? 'rgba(73, 85, 206, 0.25)' : 'transparent',
        borderRadius: 90,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Image
        source={ImageType[type]}
        style={{
          height: size,
          width: size
        }}
      />
    </View>
  );
};

export default HeaderSegmentButton;
