import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { RootState } from '.';
import { Asset } from './types';

const assetsAdapter = createEntityAdapter<Asset>({
  selectId: (asset: Asset) => asset.url
});

export const assetsSlice = createSlice({
  name: 'assets',
  initialState: assetsAdapter.getInitialState(),
  reducers: {
    upsertAssets(state, action) {
      assetsAdapter.upsertMany(state, action);
    }
  }
});

export const { selectById: selectAssetById } =
  assetsAdapter.getSelectors<RootState>((state) => state.assets);

export const selectMultipleAssetsById = (state: RootState, ids: string[]) =>
  ids.map((id) => selectAssetById(state, id));

export const { upsertAssets } = assetsSlice.actions;

export default assetsSlice.reducer;
