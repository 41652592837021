import React, { FunctionComponent } from 'react';
import {
  FlatList,
  View,
  StyleSheet,
  Image,
  ListRenderItem,
  TouchableOpacity,
  ViewStyle
} from 'react-native';

import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import countryData, { Country } from './countryData';

interface CountryListProps {
  selectedCountry: Country;
  onChangeCountry: (country: Country) => void;
  containerStyle?: ViewStyle;
}

const CountryList: FunctionComponent<CountryListProps> = (props) => {
  const renderItem: ListRenderItem<Country> = ({ item }) => (
    <TouchableOpacity
      style={styles.itemContainer}
      onPress={() => props.onChangeCountry(item)}
    >
      <View style={styles.leftContainer}>
        <View style={styles.shadowContainer}>
          <Image source={item.flag} style={styles.flagImage} />
        </View>
        <MediumText style={{ flex: 1, marginLeft: 8 }}>
          <MediumText style={{ color: Colors.GREY3 }}>{item.name}</MediumText>
          {'  '}
          <MediumText style={{ color: Colors.GREY3 }}>
            {`(+${item.callingCode})`}
          </MediumText>
        </MediumText>
      </View>
      <View style={{ width: 36 }}>
        {item.name === props.selectedCountry.name && (
          <Image
            source={require('../../../../../assets/Check.png')}
            style={styles.checkImage}
          />
        )}
      </View>
    </TouchableOpacity>
  );

  return (
    <View style={[styles.container, props.containerStyle]}>
      <FlatList
        initialNumToRender={50}
        maxToRenderPerBatch={50}
        updateCellsBatchingPeriod={20}
        showsVerticalScrollIndicator={false}
        style={styles.list}
        data={countryData}
        keyExtractor={(item, index) => `${item.name}${index}`}
        renderItem={renderItem}
      />
    </View>
  );
};

export default CountryList;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 56,
    width: '100%',
    height: 300,
    borderRadius: 16,
    backgroundColor: Colors.WHITE,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.05,
    shadowRadius: 10,
    elevation: 3
  },
  list: {
    width: '100%',
    paddingHorizontal: 16
  },
  itemContainer: {
    minHeight: 50,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  leftContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  shadowContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    shadowOffset: { height: 1, width: 0 },
    shadowRadius: 10,
    shadowColor: '#000',
    shadowOpacity: 0.15,
    elevation: 3
  },
  flagImage: {
    height: 18,
    width: 28,
    resizeMode: 'contain'
  },
  checkImage: {
    height: 20,
    width: 20,
    marginLeft: 16
  }
});
