import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import Colors from '../../../../colors';
import Slider from '../../../../components/Slider';
import { parseValue } from '../../../../helpers';
import { ListExerciseTemplateBlock } from '../../../../store/types';

interface SliderAnswerProps {
  block: ListExerciseTemplateBlock;
  completed: boolean;
  value: number | null;
  onComplete: (value: string) => void;
}

const SliderListAnswer: FunctionComponent<SliderAnswerProps> = (props) => {
  const getLabels = (block: ListExerciseTemplateBlock) => {
    let leftLabel, rightLabel;
    if (block.options && block.options.length > 0) {
      leftLabel = block.options[0].text;
      rightLabel = block.options[block.options.length - 1].text;
    }
    return { leftLabel, rightLabel };
  };

  const getRange: (block: ListExerciseTemplateBlock) => {
    min: number | undefined;
    max: number | undefined;
    interval: number | undefined;
  } = (block) => {
    // if options exist use indeces else use min/max
    const hasOptions = block.options && block.options.length > 2;
    const min = hasOptions ? 0 : block.min ?? 0;
    const max = hasOptions ? block.options.length - 1 : block.max ?? 1;
    const interval = hasOptions ? 1 : block.interval ?? 0;
    return { min, max, interval };
  };

  const { leftLabel, rightLabel } = getLabels(props.block);
  const { min, max, interval } = getRange(props.block);

  return (
    <>
      <View style={{ width: '100%' }}>
        <Slider
          minimumValue={min}
          maximumValue={max}
          step={interval}
          leftLabel={leftLabel}
          rightLabel={rightLabel}
          value={props.value ?? 0}
          onSlidingComplete={(val) => props.onComplete(parseValue(val))}
          style={{ marginBottom: 4, width: '100%' }}
          thumbTintColor={Colors.BLUE}
        />
      </View>
    </>
  );
};

export default SliderListAnswer;
