import React, { FunctionComponent, PropsWithChildren } from 'react';

interface Props {
  step: number;
  current: number;
}

const Step: FunctionComponent<PropsWithChildren<Props>> = (props) => {
  if (props.step !== props.current || !props.children) {
    return <></>;
  }
  return <>{props.children}</>;
};

export default Step;
