import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

import { RootState } from '.';
import env from '../env';
import { filterTranslations } from '../helpers';
import { FAQ } from './types';

const faqAdapter = createEntityAdapter<FAQ>({
  selectId: (faq) => faq.id
});

const initialState = faqAdapter.getInitialState();

export const fetchFAQ = createAsyncThunk<FAQ[], string, { state: RootState }>(
  'faq/fetchFAQ',
  async (languageCode) => {
    const response = await fetch(`${env.API_BASE_URL}/faq`);

    let data = await response.json();

    if (!response.ok) {
      throw new Error(
        data.code && data.message
          ? `${data.code}: ${data.message}`
          : `${response.status} ${response.statusText}`
      );
    }

    data = filterTranslations(data, languageCode);

    return data.map((faq: any) => {
      return {
        id: faq.id,
        name: faq.name,
        answer: faq.translations[0]?.answer ?? faq.translations[0]?.placeholder,
        question:
          faq.translations[0]?.question ?? faq.translations[0]?.placeholder,
        study: faq.study
      };
    });
  }
);

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFAQ.fulfilled, (state, action) => {
      faqAdapter.setAll(state, action.payload);
    });
  }
});

export const selectApplicableFAQ = (studies: { id: number; name: string }[]) =>
  createSelector(
    [selectAllFAQ],
    (faqs) =>
      faqs.filter((faq) => {
        const faqStudies = faq.study
          ?.split(',')
          .map((study: any) => study.trim().toLowerCase());
        return (
          !faq.study ||
          !!studies.find(
            (study) =>
              faqStudies?.includes((study.name as string).toLowerCase()) // NOTE: includes() allows the study field to be a string that contains more than one study (e.g. "pilot,eceta")
          )
        );
      }) as FAQ[]
  );

export const { selectAll: selectAllFAQ } = faqAdapter.getSelectors<RootState>(
  (state) => state.faq
);

export default faqSlice.reducer;
