import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { View, StyleSheet, ScrollView } from 'react-native';

import Colors from '../../../../colors';
import Header from '../../../../components/Header';
import ListItem from '../../../../components/ListItem';
import MediumText from '../../../../components/MediumText';
import SemiBoldText from '../../../../components/SemiBoldText';
import { selectUnlockedFeatures } from '../../../../store/blocks';
import {
  selectLetterToAFriendExercises,
  selectResponsibilityPieExercises,
  selectSafetyItems,
  selectWrittenExposuresExercises
} from '../../../../store/exercises';
import { useAppSelector } from '../../../../store/hooks';
import {
  AudioExerciseTemplate,
  ExerciseType,
  ListExerciseTemplate,
  UnlockableFeature,
  WrittenExposureTemplate
} from '../../../../store/types';
import { AppStackParamList } from '../../../AppNavigator';
import { RootStackParamList } from '../../../RootNavigator';
import { unlockedFeaturesDebug } from './debug';

const LibraryScreen: FunctionComponent = () => {
  const intl = useIntl();
  const unlockedFeatures = useAppSelector(selectUnlockedFeatures);
  const safetyItemsEmpty = useAppSelector(selectSafetyItems).length === 0;
  const letterToAFriendExercisesEmpty =
    useAppSelector(selectLetterToAFriendExercises).length === 0;
  const responsibilityPieExercisesEmpty =
    useAppSelector(selectResponsibilityPieExercises).length === 0;
  const writtenExposureExercisesEmpty =
    useAppSelector(selectWrittenExposuresExercises).length === 0;
  const libraryEmpty =
    unlockedFeatures.length === 0 &&
    safetyItemsEmpty &&
    letterToAFriendExercisesEmpty &&
    responsibilityPieExercisesEmpty &&
    writtenExposureExercisesEmpty;

  const debugMode = useAppSelector((state) => state.user.debugMode);

  const navigation =
    useNavigation<
      StackNavigationProp<RootStackParamList & AppStackParamList>
    >();

  const getItemProps = (feature: UnlockableFeature) => {
    const exercises = [
      {
        type: ExerciseType.Audio,
        title: intl.formatMessage({
          defaultMessage: 'Audio exercise',
          description: 'Library audio exercise default title'
        }),
        onPress: () => {
          if (!feature.template) {
            alert('No Audio template found.');
            return;
          }
          navigation.navigate('Drawer', {
            screen: 'AudioPlayer',
            params: {
              template: feature.template as AudioExerciseTemplate,
              onFinish: () => {}
            }
          });
        }
      },
      {
        type: ExerciseType.CheckingIn,
        title: intl.formatMessage({
          defaultMessage: 'Checking In',
          description: 'Checking in title'
        }),
        onPress: () =>
          navigation.navigate('Drawer', {
            screen: 'CheckingIn'
          })
      },
      {
        type: ExerciseType.CognitiveTriangle,
        title: intl.formatMessage({
          defaultMessage: 'Cognitive Triangle',
          description: 'Cognitive triangle title'
        }),
        onPress: () => navigation.navigate('CognitiveTriangleArchive')
      },

      {
        type: ExerciseType.Lifeline,
        title: intl.formatMessage({
          defaultMessage: 'Lifeline',
          description: 'Lifeline title'
        }),
        onPress: () =>
          navigation.navigate('Drawer', {
            screen: 'Lifeline'
          })
      },
      {
        type: ExerciseType.ListExercise,
        title: intl.formatMessage({
          defaultMessage: 'List',
          description: 'Library list exercise default title'
        }),
        onPress: () => {
          if (!feature.template) {
            alert('No List exercise template found.');
            return;
          }
          if ((feature.template as ListExerciseTemplate).complexList) {
            navigation.navigate('Drawer', {
              screen: 'ListExercise',
              params: {
                onFinish: () => {},
                template: feature.template as ListExerciseTemplate
              }
            });
          } else {
            navigation.navigate('Drawer', {
              screen: 'SimpleListExercise',
              params: {
                onFinish: () => {},
                template: feature.template as ListExerciseTemplate
              }
            });
          }
        }
      },
      {
        type: ExerciseType.ResponsibilityPie,
        title: intl.formatMessage({
          defaultMessage: 'Responsibility Pie Archive',
          description: 'Responsibility pie archive title'
        }),
        onPress: () => navigation.navigate('ResponsibilityPieArchive')
      },
      {
        type: ExerciseType.WrittenExposure,
        title: intl.formatMessage({
          defaultMessage: 'Written Exposure',
          description: 'Written exposure title'
        }),
        onPress: () =>
          navigation.navigate('Drawer', {
            screen: 'WrittenExposure',
            params: {
              onFinish: () => {},
              template: feature.template as WrittenExposureTemplate
            }
          })
      }
    ];
    return exercises.find(
      (exercise) => exercise.type === feature.unlockedFeature
    );
  };

  const renderHeader = () => {
    return (
      <Header arrowPosition="start" arrowOffset={20}>
        <SemiBoldText style={styles.headerTitle}>
          <FormattedMessage
            defaultMessage="Library"
            description="Library header title"
          />
        </SemiBoldText>
      </Header>
    );
  };

  const renderItems = (unlockedFeatures: UnlockableFeature[]) => {
    return unlockedFeatures
      .sort((a, b) => a.unlockedFeature.localeCompare(b.unlockedFeature))
      .map((feature) => (
        <View style={{ marginBottom: 12 }}>
          <ListItem
            {...getItemProps(feature)}
            iconSource={feature.file}
            title={
              feature.template && 'title' in feature.template
                ? feature.template.title
                : (getItemProps(feature)?.title as string)
            }
          />
        </View>
      ));
  };

  const renderLetterToAFriendArchive = () => {
    return !letterToAFriendExercisesEmpty ? (
      <ListItem
        title={intl.formatMessage({
          defaultMessage: 'Letter To A Friend Archive',
          description: 'Library screen letter to a friend archive item title'
        })}
        onPress={() => navigation.navigate('LetterToAFriendArchive')}
        style={{ marginBottom: 12 }}
      />
    ) : null;
  };

  const renderWrittenExposureArchive = () => {
    return (
      !writtenExposureExercisesEmpty && (
        <View style={{ marginBottom: 12 }}>
          <ListItem
            title={intl.formatMessage({
              defaultMessage: 'Written Exposure Archive',
              description: 'Written exposure archive title'
            })}
            onPress={() => navigation.navigate('WrittenExposureArchive')}
          />
        </View>
      )
    );
  };

  const renderArchives = () => {
    return (
      <>
        {renderLetterToAFriendArchive()}
        {renderWrittenExposureArchive()}
      </>
    );
  };

  const renderExercisesSection = () => {
    return (
      <>
        <MediumText style={styles.subHeadline}>
          <FormattedMessage
            defaultMessage="Exercises"
            description="Exercises Library Section"
          />
        </MediumText>
        {(!safetyItemsEmpty || debugMode) && (
          <View style={{ marginBottom: 12 }}>
            <ListItem
              title={intl.formatMessage({
                defaultMessage: 'Safety Plan',
                description: 'Safety plan title'
              })}
              iconSource={require('../../../../assets/safetyplan.png')}
              lockedIconSource={require('./assets/triangleIconLocked.png')}
              onPress={() => navigation.navigate('SafetyPlan')}
            />
          </View>
        )}
        {debugMode && renderItems(unlockedFeaturesDebug)}
        {renderItems(unlockedFeatures)}
      </>
    );
  };

  return (
    <View style={styles.container}>
      {renderHeader()}
      <ScrollView style={styles.content}>
        {!debugMode && libraryEmpty ? (
          <MediumText>
            <FormattedMessage
              defaultMessage="Once you have unlocked exercises, you will find them here."
              description="Locked library message"
            />
          </MediumText>
        ) : (
          <>
            {renderExercisesSection()}
            {renderArchives()}
          </>
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  content: {
    flex: 1,
    marginTop: 40,
    paddingHorizontal: 16
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: 32
  },
  subHeadline: {
    fontSize: 20,
    marginBottom: 14
  }
});

export default LibraryScreen;
