import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { Platform } from 'react-native';

import { RootState } from '.';
import env from '../env';
import { getExerciseFromApiData } from '../helpers';
import { upsertAssets } from './assets';
import { sortByCreationDateDsc } from './helpers';
import {
  Asset,
  CognitiveTriangleExercise,
  ConcernsAndBarriersExercise,
  Exercise,
  ExerciseType,
  LetterToAFriendExercise,
  LifelineExercise,
  ListExercise,
  SafetyItem,
  WrittenExposureExercise
} from './types';

const exercisesAdapter = createEntityAdapter<Exercise>({
  selectId: (exercise) => exercise.id
});

export const getExercises = createAsyncThunk<
  Exercise[],
  void,
  { state: RootState }
>('exercises/getExercises', async (_, { getState, dispatch }) => {
  const response = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/exercises`,
    {
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      }
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      data.code && data.message
        ? `${data.code}: ${data.message}`
        : `${response.status} ${response.statusText}`
    );
  }

  dispatch(
    upsertAssets(
      data.items.reduce((files: Asset[], exercise: any) => {
        return [
          ...files,
          ...exercise.files.map((file: Asset) => ({
            url: file.url,
            mimetype: file.mimetype,
            type: file.mimetype?.split('/')[0],
            localUrl: null
          }))
        ];
      }, [])
    )
  );

  return data.items.map((exercise: any) => getExerciseFromApiData(exercise));
});

export const saveExercise = createAsyncThunk<
  Exercise,
  Exercise,
  { state: RootState }
>('exercises/saveExercise', async (exerciseData, { getState, dispatch }) => {
  if (!getState().user.userId) {
    return exerciseData;
  }

  const { id, type, createdAt, files, ...rest } = exerciseData;

  const formData = new FormData();

  formData.append('id', id);
  formData.append('type', type);
  formData.append('createdAt', createdAt);
  formData.append('data', JSON.stringify(rest));

  if (files) {
    files.forEach((uri, idx) =>
      formData.append('files', {
        uri: Platform.OS === 'ios' ? uri.replace(/^file:\/\//, '') : uri,
        name: `file-${idx}`,
        type: 'image/jpeg'
      } as any)
    );
  }

  const response = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/exercises`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      },
      body: formData
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      data.code && data.message
        ? `${data.code}: ${data.message}`
        : `${response.status} ${response.statusText}`
    );
  }

  dispatch(
    upsertAssets(
      data.files.map((file: Asset) => ({
        url: file.url,
        mimetype: file.mimetype,
        type: file.mimetype?.split('/')[0],
        localUrl: null
      }))
    )
  );

  return getExerciseFromApiData({
    ...data
  });
});

export const deleteExercise = createAsyncThunk<
  string,
  string,
  { state: RootState }
>('exercises/deleteExercise', async (exerciseId, { getState }) => {
  if (!getState().user.userId) {
    return exerciseId;
  }

  const response = await fetch(
    `${env.API_BASE_URL}/users/${
      getState().user.userId
    }/exercises/${exerciseId}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        'Content-Type': 'application/json'
      }
    }
  );

  if (!response.ok) {
    throw new Error('Exercise could not be deleted');
  }

  return exerciseId;
});

export const exercisesSlice = createSlice({
  name: 'exercises',
  initialState: exercisesAdapter.getInitialState(),
  reducers: {
    addExercise: exercisesAdapter.addOne,
    removeExercise: exercisesAdapter.removeOne
  },
  extraReducers: (builder) => {
    builder.addCase(getExercises.fulfilled, (state, action) => {
      exercisesAdapter.setAll(state, action.payload);
    });
    builder.addCase(saveExercise.fulfilled, (state, action) => {
      exercisesAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(deleteExercise.fulfilled, (state, action) => {
      exercisesAdapter.removeOne(state, action.payload);
    });
  }
});

export const {
  selectById: selectExerciseById,
  selectIds: selectExerciseIds,
  selectEntities: selectExerciseEntities,
  selectAll: selectAllExercises,
  selectTotal: selectTotalExercises
} = exercisesAdapter.getSelectors<RootState>((state) => state.exercises);

export const selectExercisesByIds = (ids: string[]) =>
  createSelector([selectAllExercises], (exercises) =>
    exercises.filter((exercise) => ids.includes(exercise.id))
  );

export const selectLifelineExercises = createSelector(
  [selectAllExercises],
  (exercises) =>
    (
      exercises.filter(
        (exercise) => exercise.type === ExerciseType.Lifeline
      ) as LifelineExercise[]
    ).sort((a, b) => a.age - b.age)
);

export const selectCognitiveTriangleExercises = createSelector(
  [selectAllExercises],
  (exercises) =>
    exercises.filter(
      (exercise) => exercise.type === ExerciseType.CognitiveTriangle
    ) as CognitiveTriangleExercise[]
);

export const selectResponsibilityPieExercises = createSelector(
  [selectAllExercises],
  (exercises) =>
    exercises
      .filter((exercise) => exercise.type === ExerciseType.ResponsibilityPie)
      .sort(sortByCreationDateDsc)
);

export const selectWrittenExposuresExercises = createSelector(
  [selectAllExercises],
  (exercises) =>
    (
      exercises.filter(
        (exercise) => exercise.type === ExerciseType.WrittenExposure
      ) as WrittenExposureExercise[]
    ).sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
);

export const selectWrittenExposuresExercisesByLifelineId = (
  lifelineId: string
) =>
  createSelector([selectWrittenExposuresExercises], (exercises) =>
    exercises.filter((exercise) => exercise.lifelineEventId === lifelineId)
  );

export const selectConcernsAndBarriersExercises = createSelector(
  [selectAllExercises],
  (exercises) =>
    (
      exercises.filter(
        (exercise) => exercise.type === ExerciseType.ConcernsAndBarriers
      ) as ConcernsAndBarriersExercise[]
    ).sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
);

export const selectLetterToAFriendExercises = createSelector(
  [selectAllExercises],
  (exercises) =>
    (
      exercises.filter(
        (exercise) => exercise.type === ExerciseType.LetterToAFriend
      ) as LetterToAFriendExercise[]
    ).sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
);

export const selectListExercises = createSelector(
  [selectAllExercises],
  (exercises) =>
    (
      exercises.filter(
        (exercise) => exercise.type === ExerciseType.ListExercise
      ) as ListExercise[]
    ).sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
);

export const selectListExercisesByTemplateId = (id: number) =>
  createSelector([selectListExercises], (exercises) =>
    exercises.find((exercise) => exercise.templateId === id)
  );

export const selectSafetyItems = createSelector(
  [selectAllExercises],
  (exercises) =>
    exercises.filter(
      (exercise) =>
        exercise.type === ExerciseType.SafetyPlanBasicList ||
        exercise.type === ExerciseType.SafetyPlanAddressList
    ) as SafetyItem[]
);

export const selectAllSafetyItemsGroupedBySection = createSelector(
  [selectSafetyItems],
  (items) => {
    const groupedBySection = items.reduce((acc, item) => {
      const section = item.section;
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(item);
      return acc;
    }, {} as { [key: string]: SafetyItem[] });
    return Object.entries(groupedBySection)
      .map(([section, items]) => ({
        section,
        items
      }))
      .sort((a, b) => a.items[0].cmsName.localeCompare(b.items[0].cmsName));
  }
);

export const selectSafetyItemsBySection = (section: string) =>
  createSelector([selectSafetyItems], (items) =>
    items.filter((item) => item.section === section)
  );

export const { addExercise, removeExercise } = exercisesSlice.actions;
export default exercisesSlice.reducer;
