import Slider from '@react-native-community/slider';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';

import Colors from '../../../../colors';
import { secondsToMMSS } from '../../../../helpers';

interface Props {
  trackLength: number;
  currentPosition: number;
  onSeek: (v: any) => void;
}

const SeekBar: FunctionComponent<Props> = ({
  trackLength,
  currentPosition,
  onSeek
}) => {
  const [tempCurrentPosition, setTempCurrentPosition] = useState<number>(-1);
  const elapsed = secondsToMMSS(currentPosition / 1000);
  const remaining = secondsToMMSS(trackLength / 1000 - currentPosition / 1000);

  useEffect(() => {
    if (Math.floor(tempCurrentPosition) === Math.floor(currentPosition)) {
      setTempCurrentPosition(-1);
    }
  }, [currentPosition]);

  return (
    <View style={styles.container}>
      <Slider
        maximumValue={Math.floor(Math.max(trackLength, 1, currentPosition))}
        onSlidingStart={() => {
          setTempCurrentPosition(currentPosition);
        }}
        onSlidingComplete={(val) => {
          onSeek(val);
          setTempCurrentPosition(val);
        }}
        value={
          tempCurrentPosition === -1
            ? Math.floor(currentPosition)
            : tempCurrentPosition
        }
        style={
          Platform.OS === 'android' && {
            marginHorizontal: -10,
            marginBottom: 10
          }
        }
        minimumTrackTintColor={Colors.BLONDE}
        maximumTrackTintColor={Colors.WHITE}
        thumbTintColor={Colors.BLONDE}
      />
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.text}>{elapsed}</Text>
        <View style={{ flex: 1 }} />
        <Text style={styles.text}>{trackLength > 1 && '-' + remaining}</Text>
      </View>
    </View>
  );
};
export default SeekBar;

const styles = StyleSheet.create({
  slider: {
    marginTop: -12
  },
  container: {
    paddingTop: 16
  },
  text: {
    color: Colors.WHITE,
    fontSize: 12,
    textAlign: 'center'
  }
});
