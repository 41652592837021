import { RouteProp, useRoute } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { View } from 'react-native';

import MediumText from '../../../../components/MediumText';
import { getUserFlow } from '../../../../store/flow';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectActiveLanguage } from '../../../../store/languages';
import {
  selectModuleProgresses,
  selectModulesByMultipleIds
} from '../../../../store/modules';
import {
  fetchSession,
  selectSessionById,
  upsertSession
} from '../../../../store/sessions';
import { AppStackParamList } from '../../../AppNavigator';
import ModuleScreen from './ModuleScreen';
import { createCustomTabNavigator } from './components/CustomTabNavigator';

const SessionTab = createCustomTabNavigator();

const SessionTabNavigator = () => {
  const route = useRoute<RouteProp<AppStackParamList, 'Session'>>();
  const { sessionEntityId } = route.params;

  const flow = useAppSelector((state) => state.flow);

  const session = useAppSelector((state) =>
    selectSessionById(state, sessionEntityId)
  );
  const modules = useAppSelector((state) =>
    selectModulesByMultipleIds(state, session?.moduleIds ?? [])
  );
  const moduleProgresses = useAppSelector(selectModuleProgresses);

  const activeLanguage = useAppSelector(selectActiveLanguage);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const syncSession = async () => {
      if (!session?.started) {
        await dispatch(
          upsertSession({
            entityId: sessionEntityId,
            changes: {
              unlocked: true,
              unlockedAt: session?.unlockedAt,
              started: true,
              startedAt: new Date().toISOString()
            }
          })
        );
      }
      if (flow?.id) {
        await dispatch(getUserFlow(activeLanguage.code));
      } else {
        await dispatch(
          fetchSession({
            entityId: sessionEntityId,
            languageCode: activeLanguage.code
          })
        );
      }
    };

    syncSession();
  }, [activeLanguage]);

  const getInitialIndex: () => number = () => {
    const firstUncompletedIndex = modules.findIndex(
      (module) => !module.completed
    );
    return firstUncompletedIndex === -1
      ? modules.length - 1
      : firstUncompletedIndex;
  };

  if (!session || modules.length === 0) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <MediumText>Session or Modules not found</MediumText>
      </View>
    );
  }

  return (
    <SessionTab.Navigator initialRouteName={`Module${getInitialIndex()}`}>
      {modules.map((module, idx, arr) => (
        <SessionTab.Screen
          key={`Module${idx}`}
          name={`Module${idx}`}
          options={{
            title: `${session?.title} - Part ${idx + 1}/${arr.length}`,
            // a module is unlocked if a) it is completed b) it is the first uncompleted module
            tabLocked:
              !module?.completed &&
              idx !== arr.findIndex((module) => !module?.completed),
            percentage: moduleProgresses[module.id],
            imageUri: module.file?.url
          }}
        >
          {(props) => (
            <ModuleScreen
              sessionEntityId={sessionEntityId}
              moduleEntityId={module.id}
              {...props}
            />
          )}
        </SessionTab.Screen>
      ))}
    </SessionTab.Navigator>
  );
};

export default SessionTabNavigator;
