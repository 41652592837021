import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import { RootState } from '.';
import env from '../env';
import { extractFileTypeFromMimeType, filterTranslations } from './../helpers';
import { ExclusionBlock, UserState } from './types';

const initialState: UserState = {
  token: '',
  exclusionData: null,
  debugMode: false,

  fullname: '',
  userId: null,
  userStory: null,
  status: null,
  exclusionReason: null,
  flowId: null,
  language: null,
  gender: null,
  countryCode: null,
  nationality: null,
  preferredContactOption: null,
  preferredHelperGender: null,
  preferredContactMethod: null,
  availabilityDay: null,
  availabilityTime: null,
  studies: [],
  participantId: null,
  email: null,
  phone: null,
  fcmToken: null,
  treatmentGroup: null
};

export const register = createAsyncThunk<
  { created: boolean; userData: any; blockId: string },
  { username: string; password: string; blockId: string },
  { state: RootState }
>('user/register', async ({ username, password, blockId }, { getState }) => {
  const response = await fetch(`${env.API_BASE_URL}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username,
      password,
      fullname: getState().user.fullname
    })
  });
  const userData = await response.json();

  return { created: response.status === 201, userData, blockId };
});

export const saveFcmToken = createAsyncThunk<
  string | null,
  string | null,
  { state: RootState }
>('user/saveFcmToken', async (token, { getState }) => {
  if (getState().user.userId && token) {
    const response = await fetch(
      `${env.API_BASE_URL}/users/${getState().user.userId}/fcmTokens`,
      {
        method: 'post',
        headers: {
          Authorization: `Bearer ${getState().user.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      }
    );

    const fcmTokenData = await response.json();

    if (!response.ok) {
      throw new Error(
        fcmTokenData.code && fcmTokenData.message
          ? `${fcmTokenData.code}: ${fcmTokenData.message}`
          : `${response.status} ${response.statusText}`
      );
    }
  }

  return token;
});

export const authenticate = createAsyncThunk(
  'user/login',
  async ({ username, password }: { username: string; password: string }) => {
    const response = await fetch(`${env.API_BASE_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });
    const authData = await response.json();

    if (!response.ok) {
      throw new Error(
        authData.code && authData.message
          ? `${authData.code}: ${authData.message}`
          : `${response.status} ${response.statusText}`
      );
    }

    return authData;
  }
);

export const getUserData = createAsyncThunk<
  Partial<UserState>,
  void,
  { state: RootState }
>('user/getUserData', async (_, { getState }) => {
  const response = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      }
    }
  );
  const userData = await response.json();

  if (!response.ok) {
    throw new Error(
      userData.code && userData.message
        ? `${userData.code}: ${userData.message}`
        : `${response.status} ${response.statusText}`
    );
  }
  return userData;
});

export const updateUserData = createAsyncThunk<
  Partial<UserState>,
  Partial<UserState>,
  {
    state: RootState;
    rejectValue: { error: boolean; code: string; message: string };
  }
>('user/updateUserData', async (updateData, { getState, rejectWithValue }) => {
  const response = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getState().user.token}`
      },
      body: JSON.stringify(updateData)
    }
  );
  const userData = await response.json();

  if (response.status === 400) {
    return rejectWithValue(userData);
  }

  if (!response.ok) {
    throw new Error(
      userData.code && userData.message
        ? `${userData.code}: ${userData.message}`
        : `${response.status} ${response.statusText}`
    );
  }
  return {
    ...userData,
    flowId: userData.flow ? userData.flow.id : null
  };
});

export const assignToStudy = createAsyncThunk<
  { blockId: string },
  { blockId: string; code: string },
  { state: RootState }
>(
  'user/assignToStudy',
  async ({ blockId, code }, { getState, rejectWithValue }) => {
    const response = await fetch(
      `${env.API_BASE_URL}/users/${getState().user.userId}/studies`,
      {
        method: 'post',
        headers: {
          Authorization: `Bearer ${getState().user.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code })
      }
    );

    if (!response.ok) {
      const data = await response.json();
      return rejectWithValue({
        code: data.code ?? response.status,
        message: data.message ?? response.statusText
      });
    }

    return { blockId };
  }
);

export const activateUser = createAsyncThunk<
  { blockId: string },
  { blockId: string; token: string; newPassword: string },
  { state: RootState }
>(
  'user/activateUser',
  async ({ blockId, token, newPassword }, { getState, rejectWithValue }) => {
    if (getState().user.userId) {
      return { blockId };
    }
    const response = await fetch(`${env.API_BASE_URL}/auth/password`, {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token,
        password: newPassword
      })
    });

    if (!response.ok) {
      const data = await response.json();
      return rejectWithValue({
        code: data.code ?? response.status,
        message: data.message ?? response.statusText
      });
    }

    return { blockId };
  }
);

export const getExclusionData = createAsyncThunk<
  ExclusionBlock,
  string,
  { state: RootState }
>('user/getExclusionCriterion', async (languageCode, { getState }) => {
  const response = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/exclusionCriterion`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      }
    }
  );
  const data = extractFileTypeFromMimeType(
    filterTranslations(await response.json(), languageCode)
  );

  if (!response.ok) {
    throw new Error(
      data.code && data.message
        ? `${data.code}: ${data.message}`
        : `${response.status} ${response.statusText}`
    );
  }

  return {
    id: data.id,
    text: data.translations[0]?.text ?? data.translations[0]?.placeholder,
    file: {
      id: data.translations[0]?.file?.id,
      type: data.translations[0]?.file?.type,
      url: data.translations[0]?.file?.id
        ? `${env.CMS_BASE_URL}/assets/${data.translations[0].file?.id}`
        : '',
      localUrl: null
    }
  };
});

const getNewStateFromFetchedUserData = (
  state: any,
  fetched: Partial<UserState>
) => {
  const filtered = Object.keys(fetched).reduce((filtered, key) => {
    if (key in state) {
      return { ...filtered, [key]: fetched[key as keyof UserState] };
    }
    return filtered;
  }, {});
  return { ...state, ...filtered };
};
export const logout = createAction('auth/logout');

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFullname: (state, { payload }: PayloadAction<string>) => {
      state.fullname = payload;
    },
    cacheInfo: (state, { payload }: PayloadAction<object>) => {
      for (const [key, value] of Object.entries(payload)) {
        (state as any)[key as keyof UserState] = value;
      }
    },
    setFcmToken: (state, { payload }: PayloadAction<string | null>) => {
      state.fcmToken = payload;
    },
    setDebugMode: (state, { payload }: PayloadAction<boolean>) => {
      state.debugMode = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(register.fulfilled, (state, action) => {
      state.userId = action.payload.userData.id ?? '';
    });
    builder.addCase(authenticate.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.userId = action.payload.userId;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      return getNewStateFromFetchedUserData(state, action.payload);
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      if (action.error.message?.match(/User got excluded/)) {
        state.status = 'excluded';
        state.exclusionReason = action.error.message.split(`"`)[1];
      }
    });
    builder.addCase(updateUserData.fulfilled, (state, action) => {
      return getNewStateFromFetchedUserData(state, action.payload);
    });
    builder.addCase(getExclusionData.fulfilled, (state, action) => {
      state.exclusionData = action.payload;
    });
    builder.addCase(saveFcmToken.fulfilled, (state, action) => {
      state.fcmToken = action.payload;
    });
  }
});

export const { setFullname, cacheInfo, setDebugMode, setFcmToken } =
  userSlice.actions;
export default userSlice.reducer;
