import { RouteProp, useRoute } from '@react-navigation/native';
import React, { FunctionComponent } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Colors from '../../../colors';
import Drawer from '../../../components/Drawer';
import MediumText from '../../../components/MediumText';
import RemovableImage from '../../../components/RemovableImage';
import { DrawerStackParamList } from '../../DrawerNavigator';

const WrittenExposureArchiveItemScreen: FunctionComponent = () => {
  const { params } =
    useRoute<RouteProp<DrawerStackParamList, 'WrittenExposureArchiveItem'>>();
  const intl = useIntl();
  const insets = useSafeAreaInsets();

  return (
    <Drawer
      title={intl.formatMessage({
        defaultMessage: 'Written Exposure',
        description: 'WrittenExposureArchiveItemScreen drawer title'
      })}
    >
      <ScrollView
        contentContainerStyle={[
          styles.container,
          { paddingBottom: insets.bottom + 10 }
        ]}
      >
        <MediumText style={styles.topic}>{params.topic}</MediumText>
        <MediumText style={styles.date}>
          <FormattedDate
            value={new Date(params.date)}
            year="numeric"
            month="2-digit"
            day="2-digit"
          />
        </MediumText>
        {params.files
          ? params.files.map((file) => (
              <View style={styles.imageContainer}>
                <RemovableImage uri={file} />
              </View>
            ))
          : null}
        <MediumText style={styles.text}>{params.text}</MediumText>
      </ScrollView>
    </Drawer>
  );
};

export default WrittenExposureArchiveItemScreen;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingTop: 16
  },
  topic: {
    fontSize: 18,
    lineHeight: 24,
    color: Colors.GREY1
  },
  date: {
    fontSize: 14,
    lineHeight: 20,
    color: Colors.GREY2
  },
  imageContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10
  },
  text: {
    marginTop: 16,
    lineHeight: 26,
    fontSize: 16,
    color: Colors.GREY1
  }
});
