import { useNavigation } from '@react-navigation/native';
import React, { FocusEvent, FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Image,
  Platform,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle
} from 'react-native';

import Colors from '../colors';
import KeyboardInput from './KeyboardInput';
import MediumText from './MediumText';
import PillButton from './PillButton';

export interface MultipleInputValue {
  key: string;
  value: string;
  locked?: boolean;
}

interface Props {
  values: MultipleInputValue[];
  selectedKeys: string[];
  onSelect: (key: string[]) => void;
  onChangeValues: (val: MultipleInputValue[]) => void;
  allowInput?: boolean;
  addInputText: string;
  style?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
}

const MultipleInputSelect: FunctionComponent<Props> = (props) => {
  const allowInput = props.allowInput === undefined ? true : props.allowInput;
  const [inputText, setInputText] = useState<string>('');
  const [inputMode, setInputMode] = useState(false);

  const navigation = useNavigation();

  const handleInputUnmount = () => {
    setInputMode(false);
    setInputText('');
  };

  const handleAddingValue = () => {
    const newKey = new Date().getTime().toString();
    props.onChangeValues([
      ...props.values,
      {
        key: newKey,
        value: inputText
      }
    ]);
    handleInputUnmount();
    props.onSelect([...props.selectedKeys, newKey]);
  };

  const renderInput = () => {
    return (
      <View
        style={{
          marginTop: 10
        }}
      >
        <KeyboardInput
          onBlur={(e) => {
            /*  
              Workaround to avoid onBlur blocking the onPress function from the close button to fire
              https://github.com/facebook/react/issues/4210 
            */
            if (Platform.OS === 'web') {
              const evt = e as unknown as FocusEvent<HTMLInputElement>;
              (evt?.relatedTarget as any)?.click();
            }
            handleInputUnmount();
          }}
          value={inputText}
          onChangeText={setInputText}
          autoFocus
        />
        <View
          style={{
            justifyContent: 'flex-end',
            flexDirection: 'row',
            marginTop: 20
          }}
        >
          <PillButton
            disabled={inputText.length === 0}
            onPress={handleAddingValue}
          >
            <FormattedMessage defaultMessage="Add" description="Add" />
          </PillButton>
        </View>
      </View>
    );
  };

  const renderAddValue = () => {
    return (
      <TouchableOpacity
        style={styles.addInputContainer}
        onPress={() => setInputMode(true)}
      >
        <MediumText style={styles.text}>{props.addInputText}</MediumText>
        <View style={styles.plusButton}>
          <MediumText style={styles.text}>+</MediumText>
        </View>
      </TouchableOpacity>
    );
  };

  const renderValue = (value: MultipleInputValue) => {
    const active = props.selectedKeys.includes(value.key);
    return (
      <PillButton
        key={value.key}
        style={[
          styles.baseButton,
          active ? styles.buttonActive : styles.buttonInactive,
          props.buttonStyle
        ]}
        onPress={() => {
          if (active)
            props.onSelect(
              props.selectedKeys.filter((val) => val !== value.key)
            );
          else props.onSelect([...props.selectedKeys, value.key]);
        }}
      >
        <MediumText style={styles.text}>{value.value}</MediumText>
      </PillButton>
    );
  };

  const renderDelete = (key: string) => {
    return (
      <TouchableOpacity
        style={styles.binContainer}
        onPress={() =>
          navigation.navigate('ConfirmationQuestion', {
            onPressYes: () =>
              props.onChangeValues(
                props.values.filter((val) => val.key !== key)
              )
          })
        }
      >
        <Image
          source={require('../assets/binRed.png')}
          style={{ width: 16, height: 16 }}
        />
      </TouchableOpacity>
    );
  };

  return (
    <View style={[styles.container, props.style]}>
      {props.values.map((value) => {
        const active = props.selectedKeys.includes(value.key);
        return (
          <View key={value.key} style={styles.itemContainer}>
            {renderValue(value)}
            {!active && !value.locked && renderDelete(value.key)}
          </View>
        );
      })}
      {allowInput && (inputMode ? renderInput() : renderAddValue())}
    </View>
  );
};

export default MultipleInputSelect;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%'
  },
  baseButton: {
    marginTop: 10,
    borderRadius: 20,
    alignSelf: 'flex-end'
  },
  text: {
    color: Colors.BLUE
  },
  buttonInactive: {
    backgroundColor: 'transparent',
    borderWidth: 1.5,
    borderColor: Colors.GREY5
  },
  buttonActive: {
    backgroundColor: 'transparent',
    borderWidth: 1.5,
    borderColor: Colors.BLUE
  },
  plusButton: {
    width: 30,
    height: 30,
    borderColor: Colors.GREY4,
    borderWidth: 1,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  addInputContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 10
  },
  binContainer: {
    borderWidth: 1,
    borderColor: Colors.RED,
    borderRadius: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    marginTop: 8,
    marginLeft: 5
  }
});
