import React, { FunctionComponent } from 'react';
import { View, StyleSheet } from 'react-native';

import Colors from '../../../../colors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectActiveLanguage,
  selectGenderCompatibleLanguages,
  setLanguage
} from '../../../../store/languages';
import SettingsMenuItem from './components/SettingsMenuItem';

const SettingsLanguageScreen: FunctionComponent = () => {
  const languages = useAppSelector(selectGenderCompatibleLanguages);
  const activeLanguage = useAppSelector(selectActiveLanguage);
  const dispatch = useAppDispatch();

  return (
    <View style={styles.container}>
      <View style={styles.itemsContainer}>
        {languages.map((language) => {
          return (
            <SettingsMenuItem
              key={language.code}
              title={language.displayedName ?? language.language}
              type="none"
              onPress={() => dispatch(setLanguage(language.code))}
              textStyle={
                language.code === activeLanguage.code
                  ? {
                      color: Colors.BLUE
                    }
                  : undefined
              }
            />
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND,
    paddingTop: 56
  },
  itemsContainer: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.GREY5,
    backgroundColor: Colors.WHITE,
    paddingLeft: 24
  }
});

export default SettingsLanguageScreen;
