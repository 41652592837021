import React, { useEffect, FunctionComponent, useRef } from 'react';
import { FlatList, ListRenderItem, StyleSheet, View } from 'react-native';

import Colors from '../../../../colors';
import { fetchFAQ, selectApplicableFAQ } from '../../../../store/faq';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectActiveLanguage } from '../../../../store/languages';
import { FAQ } from '../../../../store/types';
import FAQCard from './components/FAQCard';

const FAQScreen: FunctionComponent = () => {
  const { code: languageCode } = useAppSelector(selectActiveLanguage);
  const userStudies = useAppSelector((state) => state.user.studies);
  const faq = useAppSelector(selectApplicableFAQ(userStudies));
  const dispatch = useAppDispatch();
  const flatList = useRef<FlatList>(null);

  const scrollTo = (target: number) => {
    flatList.current?.scrollToOffset({ offset: target });
  };

  const renderItem: ListRenderItem<FAQ> = ({ item }) => (
    <FAQCard {...item} scrollTo={scrollTo} />
  );

  useEffect(() => {
    dispatch(fetchFAQ(languageCode));
  }, [languageCode]);

  return (
    <View style={styles.container}>
      <FlatList
        contentContainerStyle={styles.content}
        data={faq}
        keyExtractor={(_, index) => `FAQ${index}`}
        ref={flatList}
        CellRendererComponent={renderItem}
        renderItem={() => null}
        style={styles.list}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  list: {
    height: '100%',
    width: '100%'
  },
  content: {
    paddingTop: 32
  }
});

export default FAQScreen;
