import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import React, { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import Colors from '../../../../colors';
import Drawer from '../../../../components/Drawer';
import { useBackHandler } from '../../../../hooks/useBackHandler';
import { saveExercise } from '../../../../store/exercises';
import { useAppDispatch } from '../../../../store/hooks';
import {
  ExerciseType,
  ResponsibilityPieExercise,
  ResponsibilityPieReason
} from '../../../../store/types';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import OthersResponsibilityPie from './components/OthersResponsibilityPie';
import OwnResponsibilityPie from './components/OwnResponsibilityPie';
import Summary from './components/Summary';

export enum ResponsibilityPieSteps {
  FIRST_QUESTION,
  SECOND_QUESTION,
  SUMMARY
}

const ResponsibilityPieScreen: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigation = useNavigation();
  const route =
    useRoute<RouteProp<DrawerStackParamList, 'ResponsibilityPie'>>();

  const [stepIndex, setStepIndex] = useState<ResponsibilityPieSteps>(
    route.params.stepIndex ?? ResponsibilityPieSteps.FIRST_QUESTION
  );
  const [ownResponsibility, setOwnResponsibility] =
    useState<ResponsibilityPieReason>({
      id: uuidv4(),
      reason: intl.formatMessage({
        defaultMessage: 'My fault',
        description: 'ResponsibilityPieScreen default reason 1'
      }),
      responsibility: 0,
      maxResponsibility: 100,
      color: Colors.BLUE
    });
  const [othersResponsibility, setOthersResponsibility] = useState<
    ResponsibilityPieReason[]
  >(
    route.params.reasons.length !== 0 // If going backwards
      ? [...route.params.reasons]
      : []
  );

  const handleNext = () => {
    setStepIndex(stepIndex + 1);
  };

  const handleSave = () => {
    const exercise = {
      id: uuidv4(),
      linkedCognitiveTriangleId: route.params.linkedCognitiveTriangleId,
      type: ExerciseType.ResponsibilityPie,
      createdAt: new Date().toISOString(),
      reasons: [ownResponsibility, ...othersResponsibility]
    } as ResponsibilityPieExercise;

    route.params.onFinish
      ? route.params.onFinish(exercise)
      : dispatch(saveExercise(exercise));
    navigation.goBack();
  };

  const handlePopUp = () => {
    navigation.navigate('ConfirmationQuestion', {
      onPressYes: () => handleNext(),
      affirmativeOption: intl.formatMessage({
        defaultMessage: 'Continue',
        description: 'Confirmation screen continue button'
      }),
      negativeOption: intl.formatMessage({
        defaultMessage: 'Back',
        description: 'Confirmation screen back button'
      }),
      question: intl.formatMessage({
        defaultMessage:
          'Can you think of any other responsible parties that you have not listed yet?',
        description: 'Responsibility pie pop up before summary screen'
      })
    });
  };

  const handleCloseExercise = () => {
    const exercise = {
      id: uuidv4(),
      type: ExerciseType.ResponsibilityPie,
      createdAt: new Date().toISOString(),
      reasons: [ownResponsibility, ...othersResponsibility]
    } as ResponsibilityPieExercise;

    route.params.onBack?.(exercise);
    navigation.goBack();
  };

  const isFirstScreen = () => stepIndex === 0;

  const handleBackwardNav = () => {
    setStepIndex(stepIndex - 1);
  };

  const { getDrawerIcon } = useBackHandler(
    isFirstScreen,
    handleBackwardNav,
    [stepIndex],
    navigation,
    handleCloseExercise
  );

  const renderStep = () => {
    switch (stepIndex) {
      case ResponsibilityPieSteps.FIRST_QUESTION:
        return (
          <OwnResponsibilityPie
            ownResponsibility={ownResponsibility}
            setOwnResponsibility={setOwnResponsibility}
            onNext={handleNext}
          />
        );
      case ResponsibilityPieSteps.SECOND_QUESTION:
        return (
          <OthersResponsibilityPie
            othersResponsibility={othersResponsibility}
            setOthersResponsibility={setOthersResponsibility}
            onNext={handlePopUp}
          />
        );
      case ResponsibilityPieSteps.SUMMARY:
        const accOthersResponsibility = {
          id: uuidv4(),
          maxResponsibility: 100,
          reason: intl.formatMessage({
            defaultMessage: 'Other reasons',
            description: 'ResponsibilityPieScreen default reason 2'
          }),
          color: Colors.MELON,
          responsibility: othersResponsibility.reduce(
            (acc, r) => acc + r.responsibility,
            0
          )
        };
        return (
          <Summary
            onNext={handleSave}
            ownResponsibility={ownResponsibility}
            othersResponsibility={accOthersResponsibility}
          />
        );
      default:
    }
  };

  return (
    <Drawer
      leftComponent={getDrawerIcon()}
      onBackgroundPress={() =>
        navigation.navigate('DrawerCloseExercise', {
          closeExercise: () => navigation.goBack()
        })
      }
      title={intl.formatMessage({
        defaultMessage: 'Responsibility Pie',
        description: 'Responsibility Pie drawer title'
      })}
    >
      {renderStep()}
    </Drawer>
  );
};

export default ResponsibilityPieScreen;
