import React, { FunctionComponent, useState } from 'react';
import { View, StyleSheet, Pressable, Keyboard, Platform } from 'react-native';

import Colors from '../../../../colors';
import SettingsMenuInput from './components/SettingsMenuInput';

const SettingsPinScreen: FunctionComponent = () => {
  const [pin, setPin] = useState('1234');

  return (
    <>
      <Pressable
        style={styles.container}
        onPress={() => (Platform.OS === 'web' ? undefined : Keyboard.dismiss())}
      >
        <View style={styles.itemsContainer}>
          <SettingsMenuInput value={pin} hideBorder onChangeText={setPin} />
        </View>
      </Pressable>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND,
    paddingTop: 56
  },
  itemsContainer: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.GREY5,
    backgroundColor: Colors.WHITE,
    paddingLeft: 24
  },
  title: {
    color: Colors.GREY2,
    fontSize: 14,
    paddingTop: 27,
    paddingLeft: 24,
    paddingBottom: 9
  }
});

export default SettingsPinScreen;
