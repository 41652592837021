import React, { FunctionComponent } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import Colors from '../colors';

interface Props {
  value: boolean;
  onValueChange: (val: boolean) => void;
}

const Switch: FunctionComponent<Props> = (props) => {
  return (
    <TouchableOpacity
      onPress={() => props.onValueChange(!props.value)}
      style={[
        styles.container,
        {
          backgroundColor: props.value ? '#34C759' : 'rgba(120, 120, 128, 0.16)'
        }
      ]}
    >
      <View
        style={[
          styles.thumb,
          props.value ? styles.trueThumb : styles.falseThumb
        ]}
      />
    </TouchableOpacity>
  );
};

export default Switch;

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    width: 50,
    height: 30
  },
  thumb: {
    position: 'absolute',
    width: 26,
    height: 26,
    top: 2,
    borderRadius: 14,
    backgroundColor: Colors.WHITE
  },
  trueThumb: {
    right: 2
  },
  falseThumb: {
    left: 2
  }
});
