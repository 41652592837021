import { createStackNavigator } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { Image, StyleSheet, View } from 'react-native';

import Colors from '../../colors';
import { isRtl } from '../../lang';
import { useAppSelector } from '../../store/hooks';
import { selectActiveLanguage } from '../../store/languages';
import ForgotPasswordScreen, {
  ForgotPasswordOptions
} from './ForgotPasswordScreen';
import LoginScreen from './LoginScreen';
import NewPasswordScreen from './NewPasswordScreen';
import VerificationCodeScreen from './VerificationCodeScreen';

export type AuthStackParamList = {
  Login: undefined | { showRegistrationHint: boolean };
  ForgotPassword: undefined;
  VerificationCode: {
    contactOption: ForgotPasswordOptions;
    contactInfo: string;
  };
  NewPassword: {
    code: string;
  };
};

const AuthStack = createStackNavigator<AuthStackParamList>();

const AuthenticationNavigator: FunctionComponent = () => {
  const rtl = isRtl(useAppSelector(selectActiveLanguage).language);
  return (
    <AuthStack.Navigator
      screenOptions={({ route }) => ({
        headerBackImage: () => (
          <View style={styles.iconContainer}>
            <Image
              source={
                route.name === 'ForgotPassword'
                  ? require('../../assets/arrow_left.png')
                  : require('../../assets/Close.png')
              }
              style={[styles.icon, rtl && { transform: [{ scaleX: -1 }] }]}
            />
          </View>
        ),
        headerBackTitleVisible: false,
        headerTitle: '',
        headerStyle: {
          backgroundColor: Colors.BACKGROUND,
          shadowOpacity: 0,
          borderBottomWidth: 0,
          elevation: 0
        }
      })}
    >
      <AuthStack.Screen name="Login" component={LoginScreen} />
      <AuthStack.Screen
        name="ForgotPassword"
        component={ForgotPasswordScreen}
      />
      <AuthStack.Screen
        name="VerificationCode"
        component={VerificationCodeScreen}
      />
      <AuthStack.Screen name="NewPassword" component={NewPasswordScreen} />
    </AuthStack.Navigator>
  );
};

export default AuthenticationNavigator;

const styles = StyleSheet.create({
  iconContainer: {
    height: 45,
    width: 45,
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    height: 24,
    width: 24
  }
});
