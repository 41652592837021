import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { ExclusionBlock, UserState } from '../../../../../store/types';
import { updateUserData } from '../../../../../store/user';

type UserStateType =
  | string
  | number
  | boolean
  | ExclusionBlock
  | { id: number; name: string }[]
  | null;

export type UserStateFormatter = (setting: UserStateType) => UserStateType;

export const useSetting = (setting: string, formatter?: UserStateFormatter) => {
  const intl = useIntl();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const value = useRef(user[setting as keyof UserState]);

  const {
    control,
    watch,
    setError,
    reset,
    handleSubmit: handleFormSubmit,
    formState: { errors }
  } = useForm<{ value: UserStateType }>({
    defaultValues: { value: value.current }
  });

  value.current = watch('value', value.current);

  const errorFormatter = (error?: string): string => {
    if (error && setting.match('email') && error.match('ERR_NOT_UNIQUE')) {
      return intl.formatMessage({
        defaultMessage: 'The email is already in use',
        description: 'SettingsMenu email already in use error'
      });
    }

    return intl.formatMessage({
      defaultMessage: 'There was an error processing your request',
      description: 'SettingsMenu generic server error message'
    });
  };

  const handleSave = async () => {
    if (editMode) {
      const changeSettingAction = await dispatch(
        updateUserData({
          [setting]: formatter ? formatter(value.current) : value.current
        })
      );

      if (!updateUserData.fulfilled.match(changeSettingAction)) {
        setError('value', {
          message: errorFormatter(changeSettingAction.payload?.code)
        });
        return;
      }
    }
    setEditMode(!editMode);
  };

  const handleDelete = () => {
    reset({ value: null });
    dispatch(updateUserData({ [setting]: null }));
  };

  return {
    value,
    editMode,
    setEditMode,
    control,
    errors,
    handleFormSubmit,
    handleSave,
    handleDelete
  };
};
