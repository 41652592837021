import React, { FunctionComponent, useState } from 'react';
import {
  Image,
  View,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
  Keyboard
} from 'react-native';

import Colors from '../../../../colors';
import KeyboardInput, {
  KeyboardInputProps
} from '../../../../components/KeyboardInput';
import MediumText from '../../../../components/MediumText';
import CountryList from './CountryList';
import { Country } from './CountryList/countryData';

interface CountryCodeInputProps
  extends Omit<KeyboardInputProps, 'containerStyle'> {
  selectedCountry: Country;
  onChangeCounty: (country: Country) => void;
  containerStyle?: ViewStyle;
}

const CountryCodeInput: FunctionComponent<CountryCodeInputProps> = ({
  selectedCountry,
  onChangeCounty,
  containerStyle,
  ...props
}) => {
  const [listVisible, setListVisible] = useState(false);
  return (
    <View
      style={[{ zIndex: 1, height: listVisible ? 356 : 68 }, containerStyle]}
    >
      <KeyboardInput
        multiline={false}
        keyboardType="phone-pad"
        autoComplete="tel"
        textContentType="telephoneNumber"
        editable={!listVisible}
        {...props}
        style={[styles.textInput, props.style]}
      />
      <View style={styles.overlay}>
        <TouchableOpacity
          onPress={() => {
            Keyboard.dismiss();
            setListVisible(!listVisible);
          }}
          style={styles.dropdownButton}
        >
          <Image source={selectedCountry.flag} style={styles.flagImage} />
          <Image
            source={require('../../../../assets/arrow_down.png')}
            style={[
              styles.arrowImage,
              listVisible && { transform: [{ rotate: '180deg' }] }
            ]}
          />
        </TouchableOpacity>
        <MediumText style={styles.callingCodeText}>
          {`+${selectedCountry.callingCode}`}
        </MediumText>
      </View>
      {listVisible && (
        <CountryList
          selectedCountry={selectedCountry}
          onChangeCountry={(country) => {
            setListVisible(false);
            onChangeCounty(country);
          }}
        />
      )}
    </View>
  );
};

export default CountryCodeInput;

const styles = StyleSheet.create({
  textInput: {
    paddingLeft: 130,
    color: Colors.GREY3,
    height: 48
  },
  overlay: {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dropdownButton: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 48,
    width: 66,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    backgroundColor: `${Colors.BLUE}0D`
  },
  flagImage: {
    height: 18,
    width: 28,
    resizeMode: 'contain',
    shadowOffset: { height: 1, width: 0 },
    shadowRadius: 2,
    shadowColor: '#000',
    shadowOpacity: 0.15,
    elevation: 3,
    marginLeft: 12
  },
  arrowImage: {
    width: 12,
    height: 12,
    marginLeft: 5
  },
  callingCodeText: {
    width: 54,
    lineHeight: 26,
    color: Colors.GREY3,
    marginLeft: 8,
    textAlign: 'center'
  }
});
