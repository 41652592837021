import { useNavigation } from '@react-navigation/native';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Keyboard,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View
} from 'react-native';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Colors from '../../../colors';
import KeyboardInput from '../../../components/KeyboardInput';
import MediumText from '../../../components/MediumText';
import PillButton from '../../../components/PillButton';
import Popup from '../../../components/Popup';
import Slider from '../../../components/Slider';
import { saveExercise } from '../../../store/exercises';
import { ExerciseType } from '../../../store/types';

const LifelineAddEventScreen: FunctionComponent = () => {
  const [feeling, setFeeling] = useState<'positive' | 'negative' | void>(
    'negative'
  );
  const [description, setDescription] = useState('');
  const [age, setAge] = useState<string>('');
  const [influence, setInfluence] = useState(5);
  const [sliderMoved, setSliderMoved] = useState(false);

  const navigation = useNavigation();
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleSetFeeling = (val: 'positive' | 'negative' | void) => {
    if (feeling === val) {
      setFeeling();
    } else setFeeling(val);
  };

  const handleSave = () => {
    dispatch(
      saveExercise({
        id: uuidv4(),
        type: ExerciseType.Lifeline,
        createdAt: new Date().toISOString(),
        age: Number(age),
        description: description.replace(/(\r\n|\n|\r)/gm, ' '),
        influenceScale: influence
      })
    );

    navigation.goBack();
  };

  const isSaveDisabled =
    !feeling ||
    description.trim().length === 0 ||
    age.length === 0 ||
    !sliderMoved;

  return (
    <Popup
      title={intl.formatMessage({
        defaultMessage: 'Add a negative life event',
        description: 'Add lifelife event popup title'
      })}
    >
      <TouchableWithoutFeedback
        onPress={() => Platform.OS !== 'web' && Keyboard.dismiss()}
      >
        <View style={styles.container}>
          <View style={styles.listContainer}>
            <View style={styles.listItem}>
              <KeyboardInput
                placeholder={intl.formatMessage({
                  defaultMessage: 'Add a negative life event',
                  description: 'Add lifelife event popup title'
                })}
                value={description}
                numberOfLines={2}
                onChangeText={setDescription}
                multiline
                style={styles.descriptionInput}
              />
            </View>
            <View style={styles.listItem}>
              <View style={styles.row}>
                <MediumText>
                  <FormattedMessage
                    defaultMessage="How old are you?"
                    description="Add life event age question"
                  />
                </MediumText>
                <KeyboardInput
                  style={styles.ageInput}
                  value={age}
                  onChangeText={setAge}
                  numberOfLines={1}
                  maxLength={3}
                  multiline={false}
                  keyboardType="numeric"
                />
              </View>
            </View>
            {/* Hidden for pilot version 
            <View style={styles.listItem}>
              <MediumText style={styles.feelingTitle}>
                <FormattedMessage
                  defaultMessage="How was the event for you?"
                  description="Add life event feeling question"
                />
              </MediumText>
              <View style={styles.row}>
                <PillButton
                  onPress={() => handleSetFeeling('positive')}
                  textStyle={{ color: Colors.BLUE }}
                  style={[
                    styles.pillButton,
                    feeling === 'positive' && {
                      borderColor: Colors.BLUE
                    },
                    { marginRight: 10 }
                  ]}
                >
                  <FormattedMessage
                    defaultMessage="Positive"
                    description="Add life event positive feeling button"
                  />
                </PillButton>
                <PillButton
                  onPress={() => handleSetFeeling('negative')}
                  textStyle={{ color: Colors.BLUE }}
                  style={[
                    styles.pillButton,
                    feeling === 'negative' && {
                      borderColor: Colors.BLUE
                    },
                    { marginLeft: 10 }
                  ]}
                >
                  <FormattedMessage
                    defaultMessage="Negative"
                    description="Add life event negative feeling button"
                  />
                </PillButton>
              </View>
            </View> */}
            <View style={[styles.listItem, { borderBottomWidth: 0 }]}>
              <Slider
                title={intl.formatMessage({
                  defaultMessage: 'How much influence did the event have?',
                  description: 'Add life event influence question'
                })}
                leftLabel={intl.formatMessage({
                  defaultMessage: 'a little',
                  description: 'Add life event influence slider left choice'
                })}
                rightLabel={intl.formatMessage({
                  defaultMessage: 'a lot',
                  description: 'Add life event influence slider right choice'
                })}
                value={influence}
                minimumValue={0}
                maximumValue={10}
                onSlidingComplete={(val) => {
                  if (!sliderMoved) setSliderMoved(true);
                  setInfluence(val);
                }}
                containerStyle={{
                  paddingHorizontal: 0
                }}
              />
            </View>
          </View>
          <View style={styles.buttonsContainer}>
            <PillButton
              onPress={navigation.goBack}
              style={{ backgroundColor: 'transparent' }}
              textStyle={{ color: Colors.BLUE }}
            >
              <FormattedMessage
                defaultMessage="Cancel"
                description="Add life event cancel button"
              />
            </PillButton>
            <PillButton onPress={handleSave} disabled={isSaveDisabled}>
              <FormattedMessage
                defaultMessage="Save"
                description="Add life event save button"
              />
            </PillButton>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Popup>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.WHITE
  },
  listContainer: {
    width: '100%',
    paddingHorizontal: 16
  },
  listItem: {
    paddingTop: 19,
    paddingBottom: 16,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.GREY4,
    width: '100%'
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  pillButton: {
    flex: 1,
    backgroundColor: Colors.WHITE,
    borderColor: Colors.GREY5,
    borderWidth: 1
  },
  buttonsContainer: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.GREY4,
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 16,
    paddingHorizontal: 16,
    justifyContent: 'flex-end'
  },
  feelingTitle: {
    marginBottom: 10
  },
  descriptionInput: {
    borderColor: Colors.GREY3,
    borderRadius: 5,
    maxHeight: 50,
    width: '100%'
  },
  ageInput: {
    borderColor: Colors.GREY3,
    width: 80,
    maxHeight: 20,
    borderRadius: 5,
    textAlign: 'center'
  }
});

export default LifelineAddEventScreen;
