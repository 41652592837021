import messaging from '@react-native-firebase/messaging';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { saveFcmToken } from './store/user';

const PushNotifications: FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const requestFcmPermission = async () => {
      await messaging().requestPermission();
    };

    requestFcmPermission();
  }, []);

  useEffect(() => {
    const unsubscribe = messaging().onTokenRefresh((token: string) => {
      dispatch(saveFcmToken(token));
    });

    return unsubscribe();
  }, []);

  return <></>;
};

export default PushNotifications;
