import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Platform,
  ScrollView,
  View,
  TouchableOpacity,
  StyleSheet
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { v4 as uuidv4 } from 'uuid';

import { VictoryPie, VictoryContainer } from '../../../../../aux/victory';
import Colors from '../../../../../colors';
import InputDrawer from '../../../../../components/InputDrawer';
import MediumText from '../../../../../components/MediumText';
import Message from '../../../../../components/Message';
import PillButton from '../../../../../components/PillButton';
import Slider from '../../../../../components/Slider';
import useAppWindowDimensions from '../../../../../hooks/useAppWindowDimensions';
import { ResponsibilityPieReason } from '../../../../../store/types';
import sharedStyles from './styles';
interface OthersResponsibilityPieProps {
  othersResponsibility: ResponsibilityPieReason[];
  setOthersResponsibility: (
    responsibilities: ResponsibilityPieReason[]
  ) => void;
  onNext: () => void;
  archived?: boolean; // This screen is used in the Responsibility Pie Archive. You can't edit archive items.
}

const OthersResponsibilityPie = ({
  othersResponsibility,
  setOthersResponsibility,
  onNext,
  archived
}: OthersResponsibilityPieProps) => {
  const insets = useSafeAreaInsets();
  const intl = useIntl();
  const { height: windowHeight } = useAppWindowDimensions();
  const [remainingResponsibility, setRemainingResponsibility] = useState(
    100 -
      othersResponsibility.reduce(
        (acc, responsibility) => acc + responsibility.responsibility,
        0
      )
  );
  const [isAddingCustomReason, setIsAddingCustomReason] = useState(false);
  let allColors = Object.values(Colors);
  const colorsToFilter = [
    '#36465D',
    '#567285',
    '#91A8B8',
    '#C3D0D9',
    '#E9EFF3',
    '#F8FAFB',
    '#FFFFFF',
    'rgba(54, 70, 93, 0.4)'
  ];
  allColors = allColors.filter((color) => !colorsToFilter.includes(color));

  const [currentColorIndex, setCurrentColorIndex] = useState(
    othersResponsibility.length
  );

  const responsibilityValues = [
    intl.formatMessage({
      defaultMessage: 'Not at all',
      description: 'Not at all slider value'
    }),
    intl.formatMessage({
      defaultMessage: 'Very much',
      description: 'Very much slider value'
    })
  ];

  const handleSliderChange = (id: string, responsibilityValue: number) => {
    const updatedResponsibilityIndex = othersResponsibility.findIndex(
      (responsibility) => responsibility.id === id
    );
    const newOthersResponsibility = [...othersResponsibility];

    // remaining = remaining - (<new responsibility value for this slider in particular> - <old responsibility value for this slider in particular>)
    const newRemainingResponsibility =
      remainingResponsibility -
      (responsibilityValue -
        newOthersResponsibility[updatedResponsibilityIndex].responsibility);
    setRemainingResponsibility(newRemainingResponsibility);

    newOthersResponsibility.forEach((r) => {
      if (r.id === id) {
        r.responsibility = responsibilityValue;
      }
      r.maxResponsibility = r.responsibility + newRemainingResponsibility;
    });

    setOthersResponsibility(newOthersResponsibility);
  };

  const handleAddResponsibility = (reason: string) => {
    setOthersResponsibility([
      ...othersResponsibility,
      {
        id: uuidv4(),
        reason,
        responsibility: 0,
        color: allColors[currentColorIndex % allColors.length] as Colors,
        maxResponsibility: remainingResponsibility
      }
    ]);

    setCurrentColorIndex(currentColorIndex + 1);
  };

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={[
          { paddingBottom: insets.bottom + 16 },
          sharedStyles.scrollViewContentContainer
        ]}
        style={[
          sharedStyles.scrollViewStyle,
          Platform.OS === 'web' && {
            height: windowHeight - 125
          }
        ]}
      >
        <Message
          component={
            <MediumText style={sharedStyles.messageInnerText}>
              <FormattedMessage
                description="More reasons for the responsibility pie"
                defaultMessage="Now please think of other reasons"
              />
            </MediumText>
          }
          style={sharedStyles.message}
          containerStyle={sharedStyles.messageContainer}
        />
        <View style={sharedStyles.pieContainer}>
          <VictoryPie
            data={[
              ...othersResponsibility.map((responsibility) => ({
                x: responsibility.reason,
                y: responsibility.responsibility,
                fill: responsibility.color
              })),
              {
                x: 'Placeholder', // This label isn't displayed anywhere
                y: remainingResponsibility,
                fill: Colors.GREY5
              }
            ]}
            labels={() => null}
            // A fixed width and height limits the size of the pie chart in mobile
            width={300}
            height={300}
            containerComponent={
              <VictoryContainer
                style={Platform.OS === 'web' ? { maxWidth: '40%' } : {}}
              />
            }
            style={{
              data: {
                fill: ({ datum }) => datum.fill
              }
            }}
          />
        </View>
        <ScrollView style={sharedStyles.slidersScroller}>
          {othersResponsibility.map((responsibility) => (
            <Slider
              key={uuidv4()}
              containerStyle={sharedStyles.slider}
              titleStyle={sharedStyles.sliderTitle}
              barStyle={sharedStyles.sliderBar}
              borderStyle={[
                sharedStyles.sliderBorder,
                { borderColor: responsibility.color }
              ]}
              title={responsibility.reason}
              maximumValue={responsibility.maxResponsibility}
              minimumValue={0}
              onSlidingComplete={(r) =>
                handleSliderChange(responsibility.id, r)
              }
              value={responsibility.responsibility}
              leftLabel={responsibilityValues[0]}
              rightLabel={responsibilityValues[1]}
              remainingPercentage={`${responsibility.maxResponsibility}%`}
              minimumTrackTintColor="rgba(73, 85, 206, 0.25)" // Colors.BLUE at 25% opacity
              maximumTrackTintColor="rgba(73, 85, 206, 0.25)" // Colors.BLUE at 25% opacity
              disabled={archived}
            />
          ))}
          {!archived && (
            <TouchableOpacity
              onPress={() => setIsAddingCustomReason(!isAddingCustomReason)}
              style={styles.addNewReasonContainer}
            >
              <MediumText style={styles.addNewReasonText}>
                {' '}
                <FormattedMessage
                  defaultMessage="Add new item"
                  description="Add new item"
                />
              </MediumText>
              <View style={styles.addNewReasonButton}>
                <MediumText style={styles.addNewReasonText}>+</MediumText>
              </View>
            </TouchableOpacity>
          )}
        </ScrollView>
        {!archived && (
          <View style={sharedStyles.pillButtonContainer}>
            <PillButton onPress={onNext}>
              <FormattedMessage defaultMessage="Next" description="Next" />
            </PillButton>
          </View>
        )}
      </ScrollView>
      {isAddingCustomReason && (
        <InputDrawer
          onSave={(t) => {
            handleAddResponsibility(t);
            setIsAddingCustomReason(false);
          }}
          onCancel={() => {
            setIsAddingCustomReason(false);
          }}
        />
      )}
    </>
  );
};

export default OthersResponsibilityPie;

const styles = StyleSheet.create({
  addNewReasonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 24,
    backgroundColor: Colors.GREY6,
    padding: 16,
    borderRadius: 4
  },
  addNewReasonButton: {
    width: 30,
    height: 30,
    borderColor: Colors.GREY4,
    borderWidth: 1,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12,
    backgroundColor: Colors.WHITE
  },
  addNewReasonText: {
    color: Colors.BLUE
  }
});
