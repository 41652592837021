import { registerRootComponent } from 'expo';
import * as SplashScreen from 'expo-splash-screen';

import App from './src/App';

// Manually controls the splash screen duration
SplashScreen.preventAutoHideAsync().then(() => {
  setTimeout(() => {
    SplashScreen.hideAsync();
  }, 1000);
});

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately

registerRootComponent(App);
