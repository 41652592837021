import { Audio } from 'expo-av';
import {
  RecordingOptions,
  AndroidOutputFormat,
  AndroidAudioEncoder,
  IOSOutputFormat,
  IOSAudioQuality
} from 'expo-av/build/Audio';
import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import {
  ActivityIndicator,
  Image,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import Colors from '../../../../../colors';
import TimeLabel from '../../../../../components/TimeLabel';
import useSound, { StateType } from '../../../../../hooks/useSound';

interface Props {
  isRecording: boolean;
  onClose: () => void;
  onSend: (uri: string) => void;
}

type Interval = ReturnType<typeof setInterval>;

const recordingOptions: RecordingOptions = {
  isMeteringEnabled: true,
  android: {
    extension: '.aac',
    outputFormat: AndroidOutputFormat.MPEG_4,
    audioEncoder: AndroidAudioEncoder.AAC,
    sampleRate: 44100,
    numberOfChannels: 2,
    bitRate: 128000
  },
  ios: {
    extension: '.aac',
    outputFormat: IOSOutputFormat.MPEG4AAC,
    audioQuality: IOSAudioQuality.MAX,
    sampleRate: 44100,
    numberOfChannels: 2,
    bitRate: 128000,
    linearPCMBitDepth: 16,
    linearPCMIsBigEndian: false,
    linearPCMIsFloat: false
  },
  web: {}
};

const RecordingPanel: FunctionComponent<Props> = ({
  isRecording,
  onClose,
  onSend
}) => {
  const [recording, setRecording] = useState<Audio.Recording>();
  const [uri, setUri] = React.useState<string>();
  const [time, setTime] = React.useState<number>(0);
  const counterRef = useRef<Interval>();
  const sound = useSound(uri);

  // Handle recording changes.
  useEffect(() => {
    if (isRecording) {
      startRecording();
    } else {
      // Buffer as recording ends very abruptly.
      setTimeout(stopRecording, 200);
    }
  }, [isRecording]);

  const startTimer = () => {
    setTime(0);
    const interval = 1000;
    counterRef.current = setInterval(() => {
      setTime((time) => time + interval);
    }, interval);
  };

  const stopTimer = () => {
    if (counterRef.current) {
      clearInterval(counterRef.current);
    }
  };

  const startRecording = async () => {
    startTimer();
    try {
      const audioRecording = new Audio.Recording();
      setRecording(audioRecording);
      await audioRecording.prepareToRecordAsync(recordingOptions);
      await audioRecording.startAsync();
    } catch (err) {
      console.error('Failed to start recording', err);
    }
  };

  const stopRecording = async () => {
    stopTimer();
    await recording?.stopAndUnloadAsync();
    const newUri = recording?.getURI();
    if (newUri) {
      setUri(newUri);
    }
  };

  const renderActivityIndicator = () =>
    !isRecording ? <ActivityIndicator color={Colors.BLUE} /> : null;

  const renderPlaybackButton = () => {
    const { state, duration } = sound;
    const value = state === StateType.Stopped ? duration : sound.progress;
    return (
      <TouchableOpacity
        style={styles.playbackContainer}
        onPress={sound.playPause}
      >
        <Image
          source={
            state === StateType.Playing
              ? require('../../../../../assets/pause.png')
              : require('../../../../../assets/play.png')
          }
          style={styles.playbackIcon}
        />
        <TimeLabel value={value} />
      </TouchableOpacity>
    );
  };

  const deleteSound = () => {
    if (sound.state === StateType.Playing) {
      sound.playPause();
    }
    onClose();
  };

  const renderDeleteButton = () => {
    return !isRecording ? (
      <TouchableOpacity onPress={deleteSound}>
        <Image source={require('../assets/delete.png')} style={styles.icon} />
      </TouchableOpacity>
    ) : null;
  };

  const sendMessage = () => {
    if (uri) {
      onSend(uri);
      onClose();
    }
  };

  const renderSendButton = () => {
    const isPlaying = sound.state === StateType.Playing;
    return !isRecording ? (
      <TouchableOpacity onPress={sendMessage}>
        <Image
          source={require('../assets/send-outline.png')}
          style={[styles.icon, isPlaying ? styles.tintWhite : styles.tintBlue]}
        />
      </TouchableOpacity>
    ) : null;
  };

  const renderRecordingStatus = () => (
    <View style={styles.recordingContainer}>
      <Image
        source={require('../assets/microphone.png')}
        style={styles.recordingIcon}
      />
      <TimeLabel value={time} style={styles.recordingText} />
    </View>
  );

  return (
    <View
      style={[
        styles.container,
        sound.state === StateType.Playing && styles.containerPlaying
      ]}
    >
      {isRecording && renderRecordingStatus()}
      {!uri && renderActivityIndicator()}
      {uri && renderPlaybackButton()}
      {uri && (
        <View style={styles.optionsContainer}>
          {renderDeleteButton()}
          {renderSendButton()}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.GREY5,
    paddingHorizontal: '6%',
    flex: 1,
    flexDirection: 'row',
    height: 48,
    justifyContent: 'space-between'
  },
  containerPlaying: {
    backgroundColor: Colors.BLUE
  },
  recordingContainer: {
    flexDirection: 'row',
    position: 'absolute',
    left: 32,
    alignSelf: 'center'
  },
  recordingIcon: {
    height: 24,
    width: 24,
    marginRight: 5,
    tintColor: Colors.RED
  },
  recordingText: {
    color: Colors.RED
  },
  playbackContainer: {
    height: 32,
    width: 80,
    borderRadius: 40,
    backgroundColor: Colors.BLUE,
    paddingHorizontal: 8,
    paddingVertical: 3,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  playbackIcon: {
    height: 18,
    width: 18
  },
  optionsContainer: {
    width: 110,
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  icon: {
    height: 32,
    width: 32
  },
  tintBlue: {
    tintColor: Colors.BLUE
  },
  tintWhite: {
    tintColor: Colors.WHITE
  }
});

export default RecordingPanel;
