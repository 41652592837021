import { isSameDay, startOfDay } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { Calendar, CalendarProvider } from 'react-native-calendars';
import { TouchableOpacity } from 'react-native-gesture-handler';
import XDate from 'xdate';

import Colors from '../../colors';
import MediumText from '../../components/MediumText';
import CalendarArrow from './components/CalendarArrow';
import WeeklyCalendar from './components/WeeklyCalendar';
import { StyleCheckProperties } from './types';

interface Props {
  currentDate: Date;
  selectedDate?: Date;
  // List of marked days as timestamps.
  markedDates?: number[];
  // Show weekly view, rather than monthly.
  weekly?: boolean;
  // Callback when a day number is pressed,
  onSelect?: (date: number) => void;
}

const CalendarComponent: FunctionComponent<Props> = ({
  currentDate,
  markedDates = [],
  selectedDate = new Date(),
  weekly = false,
  onSelect
}) => {
  const Component = weekly ? WeeklyCalendar : Calendar;

  // Calculate day style depending on selection / markings.
  const getDayStyle = ({
    marked,
    selected,
    timestamp
  }: StyleCheckProperties) => {
    const style = [styles.day] as ViewStyle[];
    if (isSameDay(timestamp, currentDate)) {
      style.push(styles.today);
    }
    if (selected) {
      return [...style, styles.selectedDay];
    }
    if (marked) {
      return [...style, styles.markedDay];
    }
    return style;
  };

  // Calculate text style depending on selection / markings.
  const getDayTextStyle = ({ selected }: StyleCheckProperties) => {
    return selected ? [styles.dayText, styles.whiteDayText] : styles.dayText;
  };

  const renderDay = ({ date: { day, timestamp } }: any) => {
    const marked = markedDates.includes(startOfDay(timestamp).getTime());
    const selected = selectedDate ? isSameDay(timestamp, selectedDate) : false;
    return (
      <TouchableOpacity
        onPress={() => onSelect?.(timestamp)}
        style={getDayStyle({ marked, selected, timestamp })}
      >
        <MediumText style={getDayTextStyle({ marked, selected, timestamp })}>
          {day}
        </MediumText>
      </TouchableOpacity>
    );
  };

  return (
    <CalendarProvider
      date={new XDate(selectedDate).toString()}
      style={styles.container}
    >
      <Component
        disableMonthChange
        firstDay={1}
        headerStyle={styles.header}
        dayComponent={renderDay}
        renderArrow={(direction) => <CalendarArrow direction={direction} />}
        theme={theme}
      />
    </CalendarProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 0
  },
  header: {
    backgroundColor: Colors.WHITE,
    height: 60,
    justifyContent: 'center',
    marginBottom: 16,
    paddingHorizontal: 0,
    paddingTop: 16
  },
  day: {
    height: 32,
    width: 32,
    alignContent: 'center',
    justifyContent: 'center',
    top: -4,
    borderRadius: 16
  },
  dayText: {
    textAlign: 'center'
  },
  today: {
    borderWidth: 3,
    borderColor: Colors.BLUE
  },
  markedDay: {
    backgroundColor: Colors.GREY5
  },
  selectedDay: {
    backgroundColor: Colors.BLUE
  },
  whiteDayText: {
    color: Colors.WHITE
  }
});

// Theme for customizing library's components.
const theme = {
  textDayFontFamily: 'NotoSans-Medium',
  textDayFontWeight: '500' as const,
  textDayFontSize: 14,
  dayTextColor: Colors.GREY2,
  'stylesheet.calendar.header': {
    header: {
      marginTop: 25,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 0,
      paddingRight: 0,
      alignItems: 'center',
      height: 30
    },
    monthText: {
      fontSize: 14,
      fontFamily: 'NotoSans-Medium',
      fontWeight: '500',
      color: Colors.GREY2
    },
    dayHeader: {
      marginTop: 2,
      marginBottom: 7,
      textAlign: 'center',
      width: 32,
      color: Colors.GREY2,
      fontFamily: 'NotoSans-Medium'
    },
    week: {
      marginTop: 7,
      marginBottom: 7,
      flexDirection: 'row',
      justifyContent: 'space-around',
      backgroundColor: Colors.WHITE
    }
  },
  'stylesheet.expandable.main': {
    containerShadow: {
      shadowColor: Colors.SHADOW,
      shadowOpacity: 0.25,
      shadowRadius: 5,
      shadowOffset: { height: 0, width: 0 }
    },
    container: {
      backgroundColor: Colors.WHITE
    },
    header: {
      backgroundColor: Colors.WHITE
    }
  }
};

export default CalendarComponent;
