import { useNavigation } from '@react-navigation/core';
import { MediaTypeOptions } from 'expo-image-picker';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  EmitterSubscription,
  FlatList,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';

import AuthorizedImage from '../../../../../components/AuthorizedImage';
import KeyboardInput, {
  KeyboardInputRef
} from '../../../../../components/KeyboardInput';
import MediumText from '../../../../../components/MediumText';
import PillButton from '../../../../../components/PillButton';
import RemovableImage from '../../../../../components/RemovableImage';
import Swiper, { SwiperRef } from '../../../../../components/Swiper';
import useAppWindowDimensions from '../../../../../hooks/useAppWindowDimensions';
import { selectWrittenExposuresExercisesByLifelineId } from '../../../../../store/exercises';
import { useAppSelector } from '../../../../../store/hooks';
import { WrittenExposureExercise } from '../../../../../store/types';

interface Props {
  onSave: () => void;
  isTimeLimitReached: boolean;
  exerciseTopic: string;
  lifelineId: string;
  instruction: string;
  text: string;
  pictureUris: string[];
  onChangeText: (text: string) => void;
  onChangePictureUris: (uris: string[]) => void;
}

const ExposureStep: FunctionComponent<Props> = ({
  text,
  pictureUris,
  onChangeText,
  onChangePictureUris,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [editText, setEditText] = useState(false);
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);

  const archivedWrittenExposures = useAppSelector(
    selectWrittenExposuresExercisesByLifelineId(props.lifelineId)
  );
  const swiperRef = useRef<SwiperRef>(null);
  const keyboardRef = useRef<KeyboardInputRef>(null);
  const dimensions = useAppWindowDimensions();
  const navigation = useNavigation();

  const drawerUpperSectionHeight = 50 + dimensions.height * 0.07;
  const tabBarHeight = 50;
  const margins = 50;
  const bottomContainerHeight = 50;

  useEffect(() => {
    const handleKeyboardDidShow = () => setKeyboardIsOpen(true);
    const handleKeyboardDidHide = () => setKeyboardIsOpen(false);

    const showSubscription: EmitterSubscription = Keyboard.addListener(
      'keyboardDidShow',
      handleKeyboardDidShow
    );
    const hideSubscription: EmitterSubscription = Keyboard.addListener(
      'keyboardDidHide',
      handleKeyboardDidHide
    );

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    // https://github.com/facebook/react-native/issues/24531
    setTimeout(() => {
      swiperRef.current?.scrollToIndex(1, false);
    }, 1);
  }, [swiperRef]);

  const onButtonPress = (index: number) => {
    Keyboard.dismiss();
    swiperRef.current?.scrollToIndex(index);
  };

  const renderTabBar = () => {
    return (
      <View style={styles.tabBarContainer}>
        <TouchableOpacity
          onPress={() => onButtonPress(0)}
          style={{ marginRight: 20 }}
        >
          <Image
            style={styles.tabIcon}
            source={
              selectedTab === 0
                ? require('../assets/bubbleBlue.png')
                : require('../assets/bubbleGrey.png')
            }
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => onButtonPress(1)}
          style={{ marginRight: 20 }}
        >
          <Image
            style={styles.tabIcon}
            source={
              selectedTab === 1
                ? require('../assets/penBlue.png')
                : require('../assets/penGrey.png')
            }
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => onButtonPress(2)}>
          <Image
            style={styles.tabIcon}
            source={
              selectedTab === 2
                ? require('../assets/hamburgerBlue.png')
                : require('../assets/hamburgerGrey.png')
            }
          />
        </TouchableOpacity>
      </View>
    );
  };

  const renderInstructionTab = () => {
    return (
      <ScrollView contentContainerStyle={styles.scrollViewContentContainer}>
        <View
          style={[
            styles.contentContainer,
            Platform.OS === 'web' && {
              height:
                dimensions.height -
                drawerUpperSectionHeight -
                tabBarHeight -
                margins
            }
          ]}
        >
          <View style={styles.instructionContainer}>
            <MediumText style={styles.title}>
              <FormattedMessage
                defaultMessage="Your topic:"
                description="Your written exposure topic"
              />{' '}
            </MediumText>
            <MediumText style={styles.instructionBody}>
              {props.exerciseTopic}
            </MediumText>
            <MediumText style={styles.title}>
              <FormattedMessage
                defaultMessage="Instruction:"
                description="The written exposure instruction"
              />{' '}
            </MediumText>
            <MediumText style={styles.instructionBody}>
              {props.instruction}
            </MediumText>
          </View>
        </View>
      </ScrollView>
    );
  };

  const renderKeyboardInput = () => {
    const textInputHeight =
      dimensions.height -
      drawerUpperSectionHeight -
      tabBarHeight -
      bottomContainerHeight -
      margins;

    return (
      <ScrollView>
        <FlatList
          data={pictureUris}
          renderItem={({ item }) => (
            <View style={styles.removableImageContainer}>
              <RemovableImage
                uri={item}
                onDelete={() =>
                  onChangePictureUris(pictureUris.filter((pic) => pic !== item))
                }
              />
            </View>
          )}
        />
        <KeyboardInput
          ref={keyboardRef}
          multiline
          scrollEnabled={Platform.OS !== 'ios'}
          noHeightLimit
          value={text}
          onTouchMove={() => {
            if (!keyboardIsOpen) {
              setEditText(false);
            }
          }}
          onTouchEnd={() => {
            if (selectedTab === 1) {
              if (!editText) setEditText(true);
            } else Keyboard.dismiss();
          }}
          onChangeText={onChangeText}
          style={[
            {
              borderWidth: 0,
              ...(Platform.OS === 'web' && {
                height: textInputHeight
              })
            }
          ]}
          editable={Platform.OS === 'ios' ? editText : true}
        />
      </ScrollView>
    );
  };

  const renderTypewriterTab = () => {
    /* 
      There are two "hacky" workarounds here to make the behavior of the scrollview + the textinput work without bugs.
      - Having the editText prop helps avoid opening the keyboard on vertical scroll. (iOS)
      - Having no fixed height prevents us from spanning the input all the way to the bottom. So we can still focus when pressing
        the white space, we're wrapping the whole thing with a Pressable that programatically focus on the textinput.
    */
    return (
      <View
        style={
          Platform.OS === 'web'
            ? {
                height:
                  dimensions.height -
                  drawerUpperSectionHeight -
                  tabBarHeight -
                  margins
              }
            : {}
        }
      >
        <KeyboardAvoidingView
          style={{
            height: '100%'
          }}
          behavior="padding"
          keyboardVerticalOffset={Platform.OS === 'ios' ? 160 : -100}
        >
          <Pressable
            onPress={() => {
              if (!keyboardIsOpen) {
                setEditText(true);
                setTimeout(() => {
                  keyboardRef.current?.focus();
                }, 0);
              }
            }}
            style={{
              height: '88%'
            }}
          >
            {renderKeyboardInput()}
          </Pressable>
          <View style={styles.bottomButtonsContainer}>
            {Platform.OS !== 'web' && (
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('Drawer', {
                    screen: 'Camera',
                    params: {
                      mediaTypes: MediaTypeOptions.Images,
                      setUri: (uri: string) =>
                        onChangePictureUris([...pictureUris, uri])
                    }
                  });
                }}
              >
                <Image
                  source={require('../assets/camera.png')}
                  resizeMode="contain"
                  style={styles.cameraIcon}
                />
              </TouchableOpacity>
            )}
            <PillButton
              onPress={props.onSave}
              disabled={
                props.isTimeLimitReached ||
                (text.length === 0 && pictureUris.length === 0)
              }
            >
              <FormattedMessage defaultMessage="Save" description="Save" />
            </PillButton>
          </View>
        </KeyboardAvoidingView>
      </View>
    );
  };

  const renderArchiveTab = () => {
    const renderItem = (item: WrittenExposureExercise) => {
      return (
        <>
          <MediumText style={styles.title}>
            <FormattedMessage
              defaultMessage="Written Exposure from"
              description="Written Exposure archive date title"
            />{' '}
            <FormattedDate
              value={item.createdAt}
              month="short"
              day="2-digit"
              year="numeric"
            />
          </MediumText>
          <MediumText style={styles.title}>{item.text}</MediumText>
          <FlatList
            data={item.files}
            renderItem={({ item }) => (
              <AuthorizedImage source={{ uri: item }} style={styles.picture} />
            )}
          />
        </>
      );
    };

    return (
      <FlatList
        contentContainerStyle={{
          ...styles.contentContainer,
          ...(Platform.OS === 'web' && {
            // The `height` needs to be defined for scrolling on the web to work
            height:
              dimensions.height -
              drawerUpperSectionHeight -
              tabBarHeight -
              margins
          })
        }}
        renderItem={({ item }) => renderItem(item)}
        data={archivedWrittenExposures}
        showsVerticalScrollIndicator={false}
        scrollEnabled
      />
    );
  };

  return (
    <>
      {renderTabBar()}
      <Swiper
        elementWidth={dimensions.width}
        ref={swiperRef}
        onMomentumScrollEnd={Keyboard.dismiss}
        onScroll={(event) => {
          Keyboard.dismiss();
          const idx = Math.round(
            event.nativeEvent.contentOffset.x / dimensions.width
          );
          if (idx !== selectedTab) setSelectedTab(idx);
        }}
        scrollEnabled={Platform.OS !== 'web'}
        containerStyle={
          Platform.OS === 'web'
            ? { alignItems: 'flex-start', height: '100%' }
            : {}
        }
      >
        {renderInstructionTab()}
        {renderTypewriterTab()}
        {renderArchiveTab()}
      </Swiper>
    </>
  );
};

export default ExposureStep;

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    marginBottom: 15
  },
  instructionBody: {
    fontSize: 16,
    marginBottom: 30,
    lineHeight: 26
  },
  tabBarContainer: {
    height: 50,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tabIcon: {
    width: 20,
    height: 20
  },
  keyboardAvoidingView: {
    position: 'absolute',
    bottom: 40,
    right: 20
  },
  // The `height` property can't be defined for `FlatList`'s to work on mobile
  // Not quite sure if this is still applicable, but removing it seems to have worked (https://stackoverflow.com/questions/48674848/flatlist-not-scrolling)
  contentContainer: {
    width: '100%',
    paddingHorizontal: 24
  },
  cameraIcon: {
    width: 22,
    height: 22,
    marginRight: 24
  },
  bottomButtonsContainer: {
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 20,
    marginVertical: 10
  },
  picture: {
    marginHorizontal: 20,
    resizeMode: 'contain',
    height: 200,
    marginBottom: 10
  },
  removableImageContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10
  },
  instructionContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1
  },
  scrollViewContentContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1
  }
});
