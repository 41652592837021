export interface FlowState {
  id: number | null;
  sessionIds: string[];
  name: string | null;
  description: string | null;
  started: boolean;
  startedAt: string | null;
  completed: boolean;
  completedAt: string | null;
  loading: boolean;
}

export interface MoodEntriesState {
  ids: string[];
  entities: {
    [id: string]: MoodEntry;
  };
}

export interface MoodEntry {
  id: string;
  createdAt: string;
  value: number;
  files?: Asset[];
}

export enum Substance {
  BeerBig = 'beer-big',
  BeerSmall = 'beer-small',
  Cannabis = 'cannabis',
  Cocaine = 'cocaine',
  Gambling = 'gambling',
  Needle = 'needle',
  Pill = 'pill',
  Shot = 'shot',
  Whisky = 'whisky',
  WineRed = 'wine-red',
  WineWhite = 'wine-white'
}

export interface SubstanceEntriesState {
  ids: string[];
  entities: {
    [id: string]: SubstanceEntry;
  };
}

export interface SubstanceEntry {
  date: string;
  substance: Substance;
  count: number;
}

export interface Asset {
  type: 'audio' | 'video' | 'image';
  mimetype?: string;
  localUrl: string | null;
  url: string;
}

export enum ExerciseType {
  CognitiveTriangle = 'cognitive-triangle',
  WrittenExposure = 'written-exposure',
  Lifeline = 'lifeline',
  LetterToAFriend = 'letter-to-a-friend',
  CheckingIn = 'checking-in',
  ConcernsAndBarriers = 'concerns-and-barriers',
  ListExercise = 'list',
  SafetyPlanBasicList = 'safety-plan-basic-list',
  SafetyPlanAddressList = 'safety-plan-address-list',
  Audio = 'audio',
  ResponsibilityPie = 'responsibility-pie'
}

export interface BaseExercise {
  id: string;
  type: ExerciseType;
  files?: string[];
  feedbackId?: string;
  createdAt: string;
}

/**
 * Union type of exercises saved in redux and in backend.
 * Contains all information about the users inputs to the exercises.
 */
export type Exercise =
  | CognitiveTriangleExercise
  | WrittenExposureExercise
  | LifelineExercise
  | LetterToAFriendExercise
  | CheckingInExercise
  | ConcernsAndBarriersExercise
  | ListExercise
  | BaseSafetyItem
  | ContactSafetyItem
  | AudioExercise
  | ResponsibilityPieExercise;

export interface ListExercise extends BaseExercise {
  templateId: number;
  items: ListExerciseItem[];
}

export interface ListExerciseItem {
  id: string;
  selected: boolean;
  text: string;
  response: string | ListExerciseBlock[];
}

export interface ListExerciseBlock {
  id: string;
  question: string;
  response: string | string[] | number | Option | Option[] | null;
}

export interface ListExerciseTemplate {
  id: number;
  title: string;
  instruction: string;
  items: ListExerciseTemplateItem[];
  defaultResponse: string;
  defaultBlocks: ListExerciseTemplateBlock[];
  complexList: boolean;
  allowCustomItems: boolean;
  scheduling: boolean;
}

export interface ListExerciseTemplateItem {
  id: string;
  text: string;
  response?: string;
  blocks: ListExerciseTemplateBlock[];
}

export interface ListExerciseTemplateBlock {
  id: string;
  type:
    | 'single-select'
    | 'multiple-select'
    | 'keyboard-input'
    | 'slider'
    | 'list-input';
  options: Option[];
  text: string;
  min?: number;
  max?: number;
  interval?: number;
}

export interface ResponsibilityPieReason {
  id: string;
  reason: string;
  responsibility: number;
  maxResponsibility: number;
  color: string;
}
export interface ResponsibilityPieExercise extends BaseExercise {
  linkedCognitiveTriangleId?: string;
  reasons: ResponsibilityPieReason[];
}

export interface CognitiveTriangleExerciseSituationNew {
  id: number | string;
  text: string;
}

export type CognitiveTriangleExerciseSituationLegacy = string;
export interface CognitiveTriangleExercise extends BaseExercise {
  situation:
    | CognitiveTriangleExerciseSituationNew
    | CognitiveTriangleExerciseSituationLegacy;
  unhelpful: CognitiveTriangle;
  helpful: CognitiveTriangle;
  linkedExerciseId?: string;
}

export enum CognitiveTriangleFlow {
  CLOSED,
  MIXED,
  OPEN,
  OVERVIEW
}

export interface WrittenExposureExercise extends BaseExercise {
  lifelineEventId: string;
  moodScale: {
    before: number;
    tenMinutesIn: number;
    twentyMinutesIn: number;
    after: number;
  };
  text: string;
  finalThought: string;
  templateId: number;
}

export interface WrittenExposureTemplate {
  id: number;
  time: number;
  instruction: string;
  rating: boolean;
  skipTopicSelection: boolean;
  defaultTopic: string | null;
}

export interface LifelineExercise extends BaseExercise {
  description: string;
  age: number;
  influenceScale: number;
}

export interface LetterToAFriendExercise extends BaseExercise {
  thought: string;
  letter: string;
  commitmentScale: number;
}

export interface CheckingInAnswer {
  id: string;
  problem: string;
  impactScale: number;
}

export interface CheckingInExercise extends BaseExercise {
  answers: CheckingInAnswer[];
}

export interface ConcernsAndBarriersItems {
  problem: string;
  response: string;
}

export interface ConcernsAndBarriersExercise extends BaseExercise {
  values: ConcernsAndBarriersItems[];
}

export interface ConcernsAndBarriersTemplate {
  instruction: string;
  userInput: boolean;
  items: ConcernsAndBarriersItems[];
}

export interface AudioExercise extends BaseExercise {
  timePlayed: number;
}

export interface AudioExerciseTemplate {
  title: string;
  audioName: string;
  file: Asset;
}

export interface CognitiveTriangle {
  thought: Option | null;
  feelings: {
    feeling: Option | null;
    scale: number;
  }[];
  behavior: Option | null;
}

export interface CognitiveTriangleTemplate {
  instructionInitial: string;
  instructionHelpfulApproach: string;
  instructionSummary: string;
  linkedExercise: ExerciseType;
  instructionLinkedExercise: string;
  situations: Situation[];
  linkedListExerciseTemplate: ListExerciseTemplate | null;
  letterToAFriendRating: boolean;
}

export interface Situation {
  id: number;
  text: string;
  helpful: {
    thoughts: Option[];
    feelings: Option[];
    behaviors: Option[];
  };
  unhelpful: {
    thoughts: Option[];
    feelings: Option[];
    behaviors: Option[];
  };
}

export interface Feedback {
  id: string;
  date: string;
  text: string;
}

export interface AssetsState {
  ids: string[];
  entities: {
    [id: string]: Asset;
  };
}

export interface Session {
  id: string;
  sessionId: number;
  flowId: number | null;
  moduleIds: string[];
  title: string;
  file: Asset;
  theme: Theme;
  unlocked: boolean;
  started: boolean;
  completed: boolean;
  unlockedAt: string | null;
  startedAt: string | null;
  completedAt: string | null;
  wait: number;
  waitFrom: 'start' | 'last';
}

export interface Module {
  id: string;
  moduleId: number;
  sessionId: number;
  flowId: number;
  blockIds: string[];
  file: Asset;
  narrator: {
    id: number;
    file: Asset | null;
  } | null;
  started: boolean;
  completed: boolean;
  startedAt: string | null;
  completedAt: string | null;
}

export type Block = NarrativeBlock | QuestionBlock | ExerciseBlock;

interface BaseBlock {
  id: string;
  blockId: number;
  moduleId: number;
  sessionId: number;
  flowId: number;
  collection: 'exercise_blocks' | 'narrative_blocks' | 'question_blocks';
  narrator: {
    id: number;
    file: Asset | null;
  } | null;
  typing: boolean;
  text?: string;
  file: Asset;
  condition?: Condition;
  completed: boolean;
  completedAt: string | null;
  answer: Answer;
  unlockFeatures?: UnlockableFeature[];
  pause: boolean;
}

export type Condition = [
  string[],
  '=' | '!=' | '<' | '<=' | '>' | '>=',
  string
];

export type ExerciseTemplate =
  | AudioExerciseTemplate
  | CognitiveTriangleTemplate
  | ListExerciseTemplate
  | SafetyPlanListItemTemplate
  | WrittenExposureTemplate;

export interface UnlockableFeature {
  id: number;
  unlockedFeature: ExerciseType | 'substance-tracker';
  file: Asset;
  template?: ExerciseTemplate;
}

export interface ExerciseBlock extends BaseBlock {
  exercise: ExerciseType;
  cognitiveTriangleTemplate: CognitiveTriangleTemplate;
  concernsAndBarriersTemplate: ConcernsAndBarriersTemplate;
  safetyPlanListInputTemplate: SafetyPlanListItemTemplate;
  audioExercise: AudioExerciseTemplate;
  writtenExposureTemplate: WrittenExposureTemplate;
  listExerciseTemplate: ListExerciseTemplate;
}

export type QuestionType =
  | 'keyboard-input'
  | 'slider'
  | 'single-select'
  | 'multiple-select'
  | 'keyword-select';

export interface Question {
  skippable: boolean;
  type: QuestionType;
  options: Option[];
  inputType?: 'email' | 'number' | 'phone' | 'text';
  min?: number;
  max?: number;
  interval?: number;
  exclusionCheck?: boolean;
  userConfig?: string;
}

export type QuestionBlock = BaseBlock & Question;
export type QuestionItem = { id: string; text: string } & Question;

export type Answer = string | string[];

export interface Option {
  id: string;
  text: string;
  value: string;
}

export interface NarrativeBlock extends BaseBlock {
  messengerLink?: boolean;
  buttonText: string;
  buttonAudio: Asset;
}

export interface ExclusionBlock {
  id: string;
  text: string;
  file?: Asset;
}

export interface Event {
  id: string;
  timestamp: string;
  itemType: MyJourneyItemType;
  name?: string;
  exerciseType?: ExerciseType;
  scheduledFor?: string;
  completed?: boolean;
}

// ordered by order of MyJourneyHeader buttons
export enum MyJourneyItemType {
  Session = 1,
  Exercise,
  Feedback,
  Diary,
  Assessment,
  Other
}

export interface Theme {
  primary: string;
  secondary: string;
}

export enum MessageOrigin {
  Helper = 'helper-origin',
  User = 'user-origin'
}

export enum MessageType {
  Text = 'text-message',
  Voice = 'voice-message'
}

interface MessageBase {
  id: number;
  date: string;
  origin: MessageOrigin;
}

export interface TextMessage extends MessageBase {
  text: string;
  type: MessageType.Text;
}

export interface VoiceMessage extends MessageBase {
  file: Asset;
  type: MessageType.Voice;
}

export type Message = VoiceMessage | TextMessage;

type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

export type DatedMessage = DistributiveOmit<Message, 'date'> & {
  date: Date;
};

export type SafetyItemType =
  | ExerciseType.SafetyPlanAddressList
  | ExerciseType.SafetyPlanBasicList;

export interface BaseSafetyItem extends BaseExercise {
  cmsName: string;
  section: string;
  name: string;
}

export interface ContactSafetyItem extends BaseSafetyItem {
  phoneNumber: string;
  address: string;
}

export type SafetyItem = ContactSafetyItem | BaseSafetyItem;

export interface SafetyPlanListItemTemplate {
  cmsName: string;
  title: string;
  instruction: string;
}

export enum Gender {
  Female = 'f',
  Male = 'm'
}

export interface UserState {
  token: string;
  fcmToken: string | null;
  flowId: number | null;
  exclusionData: ExclusionBlock | null;
  debugMode: boolean;

  // from API user model
  fullname: string;
  status: string | null;
  exclusionReason: string | null;
  userId: number | null;
  userStory: string | null;
  language: string | null;
  gender: Gender | null;
  countryCode: string | null;
  nationality: string | null;
  preferredContactOption: string | null;
  preferredHelperGender: string | null;
  preferredContactMethod: string | null;
  availabilityDay: string | null;
  availabilityTime: string | null;
  studies: {
    id: number;
    name: string;
  }[];
  participantId: string | null;
  email: string | null;
  phone: string | null;
  treatmentGroup: number | null;
}

export interface Language {
  code: string;
  language: string;
  country: string;
  gender: Gender | null;
  variation: string | null;
  displayedName: string | null;
  selected: boolean;
}

export interface FAQ {
  id: string;
  name: string;
  question: string;
  answer: string;
  study?: string;
}

export type WithRequired<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>;
