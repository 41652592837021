import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { View, StyleSheet } from 'react-native';

import PillButton from '../../../../../components/PillButton';
import SingleSelect from '../../../../../components/SingleSelect';
import { Option, QuestionBlock } from '../../../../../store/types';

interface SingleSelectAnswerProps {
  block: QuestionBlock;
  value: Option | null;
  onValueChange: (value: Option) => void;
  completed: boolean;
  onComplete: () => void;
  loading?: boolean;
}

const SingleSelectAnswer: FunctionComponent<SingleSelectAnswerProps> = (
  props
) => {
  const renderCompletedBlock = () => {
    const answer =
      props.block.options.find((option) => option.value === props.block.answer)
        ?.text ?? props.block.answer;
    return <PillButton disabled>{answer}</PillButton>;
  };

  const renderUncompletedBlock = () => {
    return (
      <>
        <View style={styles.uncompletedContainer}>
          {props.block.type === 'single-select' && (
            <SingleSelect
              selected={props.value}
              values={props.block.options}
              onSelect={(option) => props.onValueChange(option as Option)}
              buttonStyle={{ alignSelf: 'flex-end' }}
            />
          )}
        </View>
        <PillButton
          disabled={props.value === null && props.block.options.length !== 0}
          onPress={() => props.onComplete()}
          loading={props.loading}
        >
          <FormattedMessage
            defaultMessage="Save"
            description="Save button text for blocks"
          />
        </PillButton>
      </>
    );
  };

  if (props.completed) {
    return renderCompletedBlock();
  } else {
    return renderUncompletedBlock();
  }
};

export default SingleSelectAnswer;

const styles = StyleSheet.create({
  uncompletedContainer: {
    marginBottom: 12,
    width: '100%',
    alignItems: 'flex-end',
    marginTop: -10,
    marginRight: -10
  },
  completedContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: -10,
    marginRight: -10
  }
});
