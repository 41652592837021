import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { compareAsc } from 'date-fns';

import { RootState } from '.';
import { selectAllExercises } from './exercises';
import { selectCompletedSessions } from './sessions';
import { MyJourneyItemType, Event } from './types';

const eventsAdapter = createEntityAdapter<Event>({
  selectId: (event) => event.id
});

export const eventsSlice = createSlice({
  name: 'entries',
  initialState: eventsAdapter.getInitialState(),
  reducers: {
    addEvent: eventsAdapter.addOne
  }
});

export const { selectAll: selectAllEvents } =
  eventsAdapter.getSelectors<RootState>((state) => state.events);

export const selectMyJourneyData = createSelector(
  [selectAllEvents, selectCompletedSessions, selectAllExercises],
  (events, sessions, exercises) => {
    const eventEntries: Event[] = events;
    const sessionEntries: Event[] = sessions
      .filter((session) => !!session.completedAt)
      .map((session) => ({
        id: session.id.toString(),
        name: session.title,
        itemType: MyJourneyItemType.Session,
        timestamp: session.completedAt as string
      }));
    const exerciseEntries: Event[] = exercises.map((exercise) => ({
      id: exercise.id,
      exerciseType: exercise.type,
      itemType: MyJourneyItemType.Exercise,
      timestamp: exercise.createdAt
    }));
    return [...eventEntries, ...sessionEntries, ...exerciseEntries].sort(
      (a, b) => compareAsc(new Date(a.timestamp), new Date(b.timestamp))
    );
  }
);

export const selectMyJourneyDataByCategory =
  (category: MyJourneyItemType) => (state: RootState) => {
    return selectMyJourneyData(state).filter(
      (event) => event.itemType === category
    );
  };

export const { addEvent } = eventsSlice.actions;
export default eventsSlice.reducer;
