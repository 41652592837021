import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React, { FunctionComponent } from 'react';

import { useAppSelector } from '../../../store/hooks';
import IconTopTabHeader from '../IconTopTabHeader';
import FAQScreen from './FAQScreen';
import eHelperScreen from './eHelperScreen';

export type TrackerTabParamList = {
  FAQ: undefined;
  eHelper: undefined;
};

const eHelperTab = createMaterialTopTabNavigator<TrackerTabParamList>();

const EHelperNavigator: FunctionComponent = () => {
  const { userId, studies, treatmentGroup } = useAppSelector(
    (state) => state.user
  );
  const isMessengerActivated =
    userId &&
    studies[0].name.localeCompare('CETA-BLENDED', undefined, {
      sensitivity: 'base'
    }) !== 0;

  return (
    <eHelperTab.Navigator
      tabBar={(props) => (
        <IconTopTabHeader
          {...props}
          icons={{ FAQ: 'FAQ', eHelper: 'EHELPER' }}
        />
      )}
    >
      <eHelperTab.Screen
        name="FAQ"
        component={FAQScreen}
        options={{
          title: 'FAQ'
        }}
      />
      {isMessengerActivated && (
        <eHelperTab.Screen
          name="eHelper"
          component={eHelperScreen}
          options={{
            title: 'eHelper'
          }}
        />
      )}
    </eHelperTab.Navigator>
  );
};

export default EHelperNavigator;
