import { useNavigation } from '@react-navigation/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  I18nManager,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ItemOptionAnswer } from '.';
import Colors from '../../../../colors';
import InputDrawer from '../../../../components/InputDrawer';
import KeyboardInput from '../../../../components/KeyboardInput';
import ListSelect from '../../../../components/ListSelect';
import MediumText from '../../../../components/MediumText';
import Message from '../../../../components/Message';
import PillButton from '../../../../components/PillButton';
import RadioSelect from '../../../../components/RadioSelect';
import useAppWindowDimensions from '../../../../hooks/useAppWindowDimensions';
import {
  ListExerciseTemplateBlock,
  ListExerciseTemplateItem,
  Option
} from '../../../../store/types';
import SliderListAnswer from './SliderListAnswer';

interface Props {
  selectedItems: ListExerciseTemplateItem[];
  itemInFocus: string;
  progress: number;
  answers: ItemOptionAnswer[];
  setAnswer: (answer: ItemOptionAnswer) => void;
  deleteAnswer: (id: string) => void;
  setItemInFocus: (itemId: string) => void;
  defaultBlocks: ListExerciseTemplateBlock[];
  backwardsNav: () => void;
}

const ComplexItemStep: FunctionComponent<Props> = (props) => {
  const [inputOfQuestionId, showInputOfQuestionId] = useState<string | null>(
    null
  );
  const [keyboardAvoidingEnabled, setKeyboardAvoiding] = useState(false);
  const { height: windowHeight } = useAppWindowDimensions();
  const insets = useSafeAreaInsets();

  const itemIndex = props.selectedItems.findIndex(
    (val) => val.id === props.itemInFocus
  );
  const item = props.selectedItems[itemIndex];

  const blocks: ListExerciseTemplateBlock[] =
    item.blocks.length > 0 ? item.blocks : props.defaultBlocks;

  const navigation = useNavigation();
  const intl = useIntl();

  // workaround to avoid awkward scrolling effect when closing list input keyboard
  useEffect(() => {
    if (inputOfQuestionId === null) {
      setTimeout(() => setKeyboardAvoiding(true), 100);
    } else {
      setKeyboardAvoiding(false);
    }
  }, [inputOfQuestionId]);

  const renderQuestion = (block: ListExerciseTemplateBlock) => {
    const value = props.answers.find((val) => val.blockId === block.id)?.value;
    const renderQuestionInput = () => {
      if (block.type === 'slider') {
        return (
          <SliderListAnswer
            onComplete={(value) => {
              props.setAnswer({
                blockId: block.id,
                itemId: props.itemInFocus,
                value
              });
            }}
            block={block}
            value={(value ?? 0) as number}
            completed={false}
          />
        );
      }
      if (block.type === 'single-select') {
        return (
          <RadioSelect
            values={block.options}
            value={value as Option}
            onComplete={(value) => {
              props.setAnswer({
                blockId: block.id,
                itemId: props.itemInFocus,
                value
              });
            }}
          />
        );
      }
      if (block.type === 'multiple-select') {
        return (
          <RadioSelect
            values={block.options}
            value={(value ?? []) as Option[]}
            onComplete={(value) => {
              props.setAnswer({
                blockId: block.id,
                itemId: props.itemInFocus,
                value
              });
            }}
          />
        );
      }
      if (block.type === 'list-input') {
        return (
          <View style={styles.answerContainer}>
            {((value ?? []) as string[]).map((val, index) => {
              return (
                <View style={{ marginBottom: 20 }} key={index.toString()}>
                  <ListSelect
                    selected={false}
                    editMode
                    onDelete={() => {
                      navigation.navigate('ConfirmationQuestion', {
                        question: intl.formatMessage({
                          defaultMessage:
                            'Are you sure you want to delete this item?',
                          description: 'Delete item confirmation'
                        }),
                        onPressYes: () => {
                          if ((value as string[]).length === 1) {
                            props.deleteAnswer(block.id);
                          } else
                            props.setAnswer({
                              blockId: block.id,
                              itemId: props.itemInFocus,
                              value: (value as string[]).filter(
                                (v) => v !== val
                              )
                            });
                        }
                      });
                    }}
                  >
                    {val}
                  </ListSelect>
                </View>
              );
            })}
            <Pressable
              style={styles.addInputContainer}
              onPress={() => {
                showInputOfQuestionId(block.id);
              }}
            >
              <MediumText style={styles.text}>
                <FormattedMessage
                  defaultMessage="Add new item"
                  description="Add new item in complex list exercise"
                />
              </MediumText>
              <View style={styles.plusButton}>
                <MediumText style={styles.text}>+</MediumText>
              </View>
            </Pressable>
          </View>
        );
      }
      if (block.type === 'keyboard-input') {
        return (
          <KeyboardInput
            containerStyle={styles.answerContainer}
            value={(value ?? '') as string}
            onChangeText={(value) => {
              props.setAnswer({
                blockId: block.id,
                itemId: props.itemInFocus,
                value
              });
            }}
          />
        );
      }
    };
    return (
      <View style={{ marginTop: 16 }}>
        <Message text={block.text} />
        <View style={{ marginVertical: 16 }}>{renderQuestionInput()}</View>
      </View>
    );
  };

  const handleSave = () => {
    if (itemIndex >= props.selectedItems.length - 1) {
      props.backwardsNav();
      return;
    }

    const nextId = props.selectedItems[itemIndex + 1].id;
    props.setItemInFocus(nextId);
  };

  const handlePrevious = () => {
    if (itemIndex <= 0) {
      props.backwardsNav();
      return;
    }

    const previousId = props.selectedItems[itemIndex - 1].id;
    props.setItemInFocus(previousId);
  };

  return (
    <>
      <View style={styles.rootContainer}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'position' : undefined}
          keyboardVerticalOffset={Platform.OS === 'ios' ? 85 : undefined}
          // InputDrawer already has its own KeyboardAvoiding so we disable it here when it is shown
          enabled={inputOfQuestionId === null && keyboardAvoidingEnabled}
        >
          <ScrollView
            keyboardShouldPersistTaps="always"
            style={[
              styles.scrollContainer,
              Platform.OS === 'web' && {
                height: windowHeight - 125
              }
            ]}
          >
            <Pressable
              onPress={() => {
                Platform.OS !== 'web' && Keyboard.dismiss();
                showInputOfQuestionId(null);
              }}
              pointerEvents={inputOfQuestionId !== null ? 'none' : 'auto'}
            >
              <View style={styles.contentContainer}>
                <ListSelect selected progress={props.progress}>
                  {item?.text}
                </ListSelect>
                {blocks.map((question) => (
                  <View key={question.id}>{renderQuestion(question)}</View>
                ))}
              </View>
              <View
                style={[
                  styles.navButtonsContainer,
                  {
                    paddingBottom: insets.bottom + 16,
                    justifyContent: 'space-between'
                  }
                ]}
              >
                <Pressable onPress={handlePrevious}>
                  <Image
                    style={[
                      styles.backButton,
                      I18nManager.getConstants().isRTL && {
                        transform: [{ scaleX: -1 }]
                      }
                    ]}
                    source={require('../../../../assets/link-back.png')}
                  />
                </Pressable>
                <PillButton onPress={handleSave}>
                  <FormattedMessage
                    description="Complete"
                    defaultMessage="Complete"
                  />
                </PillButton>
              </View>
            </Pressable>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
      {inputOfQuestionId !== null && (
        <InputDrawer
          onSave={(val) => {
            const value = props.answers.find(
              (val) => val.blockId === inputOfQuestionId
            )?.value;
            props.setAnswer({
              blockId: inputOfQuestionId,
              itemId: props.itemInFocus,
              value: [...((value as string[]) ?? []), val]
            });
            showInputOfQuestionId(null);
          }}
          onCancel={() => {
            showInputOfQuestionId(null);
          }}
        />
      )}
    </>
  );
};

export default ComplexItemStep;

const styles = StyleSheet.create({
  answerContainer: {
    backgroundColor: Colors.GREY6,
    padding: 16,
    borderRadius: 4
  },
  addInputContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 10
  },
  arrowLeftContainer: {
    marginRight: 16,
    width: 28,
    height: 28
  },
  text: {
    color: Colors.BLUE
  },
  plusButton: {
    width: 30,
    height: 30,
    borderColor: Colors.GREY4,
    borderWidth: 1,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12
  },
  rootContainer: {
    flex: 1
  },
  contentContainer: { flexDirection: 'column', paddingHorizontal: 16 },
  scrollContainer: {
    paddingTop: 16,
    paddingBottom: 30
  },
  navButtonsContainer: {
    paddingHorizontal: 16,
    flex: 1,
    flexDirection: 'row'
  },
  backButton: {
    width: 32,
    height: 32
  }
});
