import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  Image,
  LayoutChangeEvent,
  Pressable,
  StyleSheet,
  View
} from 'react-native';

import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import { FAQ } from '../../../../../store/types';
interface Props extends FAQ {
  scrollTo: (offset: number) => void;
}

const FAQCard: FunctionComponent<Props> = ({ question, answer, scrollTo }) => {
  const offset = useRef(0);
  const [expanded, setExpanded] = useState(false);

  // If expanded, scroll to top of card.
  useEffect(() => {
    if (expanded) {
      scrollTo(offset.current - 24);
    }
  }, [expanded]);

  const onPress = () => {
    setExpanded(!expanded);
  };

  // Keep reference of offset for scrolling.
  const onLayout = ({ nativeEvent: { layout } }: LayoutChangeEvent) => {
    offset.current = layout.y;
  };

  const renderAnswer = () => (
    <View style={styles.answerContainer}>
      <MediumText style={[styles.text, styles.answerText]}>{answer}</MediumText>
    </View>
  );

  return (
    <Pressable style={styles.container} onPress={onPress} onLayout={onLayout}>
      <View style={styles.questionContainer}>
        <MediumText style={styles.text}>{question}</MediumText>
        <Image
          source={require('../../../../../assets/arrow_down.png')}
          style={[
            styles.icon,
            expanded && { transform: [{ rotate: '180deg' }] }
          ]}
        />
      </View>
      {expanded && renderAnswer()}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 12,
    borderRadius: 4,
    shadowColor: Colors.GREY5,
    shadowRadius: 8,
    elevation: 6,
    shadowOpacity: 1,
    backgroundColor: Colors.WHITE
  },
  questionContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 15,
    width: '100%'
  },
  text: {
    flex: 1,
    fontSize: 16,
    lineHeight: 26
  },
  icon: {
    marginLeft: 24,
    height: 24,
    width: 24
  },
  answerContainer: {
    borderTopColor: Colors.GREY5,
    borderTopWidth: 1,
    paddingHorizontal: 16,
    paddingVertical: 15
  },
  answerText: {
    color: Colors.GREY2
  }
});

export default FAQCard;
