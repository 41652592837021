import React, { FunctionComponent } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';

interface ViewPasswordToggleProps {
  onPress?: () => void;
  visible?: boolean;
}

const ViewPasswordToggle: FunctionComponent<ViewPasswordToggleProps> = ({
  onPress,
  visible = false
}) => {
  return (
    <TouchableOpacity
      onPress={() => {
        onPress?.();
      }}
    >
      <View style={styles.container}>
        <Image
          style={styles.image}
          source={
            visible
              ? require('./assets/view_strike_through.png')
              : require('./assets/view.png')
          }
        />
      </View>
    </TouchableOpacity>
  );
};

export default ViewPasswordToggle;

const styles = StyleSheet.create({
  container: {
    height: 34,
    width: 34,
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    height: 24,
    width: 24
  }
});
