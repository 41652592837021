import { useNavigation } from '@react-navigation/native';
import React, { FunctionComponent, useRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  View,
  StyleSheet,
  Pressable,
  Keyboard,
  Platform,
  TouchableOpacity
} from 'react-native';

import Colors from '../../../../colors';
import MediumText from '../../../../components/MediumText';
import SettingsMenuInput from './components/SettingsMenuInput';

interface FormValues {
  password: string;
  repeatedPassword: string;
}

const SettingsPasswordScreen: FunctionComponent = () => {
  const intl = useIntl();
  const navigation = useNavigation();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<FormValues>();
  const password = useRef({});
  const repeatedPassword = useRef({});
  password.current = watch('password', '');
  repeatedPassword.current = watch('repeatedPassword', '');

  const handleButtonPress = (data: FormValues) => {
    navigation.goBack();
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        const disableSaving = !password.current || !repeatedPassword.current;
        return (
          <View
            style={{
              width: 86,
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <TouchableOpacity
              onPress={handleSubmit(handleButtonPress)}
              disabled={!password.current || !repeatedPassword.current}
            >
              <MediumText
                style={{ color: disableSaving ? Colors.GREY4 : Colors.BLUE }}
              >
                <FormattedMessage defaultMessage="Save" description="Save" />
              </MediumText>
            </TouchableOpacity>
          </View>
        );
      }
    });
  }, [navigation, password.current, repeatedPassword.current]);

  return (
    <>
      <Pressable
        style={styles.container}
        onPress={() => (Platform.OS === 'web' ? undefined : Keyboard.dismiss())}
      >
        <View style={styles.itemsContainer}>
          <Controller
            control={control}
            name="password"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  defaultMessage: 'Please enter a password',
                  description: 'NewPasswordScreen error label no password'
                })
              },
              minLength: {
                value: 6,
                message: intl.formatMessage({
                  defaultMessage: 'Please use at least 6 characters',
                  description: 'NewPasswordScreen error label password length'
                })
              }
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <>
                <SettingsMenuInput
                  value={value}
                  onChangeText={onChange}
                  secureTextEntry
                  multiline={false}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'New password',
                    description: 'New password input placeholder'
                  })}
                  onBlur={onBlur}
                  hasValidation={errors.password?.message !== undefined}
                  errorText={errors.password ? errors.password.message : null}
                  autoFocus
                  hideBorder
                />
              </>
            )}
          />
        </View>
        <View style={styles.itemsContainer}>
          <Controller
            control={control}
            name="repeatedPassword"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  defaultMessage: 'Please confirm your password',
                  description:
                    'NewPasswordScreen error label no confirm password'
                })
              },
              validate: (val) =>
                val === password.current ||
                intl.formatMessage({
                  defaultMessage: "Passwords don't match",
                  description: 'NewPasswordScreen error label not matching'
                })
            }}
            render={({ field: { value, onChange, onBlur } }) => (
              <SettingsMenuInput
                value={value}
                onChangeText={onChange}
                secureTextEntry
                multiline={false}
                placeholder={intl.formatMessage({
                  defaultMessage: 'Confirm new password',
                  description: 'New password confirmation input placeholder'
                })}
                onBlur={onBlur}
                hasValidation={errors.repeatedPassword !== undefined}
                hideBorder
                errorText={
                  errors.repeatedPassword
                    ? errors.repeatedPassword.message
                    : null
                }
              />
            )}
          />
        </View>
      </Pressable>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND,
    paddingTop: 56
  },
  itemsContainer: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.GREY5,
    backgroundColor: Colors.WHITE,
    paddingLeft: 24,
    marginBottom: 24
  },
  title: {
    color: Colors.GREY2,
    fontSize: 14,
    paddingTop: 27,
    paddingLeft: 24,
    paddingBottom: 9
  }
});

export default SettingsPasswordScreen;
