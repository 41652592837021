import React, { FunctionComponent } from 'react';
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps
} from 'react-native';

import Colors from '../colors';
import LoadingOverlay from './LoadingOverlay';
import MediumText from './MediumText';

interface Props extends TouchableOpacityProps {
  textStyle?: StyleProp<TextStyle>;
  numberOfLines?: number;
  loading?: boolean;
}

const PillButton: FunctionComponent<Props> = ({ style, ...props }) => (
  <TouchableOpacity
    {...props}
    style={[
      styles.container,
      props.disabled && {
        backgroundColor: Colors.GREY4
      },
      style
    ]}
    disabled={props.loading || props.disabled}
  >
    {props.loading && !props.disabled && (
      <LoadingOverlay color={Colors.WHITE} />
    )}
    <MediumText
      style={[styles.text, props.textStyle]}
      numberOfLines={props.numberOfLines}
    >
      {props.children}
    </MediumText>
  </TouchableOpacity>
);

export default PillButton;

const styles = StyleSheet.create({
  container: {
    borderRadius: 40,
    backgroundColor: Colors.BLUE,
    paddingHorizontal: 20,
    paddingVertical: 3,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  text: {
    color: Colors.WHITE,
    lineHeight: 26
  }
});
