import { MaterialIcons } from '@expo/vector-icons';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Camera, CameraType, FlashMode } from 'expo-camera';
import * as ImagePicker from 'expo-image-picker';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Alert,
  BackHandler,
  ImageBackground,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Colors from '../../../../colors';
import MediumText from '../../../../components/MediumText';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import { RootStackParamList } from '../../../RootNavigator';

const CameraScreen: FunctionComponent = () => {
  const [hasCameraPermission, setHasCameraPermission] = useState<
    boolean | null
  >(null);
  const [cameraType, setCameraType] = useState(CameraType.back);
  const [flashMode, setFlashMode] = useState(FlashMode.off);
  const [cameraImage, setCameraImage] = useState<string | null>(null);
  const [pickedImage, setPickedImage] = useState<string | null>(null);
  const [photoTaken, setPhotoTaken] = useState(false);
  const aspectRatio = '4:3';
  const insets = useSafeAreaInsets();
  const camera = useRef<Camera>(null);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<DrawerStackParamList, 'Camera'>>();

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestPermissionsAsync();
      setHasCameraPermission(status === 'granted');
    })();
  }, []);

  useEffect(() => {
    const backAction = () => {
      if (cameraImage) {
        handleCancel();
        return true;
      } else {
        return false;
      }
    };
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction
    );
    return () => backHandler.remove();
  }, [cameraImage]);

  useEffect(() => {
    if (pickedImage) {
      handleFinish(pickedImage);
    }
  }, [pickedImage]);

  const handleTakePhoto = async () => {
    setPhotoTaken(true);
    const picture = await camera.current?.takePictureAsync({
      skipProcessing: true
    });
    if (picture) {
      setCameraImage(picture?.uri);
    }
  };

  const handlePickPhoto = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status === 'granted') {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: route.params.mediaTypes ?? ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        quality: 1
      });

      if (!result.canceled) {
        setPickedImage(result.assets[0]?.uri);
      }
    } else {
      if (Platform.OS === 'ios') {
        Alert.alert(
          'No Permission',
          'Please grant Photos access to pick an image from your gallery.'
        );
      }
      if (Platform.OS === 'android') {
        Alert.alert(
          'No Permission',
          'Please grant Storage access to pick an Image from your gallery.'
        );
      }
    }
  };

  const handleFinish = (uri: string) => {
    route.params.setUri(uri);
    navigation.goBack();
  };

  const handleCancel = () => {
    setCameraImage(null);
    setPhotoTaken(false);
  };

  const getAspectRatioFromString = (arString: string) => {
    const numbers = arString.split(':');
    if (numbers.length === 2) {
      const ratio = parseInt(numbers[1], 10) / parseInt(numbers[0], 10);
      return ratio ?? undefined;
    }
  };

  const renderTopButtons = () => (
    <View style={[styles.topButtons, { top: insets.top + 20 }]}>
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <MaterialIcons
          name="close"
          size={32}
          color="white"
          style={styles.iconStyle}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={handlePickPhoto}>
        <MediumText
          style={[{ fontSize: 20, color: Colors.WHITE }, styles.iconStyle]}
        >
          <FormattedMessage
            defaultMessage="Gallery"
            description="Camera screen gallery button"
          />
        </MediumText>
      </TouchableOpacity>
    </View>
  );

  const renderCameraButtons = () => (
    <View style={styles.bottomButtons}>
      <TouchableOpacity
        onPress={() => {
          setFlashMode(
            flashMode === FlashMode.off ? FlashMode.on : FlashMode.off
          );
        }}
      >
        <MaterialIcons
          name={flashMode === FlashMode.off ? 'flash-off' : 'flash-on'}
          size={32}
          color="white"
          style={styles.iconStyle}
        />
      </TouchableOpacity>
      <TouchableOpacity disabled={photoTaken} onPress={handleTakePhoto}>
        <View style={styles.shutterButton} />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          setCameraType(
            cameraType === CameraType.back ? CameraType.front : CameraType.back
          );
        }}
      >
        <MaterialIcons
          name={cameraType === CameraType.back ? 'camera-front' : 'camera-rear'}
          size={32}
          color="white"
          style={styles.iconStyle}
        />
      </TouchableOpacity>
    </View>
  );

  if (!hasCameraPermission) {
    return (
      <View style={styles.noPermissionContainer}>
        {renderTopButtons()}
        <MediumText style={{ color: Colors.GREY6 }}>
          <FormattedMessage
            defaultMessage="Please allow access to Camera to take a photo."
            description="No camera permission text"
          />
        </MediumText>
      </View>
    );
  }

  return (
    <View style={{ flex: 1, backgroundColor: 'black' }}>
      {!cameraImage ? (
        <View style={{ flex: 1 }}>
          <View style={{ aspectRatio: getAspectRatioFromString(aspectRatio) }}>
            <Camera
              ref={camera}
              style={{ flex: 1 }}
              type={cameraType}
              flashMode={flashMode}
              ratio={aspectRatio}
            />
          </View>
          {renderTopButtons()}
          <View style={styles.bottomButtonsContainer}>
            {renderCameraButtons()}
          </View>
        </View>
      ) : (
        <>
          <ImageBackground
            source={{ uri: cameraImage }}
            style={{ aspectRatio: getAspectRatioFromString(aspectRatio) }}
          />
          <View
            style={{ position: 'absolute', top: insets.top + 20, left: 20 }}
          >
            <TouchableOpacity onPress={handleCancel}>
              <MaterialIcons
                name="arrow-back"
                size={32}
                color="white"
                style={styles.iconStyle}
              />
            </TouchableOpacity>
          </View>
          <View style={styles.bottomButtonsContainer}>
            <View style={styles.bottomButtons}>
              <TouchableOpacity onPress={() => handleFinish(cameraImage)}>
                <MaterialIcons
                  name="check-circle"
                  color={Colors.BLUE}
                  size={60}
                />
              </TouchableOpacity>
            </View>
          </View>
        </>
      )}
    </View>
  );
};

export default CameraScreen;

const styles = StyleSheet.create({
  noPermissionContainer: {
    flex: 1,
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bottomButtonsContainer: {
    flex: 1,
    justifyContent: 'center'
  },
  bottomButtons: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  topButtons: {
    position: 'absolute',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20
  },
  iconStyle: {
    textShadowColor: 'black',
    textShadowRadius: 3
  },
  shutterButton: {
    height: 50,
    width: 50,
    borderWidth: 2,
    borderColor: 'white',
    borderRadius: 90
  }
});
