import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { FlatList, ListRenderItem, StyleSheet, View } from 'react-native';
import {
  SafeAreaView,
  useSafeAreaInsets
} from 'react-native-safe-area-context';

import Colors from '../../../../colors';
import FAB from '../../../../components/FAB';
import { selectWrittenExposureTemplates } from '../../../../store/blocks';
import {
  selectLifelineExercises,
  selectWrittenExposuresExercises
} from '../../../../store/exercises';
import { useAppSelector } from '../../../../store/hooks';
import { WrittenExposureExercise } from '../../../../store/types';
import { AppStackParamList } from '../../../AppNavigator';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import { RootStackParamList } from '../../../RootNavigator';
import WrittenExposureArchiveItem from './components/WrittenExposureArchiveItem';

const WrittenExposureArchiveScreen: FunctionComponent = () => {
  const { debugMode } = useAppSelector((state) => state.user);
  const writtenExposureExercises = useAppSelector(
    selectWrittenExposuresExercises
  );
  const lifelineExercises = useAppSelector(selectLifelineExercises);
  const writtenExposureTemplates = useAppSelector(
    selectWrittenExposureTemplates
  );
  const { params } =
    useRoute<RouteProp<AppStackParamList, 'WrittenExposureArchive'>>();
  const navigation =
    useNavigation<
      StackNavigationProp<RootStackParamList & DrawerStackParamList>
    >();
  const insets = useSafeAreaInsets();

  const renderItem: ListRenderItem<WrittenExposureExercise> = ({ item }) => {
    const template = writtenExposureTemplates.find(
      (template) => template.id === item.templateId
    );

    const topic = (() => {
      if (template?.skipTopicSelection) {
        return template?.defaultTopic;
      }
      return lifelineExercises.find(
        (exercise) => exercise.id === item.lifelineEventId
      )?.description;
    })();

    const onPress = () => {
      navigation.navigate('Drawer', {
        screen: 'WrittenExposureArchiveItem',
        params: {
          text: item.text,
          topic: topic ?? '',
          date: item.createdAt,
          files: item.files
        }
      });
    };

    return (
      <WrittenExposureArchiveItem
        topic={topic ?? ''}
        date={item.createdAt}
        text={item.text}
        onPress={onPress}
      />
    );
  };

  return (
    <SafeAreaView style={styles.container} edges={['left', 'right']}>
      <FlatList
        data={writtenExposureExercises}
        renderItem={renderItem}
        contentContainerStyle={{
          paddingTop: 16,
          paddingBottom: insets.bottom + 8
        }}
      />
      {debugMode && (
        <View style={[styles.fabContainer, { bottom: insets.bottom + 16 }]}>
          <FAB
            onPress={() =>
              navigation.navigate('Drawer', {
                screen: 'WrittenExposure',
                params: {
                  onFinish: () => {},
                  template: params?.template
                }
              })
            }
          />
        </View>
      )}
    </SafeAreaView>
  );
};

export default WrittenExposureArchiveScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  fabContainer: {
    position: 'absolute',
    right: 20
  }
});
