import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '.';
import env from '../env';
import {
  extractFileTypeFromMimeType,
  filterTranslations,
  nullishCoalesce,
  parseIfJson
} from '../helpers';
import {
  getAssetFromFile,
  getCognitiveTriangleTemplateFromApiObject,
  getListExerciseTemplateFromApiObject,
  getTemplateFromM2A,
  getWrittenExposureTemplateFromApiObject
} from './helpers';
import {
  Asset,
  Block,
  FlowState,
  Module,
  Session,
  Theme,
  UnlockableFeature
} from './types';

const initialState: FlowState = {
  id: null,
  name: null,
  description: null,
  started: false,
  startedAt: null,
  completed: false,
  completedAt: null,
  sessionIds: [],
  loading: false
};

export const getOnboardingFlow = createAsyncThunk<
  {
    flow: FlowState;
    sessions: Session[];
    modules: Module[];
    files: Asset[];
    themes: Theme[];
  },
  string,
  { state: RootState }
>('flow/getOnboardingFlow', async (languageCode, { getState }) => {
  const studyId = getState().user.studies[0]?.id;

  const response = await fetch(
    `${env.API_BASE_URL}/flows/onboarding/${studyId ?? ''}`,
    {
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      }
    }
  );

  const data = extractFileTypeFromMimeType(
    filterTranslations(await response.json(), languageCode)
  );

  if (!response.ok) {
    throw new Error(
      data.code && data.message
        ? `${data.code}: ${data.message}`
        : `${response.status} ${response.statusText}`
    );
  }

  const flowId = null;

  const flow = {
    ...getState().flow,
    id: flowId,
    name: data.name,
    description: data.description,
    sessionIds: data.sessions.map((session: any) => `${session.id}`)
  };

  let apiSessions: { [id: number]: Partial<Session> } = {};

  if (getState().user.userId) {
    const apiSessionsRes = await fetch(
      `${env.API_BASE_URL}/users/${getState().user.userId}/sessions`,
      {
        headers: {
          Authorization: `Bearer ${getState().user.token}`
        }
      }
    );

    const apiSessionsData = (await apiSessionsRes.json()).items;

    apiSessions = apiSessionsData.reduce(
      (
        res: typeof apiSessions,
        session: Partial<Session> & { prototypicalSession: number }
      ) => {
        return {
          ...res,
          [session.prototypicalSession]: session
        };
      },
      apiSessions
    );
  }

  const sessions = data.sessions.map((session: any) => {
    const apiSession = apiSessions[session.id];
    const sessionState = getState().sessions.entities[session.id];

    return {
      id: `${session.id}`,
      sessionId: session.id,
      flowId,
      title:
        session.translations[0]?.title ?? session.translations[0]?.placeholder,
      file: {
        id: session.file?.id,
        type: session.file?.type,
        url: session.file?.id
          ? `${env.CMS_BASE_URL}/assets/${session.file?.id}`
          : null
      },
      theme: {
        primary: session.theme?.primary_color,
        secondary: session.theme?.secondary_color
      },
      moduleIds: session.modules.map(
        (module: any) => `${session.id}-${module.id}`
      ),
      unlocked: nullishCoalesce(
        apiSession?.unlocked,
        sessionState?.unlocked,
        false
      ),
      started: nullishCoalesce(
        apiSession?.started,
        sessionState?.started,
        false
      ),
      completed: nullishCoalesce(
        apiSession?.completed,
        sessionState?.completed,
        false
      ),
      unlockedAt: nullishCoalesce(
        apiSession?.unlockedAt,
        sessionState?.unlockedAt,
        null
      ),
      startedAt: nullishCoalesce(
        apiSession?.startedAt,
        sessionState?.startedAt,
        null
      ),
      completedAt: nullishCoalesce(
        apiSession?.completedAt,
        sessionState?.completedAt,
        null
      ),
      wait: session.wait,
      waitFrom: session.wait_from
    };
  });

  let apiModules: { [id: number]: { [id: number]: Partial<Module> } } = {};

  if (getState().user.userId) {
    const apiModulesRes = await Promise.all(
      data.sessions
        .filter((session: any) => {
          return apiSessions[session.id]?.unlocked;
        })
        .map((session: any) => {
          return fetch(
            `${env.API_BASE_URL}/users/${getState().user.userId}/sessions/${
              session.id
            }/modules`,
            {
              headers: {
                Authorization: `Bearer ${getState().user.token}`
              }
            }
          ).catch(() => null);
        })
    );

    const apiModulesData = await Promise.all(
      apiModulesRes.map((res: any) => res.json())
    );

    apiModules = data.sessions
      .filter((session: any) => {
        return apiSessions[session.id]?.unlocked;
      })
      .reduce((res: any, session: any, idx: number) => {
        return {
          ...res,
          [session.id]: (apiModulesData[idx]?.items ?? []).reduce(
            (
              modulesRes: any,
              module: Partial<Module> & { prototypicalModule: number }
            ) => {
              return {
                ...modulesRes,
                [module.prototypicalModule]: module
              };
            },
            {}
          )
        };
      }, apiModules);
  }

  const modules: Module[] = data.sessions.reduce(
    (modulesAcc: Module[], session: any) => {
      return [
        ...modulesAcc,
        ...session.modules.map((module: any) => {
          const entityId = `${session.id}-${module.id}`;
          const moduleState = getState().modules.entities[entityId];

          const apiModule = apiModules[session.id]
            ? apiModules[session.id][module.id]
            : null;

          return {
            id: entityId,
            moduleId: module.id,
            sessionId: session.id,
            flowId: getState().flow.id,
            file: {
              type: module.file?.type,
              url: module.file?.id
                ? `${env.CMS_BASE_URL}/assets/${module.file.id}`
                : '',
              localUrl: null
            } as Asset,
            narrator: module.narrator
              ? {
                  id: module.narrator.id,
                  name: module.narrator.name,
                  file: {
                    id: module.narrator.file?.id,
                    type: module.narrator.file?.type,
                    url: module.narrator.file?.id
                      ? `${env.CMS_BASE_URL}/assets/${module.narrator.file?.id}`
                      : null
                  }
                }
              : null,
            blockIds: module.blocks.map(
              (block: any) =>
                `${session.id}-${module.id}-${block.item}-${block.collection}`
            ),
            started: nullishCoalesce(
              apiModule?.started,
              moduleState?.started,
              false
            ),
            completed: nullishCoalesce(
              apiModule?.completed,
              moduleState?.completed,
              false
            ),
            startedAt: nullishCoalesce(
              apiModule?.startedAt,
              moduleState?.startedAt,
              null
            ),
            completedAt: nullishCoalesce(
              apiModule?.completedAt,
              moduleState?.completedAt,
              null
            )
          };
        })
      ];
    },
    [] as Module[]
  );

  const files = data.sessions.map((session: any) => {
    return session.file;
  });
  const themes = data.sessions.map((session: any) => {
    return session.theme;
  });

  return {
    flow,
    sessions,
    modules,
    files,
    themes
  };
});

export const getUserFlow = createAsyncThunk<
  {
    flow: FlowState;
    sessions: Session[];
    modules: Module[];
    blocks: Block[];
  },
  string,
  { state: RootState }
>('flow/getUserFlow', async (languageCode, { getState }) => {
  const res = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/flow`,
    {
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      }
    }
  );

  const flow = await res.json();

  const cmsSessionsRes = await fetch(
    `${env.API_BASE_URL}/sessions?ids[]=${flow.sessions.map(
      (s: Partial<Session> & { prototypicalSession: number }) =>
        s.prototypicalSession
    )}`,
    {
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      }
    }
  );

  const cmsSessionsData = extractFileTypeFromMimeType(
    filterTranslations(await cmsSessionsRes.json(), languageCode)
  );

  const cmsSessionsById = cmsSessionsData.items.reduce(
    (res: { [id: string]: Session }, curr: Session) => {
      return {
        ...res,
        [curr.id]: curr
      };
    },
    {}
  );

  const sessions = flow.sessions.map(
    (
      apiSession: Partial<Session> & {
        prototypicalSession: number;
        modules: (Partial<Module> & { prototypicalModule: number })[];
      }
    ) => {
      const cmsSession = cmsSessionsById[apiSession.prototypicalSession];
      const sessionState = getState().sessions.entities[cmsSession.id];

      return {
        id: `${cmsSession.id}`,
        sessionId: cmsSession.id,
        flowId: flow.id,
        title:
          cmsSession.translations[0]?.title ??
          cmsSession.translations[0]?.placeholder,
        file: {
          id: cmsSession.file?.id,
          type: cmsSession.file?.type,
          url: cmsSession.file?.id
            ? `${env.CMS_BASE_URL}/assets/${cmsSession.file?.id}`
            : null
        },
        theme: {
          primary: cmsSession.theme?.primary_color,
          secondary: cmsSession.theme?.secondary_color
        },
        moduleIds: apiSession.modules.map(
          (module: any) => `${cmsSession.id}-${module.prototypicalModule}`
        ),
        unlocked: nullishCoalesce(
          apiSession?.unlocked,
          sessionState?.unlocked,
          false
        ),
        started: nullishCoalesce(
          apiSession?.started,
          sessionState?.started,
          false
        ),
        completed: nullishCoalesce(
          apiSession?.completed,
          sessionState?.completed,
          false
        ),
        unlockedAt: nullishCoalesce(
          apiSession?.unlockedAt,
          sessionState?.unlockedAt,
          null
        ),
        startedAt: nullishCoalesce(
          apiSession?.startedAt,
          sessionState?.startedAt,
          null
        ),
        completedAt: nullishCoalesce(
          apiSession?.completedAt,
          sessionState?.completedAt,
          null
        ),
        wait: cmsSession.wait,
        waitFrom: cmsSession.wait_from
      };
    }
  );

  const cmsModulesRes = await fetch(
    `${env.API_BASE_URL}/modules?ids[]=${flow.sessions
      .reduce((res: any, s: any) => [...res, ...s.modules], [])
      .map((m: any) => m.prototypicalModule)}`,
    {
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      }
    }
  );

  const cmsModulesData = extractFileTypeFromMimeType(
    filterTranslations(await cmsModulesRes.json(), languageCode)
  );

  const cmsModulesById = cmsModulesData.items.reduce(
    (res: { [id: string]: Module }, curr: Module) => {
      return {
        ...res,
        [curr.id]: curr
      };
    },
    {}
  );

  const modules = flow.sessions.reduce(
    (
      modulesAcc: Module[],
      apiSession: Partial<Session> & {
        prototypicalSession: number;
        modules: (Partial<Module> & {
          prototypicalModule: number;
          blocks: (Partial<Block> & { prototypicalBlock: number })[];
        })[];
      }
    ) => {
      const cmsSession = cmsSessionsById[apiSession.prototypicalSession];
      return [
        ...modulesAcc,
        ...apiSession.modules.map((apiModule) => {
          const cmsModule = cmsModulesById[apiModule.prototypicalModule];

          const entityId = `${cmsSession.id}-${cmsModule.id}`;
          const moduleState = getState().modules.entities[entityId];

          return {
            id: entityId,
            moduleId: cmsModule.id,
            sessionId: cmsSession.id,
            flowId: getState().flow.id,
            file: {
              type: cmsModule.file?.type,
              url: cmsModule.file?.id
                ? `${env.CMS_BASE_URL}/assets/${cmsModule.file.id}`
                : '',
              localUrl: null
            } as Asset,
            narrator: cmsModule.narrator
              ? {
                  id: cmsModule.narrator.id,
                  name: cmsModule.narrator.name,
                  file: {
                    id: cmsModule.narrator.file?.id,
                    type: cmsModule.narrator.file?.type,
                    url: cmsModule.narrator.file?.id
                      ? `${env.CMS_BASE_URL}/assets/${cmsModule.narrator.file?.id}`
                      : null
                  }
                }
              : null,
            blockIds: apiModule.blocks.map(
              (block: any) =>
                `${cmsSession.id}-${cmsModule.id}-${block.prototypicalBlock}-${block.collection}`
            ),
            started: nullishCoalesce(
              apiModule?.started,
              moduleState?.started,
              false
            ),
            completed: nullishCoalesce(
              apiModule?.completed,
              moduleState?.completed,
              false
            ),
            startedAt: nullishCoalesce(
              apiModule?.startedAt,
              moduleState?.startedAt,
              null
            ),
            completedAt: nullishCoalesce(
              apiModule?.completedAt,
              moduleState?.completedAt,
              null
            )
          };
        })
      ];
    },
    [] as Module[]
  );

  const cmsQuestionBlocksRes = await fetch(
    `${env.API_BASE_URL}/blocks/question_blocks?ids[]=${flow.sessions.reduce(
      (res: any, s: any) => [
        ...res,
        s.modules.reduce((modRes: any, m: any) => {
          const qblocks = m.blocks.filter(
            (b: any) => b.collection === 'question_blocks'
          );
          return [...modRes, qblocks.map((b: any) => b.prototypicalBlock)];
        }, [])
      ],
      []
    )}`,
    {
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      }
    }
  );

  const cmsNarrativeBlocksRes = await fetch(
    `${env.API_BASE_URL}/blocks/narrative_blocks?ids[]=${flow.sessions.reduce(
      (res: any, s: any) => [
        ...res,
        s.modules.reduce((modRes: any, m: any) => {
          const qblocks = m.blocks.filter(
            (b: any) => b.collection === 'narrative_blocks'
          );
          return [...modRes, qblocks.map((b: any) => b.prototypicalBlock)];
        }, [])
      ],
      []
    )}`,
    {
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      }
    }
  );

  const cmsExerciseBlocksRes = await fetch(
    `${env.API_BASE_URL}/blocks/exercise_blocks?ids[]=${flow.sessions.reduce(
      (res: any, s: any) => [
        ...res,
        s.modules.reduce((modRes: any, m: any) => {
          const qblocks = m.blocks.filter(
            (b: any) => b.collection === 'exercise_blocks'
          );
          return [...modRes, qblocks.map((b: any) => b.prototypicalBlock)];
        }, [])
      ],
      []
    )}`,
    {
      headers: {
        Authorization: `Bearer ${getState().user.token}`
      }
    }
  );

  const cmsQuestionsBlocksData = extractFileTypeFromMimeType(
    filterTranslations(await cmsQuestionBlocksRes.json(), languageCode)
  );
  const cmsNarrativesBlocksData = extractFileTypeFromMimeType(
    filterTranslations(await cmsNarrativeBlocksRes.json(), languageCode)
  );
  const cmsExercisesBlocksData = extractFileTypeFromMimeType(
    filterTranslations(await cmsExerciseBlocksRes.json(), languageCode)
  );

  const cmsBlocksByIdCollection = [
    ...cmsQuestionsBlocksData.items.map((b: any) => ({
      ...b,
      collection: 'question_blocks'
    })),
    ...cmsNarrativesBlocksData.items.map((b: any) => ({
      ...b,
      collection: 'narrative_blocks'
    })),
    ...cmsExercisesBlocksData.items.map((b: any) => ({
      ...b,
      collection: 'exercise_blocks'
    }))
  ].reduce((res: { [id: string]: Block }, curr: Block) => {
    return {
      ...res,
      [`${curr.id}-${curr.collection}`]: curr
    };
  }, {});

  const blocks = flow.sessions.reduce(
    (
      modulesAcc: Module[],
      apiSession: Partial<Session> & {
        prototypicalSession: number;
        modules: any[];
      }
    ) => {
      const cmsSession = cmsSessionsById[apiSession.prototypicalSession];

      return [
        ...modulesAcc,
        ...apiSession.modules.reduce(
          (
            blocksAcc,
            apiModule: Partial<Module> & {
              prototypicalModule: number;
              blocks: (Partial<Block> & {
                prototypicalBlock: number;
                answer: string;
              })[];
            }
          ) => {
            const cmsModule = cmsModulesById[apiModule.prototypicalModule];

            const getExerciseInstructions = (
              apiBlock: Partial<Block> & {
                exerciseInstructionSet: { instructions: string } | null;
              },
              templateInstructions: any
            ) => {
              return apiBlock.exerciseInstructionSet
                ? JSON.parse(apiBlock.exerciseInstructionSet.instructions)
                : templateInstructions;
            };

            const getExerciseBlockProps = (
              cmsBlock: any,
              apiBlock: Partial<Block> & {
                exerciseInstructionSet: { instructions: string } | null;
              }
            ) => {
              const ctTemplate = cmsBlock.cognitive_triangle_template;
              const ctInstructions = getExerciseInstructions(
                apiBlock,
                ctTemplate?.translations[0]
              );
              const cabTemplate = cmsBlock.concerns_and_barriers_template;
              const cabInstructions = getExerciseInstructions(
                apiBlock,
                cabTemplate?.translations[0]
              );
              const spTemplate = cmsBlock.safety_plan_list_item;
              const spInstructions = getExerciseInstructions(
                apiBlock,
                spTemplate?.translations[0]
              );
              const weTemplate = cmsBlock.written_exposure_template;
              const weInstructions = getExerciseInstructions(
                apiBlock,
                weTemplate?.translations[0]
              );
              const audioExercise = cmsBlock.audio_exercise;
              const listTemplate = cmsBlock.list_exercise_template;
              const listInstructions = getExerciseInstructions(
                apiBlock,
                listTemplate?.translations[0]
              );
              return {
                exercise: cmsBlock.exercise,
                cognitiveTriangleTemplate:
                  ctTemplate &&
                  getCognitiveTriangleTemplateFromApiObject(
                    ctTemplate,
                    ctInstructions
                  ),
                concernsAndBarriersTemplate: cabTemplate && {
                  instruction: cabInstructions?.instruction,
                  userInput: cabTemplate.user_input,
                  items: cabTemplate.items.map((item: any) => {
                    const tr =
                      item.concerns_and_barriers_items_id.translations[0];
                    return {
                      problem: nullishCoalesce(tr?.problem, tr?.placeholder),
                      response: nullishCoalesce(tr?.response, tr?.placeholder)
                    };
                  })
                },
                safetyPlanListInputTemplate: spTemplate && {
                  cmsName: spTemplate.name,
                  title: nullishCoalesce(
                    spTemplate.translations[0]?.title,
                    spTemplate.translations[0]?.placeholder
                  ),
                  instruction: nullishCoalesce(
                    spInstructions?.instruction,
                    spTemplate.translations[0]?.placeholder
                  )
                },
                audioExercise: audioExercise && {
                  title: audioExercise.translations[0]?.title,
                  audioName: audioExercise.translations[0]?.audio_name,
                  file: audioExercise.translations[0]?.file
                    ? {
                        id: audioExercise.translations[0]?.file?.id,
                        type: audioExercise.translations[0]?.file?.type,
                        url: audioExercise.translations[0]?.file?.id
                          ? `${env.CMS_BASE_URL}/assets/${audioExercise.translations[0]?.file?.id}`
                          : null
                      }
                    : null
                },
                writtenExposureTemplate:
                  weTemplate &&
                  getWrittenExposureTemplateFromApiObject(
                    weTemplate,
                    weInstructions
                  ),
                listExerciseTemplate:
                  listTemplate &&
                  getListExerciseTemplateFromApiObject(
                    listTemplate,
                    listInstructions
                  )
              };
            };

            const getQuestionBlockProps = (cmsBlock: any) => ({
              skippable: cmsBlock.skippable,
              type: cmsBlock.type,
              options: cmsBlock.options.map((opt: any) => ({
                ...opt.answer_options_id,
                text:
                  opt.answer_options_id.translations[0]?.text ??
                  opt.answer_options_id.translations[0]?.placeholder
              })),
              inputType: cmsBlock.input_type,
              min: cmsBlock.min,
              max: cmsBlock.max,
              interval: cmsBlock.interval,
              exclusionCheck: cmsBlock.exclusion_check,
              userConfig: cmsBlock.user_config
            });

            const getNarrativeBlockProps = (cmsBlock: any) => ({
              messengerLink: cmsBlock.messenger_link,
              buttonText:
                cmsBlock.translations[0]?.button_text ??
                cmsBlock.translations[0]?.placeholder,
              buttonAudio: {
                id: cmsBlock.translations[0]?.button_audio?.id,
                type: cmsBlock.translations[0]?.button_audio?.type,
                url: cmsBlock.translations[0]?.button_audio?.id
                  ? `${env.CMS_BASE_URL}/assets/${cmsBlock.translations[0]?.button_audio?.id}`
                  : null
              }
            });

            const getCollectionExtraProps = (
              block: any,
              apiBlock: any,
              blockState?: Block
            ) => {
              switch (block.collection) {
                case 'exercise_blocks':
                  return getExerciseBlockProps(block, apiBlock);
                case 'question_blocks':
                  return getQuestionBlockProps(block);
                case 'narrative_blocks':
                  return getNarrativeBlockProps(block);
                default:
                  return {};
              }
            };

            return [
              ...blocksAcc,
              ...apiModule.blocks.map((apiBlock) => {
                const cmsBlock =
                  cmsBlocksByIdCollection[
                    `${apiBlock.prototypicalBlock}-${apiBlock.collection}`
                  ];
                const entityId = `${cmsSession.id}-${cmsModule.id}-${cmsBlock.id}-${cmsBlock.collection}`;
                const blockState = getState().blocks.entities[entityId];
                const narrator = cmsBlock.narrator ?? cmsModule.narrator;

                const extraProps = getCollectionExtraProps(
                  cmsBlock,
                  apiBlock,
                  blockState
                );

                return {
                  id: entityId,
                  unlockFeatures: cmsBlock.unlock_features
                    .map((feature: any) => feature.unlockable_features_id)
                    .map(
                      (feature: any) =>
                        ({
                          id: feature.id,
                          unlockedFeature: feature.unlocked_feature,
                          file: getAssetFromFile(feature.file),
                          template: getTemplateFromM2A(feature.template)
                        } as UnlockableFeature)
                    ),
                  pause: nullishCoalesce(
                    apiBlock?.pause,
                    blockState?.pause,
                    false
                  ),
                  blockId: cmsBlock.id,
                  moduleId: cmsModule.id,
                  sessionId: cmsSession.id,
                  flowId: getState().flow.id,
                  collection: cmsBlock.collection,
                  narrator: narrator
                    ? {
                        id: narrator.id,
                        file: {
                          id: narrator.file?.id,
                          type: narrator.file?.type,
                          url: narrator.file?.id
                            ? `${env.CMS_BASE_URL}/assets/${narrator.file?.id}`
                            : null
                        }
                      }
                    : null,
                  typing: cmsBlock.typing,
                  file: cmsBlock.translations[0]?.file
                    ? {
                        id: cmsBlock.translations[0]?.file?.id,
                        type: cmsBlock.translations[0]?.file?.type,
                        url: cmsBlock.translations[0]?.file?.id
                          ? `${env.CMS_BASE_URL}/assets/${cmsBlock.translations[0]?.file?.id}`
                          : null
                      }
                    : null,
                  text:
                    cmsBlock.translations[0]?.text ??
                    cmsBlock.translations[0]?.placeholder,
                  condition: [
                    (cmsBlock.condition_questions as any[]).reduce(
                      (prev, questionBlock: any) => {
                        const questionBlockId =
                          questionBlock.related_question_blocks_id ??
                          questionBlock.question_blocks_id;
                        const conditionBlockEntityId = `${cmsSession.id}-${
                          cmsModule.id
                        }-${questionBlockId}-${'question_blocks'}`;
                        prev.push(conditionBlockEntityId);
                        return prev;
                      },
                      [] as any[]
                    ),
                    cmsBlock.condition_operator,
                    cmsBlock.condition_value
                  ],
                  ...extraProps,
                  completed: nullishCoalesce(
                    apiBlock?.completed,
                    blockState?.completed,
                    false
                  ),
                  completedAt: nullishCoalesce(
                    apiBlock?.completedAt,
                    blockState?.completedAt,
                    null
                  ),
                  answer: nullishCoalesce(
                    apiBlock?.answer
                      ? parseIfJson(apiBlock?.answer)
                      : blockState?.answer,
                    ''
                  )
                };
              })
            ];
          },
          []
        )
      ];
    },
    []
  );

  return {
    flow,
    sessions,
    modules,
    blocks
  };
});

export const updateFlow = createAsyncThunk<
  Partial<FlowState>,
  Partial<FlowState>,
  { state: RootState }
>('flow/updateFlow', async (changes, { getState }) => {
  const apiRes = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/flow`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(changes)
    }
  );

  const apiData = await apiRes.json();

  return {
    ...apiData,
    id: apiData.prototypicalFlow
  };
});

export const flowSlice = createSlice({
  name: 'flow',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOnboardingFlow.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getOnboardingFlow.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getOnboardingFlow.fulfilled, (state, action) => {
      state.loading = false;
      state.id = action.payload.flow.id;
      state.name = action.payload.flow.name;
      state.description = action.payload.flow.description;
      state.sessionIds = action.payload.flow.sessionIds;
      state.started = action.payload.flow.started;
      state.startedAt = action.payload.flow.startedAt;
      state.completed = action.payload.flow.completed;
      state.completedAt = action.payload.flow.completedAt;
    });
    builder.addCase(getUserFlow.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUserFlow.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUserFlow.fulfilled, (state, action) => {
      state.loading = false;
      state.id = action.payload.flow.id;
      state.name = action.payload.flow.name;
      state.description = action.payload.flow.description;
      state.sessionIds = action.payload.flow.sessionIds;
      state.started = action.payload.flow.started;
      state.startedAt = action.payload.flow.startedAt;
      state.completed = action.payload.flow.completed;
      state.completedAt = action.payload.flow.completedAt;
    });
    builder.addCase(updateFlow.fulfilled, (state, action) => {
      state.id = action.payload.id as FlowState['id'];
      state.started = action.payload.started as FlowState['started'];
      state.startedAt = action.payload.startedAt as FlowState['startedAt'];
      state.completed = action.payload.completed as FlowState['completed'];
      state.completedAt = action.payload
        .completedAt as FlowState['completedAt'];
    });
  }
});

export default flowSlice.reducer;
