import { Asset } from './store/types';

export const emailValidationRegex = new RegExp(
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
);

export const parseValue: (n: any) => any = (n) => {
  const parsed = typeof n === 'string' ? parseInt(n, 10) : NaN;
  return isNaN(parsed) ? n : parsed;
};

export const sortFields: (data: any) => any = (data) => {
  if (!data || typeof data !== 'object') {
    return data;
  }

  if (Array.isArray(data)) {
    let result = [...data];
    if (data.every((val) => typeof val.sort === 'number')) {
      result = result.sort((a: any, b: any) => {
        return a.sort - b.sort;
      });
    }
    return result.map((item) => sortFields(item));
  }

  return Object.keys(data).reduce((sortedData: any, key: string) => {
    return {
      ...sortedData,
      [key]: sortFields(data[key])
    };
  }, {});
};

export const filterTranslations = (data: any, languageCode: string): any => {
  if (!data || typeof data !== 'object') {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((item) => filterTranslations(item, languageCode));
  }

  return Object.keys(data).reduce((filteredData: any, key: string) => {
    const transformTranslations = (trArr: any[], name: string) => {
      const placeholder = `MISSING TRANSLATION: ${name}`;
      const filtered = trArr.filter(
        (tr: any) => tr.languages_code === languageCode
      );
      if (filtered.length === 0) {
        return [{ placeholder }];
      }
      filtered[0].placeholder = placeholder;
      return filtered;
    };
    return {
      ...filteredData,
      [key]:
        key === 'translations'
          ? transformTranslations(data[key], data.name)
          : filterTranslations(data[key], languageCode)
    };
  }, {});
};

export const extractFileTypeFromMimeType = (data: any): any => {
  if (!data || typeof data !== 'object') {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((item) => extractFileTypeFromMimeType(item));
  }

  return Object.keys(data).reduce((prev: any, key: string) => {
    return {
      ...prev,
      [key]:
        key === 'file'
          ? data[key]?.type && {
              ...data[key],
              type: data[key].type.split('/')[0]
            }
          : extractFileTypeFromMimeType(data[key])
    };
  }, {});
};

export const getExerciseFromApiData = (apiData: {
  id: string;
  type: string;
  createdAt: string;
  files: Asset[];
  data: string;
}) => {
  const { id, type, createdAt, files, data } = apiData;
  const exerciseData = JSON.parse(data);
  const baseExercise = {
    id,
    createdAt,
    type,
    files: files?.map((file: Asset) => file.url) ?? []
  };

  switch (apiData.type) {
    case 'cognitive-triangle':
      return {
        ...baseExercise,
        situation: exerciseData?.situation ?? '',
        helpful: {
          thought: exerciseData?.helpful?.thought ?? '',
          feelings: exerciseData?.helpful?.feelings ?? [],
          behavior: exerciseData?.helpful?.behavior ?? ''
        },
        unhelpful: {
          thought: exerciseData?.unhelpful?.thought ?? '',
          feelings: exerciseData?.unhelpful?.feelings ?? [],
          behavior: exerciseData?.unhelpful?.behavior ?? ''
        }
      };
    default:
      return {
        ...baseExercise,
        ...JSON.parse(apiData.data)
      };
  }
};

/**
 * Nullish coalescing as a function to circumvent complexity errors
 * due to coalescing statements
 * @param ...values a list of values to be checked for nullish
 * @returns The first values of values which is not null
 */
export const nullishCoalesce = (...values: any[]): any => {
  const value = values[0] ?? values[1];
  if (values.length === 2 || (value !== null && value !== undefined)) {
    return value;
  }
  return nullishCoalesce(...values.slice(1));
};

export const secondsToMMSS: (sec: number) => string = (sec) => {
  if (sec <= 0) {
    return '00:00';
  }
  const sec_num = Math.floor(sec);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - hours * 3600) / 60);
  const seconds = sec_num - hours * 3600 - minutes * 60;
  let minutesString = minutes.toString();
  let secondsString = seconds.toString();
  if (minutes < 10) {
    minutesString = '0' + minutes;
  }
  if (seconds < 10) {
    secondsString = '0' + seconds;
  }
  return minutesString + ':' + secondsString;
};

export const parseIfJson = (s: any) => {
  // https://stackoverflow.com/a/20392392
  const isJSON = (s: any) => {
    try {
      const o = JSON.parse(s);
      if (o && typeof o === 'object') {
        return true;
      }
    } catch (e) {}
    return false;
  };
  return isJSON(s) ? JSON.parse(s) : s;
};
