import { ResizeMode, Video, VideoReadyForDisplayEvent } from 'expo-av';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ColorValue,
  Image,
  ImageRequireSource,
  Platform,
  Pressable,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import RenderHTML, { defaultSystemFonts } from 'react-native-render-html';

import Colors from '../colors';
import useAppWindowDimensions from '../hooks/useAppWindowDimensions';
import useSound, { StateType } from '../hooks/useSound';
import { Asset } from '../store/types';
import AudioBar from './AudioBar';
import MediumText from './MediumText';
import TimeLabel from './TimeLabel';

type MessengerLink = () => void;
interface MessageProps {
  file?: Asset & { source?: ImageRequireSource };
  mediaOrientation?: 'portrait' | 'landscape';
  text?: string | React.ReactNode;
  flipped?: boolean;
  component?: React.ReactNode;
  style?: ViewStyle | ViewStyle[];
  containerStyle?: ViewStyle | ViewStyle[];
  textStyle?: TextStyle | TextStyle[];
  messengerLink?: MessengerLink;
  audioBarTintColor?: ColorValue;
  wysiwyg?: boolean;
}

const Message: FunctionComponent<MessageProps> = ({
  mediaOrientation = 'landscape',
  ...props
}) => {
  const video = useRef<Video>(null);
  const sound = useSound(props?.file?.url);
  const aspectRatio = mediaOrientation === 'landscape' ? 4 / 3 : 3 / 4;
  const [imageHeight, setImageHeight] = useState(0);
  const { width: appWidth } = useAppWindowDimensions();

  // get correct aspect ratio of video to fit it into Message
  const handleVideoReadyForDisplay = (event: VideoReadyForDisplayEvent) => {
    video.current?.playAsync();
  };

  const renderMessageWithVideo = () => (
    <>
      {props.file?.type.includes('video') &&
        typeof props.file?.url === 'string' && (
          <Video
            ref={video}
            source={{ uri: props.file?.url }}
            style={[
              { aspectRatio },
              Platform.OS === 'web' && { maxHeight: 400 }
            ]}
            resizeMode={'cover' as ResizeMode}
            useNativeControls
            isLooping
            isMuted
            focusable
            onReadyForDisplay={(event) => {
              handleVideoReadyForDisplay(event);
            }}
          />
        )}
    </>
  );

  const renderMessageWithImage = () => (
    <Image
      source={
        props.file?.url
          ? { uri: props.file?.url as string }
          : (props.file?.source as ImageRequireSource)
      }
      style={[
        styles.image,
        { height: imageHeight },
        Platform.OS === 'web' && { maxHeight: 400 }
      ]}
      resizeMode="cover"
    />
  );

  const renderMessageWithAudio = () => {
    if (getType() === 'audio') {
      const { duration, progress } = sound;
      const isActive = sound.state !== StateType.Stopped;
      const timeValue = isActive ? progress : duration;
      return (
        <TouchableOpacity onPress={sound?.playPause} style={styles.audioPlayer}>
          <Image
            source={
              sound.state === StateType.Playing
                ? require('../assets/pause.png')
                : require('../assets/play.png')
            }
            style={[styles.audioIcon, { tintColor: props.audioBarTintColor }]}
          />
          <AudioBar
            progress={(progress / duration) * 100}
            style={styles.audioBar}
            active={isActive}
            tintColor={props.audioBarTintColor}
          />
          <TimeLabel
            value={timeValue}
            style={{ color: props.audioBarTintColor }}
          />
        </TouchableOpacity>
      );
    }
    return null;
  };

  const getType = (): 'image' | 'audio' | 'video' | 'text' => {
    if (props.file?.type && (!!props.file?.source || !!props.file?.url)) {
      return props.file.type;
    } else {
      return 'text';
    }
  };

  const getStyle = () => {
    if (getType() === 'video') {
      return styles.videoMessageContainer;
    } else if (getType() === 'image') {
      return styles.imageMessageContainer;
    } else {
      return styles.textMessageContainer;
    }
  };

  const measureImageHeight = useCallback(
    (width: number) => {
      setImageHeight(width / aspectRatio);
    },
    [imageHeight]
  );

  const getFlippedStyle = () => {
    if (props.flipped) return styles.containerFlipped;
  };

  const renderMessengerLink = () => {
    return (
      <Pressable
        style={styles.messengerLinkPressable}
        onPress={() => props.messengerLink?.()}
      >
        <View style={styles.messengerLinkContainer}>
          <Image
            source={require('../assets/chatBubble.png')}
            style={styles.iconStyle}
          />
          <MediumText style={styles.messengerLinkText}>
            <FormattedMessage
              defaultMessage="Click here to go to messenger"
              description="MessengerLink button label"
            />
          </MediumText>
        </View>
      </Pressable>
    );
  };

  const calculateWysiwygWidth = () => {
    const wysiwygPaddingHorizontal =
      styles.wysiwygContainer.paddingHorizontal * 2;
    return appWidth - wysiwygPaddingHorizontal;
  };

  const renderMessageText = () => {
    return props.wysiwyg ? (
      <View style={styles.wysiwygContainer}>
        <RenderHTML
          contentWidth={calculateWysiwygWidth()}
          source={{ html: `${props.text}` }}
          systemFonts={[...defaultSystemFonts, 'NotoSans-Medium']}
          baseStyle={{
            fontFamily: 'NotoSans-Medium',
            fontSize: 16,
            lineHeight: 26,
            color: Colors.GREY1
          }}
          tagsStyles={{ p: { marginVertical: 0 } }}
        />
      </View>
    ) : (
      <MediumText style={[styles.text, props.textStyle]}>
        {props.text}
      </MediumText>
    );
  };

  return (
    <View
      style={[
        getType() === 'text' && { alignSelf: 'flex-start' },
        getFlippedStyle(),
        props.containerStyle
      ]}
      onLayout={
        getType() === 'image'
          ? (event) => measureImageHeight(event.nativeEvent.layout.width)
          : undefined
      }
    >
      {props.messengerLink && renderMessengerLink()}
      <View
        style={[
          styles.container,
          getStyle(),
          props.style,
          getFlippedStyle(),
          styles.shadowContainer
        ]}
      >
        {getType() === 'video' && renderMessageWithVideo()}
        {getType() === 'image' && renderMessageWithImage()}
        {getType() === 'audio' && renderMessageWithAudio()}
        {props.text && renderMessageText()}
        {props.component}
      </View>
    </View>
  );
};
//
export default Message;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.GREY5,
    borderRadius: 16,
    borderBottomLeftRadius: 4,
    elevation: 3,
    maxWidth: 900
  },
  containerFlipped: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 4
  },
  shadowContainer: {
    shadowRadius: 1,
    shadowColor: Colors.SHADOW,
    shadowOpacity: 1,
    shadowOffset: { height: 0, width: 0 }
  },
  imageMessageContainer: {
    alignSelf: 'stretch',
    overflow: 'hidden'
  },
  audioPlayer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8
  },
  audioIcon: {
    height: 18,
    width: 18
  },
  audioBar: {
    marginHorizontal: 16
  },
  videoMessageContainer: {
    alignSelf: 'stretch',
    overflow: 'hidden'
  },
  textMessageContainer: {
    alignSelf: 'flex-start'
  },
  image: {
    width: '100%'
  },
  text: {
    marginHorizontal: 16,
    marginVertical: 13,
    lineHeight: 26
  },
  messengerLinkPressable: {
    top: 16,
    right: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 56,
    backgroundColor: Colors.SUPERLIGHTVIOLET,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    shadowRadius: 1,
    shadowColor: 'rgba(54, 70, 93, 0.4)',
    minWidth: 284,
    position: 'relative'
  },
  messengerLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 16,
    marginRight: 12,
    marginLeft: 12
  },
  messengerLinkText: {
    color: Colors.BLUE,
    marginLeft: 8
  },
  iconStyle: {
    height: 24,
    width: 24
  },
  wysiwygContainer: {
    paddingHorizontal: 16,
    paddingVertical: 12
  }
});
