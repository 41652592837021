import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useState, useRef, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Image,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import Colors from '../../../../colors';
import Drawer from '../../../../components/Drawer';
import InputDrawer from '../../../../components/InputDrawer';
import ListSelect from '../../../../components/ListSelect';
import MediumText from '../../../../components/MediumText';
import Message from '../../../../components/Message';
import { MultipleInputValue } from '../../../../components/MultipleInputSelect';
import PillButton from '../../../../components/PillButton';
import Step from '../../../../components/Step';
import useAppWindowDimensions from '../../../../hooks/useAppWindowDimensions';
import { useBackHandler } from '../../../../hooks/useBackHandler';
import {
  saveExercise,
  selectListExercisesByTemplateId
} from '../../../../store/exercises';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ExerciseType, ListExercise } from '../../../../store/types';
import { DrawerStackParamList } from '../../../DrawerNavigator';
import { RootStackParamList } from '../../../RootNavigator';
import { ConcernsSteps } from './types';

const SimpleListExerciseScreen: FunctionComponent = () => {
  const { params } =
    useRoute<RouteProp<DrawerStackParamList, 'SimpleListExercise'>>();

  const responsesPlaceholders =
    params.template.items.map((item) => item.response) ?? [];

  const [editMode, setEditMode] = useState(false);
  const [stepIndex, setStepIndex] = useState(ConcernsSteps.CONCERNS);
  const [showInput, setShowInput] = useState(false);
  const listExercise = useAppSelector(
    selectListExercisesByTemplateId(params.template.id)
  );
  const scrollViewRef = useRef<ScrollView>(null);

  // // Prioritize ListExerciseItems passed through navigation params
  // // Then check existing list exercises for one with the same template and apply user responses
  const externalItems = params.items ?? listExercise?.items;

  const initialProblems: MultipleInputValue[] = (() => {
    if (!externalItems) {
      return params.template.items.map((item) => ({
        key: item.id,
        value: item.text
      }));
    }
    return externalItems.map((item) => ({
      key: item.id,
      value: item.text
    }));
  })();

  const [problems, setProblems] =
    useState<MultipleInputValue[]>(initialProblems);

  const [selectedIds, setSelectedIds] = useState<string[]>(
    externalItems
      ? externalItems.flatMap((item) => (item.selected ? item.id : []))
      : []
  );

  const [responses] = useState(responsesPlaceholders);
  const { height: windowHeight } = useAppWindowDimensions();
  const intl = useIntl();
  const navigation =
    useNavigation<
      StackNavigationProp<DrawerStackParamList & RootStackParamList>
    >();
  const dispatch = useAppDispatch();

  const problemsAndResponsesGrouped = problems.flatMap((prob, idx) => {
    const key = selectedIds.find((key) => prob.key === key);
    const response = responses[idx] ?? params.template.defaultResponse;
    if (!key) return [];
    return {
      id: key,
      problem: prob?.value,
      response
    };
  });

  const isFirstScreen = () => {
    return stepIndex <= ConcernsSteps.CONCERNS;
  };

  const closeExercise = () => {
    const exercise: ListExercise = {
      id: uuidv4(),
      type: ExerciseType.ListExercise,
      createdAt: new Date().toISOString(),
      templateId: params.template.id,
      items: problems.map((item, idx) => ({
        id: item.key,
        selected: selectedIds.includes(item.key),
        text: item.value,
        response: responses[idx] ?? params.template.defaultResponse
      }))
    };

    params.onBack?.(exercise);
    navigation.goBack();
  };

  const regularBackwardNav = () => {
    handleSetStepIndex(stepIndex - 1);
  };

  const { getDrawerIcon } = useBackHandler(
    isFirstScreen,
    regularBackwardNav,
    [stepIndex],
    navigation,
    closeExercise
  );

  const handleSetStepIndex = (val: number) => {
    setStepIndex(val);
    scrollViewRef.current?.scrollTo({ y: 0, animated: false });
  };

  const renderItem = (item: { key: string; value: string }) => {
    const onSelect = () => {
      if (selectedIds.includes(item.key))
        setSelectedIds(selectedIds.filter((val) => val !== item.key));
      else setSelectedIds([...selectedIds, item.key]);
    };
    const onDelete = () => {
      navigation.navigate('ConfirmationQuestion', {
        onPressYes: () =>
          setProblems(problems.filter((problem) => problem.key !== item.key)),
        question: intl.formatMessage({
          defaultMessage: 'Are you sure you want to delete this item?',
          description: 'Delete item confirmation'
        })
      });
    };
    return (
      <View style={{ marginBottom: 20, marginLeft: 16 }} key={item.key}>
        <ListSelect
          selected={selectedIds.includes(item.key)}
          editMode={editMode}
          onSelect={onSelect}
          onDelete={onDelete}
        >
          {item.value}
        </ListSelect>
      </View>
    );
  };

  const renderInputDrawer = () => {
    return (
      <InputDrawer
        onCancel={() => setShowInput(false)}
        onSave={(text) => {
          setShowInput(false);
          const newProblem = {
            key: uuidv4(),
            value: text
          };
          setProblems((prevProblems) => [...prevProblems, newProblem]);
          setSelectedIds([...selectedIds, newProblem.key]);
        }}
      />
    );
  };

  const renderConcernsStep = () => {
    const renderTapInstructions = () => {
      return (
        <View style={styles.instructionsContainer}>
          <View style={styles.bubbleContainer}>
            <MediumText style={styles.instruction}>
              <FormattedMessage
                defaultMessage="Are there any other specific barriers and thoughts you want to add?"
                description="Instruction message simple list exercise"
              />
            </MediumText>
          </View>
        </View>
      );
    };
    const renderAddValue = () => {
      return (
        <Pressable
          style={styles.addInputContainer}
          onPress={() => {
            setShowInput(true);
          }}
        >
          <MediumText style={styles.text}>
            <FormattedMessage
              defaultMessage="Add custom item"
              description="Add item"
            />
          </MediumText>
          <View style={styles.plusButton}>
            <MediumText style={styles.text}>+</MediumText>
          </View>
        </Pressable>
      );
    };
    const renderSelectedItems = () =>
      problems.filter((item) => selectedIds.includes(item.key)).map(renderItem);

    const renderUnselectedItems = () =>
      problems
        .filter((item) => !selectedIds.includes(item.key))
        .map(renderItem);

    const emptySelect = selectedIds.length === 0;

    return (
      <View style={{ paddingHorizontal: 16 }}>
        {selectedIds.length !== 0 && renderTapInstructions()}
        {renderSelectedItems()}
        <View style={styles.instructionsContainer}>
          <View style={styles.bubbleContainer}>
            <MediumText style={styles.instruction}>
              {params.instructionLinkedExercise ?? params.template.instruction}
            </MediumText>
          </View>
        </View>
        {renderUnselectedItems()}
        {params.template.allowCustomItems && renderAddValue()}
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <PillButton
            style={{ marginBottom: 50 }}
            disabled={emptySelect}
            onPress={() => {
              setStepIndex(ConcernsSteps.SUMMARY);
            }}
          >
            <FormattedMessage defaultMessage="Next" description="Next button" />
          </PillButton>
        </View>
      </View>
    );
  };

  const renderSummaryStep = () => {
    const exercise: ListExercise = {
      id: uuidv4(),
      type: ExerciseType.ListExercise,
      createdAt: new Date().toISOString(),
      templateId: params.template.id,
      items: problems.map((item, idx) => ({
        id: item.key,
        selected: selectedIds.includes(item.key),
        text: item.value,
        response: responses[idx] ?? params.template.defaultResponse
      }))
    };

    const renderProblemText = (text: string, type: 'problem' | 'response') => {
      return (
        <View
          style={[
            styles.itemContainer,
            { alignSelf: type === 'problem' ? 'flex-end' : 'flex-start' }
          ]}
        >
          <View
            style={[
              styles.itemTextContainer,
              {
                borderColor: type === 'problem' ? Colors.BLUE : Colors.GREY5,
                backgroundColor:
                  type === 'problem' ? Colors.SUPERLIGHTVIOLET : Colors.WHITE
              }
            ]}
          >
            <MediumText style={[styles.text, { marginLeft: 16 }]}>
              {text}
            </MediumText>
          </View>
        </View>
      );
    };

    return (
      <View style={{ flex: 1, marginHorizontal: 16 }}>
        <ScrollView style={styles.container}>
          <View style={{ marginBottom: 120 }}>
            <View style={{ marginHorizontal: 12, marginBottom: 16 }}>
              <Message
                text={intl.formatMessage({
                  defaultMessage:
                    'These barriers seem to apply to you. If you wish, you can add more.',
                  description: 'Summary message simple list exercise'
                })}
                style={{ width: '100%', borderBottomLeftRadius: 16 }}
                containerStyle={{ width: '100%', shadowOpacity: 0 }}
              />
            </View>
            <View style={styles.summaryMessagesContainer}>
              {problemsAndResponsesGrouped.map((vals, idx, arr) => (
                <View key={idx.toString()}>
                  {renderProblemText(vals.problem, 'problem')}
                  {vals.response !== arr[idx + 1]?.response &&
                    renderProblemText(vals.response as string, 'response')}
                </View>
              ))}
            </View>
          </View>
        </ScrollView>
        <View style={styles.pillButtonContainer}>
          <PillButton
            style={{ marginBottom: 10 }}
            onPress={() => {
              !params.preventSaving && dispatch(saveExercise(exercise));
              params.onFinish?.(exercise);
              navigation.goBack();
            }}
          >
            <FormattedMessage defaultMessage="Save" description="Save" />
          </PillButton>
        </View>
      </View>
    );
  };

  return (
    <Drawer
      title={params.template.title}
      onBackgroundPress={() =>
        navigation.navigate('DrawerCloseExercise', {
          closeExercise
        })
      }
      leftComponent={getDrawerIcon()}
      rightComponent={
        stepIndex === ConcernsSteps.CONCERNS ? (
          <TouchableOpacity onPress={() => setEditMode(!editMode)}>
            <MediumText style={{ color: Colors.BLUE }}>
              {editMode ? (
                <FormattedMessage defaultMessage="Done" description="Done" />
              ) : (
                <FormattedMessage defaultMessage="Edit" description="Edit" />
              )}
            </MediumText>
          </TouchableOpacity>
        ) : (
          <></>
        )
      }
    >
      <ScrollView
        ref={scrollViewRef}
        keyboardShouldPersistTaps
        style={[
          styles.scrollview,
          Platform.OS === 'web' && {
            height: windowHeight - 125,
            paddingBottom: 50
          }
        ]}
      >
        <Step step={ConcernsSteps.CONCERNS} current={stepIndex}>
          {renderConcernsStep()}
        </Step>
        <Step step={ConcernsSteps.SUMMARY} current={stepIndex}>
          {renderSummaryStep()}
        </Step>
      </ScrollView>
      {showInput && renderInputDrawer()}
    </Drawer>
  );
};

export default SimpleListExerciseScreen;

const styles = StyleSheet.create({
  itemTextContainer: {
    borderWidth: 1,
    borderRadius: 16,
    paddingRight: 16,
    paddingLeft: 3,
    paddingVertical: 5,
    flexDirection: 'row'
  },
  arrow: {
    width: 30,
    height: 30,
    resizeMode: 'contain'
  },
  text: {
    color: Colors.BLUE
  },
  plusButton: {
    width: 30,
    height: 30,
    borderColor: Colors.GREY4,
    borderWidth: 1,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12
  },
  itemContainer: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  addInputContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 10,
    paddingBottom: 40
  },
  scrollview: {
    marginTop: 16
  },
  instruction: {
    flex: 1,
    lineHeight: 26
  },
  instructionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16
  },
  bubbleContainer: {
    flex: 1,
    backgroundColor: Colors.GREY5,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 12,
    paddingHorizontal: 16,
    paddingVertical: 13
  },
  summaryMessagesContainer: {
    flexDirection: 'column'
  },
  pillButtonContainer: {
    position: 'absolute',
    backgroundColor: Colors.WHITE,
    width: '100%',
    bottom: 0,
    paddingBottom: 40,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
    // Android
    ...(Platform.OS === 'android' && {
      paddingBottom: 0,
      height: 42
    })
  },
  container: {
    paddingBottom: 50
  }
});
