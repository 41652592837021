import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Colors from '../colors';
import HeaderLine, { HeaderLineProps } from './HeaderLine';

interface HeaderProps extends HeaderLineProps {
  headerRight?: React.ReactNode;
  containerStyle?: ViewStyle;
}

const Header: FunctionComponent<PropsWithChildren<HeaderProps>> = (props) => {
  const insets = useSafeAreaInsets();

  return (
    <View
      style={[
        styles.container,
        { paddingTop: insets.top + 9 },
        props.containerStyle
      ]}
    >
      <View style={[styles.upperContainer]}>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'flex-start'
          }}
        >
          {props.children}
        </View>
        <View>{props.headerRight}</View>
      </View>
      <HeaderLine {...props} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
    paddingHorizontal: 16,
    backgroundColor: Colors.BACKGROUND
  },
  upperContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 13,
    height: 80
  }
});

export default Header;
