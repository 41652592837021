import React, { FunctionComponent } from 'react';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import MediumText from '../../../../../components/MediumText';

interface Props {
  icon: ImageSourcePropType;
  label: string;
  value?: number;
  update: (value: number) => void;
}

const Counter: FunctionComponent<Props> = ({
  icon,
  label,
  update,
  value = 0
}) => {
  return (
    <View style={styles.container}>
      <View style={[styles.contentContainer, styles.textContainer]}>
        <Image style={styles.icon} source={icon} />
        <MediumText numberOfLines={1} style={styles.label}>
          {label}
        </MediumText>
      </View>
      <View style={styles.contentContainer}>
        <TouchableOpacity onPress={() => update(value - 1)}>
          <Image
            style={styles.counterButton}
            source={require('../assets/counter-decrease.png')}
          />
        </TouchableOpacity>
        <MediumText style={styles.counter}>{value}</MediumText>
        <TouchableOpacity onPress={() => update(value + 1)}>
          <Image
            style={styles.counterButton}
            source={require('../assets/counter-increase.png')}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 12,
    width: '100%'
  },
  contentContainer: {
    flexDirection: 'row'
  },
  textContainer: {
    flexShrink: 1
  },
  icon: {
    height: 24,
    width: 24
  },
  label: {
    marginLeft: 10,
    flexShrink: 1,
    textAlign: 'left'
  },
  counter: {
    marginHorizontal: 12
  },
  counterButton: {
    height: 24,
    width: 24
  }
});

export default Counter;
