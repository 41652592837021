export enum TriangleSteps {
  UNHELPFUL_INTRO,
  UNHELPFUL_THOUGHT,
  UNHELPFUL_THOUGHT_REVIEW,
  UNHELPFUL_FEELINGS,
  UNHELPFUL_FEELINGS_REVIEW,
  UNHELPFUL_BEHAVIOUR,
  UNHELPFUL_BEHAVIOUR_REVIEW,
  HELPFUL_INTRO,
  HELPFUL_THOUGHT,
  HELPFUL_THOUGHT_REVIEW,
  HELPFUL_FEELINGS,
  HELPFUL_FEELINGS_REVIEW,
  HELPFUL_BEHAVIOUR,
  HELPFUL_BEHAVIOUR_REVIEW,
  OVERVIEW,
  THOUGHT_OVERVIEW,
  FEELINGS_OVERVIEW,
  BEHAVIOUR_OVERVIEW
}

// CT uses these legacy types that store answer options as string[].
// Exercise should be refactored to use types from root types.ts that use Option[].

export interface SituationLegacy {
  text: string;
  helpful: { thoughts: string[]; feelings: string[]; behaviors: string[] };
  unhelpful: { thoughts: string[]; feelings: string[]; behaviors: string[] };
}

export interface CognitiveTriangleExerciseLegacy {
  id?: string;
  situation: string;
  unhelpful: CognitiveTriangleLegacy;
  helpful: CognitiveTriangleLegacy;
  linkedExerciseId?: string;
}

export interface CognitiveTriangleLegacy {
  thought: string;
  feelings: {
    name: string;
    scale: number;
  }[];
  behavior: string;
}
