import React, {
  Children,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef
} from 'react';
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  View,
  ViewStyle,
  Platform
} from 'react-native';

import useAppWindowDimensions from '../hooks/useAppWindowDimensions';
import { isRtl } from '../lang';
import { useAppSelector } from '../store/hooks';
import { selectActiveLanguage } from '../store/languages';

interface SwiperProps {
  elementWidth?: number;
  containerStyle?: ViewStyle;
  scrollEnabled?: boolean;
  children?: React.ReactNode;
  onScroll?: (index: NativeSyntheticEvent<NativeScrollEvent>) => void;
  onMomentumScrollEnd?: (
    index: NativeSyntheticEvent<NativeScrollEvent>
  ) => void;
}

export interface SwiperRef {
  scrollToIndex: (index: number, animated?: boolean) => void;
}

const Swiper: ForwardRefRenderFunction<SwiperRef, SwiperProps> = (
  { elementWidth = 200, containerStyle, scrollEnabled = true, ...props },
  ref
) => {
  const rtl = isRtl(useAppSelector(selectActiveLanguage).language);
  const { width: windowWidth } = useAppWindowDimensions();
  const scrollView = useRef<ScrollView>(null);

  const snapOffsets = Array(Children.count(props.children))
    .fill(0)
    .map((_, idx) => idx * elementWidth);

  // For Android
  const reverseSnapOffsets = [...snapOffsets].reverse();

  // For Web
  const negativeSnapOffsets = [...snapOffsets].map((offset) => -offset);

  const platformSnapOffsets = Platform.select({
    ios: snapOffsets,
    android: reverseSnapOffsets,
    web: negativeSnapOffsets,
    windows: snapOffsets,
    macos: snapOffsets
  }) as number[];

  const scrollToIndex = (index: number, animated?: boolean) => {
    index =
      index >= 0 && index < snapOffsets.length ? index : snapOffsets.length - 1;

    scrollView.current?.scrollTo({
      x: rtl ? platformSnapOffsets[index] : snapOffsets[index],
      animated
    });
  };

  useImperativeHandle(ref, () => ({
    scrollToIndex
  }));

  return (
    <ScrollView
      ref={scrollView}
      horizontal
      showsHorizontalScrollIndicator={false}
      bounces={false}
      scrollEnabled={scrollEnabled}
      onScroll={props.onScroll}
      onMomentumScrollEnd={props.onMomentumScrollEnd}
      scrollEventThrottle={16}
      snapToOffsets={snapOffsets}
      decelerationRate="fast"
      disableIntervalMomentum
      contentContainerStyle={[styles.container, containerStyle]}
    >
      <View style={{ width: windowWidth / 2 - elementWidth / 2 }} />
      {Children.map(props.children, (child, idx) => (
        <View key={`swiper-icon-${idx}`} style={{ width: elementWidth }}>
          {child}
        </View>
      ))}
      <View style={{ width: windowWidth / 2 - elementWidth / 2 }} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center'
  }
});

export default forwardRef(Swiper);
