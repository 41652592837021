import React, { FunctionComponent } from 'react';
import { Image, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { ArrowDirection, Direction } from '../types';

interface Props {
  onPress?: (direction: ArrowDirection) => void;
  direction: ArrowDirection;
}

const CalendarArrow: FunctionComponent<Props> = ({ onPress, direction }) => (
  <TouchableOpacity
    onPress={() => onPress?.(direction)}
    hitSlop={{ left: 20, right: 20, top: 20, bottom: 20 }}
  >
    <Image
      style={styles.arrow}
      source={
        direction === Direction.Left
          ? require('../assets/arrow-left.png')
          : require('../assets/arrow-right.png')
      }
    />
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  arrow: {
    height: 24,
    width: 24
  }
});

export default CalendarArrow;
