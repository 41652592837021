import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScrollView, View, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { v4 as uuidv4 } from 'uuid';

import { VictoryContainer, VictoryPie } from '../../../../../aux/victory';
import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import Message from '../../../../../components/Message';
import PillButton from '../../../../../components/PillButton';
import Slider from '../../../../../components/Slider';
import useAppWindowDimensions from '../../../../../hooks/useAppWindowDimensions';
import { ResponsibilityPieReason } from '../../../../../store/types';
import sharedStyles from './styles';

interface SummaryProps {
  ownResponsibility: ResponsibilityPieReason;
  othersResponsibility: ResponsibilityPieReason;
  onNext: () => void;
}

const Summary = ({
  ownResponsibility,
  othersResponsibility,
  onNext
}: SummaryProps) => {
  const insets = useSafeAreaInsets();
  const { height: windowHeight } = useAppWindowDimensions();
  const intl = useIntl();

  const responsibilityValues = [
    intl.formatMessage({
      defaultMessage: 'Not at all',
      description: 'Not at all slider value'
    }),
    intl.formatMessage({
      defaultMessage: 'Very much',
      description: 'Very much slider value'
    })
  ];

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={[
        { paddingBottom: insets.bottom + 16 },
        sharedStyles.scrollViewContentContainer
      ]}
      style={[
        sharedStyles.scrollViewStyle,
        Platform.OS === 'web' && {
          height: windowHeight - 125
        }
      ]}
    >
      <Message
        component={
          <MediumText style={sharedStyles.messageInnerText}>
            <FormattedMessage
              description="Responsibility Pie summary instruction"
              defaultMessage="Summary instruction"
            />
          </MediumText>
        }
        style={sharedStyles.message}
        containerStyle={sharedStyles.messageContainer}
      />
      <View style={sharedStyles.pieContainer}>
        <VictoryPie
          data={[
            {
              x: ownResponsibility.reason,
              y: ownResponsibility.responsibility,
              fill: ownResponsibility.color
            },
            {
              x: 'Placeholder', // This label isn't displayed anywhere
              y: 100 - ownResponsibility.responsibility,
              fill: Colors.GREY5
            }
          ]}
          labels={() => null}
          // A fixed width and height limits the size of the pie chart in mobile (should be revisited)
          width={300}
          height={300}
          containerComponent={
            <VictoryContainer
              style={Platform.OS === 'web' ? { maxWidth: '40%' } : {}} // TODO: Figure out why shared styles for victoryContainer dont work here
            />
          }
          style={{
            data: {
              fill: ({ datum }) => datum.fill
            }
          }}
        />
      </View>
      <View style={sharedStyles.pieContainer}>
        <VictoryPie
          data={[
            {
              x: 'Placeholder', // This label isn't displayed anywhere
              y: 100 - othersResponsibility.responsibility,
              fill: ownResponsibility.color
            },
            {
              x: othersResponsibility.reason,
              y: othersResponsibility.responsibility,
              fill: othersResponsibility.color
            }
          ]}
          labels={() => null}
          // A fixed width and height limits the size of the pie chart in mobile
          width={300}
          height={300}
          containerComponent={
            <VictoryContainer
              style={Platform.OS === 'web' ? { maxWidth: '40%' } : {}}
            />
          }
          style={{
            data: {
              fill: ({ datum }) => datum.fill
            }
          }}
        />
      </View>
      <ScrollView style={sharedStyles.slidersScroller}>
        {[ownResponsibility, othersResponsibility].map((r) => (
          <Slider
            disabled
            key={uuidv4()}
            containerStyle={sharedStyles.slider}
            titleStyle={sharedStyles.sliderTitle}
            barStyle={sharedStyles.sliderBar}
            borderStyle={[sharedStyles.sliderBorder, { borderColor: r.color }]}
            title={r.reason}
            minimumTrackTintColor={Colors.GREY5}
            maximumTrackTintColor={Colors.GREY5}
            thumbTintColor={Colors.GREY3}
            maximumValue={r.maxResponsibility}
            minimumValue={0}
            value={r.responsibility}
            leftLabel={responsibilityValues[0]}
            rightLabel={responsibilityValues[1]}
            remainingPercentage={`${r.maxResponsibility}%`}
            step={1}
          />
        ))}
      </ScrollView>
      <View style={sharedStyles.pillButtonContainer}>
        <PillButton onPress={onNext}>
          <FormattedMessage defaultMessage="Save" description="Save" />
        </PillButton>
      </View>
    </ScrollView>
  );
};

export default Summary;
