import { useFocusEffect } from '@react-navigation/core';
import { Audio } from 'expo-av';
import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react';
import { useIntl } from 'react-intl';
import {
  FlatList,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  ListRenderItem,
  Platform,
  Pressable,
  StyleSheet,
  useWindowDimensions,
  View,
  ViewStyle
} from 'react-native';

import Colors from '../../../../colors';
import KeyboardInput, {
  KeyboardInputRef
} from '../../../../components/KeyboardInput';
import useInterval from '../../../../hooks/useInterval';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import {
  selectAllMessages,
  saveTextMessage,
  saveVoiceMessage,
  fetchMessages
} from '../../../../store/messages';
import { DatedMessage } from '../../../../store/types';
import HelperMessage from './components/HelperMessage';
import RecordingPanel from './components/RecordingPanel';

const HelperScreen: FunctionComponent = () => {
  const [message, setMessage] = useState('');
  const [isRecording, setRecording] = useState(false);
  const dispatch = useAppDispatch();
  const [isAudioMode, setAudioMode] = useState(false);
  const messages = useAppSelector(selectAllMessages);
  const user = useAppSelector((state) => state.user);
  const inputRef = useRef<KeyboardInputRef>(null);
  const flatListRef = useRef<FlatList>(null);
  const { height: screenHeight } = useWindowDimensions();
  const permissionGranted = useRef<boolean>(false);
  const intl = useIntl();
  const keyboardOffset = Platform.OS === 'ios' ? screenHeight * 0.145 : -150;
  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    dispatch(fetchMessages());
  }, [user.userId, messages.length]);

  useFocusEffect(
    useCallback(() => {
      dispatch(fetchMessages());
    }, [])
  );

  useInterval(() => {
    dispatch(fetchMessages());
  }, 10000);

  // Keep messages in view on keyboard open.
  useEffect(() => {
    const subscription = Keyboard.addListener('keyboardDidShow', scrollToEnd);
    return () => subscription.remove();
  }, []);

  useEffect(() => {
    (async () => {
      if (!isWeb)
        permissionGranted.current = (await Audio.getPermissionsAsync()).granted;
    })();
  }, []);

  const sendTextMessage = async () => {
    const resAction = await dispatch(saveTextMessage(message.trim()));
    if (saveTextMessage.fulfilled.match(resAction)) {
      setMessage('');
    }
  };

  const sendVoiceMessage = (uri: string) => {
    dispatch(saveVoiceMessage(uri));
  };

  const startRecording = async () => {
    if (!permissionGranted.current) {
      permissionGranted.current = (
        await Audio.requestPermissionsAsync()
      ).granted;

      if (!permissionGranted.current) {
        alert(
          intl.formatMessage({
            defaultMessage:
              'Please allow this app to access your microphone in Settings.',
            description: 'eHelperScreen microphone permission error'
          })
        );
      }
      return;
    }

    setAudioMode(true);
    setRecording(true);
  };

  const scrollToEnd = () => {
    flatListRef.current?.scrollToEnd();
  };

  const renderItem: ListRenderItem<DatedMessage> = ({ item, index }) => {
    const previousItem = messages[index - 1];
    const sameDate = item.date.getDate() === previousItem?.date.getDate();
    const showDate = !previousItem || !sameDate;
    return <HelperMessage message={item} showDate={showDate} />;
  };

  const renderInput = () => {
    const webStyle = isWeb && ({ scrollbarWidth: 'none' } as ViewStyle);
    return (
      <KeyboardInput
        autoCorrect={false}
        blurOnSubmit
        onChangeText={setMessage}
        onSubmitEditing={sendTextMessage}
        placeholder="Message"
        ref={inputRef}
        returnKeyType="send"
        containerStyle={styles.input}
        style={[styles.inputText, webStyle]}
        value={message}
        multiline
      />
    );
  };

  const renderSendButton = () => (
    <Pressable onPress={sendTextMessage}>
      <Image source={require('./assets/send.png')} style={styles.icon} />
    </Pressable>
  );

  const renderRecordButton = () => {
    // Only show in audio mode if recording.
    return !isWeb && (isRecording || !isAudioMode) ? (
      <Pressable
        onPressIn={startRecording}
        onPressOut={() => setRecording(false)}
      >
        <View style={isRecording && styles.recordingButton}>
          <Image
            source={require('./assets/microphone.png')}
            style={[
              styles.icon,
              isRecording ? styles.recordingIcon : styles.recordIcon
            ]}
          />
        </View>
      </Pressable>
    ) : null;
  };

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView
        style={styles.keyboardWrapper}
        behavior="padding"
        keyboardVerticalOffset={keyboardOffset}
      >
        <FlatList
          contentContainerStyle={styles.listContent}
          data={messages}
          onContentSizeChange={scrollToEnd}
          ref={flatListRef}
          renderItem={renderItem}
          showsVerticalScrollIndicator={false}
        />
        <View
          style={[styles.inputContainer, isAudioMode && styles.inputRecording]}
        >
          {isAudioMode && (
            <RecordingPanel
              isRecording={isRecording}
              onClose={() => setAudioMode(false)}
              onSend={sendVoiceMessage}
            />
          )}
          {!isAudioMode && renderInput()}
          {message.length ? renderSendButton() : renderRecordButton()}
        </View>
      </KeyboardAvoidingView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: Colors.BACKGROUND
  },
  keyboardWrapper: {
    height: '100%',
    width: Platform.OS === 'web' ? '80%' : '100%'
  },
  listContent: {
    paddingVertical: 32,
    paddingHorizontal: 16
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 9,
    backgroundColor: 'transparent'
  },
  inputRecording: {
    marginVertical: 0
  },
  input: {
    maxHeight: 58,
    marginLeft: 16,
    marginRight: Platform.OS === 'web' ? 16 : 0,
    flex: 1,
    fontFamily: 'NotoSans-Medium'
  },
  inputText: {
    color: Colors.GREY1,
    fontFamily: 'NotoSans-Medium'
  },
  icon: {
    height: 28,
    width: 28,
    marginLeft: 8,
    marginRight: Platform.OS === 'web' ? 38 : 16
  },
  recordIcon: {
    tintColor: Colors.GREY1
  },
  recordingIcon: {
    tintColor: Colors.WHITE,
    height: 33,
    width: 33,
    marginLeft: 25
  },
  recordingButton: {
    height: 62,
    width: 72,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.RED,
    position: 'absolute',
    right: 0,
    bottom: -25,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16
  }
});

export default HelperScreen;
