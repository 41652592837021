import { v4 as uuidv4 } from 'uuid';

import {
  CognitiveTriangleExercise,
  CognitiveTriangleExerciseSituationLegacy,
  CognitiveTriangleTemplate,
  ExerciseType,
  Situation
} from '../../../../store/types';
import { CognitiveTriangleExerciseLegacy, SituationLegacy } from './types';

// These helper methods convert between legacy types that store answer options as string[]
// and new types that store answer options as Option[].

export const convertLegacyExerciseToNew: (
  exerciseValues: CognitiveTriangleExerciseLegacy,
  template?: CognitiveTriangleTemplate
) => CognitiveTriangleExercise = (values, template) => {
  const baseExerciseValues = {
    id: values.id ?? uuidv4(),
    type: ExerciseType.CognitiveTriangle,
    createdAt: new Date().toISOString()
  };
  const situation = template?.situations.find(
    (situation) => situation.text === values.situation
  );
  if (situation) {
    return {
      ...baseExerciseValues,
      situation: { id: situation.id, text: situation.text },
      helpful: {
        thought: situation.helpful.thoughts.find(
          (thoughtOption) => thoughtOption.text === values.helpful.thought
        ) ?? {
          id: uuidv4(),
          text: values.helpful.thought,
          value: values.helpful.thought
        }, // helpful thought can be set through letter to a friend so it can't be found in CMS
        feelings: values.helpful.feelings.map((feeling) => ({
          feeling:
            situation.helpful.feelings.find(
              (feelingOption) => feelingOption.text === feeling.name
            ) ?? null,
          scale: feeling.scale
        })),
        behavior:
          situation.helpful.behaviors.find(
            (behaviorOption) => behaviorOption.text === values.helpful.behavior
          ) ?? null
      },
      unhelpful: {
        thought:
          situation.unhelpful.thoughts.find(
            (thoughtOption) => thoughtOption.text === values.unhelpful.thought
          ) ?? null,
        feelings: values.unhelpful.feelings.map((feeling) => ({
          feeling:
            situation.unhelpful.feelings.find(
              (feelingOption) => feelingOption.text === feeling.name
            ) ?? null,
          scale: feeling.scale
        })),
        behavior:
          situation.unhelpful.behaviors.find(
            (behaviorOption) =>
              behaviorOption.text === values.unhelpful.behavior
          ) ?? null
      }
    };
  }
  return {
    ...baseExerciseValues,
    situation: { id: uuidv4(), text: values.situation },
    helpful: {
      thought: { id: uuidv4(), text: values.helpful.thought, value: '' },
      feelings: values.helpful.feelings.map((feeling) => ({
        feeling: { id: uuidv4(), text: feeling.name, value: '' },
        scale: feeling.scale
      })),
      behavior: { id: uuidv4(), text: values.helpful.behavior, value: '' }
    },
    unhelpful: {
      thought: { id: uuidv4(), text: values.unhelpful.thought, value: '' },
      feelings: values.unhelpful.feelings.map((feeling) => ({
        feeling: { id: uuidv4(), text: feeling.name, value: '' },
        scale: feeling.scale
      })),
      behavior: { id: uuidv4(), text: values.unhelpful.behavior, value: '' }
    }
  } as CognitiveTriangleExercise;
};

export const convertExerciseToLegacy: (
  exercise: CognitiveTriangleExercise
) => CognitiveTriangleExerciseLegacy = (exercise) => {
  return {
    situation: exercise.situation as CognitiveTriangleExerciseSituationLegacy,
    helpful: {
      thought: exercise.helpful.thought?.text ?? '',
      feelings: exercise.helpful.feelings.map((feeling) => ({
        name: feeling.feeling?.text ?? '',
        scale: feeling.scale
      })),
      behavior: exercise.helpful.behavior?.text ?? ''
    },
    unhelpful: {
      thought: exercise.unhelpful.thought?.text ?? '',
      feelings: exercise.unhelpful.feelings.map((feeling) => ({
        name: feeling.feeling?.text ?? '',
        scale: feeling.scale
      })),
      behavior: exercise.unhelpful.behavior?.text ?? ''
    },
    linkedExerciseId: exercise.linkedExerciseId
  };
};

export const convertSituationTemplateToLegacy: (
  situation: Situation
) => SituationLegacy = (situation) => {
  return {
    ...situation,
    helpful: {
      thoughts: situation.helpful.thoughts.map((val) => val.text),
      feelings: situation.helpful.feelings.map((val) => val.text),
      behaviors: situation.helpful.behaviors.map((val) => val.text)
    },
    unhelpful: {
      thoughts: situation.unhelpful.thoughts.map((val) => val.text),
      feelings: situation.unhelpful.feelings.map((val) => val.text),
      behaviors: situation.unhelpful.behaviors.map((val) => val.text)
    }
  };
};
