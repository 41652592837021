import React, { FunctionComponent } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Colors from '../colors';
import { Option } from '../store/types';
import MediumText from './MediumText';
import PillButton from './PillButton';

interface Props {
  values: string[] | Option[];
  selected: string | Option | null;
  onSelect: (val: string | Option) => void;
  style?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
}

const SingleSelect: FunctionComponent<Props> = (props) => {
  const renderAnswerOption = (value: string | Option) => {
    if (typeof value === 'string') {
      const active = props.selected === value;
      return (
        <PillButton
          key={value}
          style={[
            styles.baseButton,
            active ? styles.buttonActive : styles.buttonInactive,
            props.buttonStyle
          ]}
          onPress={() => props.onSelect(value as string)}
        >
          <MediumText style={active ? styles.textActive : styles.textInactive}>
            {value}
          </MediumText>
        </PillButton>
      );
    } else {
      const active = (props.selected as Option)?.id === value.id;
      return (
        <PillButton
          key={value.id}
          style={[
            styles.baseButton,
            active ? styles.buttonActive : styles.buttonInactive,
            props.buttonStyle
          ]}
          onPress={() => props.onSelect(value)}
        >
          <MediumText style={active ? styles.textActive : styles.textInactive}>
            {value.text}
          </MediumText>
        </PillButton>
      );
    }
  };

  return (
    <View style={[styles.container, props.style]}>
      {(props.values as any[]).map((value: string | Option) =>
        renderAnswerOption(value)
      )}
    </View>
  );
};

export default SingleSelect;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  baseButton: {
    marginRight: 10,
    marginTop: 10
  },
  textInactive: {
    color: Colors.BLUE
  },
  textActive: {
    color: Colors.WHITE
  },
  buttonInactive: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: Colors.BLUE
  },
  buttonActive: {
    backgroundColor: Colors.BLUE,
    borderWidth: 1,
    borderColor: Colors.BLUE
  }
});
