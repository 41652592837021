import { Platform, useWindowDimensions } from 'react-native';

const useAppWindowDimensions = () => {
  const dimensions = useWindowDimensions();
  const maxWebWidth = 900;

  if (Platform.OS === 'web' && dimensions.width > maxWebWidth) {
    return {
      ...dimensions,
      width: maxWebWidth
    };
  }
  return dimensions;
};

export default useAppWindowDimensions;
