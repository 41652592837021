import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { View, StyleSheet, Pressable, Keyboard, Platform } from 'react-native';

import Colors from '../../../../colors';
import { useAppSelector } from '../../../../store/hooks';
import SettingsMenuItem from './components/SettingsMenuItem';

const SettingsUserIdScreen: FunctionComponent = () => {
  const intl = useIntl();
  const participantId = useAppSelector((state) => state.user.participantId);
  return (
    <>
      <Pressable
        style={styles.container}
        onPress={() => (Platform.OS === 'web' ? undefined : Keyboard.dismiss())}
      >
        <View style={styles.itemsContainer}>
          <SettingsMenuItem
            title={
              participantId ||
              intl.formatMessage({
                defaultMessage: 'Participant ID',
                description: 'Participant ID button'
              })
            }
            hideBorder
            type="none"
          />
        </View>
      </Pressable>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND,
    paddingTop: 56
  },
  itemsContainer: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: Colors.GREY5,
    backgroundColor: Colors.WHITE,
    paddingLeft: 24
  },
  title: {
    color: Colors.GREY2,
    fontSize: 14,
    paddingTop: 27,
    paddingLeft: 24,
    paddingBottom: 9
  }
});

export default SettingsUserIdScreen;
