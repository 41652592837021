import { NavigationProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Image,
  Platform,
  Pressable,
  StyleSheet,
  View,
  ViewStyle
} from 'react-native';

import Colors from '../../../../../colors';
import Avatar from '../../../../../components/Avatar';
import MediumText from '../../../../../components/MediumText';
import Message from '../../../../../components/Message';
import PillButton from '../../../../../components/PillButton';
import PlayAudio from '../../../../../components/PlayAudio';
import { useAppSelector } from '../../../../../store/hooks';
import {
  Block as BlockType,
  ExerciseType,
  Option
} from '../../../../../store/types';
import { DrawerStackParamList } from '../../../../DrawerNavigator';
import { RootStackParamList } from '../../../../RootNavigator';
import { TabParamList } from '../../../TabNavigator';
import KeyboardInputAnswer from './KeyboardInputAnswer';
import MultipleSelectAnswer from './MultipleSelectAnswer';
import PasswordAnswer from './PasswordAnswer';
import SingleSelectAnswer from './SingleSelectAnswer';
import SliderAnswer from './SliderAnswer';
import UsernameAnswer from './UsernameAnswer';

interface BlockProps {
  block: BlockType;
  completed: boolean;
  onComplete: (value?: string | Option | Option[] | null) => void;
  validation?: { error: boolean; message: string };
  containerStyle?: ViewStyle;
  loading?: boolean;
  editable?: boolean;
}

const Block: FunctionComponent<BlockProps> = (props) => {
  const { userId, token } = useAppSelector((state) => state.user);
  const eHelperNavigation = useNavigation<StackNavigationProp<TabParamList>>();
  const navigation =
    useNavigation<
      StackNavigationProp<RootStackParamList & DrawerStackParamList>
    >();
  const [value, setValue] = useState<string | Option | Option[] | null>(null);

  const renderQuestionBlockAnswer = (block: BlockType) => {
    const completed = props.completed || block.pause;
    // TODO: Validation using range
    if ('type' in block) {
      if (block.userConfig === 'password') {
        return (
          <PasswordAnswer
            block={block}
            completed={completed}
            onComplete={() => props.onComplete(value)}
            setValue={setValue}
            loading={props.loading}
          />
        );
      } else if (block.userConfig === 'username') {
        return (
          <UsernameAnswer
            block={block}
            completed={completed}
            onComplete={() => props.onComplete(value)}
            value={value as string}
            onValueChange={setValue}
            validation={props.validation}
            loading={props.loading}
          />
        );
      } else if (block.type === 'keyboard-input') {
        return (
          <KeyboardInputAnswer
            block={block}
            completed={completed}
            onComplete={() => props.onComplete(value)}
            value={value as string}
            onValueChange={setValue}
            validation={props.validation}
            loading={props.loading}
          />
        );
      } else if (block.type === 'slider') {
        return (
          <SliderAnswer
            block={block}
            completed={completed}
            onComplete={() => props.onComplete(value)}
            value={value as string}
            onValueChange={setValue}
            loading={props.loading}
          />
        );
      } else if (block.type === 'single-select') {
        return (
          <SingleSelectAnswer
            block={block}
            completed={completed}
            onComplete={() => props.onComplete(value)}
            value={(value ?? null) as Option | null}
            onValueChange={setValue}
            loading={props.loading}
          />
        );
      } else {
        return (
          <MultipleSelectAnswer
            block={block}
            completed={completed}
            onComplete={() => props.onComplete(value)}
            value={(value ?? []) as Option[]}
            onValueChange={setValue}
            loading={props.loading}
          />
        );
      }
    }
  };

  const renderAnswer = (block: BlockType) => {
    // Narrative block
    if ('buttonText' in block) {
      return (
        <>
          <PillButton
            disabled={props.completed || block.pause}
            onPress={() => props.onComplete()}
            loading={props.loading}
          >
            {block.buttonText}
          </PillButton>
        </>
      );
    }
    // Exercise block
    else if ('exercise' in block) {
      return (
        <>
          <PillButton
            disabled={props.completed || block.pause}
            style={
              props.completed || block.pause
                ? { backgroundColor: Colors.GREY4 }
                : styles.outlineButton
            }
            onPress={() => {
              switch (block.exercise) {
                case ExerciseType.WrittenExposure:
                  navigation.navigate('Drawer', {
                    screen: 'WrittenExposure',
                    params: {
                      onFinish: props.onComplete,
                      template: block.writtenExposureTemplate
                    }
                  });
                  break;
                case ExerciseType.Lifeline:
                  navigation.navigate('Drawer', {
                    screen: 'Lifeline',
                    params: {
                      onFinish: props.onComplete
                    }
                  });
                  break;
                case ExerciseType.CognitiveTriangle:
                  navigation.navigate('Drawer', {
                    screen: 'CognitiveTriangle',
                    params: {
                      onFinish: props.onComplete,
                      template: block.cognitiveTriangleTemplate
                    }
                  });
                  break;
                case ExerciseType.ConcernsAndBarriers:
                  navigation.navigate('Drawer', {
                    screen: 'ConcernsAndBarriers',
                    params: {
                      onFinish: props.onComplete,
                      template: block.concernsAndBarriersTemplate
                    }
                  });
                  break;
                case ExerciseType.SafetyPlanBasicList:
                case ExerciseType.SafetyPlanAddressList:
                  navigation.navigate('Drawer', {
                    screen: 'SafetyPlanInput',
                    params: {
                      template: block.safetyPlanListInputTemplate,
                      type: block.exercise,
                      onFinish: props.onComplete
                    }
                  });
                  break;
                case ExerciseType.Audio:
                  navigation.navigate('Drawer', {
                    screen: 'AudioPlayer',
                    params: {
                      onFinish: props.onComplete,
                      template: block.audioExercise
                    }
                  });
                  break;
                case ExerciseType.ListExercise:
                  if (block.listExerciseTemplate?.complexList) {
                    navigation.navigate('Drawer', {
                      screen: 'ListExercise',
                      params: {
                        onFinish: () => props.onComplete(),
                        template: block.listExerciseTemplate
                      }
                    });
                  } else {
                    navigation.navigate('Drawer', {
                      screen: 'SimpleListExercise',
                      params: {
                        onFinish: () => props.onComplete(),
                        template: block.listExerciseTemplate
                      }
                    });
                  }
                  break;
              }
            }}
            loading={props.loading}
          >
            <MediumText
              style={{
                color:
                  props.completed || block.pause ? Colors.WHITE : Colors.BLUE
              }}
            >
              <FormattedMessage
                defaultMessage="Start exercise"
                description="Start exercise button"
              />
            </MediumText>
          </PillButton>
        </>
      );
    }
    // Question block
    if ('type' in block) {
      return renderQuestionBlockAnswer(block);
    }
  };

  const handleMessengerLinkPress = () => {
    if (userId && token) {
      eHelperNavigation.navigate('eHelper', { screen: 'eHelper' });
    }
  };

  return (
    <View style={props.containerStyle}>
      <View style={styles.upperContainer}>
        <View style={styles.upperIconContainer}>
          {/* {<PlayAudio onPress={() => null} />} */}
          {props.block.narrator?.file?.url && (
            <Avatar
              source={{ uri: props.block.narrator.file.url }}
              style={{ marginTop: 8 }}
            />
          )}
        </View>
        <View>
          {/* <TouchableOpacity
            onPress={() => null}
            style={{ alignSelf: 'flex-end' }}
          >
            <MediumText style={styles.skipText}>
              <FormattedMessage
                defaultMessage="Skip"
                description="Skip block label"
              />
            </MediumText>
          </TouchableOpacity> */}
          <Message
            messengerLink={
              // NOTE(Mariana): "'buttonText' in props.block" to check if it's narrative block
              'buttonText' in props.block && props.block.messengerLink
                ? handleMessengerLinkPress
                : undefined
            }
            text={props.block.text ?? ''}
            style={{
              position: 'relative',
              backgroundColor: props.completed ? Colors.GREY5 : Colors.WHITE
            }}
            file={props.block.file}
          />
        </View>
      </View>
      <View style={styles.lowerContainer}>
        {/* {!props.completed && (
          <View style={styles.lowerIconContainer}>
            <PlayAudio onPress={() => null} />
          </View>
        )} */}
        {props.editable && (
          <View style={styles.lowerIconContainer}>
            <Image
              style={{ width: 20, height: 20 }}
              source={require('../../../../../assets/pencil.png')}
            />
          </View>
        )}
        {renderAnswer(props.block)}
      </View>
    </View>
  );
};

export default Block;

const styles = StyleSheet.create({
  upperIconContainer: {
    position: 'absolute',
    bottom: 0,
    left: 8,
    alignItems: 'center'
  },
  upperContainer: {
    marginVertical: 12,
    paddingHorizontal: 40,
    maxWidth: Platform.OS === 'web' ? '80%' : '100%'
  },
  lowerIconContainer: {
    position: 'absolute',
    bottom: 6,
    right: 10,
    alignItems: 'center'
  },
  lowerContainer: {
    alignItems: 'flex-end',
    marginBottom: 12,
    paddingHorizontal: 40
  },
  outlineButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: Colors.BLUE
  },
  skipText: {
    fontSize: 12,
    lineHeight: 16,
    marginBottom: 6,
    color: Colors.GREY3
  }
});
