// Colors according to Figma
enum Colors {
  BACKGROUND = '#F8FAFB',
  SURFGREEN = '#A7E8D4',
  ICEBLUE = '#B4EFF5',
  BLONDE = '#FFDB90',
  MELON = '#FF9E6F',
  PASTELRED = '#FF5F4C',
  GREY1 = '#36465D',
  GREY2 = '#567285',
  GREY3 = '#91A8B8',
  GREY4 = '#C3D0D9',
  GREY5 = '#E9EFF3',
  GREY6 = '#F8FAFB',
  BLUE = '#4955CE',
  RED = '#FF4A34',
  WHITE = '#FFFFFF',
  CHIFFON = '#F1FEC7',
  TOURQUOISEBLUE = '#78E0EB',
  BONE = '#EAD8C2',
  SHADOW = 'rgba(54, 70, 93, 0.4)',
  MELONOPAQUE = '#FFE2D4',
  BLONDEOPAQUE = '#FFF4DD',
  DARKBLUE = '#252167',
  LIGHTVIOLET = '#ABB2FD',
  SUPERLIGHTVIOLET = '#EFF1FF'
}

export default Colors;
