import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import LoginScreen from '.';
import Drawer from '../../../components/Drawer';
import { DrawerStackParamList } from '../../DrawerNavigator';

const LoginDrawer: FunctionComponent = () => {
  const intl = useIntl();
  const route = useRoute<RouteProp<DrawerStackParamList, 'LoginDrawer'>>();
  const navigation = useNavigation<StackNavigationProp<DrawerStackParamList>>();
  return (
    <Drawer
      title={intl.formatMessage({
        description: 'LoginDrawer title',
        defaultMessage: 'Login'
      })}
      leftComponent={<></>}
    >
      <LoginScreen
        onFinish={async () => {
          await route.params?.onFinish?.();
          navigation.pop();
        }}
      />
    </Drawer>
  );
};

export default LoginDrawer;
