import messaging from '@react-native-firebase/messaging';
import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp, useHeaderHeight } from '@react-navigation/stack';
import React, { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  View,
  StyleSheet,
  ScrollView,
  Platform,
  TouchableOpacity
} from 'react-native';

import { SettingsStackParamList } from '.';
import Colors from '../../../../colors';
import MediumText from '../../../../components/MediumText';
import env from '../../../../env';
import useAppWindowDimensions from '../../../../hooks/useAppWindowDimensions';
import { isRtl } from '../../../../lang';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectActiveLanguage } from '../../../../store/languages';
import { logout, saveFcmToken, setDebugMode } from '../../../../store/user';
import { RootStackParamList } from '../../../RootNavigator';
import SettingsMenuItem from './components/SettingsMenuItem';

const SettingsScreen: FunctionComponent = () => {
  const user = useAppSelector((state) => state.user);
  const [notifIndexes, setNotifIndexes] = useState<number[]>([0, 1, 4, 6]);

  const intl = useIntl();
  const { language } = useAppSelector(selectActiveLanguage);
  const dispatch = useAppDispatch();
  const navigation =
    useNavigation<
      StackNavigationProp<
        SettingsStackParamList & RootStackParamList,
        'Settings'
      >
    >();
  const { height: windowHeight } = useAppWindowDimensions();
  const headerHeight = useHeaderHeight();

  const personalInfosItems = [
    {
      title: intl.formatMessage({
        defaultMessage: 'Email address',
        description: 'Email address menu in settings'
      }),
      onPress: () => navigation.navigate('SettingsEmail'),
      disabled: !user.userId || !user.token
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Phone number',
        description: 'Phone number menu in settings'
      }),
      onPress: () => navigation.navigate('SettingsPhone'),
      disabled: !user.userId || !user.token
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Change language',
        description: 'Change language menu in settings'
      }),
      onPress: () => navigation.navigate('SettingsLanguage')
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Change password',
        description: 'Change password menu in settings'
      }),
      onPress: () => navigation.navigate('SettingsPassword'),
      disabled: !user.userId || !user.token
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Show participant ID',
        description: 'Show participant ID menu in settings'
      }),
      onPress: () => navigation.navigate('SettingsUserId'),
      disabled: !user.userId || !user.token
    }
  ];

  const notificationsItem = [
    {
      title: intl.formatMessage({
        defaultMessage: 'Scheduled event',
        description: 'Scheduled event toggle in settings'
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Push Notification',
        description: 'Push Notification description switch in settings'
      })
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'New interim measurement',
        description: 'New interim measurement switch in settings'
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Push Notification',
        description: 'Push Notification description switch in settings'
      })
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'New session',
        description: 'New session switch in settings'
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Push Notification',
        description: 'Push Notification description switch in settings'
      })
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Mood tracking',
        description: 'Mood tracking switch in settings'
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Push Notification',
        description: 'Push Notification description switch in settings'
      }),
      onPress: () => {}
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'New helper message',
        description: 'New helper message switch in settings'
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Email Notification',
        description: 'Email Notification description switch in settings'
      })
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'New interim measurement',
        description: 'New interim measurement switch in settings'
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Email Notification',
        description: 'Email Notification description switch in settings'
      })
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'New session',
        description: 'New session switch in settings'
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Email Notification',
        description: 'Email Notification description switch in settings'
      })
    }
  ];

  return (
    <ScrollView
      contentContainerStyle={{
        paddingBottom: 20,
        ...(Platform.OS === 'web' && {
          height: windowHeight - headerHeight
        })
      }}
      style={styles.container}
    >
      <MediumText style={styles.title}>
        {intl.formatMessage({
          defaultMessage: 'Personal Information',
          description: 'Settings personal information title'
        })}
      </MediumText>
      <View style={styles.itemsContainer}>
        {personalInfosItems.map((item, idx) => (
          <SettingsMenuItem
            {...item}
            rtl={isRtl(language)}
            type="arrow"
            hideBorder={idx === personalInfosItems.length - 1}
          />
        ))}
      </View>

      {/* <MediumText style={styles.title}>
        {intl.formatMessage({
          defaultMessage: 'Notifications',
          description: 'Settings Notifications title'
        })}
      </MediumText>
      <View style={styles.itemsContainer}>
        {notificationsItem.map((item, idx) => (
          <SettingsMenuItem
            {...item}
            type="switch"
            value={notifIndexes.includes(idx)}
            onPress={() =>
              setNotifIndexes(
                notifIndexes.includes(idx)
                  ? notifIndexes.filter((notifIdx) => notifIdx !== idx)
                  : [...notifIndexes, idx]
              )
            }
            hideBorder={idx === notificationsItem.length - 1}
          />
        ))}
      </View> */}

      <View style={[styles.itemsContainer, { marginTop: 44 }]}>
        <SettingsMenuItem
          title={intl.formatMessage({
            defaultMessage: 'Logout',
            description: 'Logout button'
          })}
          type="danger"
          onPress={() =>
            navigation.navigate('ConfirmationQuestion', {
              onPressYes: async () => {
                if (Platform.OS !== 'web') {
                  await messaging().deleteToken(); // Invalidate the token so that the one that was saved in the DB doesn't work anymore
                }
                dispatch(logout());
              }
            })
          }
          hideBorder
        />
      </View>
      {/development|staging/.test(env.ENV as string) && (
        <>
          <TouchableOpacity style={styles.itemsContainer}>
            <SettingsMenuItem
              title={intl.formatMessage({
                defaultMessage: 'Debug mode',
                description: 'Debug mode button'
              })}
              onPress={() => {
                dispatch(setDebugMode(!user.debugMode));
              }}
              value={user.debugMode ?? false}
              type="switch"
              hideBorder
            />
          </TouchableOpacity>
          {user.debugMode && (
            <>
              <MediumText style={styles.title}>
                {intl.formatMessage({
                  defaultMessage: 'FCM token',
                  description: 'FCM token '
                })}
              </MediumText>
              <MediumText
                style={[styles.fcmToken, styles.itemsContainer]}
                selectable
              >
                {user.fcmToken}
              </MediumText>
            </>
          )}
        </>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  itemsContainer: {
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: Colors.GREY5,
    backgroundColor: Colors.WHITE,
    paddingLeft: 24
  },
  title: {
    color: Colors.GREY2,
    fontSize: 14,
    paddingTop: 27,
    paddingLeft: 24,
    paddingBottom: 9
  },
  fcmToken: {
    paddingHorizontal: 24,
    paddingVertical: 10
  }
});

export default SettingsScreen;
