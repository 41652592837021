import React, { FunctionComponent } from 'react';
import {
  Image,
  ImageRequireSource,
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps
} from 'react-native';

import Colors from '../colors';
import { Asset } from '../store/types';
import MediumText from './MediumText';

interface Props extends TouchableOpacityProps {
  title: string;
  locked?: boolean;
  iconSource?: ImageRequireSource | Asset;
  lockedIconSource?: ImageRequireSource | Asset;
}

const parseIconSource = (src?: ImageRequireSource | Asset) => {
  let iconSource;

  if (src) {
    iconSource = typeof src !== 'number' ? { uri: src.url } : src;
  } else {
    iconSource = require('../assets/almamar.png');
  }

  return iconSource;
};

const ListItem: FunctionComponent<Props> = (props) => {
  const iconSource = parseIconSource(props.iconSource);
  const lockedIconSource = parseIconSource(props.lockedIconSource);

  const getIcon = () => {
    if (props.locked) {
      return (
        lockedIconSource && (
          <Image style={styles.icon} source={lockedIconSource} />
        )
      );
    }
    return iconSource && <Image style={styles.icon} source={iconSource} />;
  };
  return (
    <TouchableOpacity
      {...props}
      style={[styles.container, props.style]}
      disabled={props.locked}
    >
      {getIcon()}
      <MediumText
        style={[
          styles.title,
          props.locked && {
            color: Colors.GREY3
          }
        ]}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {props.title}
      </MediumText>
    </TouchableOpacity>
  );
};

export default ListItem;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 64,
    padding: 8,
    paddingRight: 20,
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: Colors.WHITE,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 16
  },
  title: {
    fontSize: 16,
    flex: 1
  }
});
