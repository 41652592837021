import React, { FunctionComponent } from 'react';
import { Image, TouchableOpacity, TouchableOpacityProps } from 'react-native';

const FAB: FunctionComponent<TouchableOpacityProps> = (props) => {
  return (
    <TouchableOpacity {...props}>
      <Image
        source={require('../assets/plus.png')}
        style={{ height: 48, width: 48 }}
      />
    </TouchableOpacity>
  );
};

export default FAB;
