import messaging from '@react-native-firebase/messaging';
import {
  RouteProp,
  useFocusEffect,
  useNavigation,
  useRoute
} from '@react-navigation/native';
import { StackNavigationProp, useHeaderHeight } from '@react-navigation/stack';
import React, { FunctionComponent, useState, useRef, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  View,
  StyleSheet,
  Image,
  Keyboard,
  Platform,
  TouchableOpacity,
  TextInput,
  Pressable,
  KeyboardAvoidingView
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Colors from '../../../colors';
import AlertBanner from '../../../components/AlertBanner';
import KeyboardInput from '../../../components/KeyboardInput';
import MediumText from '../../../components/MediumText';
import PillButton from '../../../components/PillButton';
import { useAppDispatch } from '../../../store/hooks';
import { authenticate, getUserData, saveFcmToken } from '../../../store/user';
import { AppStackParamList } from '../../AppNavigator';
import { AuthStackParamList } from '../AuthenticationNavigator';

interface FormValues {
  username: string;
  password: string;
}

const LoginScreen: FunctionComponent<{
  onFinish?: () => void | Promise<void>;
}> = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorBannerVisible, setErrorBannerVisible] = useState(false);
  const [bannerText, setBannerText] = useState('');
  const passwordInput = useRef<TextInput>(null);
  const intl = useIntl();
  const insets = useSafeAreaInsets();
  const dispatch = useAppDispatch();
  const navigation =
    useNavigation<
      StackNavigationProp<AppStackParamList & AuthStackParamList>
    >();
  const route = useRoute<RouteProp<AuthStackParamList, 'Login'>>();
  const headerHeight = useHeaderHeight();
  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm<FormValues>();
  const username = useRef('');
  const password = useRef('');
  username.current = watch('username', '');
  password.current = watch('password', '');

  useFocusEffect(
    useCallback(() => {
      reset();
    }, [])
  );

  const handleLogin = async () => {
    Keyboard.dismiss();

    const resultAuthAction = await dispatch(
      authenticate({ username: username.current, password: password.current })
    );
    if (authenticate.fulfilled.match(resultAuthAction)) {
      Platform.OS !== 'web' &&
        (await dispatch(saveFcmToken(await messaging().getToken())));
      await dispatch(getUserData());
      if (props.onFinish) {
        await props.onFinish();
      } else {
        navigation.navigate('Tab', {
          screen: 'Home',
          params: { screen: 'Home' }
        });
      }
    } else {
      if (resultAuthAction.error.message?.match(/ERR_USER_EXCLUDED/)) {
        setBannerText(
          intl.formatMessage({
            description: 'AlertBanner user excluded text',
            defaultMessage: 'Error! User got excluded.'
          })
        );
      } else {
        setBannerText(
          intl.formatMessage({
            description: 'AlertBanner incorrect credentials',
            defaultMessage: 'Error! The username or password is incorrect.'
          })
        );
        setError('username', { message: getErrorLabel() });
        setError('password', { message: getErrorLabel() });
      }
      showErrorBanner();
    }
  };

  const showErrorBanner = () => {
    setErrorBannerVisible(true);
    setTimeout(() => setErrorBannerVisible(false), 5000);
  };

  const handleForgotPress = () => {
    navigation.navigate('ForgotPassword');
  };

  const getErrorLabel: () => string = () => {
    return intl.formatMessage({
      defaultMessage: 'Username or password is incorrect',
      description: 'Login error label'
    });
  };

  const renderAlertBanner = () => (
    <AlertBanner
      bannerVisible={errorBannerVisible}
      image={require('../../../assets/loginFailed.png')}
      headlineText={intl.formatMessage({
        defaultMessage: 'Login failed!',
        description: 'Login failed banner headline'
      })}
      text={bannerText}
      linkText={intl.formatMessage({
        defaultMessage: 'Forgot password?',
        description: 'Login failed banner forgot password link'
      })}
      onLinkPress={handleForgotPress}
    />
  );

  return (
    <Pressable
      style={styles.container}
      onPress={Platform.OS === 'web' ? undefined : Keyboard.dismiss}
    >
      {renderAlertBanner()}
      <KeyboardAvoidingView
        behavior="position"
        keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 80 + headerHeight}
        style={{ alignItems: 'center' }} // fixes web align
        contentContainerStyle={styles.keyboardAvoidingContentContainer}
      >
        <View style={styles.logoImageContainer}>
          <Image
            source={require('../../../assets/test_assets/small-activities.png')}
            style={styles.logoImage}
          />
        </View>

        <View style={styles.formContainer}>
          <Controller
            control={control}
            name="username"
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <KeyboardInput
                value={value}
                onChangeText={(text) => {
                  onChange(text);
                  clearErrors();
                }}
                onBlur={onBlur}
                autoCorrect={false}
                autoCapitalize="none"
                returnKeyType="next"
                autoComplete="username"
                textContentType="username"
                style={styles.input}
                multiline={false}
                placeholder="Username"
                onSubmitEditing={() => passwordInput.current?.focus()}
                hasValidation
                errorText={errors.username?.message}
              />
            )}
          />
          <View style={{ marginTop: 13 }}>
            <Controller
              control={control}
              name="password"
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <KeyboardInput
                  ref={passwordInput}
                  value={value}
                  onChangeText={(text) => {
                    onChange(text);
                    clearErrors();
                  }}
                  onBlur={onBlur}
                  autoCorrect={false}
                  autoCapitalize="none"
                  returnKeyType="done"
                  autoComplete="password"
                  textContentType="password"
                  style={[
                    styles.input,
                    !password.current && { paddingRight: 80 }
                  ]}
                  multiline={false}
                  placeholder="Password"
                  secureTextEntry={!passwordVisible}
                  placeholderTextColor={Colors.GREY3}
                  onSubmitEditing={handleSubmit(handleLogin)}
                  hasValidation
                  errorText={errors.password?.message}
                  showPasswordToggleButton={!!password.current}
                  onPressPasswordToggleButton={() =>
                    setPasswordVisible(!passwordVisible)
                  }
                />
              )}
            />
            {!password.current ? (
              <View style={styles.inputButtonWrapper}>
                <TouchableOpacity onPress={handleForgotPress}>
                  <MediumText style={styles.forgotText}>
                    <FormattedMessage
                      defaultMessage="Forgot?"
                      description="Login screen password forgotten button"
                    />
                  </MediumText>
                </TouchableOpacity>
              </View>
            ) : null}
          </View>

          <PillButton
            disabled={!username.current || !password.current}
            style={{ alignSelf: 'flex-end', marginTop: 13 }}
            onPress={handleSubmit(handleLogin, showErrorBanner)}
          >
            <FormattedMessage
              defaultMessage="Log in"
              description="Log in screen button"
            />
          </PillButton>
        </View>
      </KeyboardAvoidingView>
      {route.params?.showRegistrationHint && (
        <View style={[styles.hintContainer, { bottom: insets.bottom }]}>
          <TouchableOpacity onPress={() => navigation.navigate('Welcome')}>
            <MediumText style={styles.hintText}>
              <FormattedMessage
                defaultMessage="You don't have an account yet? {newLine}Create one here."
                description="LoginScreen account creation hint"
                values={{ newLine: '\n' }}
              />
            </MediumText>
          </TouchableOpacity>
        </View>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  keyboardAvoidingContentContainer: {
    width: '100%',
    alignItems: 'center'
  },
  logoImageContainer: {
    height: 283,
    justifyContent: 'center'
  },
  logoImage: {
    resizeMode: 'contain',
    height: 120,
    width: 120
  },
  formContainer: {
    width: '80%'
  },
  input: {
    width: '100%',
    height: 48,
    borderRadius: 16,
    lineHeight: 20,
    justifyContent: 'center'
  },
  inputButtonWrapper: {
    height: 48,
    position: 'absolute',
    right: 16,
    justifyContent: 'center'
  },
  forgotText: {
    color: Colors.BLUE
  },
  closeImageWrapper: {
    position: 'absolute',
    left: 0,
    height: 45,
    width: 45,
    marginLeft: 5,
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeImage: {
    height: 24,
    width: 24
  },
  hintContainer: {
    marginVertical: 20,
    flexGrow: 1,
    justifyContent: 'flex-end'
  },
  hintText: {
    lineHeight: 26,
    textAlign: 'center',
    color: Colors.BLUE
  }
});

export default LoginScreen;
