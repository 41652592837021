import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { View, StyleSheet } from 'react-native';

import KeywordSelect from '../../../../../components/KeywordSelect';
import MultipleSelect from '../../../../../components/MultipleSelect';
import PillButton from '../../../../../components/PillButton';
import { Option, QuestionBlock } from '../../../../../store/types';

interface MultipleSelectAnswerProps {
  block: QuestionBlock;
  value: Option[];
  onValueChange: (value: Option[]) => void;
  completed: boolean;
  onComplete: () => void;
  loading?: boolean;
}

const MultipleSelectAnswer: FunctionComponent<MultipleSelectAnswerProps> = (
  props
) => {
  const renderCompletedBlock = () => {
    const answers = Array.isArray(props.block.answer)
      ? props.block.answer.map((answer) => {
          const chosenOption = props.block.options.find(
            (option) => option.value === answer
          );
          return chosenOption?.text ?? answer;
        })
      : [];

    return (
      <View style={styles.completedContainer}>
        {answers.map((answer, idx) => (
          <PillButton
            key={idx}
            disabled
            style={{ marginRight: 10, marginTop: 10 }}
          >
            {answer}
          </PillButton>
        ))}
      </View>
    );
  };

  const renderUncompletedBlock = () => {
    return (
      <>
        <View style={styles.uncompletedContainer}>
          {props.block.type === 'multiple-select' && (
            <MultipleSelect
              selected={props.value}
              values={props.block.options}
              onSelect={(option) => props.onValueChange(option as Option[])}
              buttonStyle={{ alignSelf: 'flex-end' }}
            />
          )}
          {props.block.type === 'keyword-select' && (
            <KeywordSelect
              selected={props.value}
              values={props.block.options}
              onSelect={(option) => props.onValueChange(option as Option[])}
              style={{ justifyContent: 'flex-end' }}
            />
          )}
        </View>
        <PillButton
          disabled={
            props.value.length === 0 && props.block.options.length !== 0
          }
          onPress={() => props.onComplete()}
          loading={props.loading}
        >
          <FormattedMessage
            defaultMessage="Save"
            description="Save button text for blocks"
          />
        </PillButton>
      </>
    );
  };

  if (props.completed) {
    return renderCompletedBlock();
  } else {
    return renderUncompletedBlock();
  }
};

export default MultipleSelectAnswer;

const styles = StyleSheet.create({
  uncompletedContainer: {
    marginBottom: 12,
    width: '100%',
    alignItems: 'flex-end',
    marginTop: -10,
    marginRight: -10
  },
  completedContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: -10,
    marginRight: -10
  }
});
