import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import Colors from '../colors';
import MediumText from './MediumText';

interface Props {
  title?: string;
}

const Popup: FunctionComponent<PropsWithChildren<Props>> = (props) => {
  return (
    <View style={styles.backdrop}>
      <View style={styles.popup}>
        <View style={styles.header}>
          <MediumText style={styles.title}>{props.title}</MediumText>
        </View>
        {props.children}
      </View>
    </View>
  );
};

export default Popup;

const styles = StyleSheet.create({
  backdrop: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  popup: {
    backgroundColor: Colors.WHITE,
    width: 327,
    overflow: 'hidden'
  },
  header: {
    paddingVertical: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: Colors.GREY5
  },
  title: { textAlign: 'center', padding: 16 }
});
