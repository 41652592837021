import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { View, StyleSheet } from 'react-native';

import Colors from '../../../colors';
import PillButton from '../../../components/PillButton';
import Popup from '../../../components/Popup';
import { RootStackParamList } from '../../RootNavigator';

const DrawerCloseExercise: FunctionComponent = () => {
  const intl = useIntl();

  const route =
    useRoute<RouteProp<RootStackParamList, 'DrawerCloseExercise'>>();
  const navigation = useNavigation();

  const handleSelect = (choice: boolean) => {
    if (choice) {
      route.params.closeExercise();
    }
    navigation.goBack();
  };

  return (
    <Popup
      title={
        route.params.text ??
        intl.formatMessage({
          defaultMessage: 'Are you sure you would like to exit this exercise?',
          description: 'Close exercise popup default text'
        })
      }
    >
      <View style={styles.container}>
        <PillButton
          onPress={() => handleSelect(true)}
          style={styles.yesPillButton}
        >
          <FormattedMessage defaultMessage="Yes" description="Yes" />
        </PillButton>
        <PillButton
          onPress={() => handleSelect(false)}
          style={styles.noPillButton}
          textStyle={styles.noButtonText}
        >
          <FormattedMessage defaultMessage="No" description="No" />
        </PillButton>
      </View>
    </Popup>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.WHITE,
    flexDirection: 'row-reverse',
    padding: 16
  },
  yesPillButton: { marginLeft: 20 },
  noPillButton: {
    backgroundColor: Colors.WHITE
  },
  noButtonText: {
    color: Colors.BLUE
  }
});

export default DrawerCloseExercise;
