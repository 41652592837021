import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { View, StyleSheet, Image, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import Colors from '../../../../colors';
import Drawer from '../../../../components/Drawer';
import MediumText from '../../../../components/MediumText';
import MoodIcon, { Mood } from '../../../../components/MoodIcon';
import { RootStackParamList } from '../../../RootNavigator';

const MoodTrackerScreen: FunctionComponent = () => {
  const [answer, setAnswer] = useState<Mood | null>(null);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const intl = useIntl();
  const shuffledMoods: Mood[] = shuffle([
    'very-sad',
    'sad',
    'meh',
    'happy',
    'very-happy'
  ]);

  const mapMoodToColor = (mood: Mood) => {
    switch (mood) {
      case 'very-sad':
        return Colors.PASTELRED;
      case 'sad':
        return Colors.MELON;
      case 'meh':
        return Colors.BLONDE;
      case 'happy':
        return Colors.SURFGREEN;
      case 'very-happy':
        return Colors.ICEBLUE;
    }
  };

  const mapMoodToString = (mood: Mood) => {
    switch (mood) {
      case 'very-sad':
        return intl.formatMessage({
          defaultMessage: 'very bad',
          description: 'MoodTracker drawer Mood label'
        });
      case 'sad':
        return intl.formatMessage({
          defaultMessage: 'bad',
          description: 'MoodTracker drawer Mood label'
        });
      case 'meh':
        return intl.formatMessage({
          defaultMessage: 'moderate',
          description: 'MoodTracker drawer Mood label'
        });
      case 'happy':
        return intl.formatMessage({
          defaultMessage: 'good',
          description: 'MoodTracker drawer Mood label'
        });
      case 'very-happy':
        return intl.formatMessage({
          defaultMessage: 'very good',
          description: 'MoodTracker drawer Mood label'
        });
    }
  };

  const handleMoodLogged = (mood: Mood) => {
    setAnswer(mood);
    // TODO: add store functionality
  };

  const renderIcon = (mood: Mood) => (
    <TouchableOpacity
      style={{ alignItems: 'center' }}
      onPress={() => handleMoodLogged(mood)}
    >
      <MoodIcon mood={mood} />
      <MediumText style={{ lineHeight: 20, marginTop: 5, textAlign: 'center' }}>
        {mapMoodToString(mood)}
      </MediumText>
    </TouchableOpacity>
  );

  return (
    <Drawer
      title={intl.formatMessage({
        defaultMessage: 'Mood tracker',
        description: 'Mood tracking drawer title'
      })}
    >
      {!answer ? (
        <View style={styles.questionContainer}>
          <MediumText style={styles.title}>
            <FormattedMessage
              defaultMessage="How are you feeling?"
              description="MoodTracker drawer prompt text"
            />
          </MediumText>
          <View style={{ marginTop: 78 }}>
            {renderIcon(shuffledMoods[0])}
            <View style={styles.secondRowContainer}>
              {renderIcon(shuffledMoods[1])}
              {renderIcon(shuffledMoods[2])}
            </View>
            <View style={styles.thirdRowContainer}>
              {renderIcon(shuffledMoods[3])}
              {renderIcon(shuffledMoods[4])}
            </View>
          </View>
        </View>
      ) : (
        <View
          style={[
            styles.answerContainer,
            { backgroundColor: mapMoodToColor(answer) }
          ]}
        >
          <MediumText style={styles.title}>
            <FormattedMessage
              defaultMessage="Mood logged!"
              description="MoodTracker drawer answered text"
            />
          </MediumText>
          <View style={{ alignItems: 'center', marginTop: 78 }}>
            <MoodIcon mood={answer} />
            <MediumText style={{ lineHeight: 20, marginTop: 5 }}>
              {mapMoodToString(answer)}
            </MediumText>
          </View>

          <View style={styles.absoluteContainer}>
            <TouchableOpacity
              style={styles.touchableWrapper}
              onPress={() =>
                navigation.navigate('Drawer', {
                  screen: 'Camera',
                  params: { setUri: (uri) => null }
                })
              }
            >
              <Image
                source={require('../../../../assets/camera.png')}
                style={{ height: 24, width: 24 }}
              />
            </TouchableOpacity>
          </View>
        </View>
      )}
    </Drawer>
  );
};

const styles = StyleSheet.create({
  questionContainer: {
    flex: 1,
    backgroundColor: Colors.WHITE
  },
  answerContainer: {
    flex: 1
  },
  title: {
    marginTop: 75,
    marginHorizontal: 50,
    textAlign: 'center',
    lineHeight: 27,
    fontSize: 20
  },
  firstRowContainer: {},
  secondRowContainer: {
    flexDirection: 'row',
    marginTop: 6,
    justifyContent: 'space-between',
    marginHorizontal: Platform.OS === 'web' ? 298 : 35
  },
  thirdRowContainer: {
    flexDirection: 'row',
    marginTop: 43,
    justifyContent: 'space-between',
    marginHorizontal: Platform.OS === 'web' ? 344 : 81
  },
  absoluteContainer: {
    position: 'absolute',
    bottom: 53,
    right: 24
  },
  touchableWrapper: {
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default MoodTrackerScreen;

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
function shuffle<T>(array: T[]): T[] {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
