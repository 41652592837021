import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View, SafeAreaView, FlatList, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Colors from '../../../../colors';
import FAB from '../../../../components/FAB';
import {
  selectExercisesByIds,
  selectResponsibilityPieExercises
} from '../../../../store/exercises';
import { useAppSelector } from '../../../../store/hooks';
import { ResponsibilityPieReason } from '../../../../store/types';
import ResponsibilityPieArchiveItem from './components/ResponsibilityPieArchiveItem';

const ResponsibilityPieArchiveScreen = () => {
  const { debugMode } = useAppSelector((state) => state.user);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const exercises = useAppSelector(selectResponsibilityPieExercises);
  const linkedTriangles = useAppSelector(
    selectExercisesByIds(
      exercises.map((e) => (e as any).linkedCognitiveTriangleId)
    )
  );

  const renderItem = ({ item }: any) => {
    const onPress = () => {
      navigation.navigate('Drawer', {
        screen: 'ResponsibilityPieArchiveItem',
        params: {
          reasons: item.reasons
        }
      });
    };

    const triangle = linkedTriangles.find(
      (t) => t.id === item.linkedCognitiveTriangleId
    );
    let topic = 'Undefined';
    if (triangle) {
      topic = (triangle as any).unhelpful.thought.text;
    }

    return (
      <ResponsibilityPieArchiveItem
        topic={topic ?? 'Undefined'}
        date={item.createdAt}
        onPress={onPress}
      />
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={exercises}
        renderItem={renderItem}
        contentContainerStyle={{
          paddingTop: 16,
          paddingBottom: insets.bottom + 8
        }}
      />
      {debugMode && (
        <View style={[styles.fabContainer, { bottom: insets.bottom + 16 }]}>
          <FAB
            onPress={() =>
              navigation.navigate('Drawer', {
                screen: 'ResponsibilityPie',
                params: {
                  reasons: [] as ResponsibilityPieReason[],
                  instruction: 'Initial instruction'
                }
              })
            }
          />
        </View>
      )}
    </SafeAreaView>
  );
};

export default ResponsibilityPieArchiveScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND
  },
  fabContainer: {
    position: 'absolute',
    right: 20
  }
});
