import React, { FunctionComponent } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';

interface WrittenExposureArchiveItemProps {
  topic: string;
  date: string;
  text: string;
  onPress: () => void;
}

const WrittenExposureArchiveItem: FunctionComponent<
  WrittenExposureArchiveItemProps
> = (props) => {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <View style={styles.container}>
        <View style={styles.upperContainer}>
          <View style={styles.headingContainer}>
            <MediumText style={styles.heading}>
              <FormattedMessage
                defaultMessage="Topic"
                description="WrittenExposureArchiveItem topic label"
              />
            </MediumText>
            <MediumText style={styles.heading}>
              <FormattedDate
                value={new Date(props.date)}
                year="numeric"
                month="2-digit"
                day="2-digit"
              />
            </MediumText>
          </View>
          <MediumText style={styles.topic}>{props.topic}</MediumText>
        </View>
        <MediumText style={styles.textPreview} numberOfLines={3}>
          {props.text}
        </MediumText>
      </View>
    </TouchableOpacity>
  );
};

export default WrittenExposureArchiveItem;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.WHITE,
    padding: 16,
    marginHorizontal: 16,
    marginBottom: 8,
    borderRadius: 4,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.18,
    shadowRadius: 1,
    elevation: 1
  },
  upperContainer: {
    borderBottomWidth: 1,
    borderColor: Colors.GREY5
  },
  headingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  heading: {
    fontSize: 14,
    lineHeight: 20,
    color: Colors.GREY2,
    marginBottom: 2
  },
  topic: {
    fontSize: 16,
    lineHeight: 26,
    color: Colors.GREY1,
    paddingBottom: 8
  },
  textPreview: {
    marginTop: 8,
    fontSize: 16,
    lineHeight: 26,
    color: Colors.GREY1
  }
});
