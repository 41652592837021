import { format, startOfDay, startOfToday } from 'date-fns';
import React, { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { ScrollView, StyleSheet, View } from 'react-native';

import Colors from '../../../../colors';
import Calendar from '../../../../components/Calendar';
import MediumText from '../../../../components/MediumText';
import { RootState } from '../../../../store';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectors, upsertSubstanceEntry } from '../../../../store/substances';
import { Substance } from '../../../../store/types';
import Counter from './components/Counter';

const SubstanceScreen: FunctionComponent = () => {
  const today = startOfToday();
  const [selected, setSelected] = useState(today);
  const substances = useAppSelector((state: RootState) =>
    selectors.selectDaysEntries(state, startOfDay(selected))
  );
  const loggedDays = useAppSelector(selectors.selectedLoggedDays);

  const dispatch = useAppDispatch();
  const intl = useIntl();

  const handleSelection = (timestamp: number) => {
    const newSelection = new Date(timestamp);
    setSelected(newSelection);
  };

  const handleUpdate = (substance: Substance) => (count: number) => {
    if (count >= 0) {
      dispatch(
        upsertSubstanceEntry({
          substance,
          date: format(selected, 'yyyy-MM-dd'),
          count
        })
      );
    }
  };

  const getSubstanceValue = (substance: Substance) =>
    substances?.find((entry) => entry.substance === substance)?.count;

  const substanceCounters = [
    {
      type: Substance.BeerBig,
      category: 'alcohol',
      icon: require('./assets/beer-big.png'),
      label: intl.formatMessage({
        defaultMessage: 'Bottle of beer 0,5',
        description: 'Substance tracking, alcohol'
      })
    },
    {
      type: Substance.BeerSmall,
      category: 'alcohol',
      icon: require('./assets/beer-small.png'),
      label: intl.formatMessage({
        defaultMessage: 'Bottle of beer 0,3',
        description: 'Substance tracking, alcohol'
      })
    },
    {
      type: Substance.WineRed,
      category: 'alcohol',
      icon: require('./assets/wine-red.png'),
      label: intl.formatMessage({
        defaultMessage: 'Glass of red wine',
        description: 'Substance tracking, alcohol'
      })
    },
    {
      type: Substance.WineWhite,
      category: 'alcohol',
      icon: require('./assets/wine-white.png'),
      label: intl.formatMessage({
        defaultMessage: 'Glass of white wine',
        description: 'Substance tracking, alcohol'
      })
    },
    {
      type: Substance.Whisky,
      category: 'alcohol',
      icon: require('./assets/whiskey.png'),
      label: intl.formatMessage({
        defaultMessage: 'Glass of whiskey',
        description: 'Substance tracking, alcohol'
      })
    },
    {
      type: Substance.Shot,
      category: 'alcohol',
      icon: require('./assets/shot.png'),
      label: intl.formatMessage({
        defaultMessage: 'Shot',
        description: 'Substance tracking, alcohol'
      })
    },
    {
      type: Substance.Pill,
      category: 'drugs',
      icon: require('./assets/pill.png'),
      label: intl.formatMessage({
        defaultMessage: 'Pill',
        description: 'Substance tracking, drugs'
      })
    },
    {
      type: Substance.Cannabis,
      category: 'drugs',
      icon: require('./assets/cannabis.png'),
      label: intl.formatMessage({
        defaultMessage: 'Joint',
        description: 'Substance tracking, drugs'
      })
    },
    {
      type: Substance.Needle,
      category: 'drugs',
      icon: require('./assets/needle.png'),
      label: intl.formatMessage({
        defaultMessage: 'Needle',
        description: 'Substance tracking, drugs'
      })
    },
    {
      type: Substance.Cocaine,
      category: 'drugs',
      icon: require('./assets/cocaine.png'),
      label: intl.formatMessage({
        defaultMessage: 'Cocaine',
        description: 'Substance tracking, drugs'
      })
    },
    {
      type: Substance.Gambling,
      category: 'gambling',
      icon: require('./assets/gambling.png'),
      label: intl.formatMessage({
        defaultMessage: 'Hours of gambling',
        description: 'Substance tracking, gambling'
      })
    }
  ];

  return (
    <ScrollView
      contentContainerStyle={styles.content}
      style={styles.container}
      showsVerticalScrollIndicator={false}
    >
      <View>
        <Calendar
          currentDate={today}
          markedDates={loggedDays}
          selectedDate={selected}
          onSelect={handleSelection}
          weekly
        />
      </View>
      <MediumText style={styles.categoryHeader}>
        {intl.formatMessage({
          defaultMessage: 'Alcohol',
          description: 'Substance tracking category, alcohol'
        })}
      </MediumText>
      <View style={styles.categoryContainer}>
        {substanceCounters
          .filter((counter) => counter.category === 'alcohol')
          .map((counter) => (
            <Counter
              icon={counter.icon}
              value={getSubstanceValue(counter.type)}
              update={handleUpdate(counter.type)}
              label={counter.label}
            />
          ))}
      </View>
      <MediumText style={styles.categoryHeader}>
        {intl.formatMessage({
          defaultMessage: 'Drugs',
          description: 'Substance tracking category, drugs'
        })}
      </MediumText>
      <View style={styles.categoryContainer}>
        {substanceCounters
          .filter((counter) => counter.category === 'drugs')
          .map((counter) => (
            <Counter
              icon={counter.icon}
              value={getSubstanceValue(counter.type)}
              update={handleUpdate(counter.type)}
              label={counter.label}
            />
          ))}
      </View>
      <MediumText style={styles.categoryHeader}>
        {intl.formatMessage({
          defaultMessage: 'Gambling',
          description: 'Substance tracking category, gambling'
        })}
      </MediumText>
      <View style={styles.categoryContainer}>
        {substanceCounters
          .filter((counter) => counter.category === 'gambling')
          .map((counter) => (
            <Counter
              icon={counter.icon}
              value={getSubstanceValue(counter.type)}
              update={handleUpdate(counter.type)}
              label={counter.label}
            />
          ))}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 34,
    paddingHorizontal: 16,
    backgroundColor: Colors.BACKGROUND
  },
  content: {
    paddingBottom: 56
  },
  categoryContainer: {
    backgroundColor: Colors.WHITE,
    paddingHorizontal: 16,
    paddingVertical: 10,
    shadowColor: Colors.SHADOW,
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    width: '100%',
    borderRadius: 4
  },
  categoryHeader: {
    marginTop: 36,
    marginBottom: 6,
    fontSize: 20
  }
});

export default SubstanceScreen;
