import { useNavigation } from '@react-navigation/native';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, ScrollView } from 'react-native';

import Drawer from '../../components/Drawer';
import MediumText from '../../components/MediumText';
import SemiBoldText from '../../components/SemiBoldText';

const PrivacyPolicyDrawer: FunctionComponent = () => {
  const navigation = useNavigation();
  const intl = useIntl();

  const text = [
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: '1. DIRECT und Ihre Daten',
        description: 'Privacy policy 1 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Der Schutz und die Vertraulichkeit Ihrer Daten bei der Nutzung von DIRECT sind uns sehr wichtig. Wir sind uns bewusst, dass wir in unseren Studien sensible Informationen erheben, die einen hohen Schutz erfordern. Aus diesem Grund erfassen wir so wenig personenbezogene Daten wie möglich und sichern deren Verarbeitung nach höchsten technischen Standards.\n\nDie Freie Universität Berlin, Kaiserswerther Straße 16-18, 14195 Berlin ist verantwortlich für den Schutz Ihrer Daten gemäß der Europäischen Datenschutzverordnung (DSGVO).\n\nWenn Sie Fragen zu den folgenden Datenschutzhinweisen haben, zögern Sie bitte nicht, uns zu kontaktieren.\n\nSie erreichen uns unter: klinpsy-int@psychologie.fu-berlin.de`,
        description: 'Privacy policy 1 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: '2. Worum geht es beim Datenschutz?',
        description: 'Privacy policy 2 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Beim Datenschutz geht es um den Schutz der personenbezogenen Daten von Einzelpersonen. Dies betrifft Daten, die sich auf eine identifizierte oder identifizierbare lebende Person beziehen. Dazu gehören Informationen wie Name, IP-Adresse, E-Mail-Adresse oder Telefonnummer. Informationen, die nicht mit Ihrer Identität in Verbindung gebracht werden können, fallen nicht unter diese Kategorie.\n\nBei der Erhebung und Nutzung personenbezogener Daten beschränken wir uns strikt auf das technisch Notwendige und halten uns an die gesetzlichen Vorgaben. Wann immer möglich, verzichten wir auf die Erhebung personenbezogener Daten. Wir erheben und nutzen personenbezogene Daten nur dann, wenn Sie uns dazu Ihre Einwilligung gegeben haben oder wenn wir gesetzlich dazu berechtigt sind. Es ist uns wichtig, dass Sie verstehen, welche Daten wir erheben, warum wir sie erheben und wofür wir sie verwenden.`,
        description: 'Privacy policy 2 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: '3. Welche Daten werden erhoben?',
        description: 'Privacy policy 3 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage:
          'Wir  erheben  und  verarbeiten  folgende  personenbezogene  Daten  von Ihnen, um die ALMAMAR-Studie auf der DIRECT-Plattform durchführen zu können:',
        description: 'Privacy policy 3 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Login-Daten',
        description: 'Privacy policy 3 login title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Ihre Anmeldedaten sind notwendig, um Ihr Konto vor unberechtigtem Zugriff  zu  schützen.  Zu  diesem  Zweck  erheben  wir:  Einen  von  Ihnen selbst  gewählten  oder  Ihnen  zugewiesenen  Benutzernamen  und  ein von Ihnen selbst gewähltes Passwort.`,
        description: 'Privacy policy 3 login text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Kontaktinformationen',
        description: 'Privacy policy 3 contact title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Wir erheben Ihre Kontaktdaten (Telefonnummer oder E-Mail-Adresse), um den Zugang zu DIRECT wiederherstellen zu können, falls Sie Ihre Zugangsdaten verlieren. Wir verwenden Ihre Kontaktdaten auch, um Sie an Erhebungen zu erinnern, die Teil der Studie sind. Wenn wir erfahren, dass Sie sich in einer akuten lebensbedrohlichen Krise befinden, behalten wir uns außerdem das Recht vor, einen Rettungsdienst hinzuzuziehen.`,
        description: 'Privacy policy 3 contact text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Gesundheitsdaten',
        description: 'Privacy policy 3 health title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Wir erheben Gesundheitsdaten, um die Wirksamkeit von Angeboten wie ALMAMAR wissenschaftlich zu untersuchen. Wir untersuchen auch, ob sich die Wirksamkeit in Abhängigkeit von Stressoren und psychischer Belastung sowie in Abhängigkeit von der Inanspruchnahme anderer Behandlungen unterscheidet.\n\nZu diesem Zweck erheben wir Fragebögen zu Stimmung, Stressoren, psychischer Belastung, aktuellen Problemen, Vorbehandlungen und der Inanspruchnahme von Gesundheitsleistungen.\n\nDarüber hinaus werden Ihre Einträge in Übungen (Text, Audio und Bilder) gespeichert, so dass Sie von anderen Geräten aus darauf zugreifen können.\n\nWenn Sie das integrierte Nachrichtensystem von DIRECT nutzen, werden Ihre Nachrichten an den Server gesendet und gespeichert, damit Sie mit Ihrem/Ihrer Behandler/in über das integrierte Nachrichtensystem kommunizieren können. Die Nachrichten sind für unser Personal sichtbar, um eine bestmögliche Betreuung zu gewährleisten.`,
        description: 'Privacy policy 3 health text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Demografische Angaben',
        description: 'Privacy policy 3 demographic title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Zu wissenschaftlichen Zwecken werden zusätzliche personenbezogene Daten erhoben. Diese werden verwendet, um zu untersuchen, ob sich die Wirkung von Angeboten wie ALMAMAR in Abhängigkeit von Faktoren wie: Geschlecht, Alter, Nationalität, Bildungshintergrund, Familienstand und beruflicher Status.\n\nDie Verarbeitung Ihrer Daten beruht auf Ihrer freiwilligen Einwilligung zur Teilnahme an der Studie gemäß Artikel 6 Absatz 1 der Allgemeinen Datenschutzverordnung (DSGVO). Diese Einwilligung können Sie jederzeit widerrufen. Damit treten Sie von der Studie zurück.\n\nIhre Daten werden zusammen mit Ihrem Login-Namen gespeichert, solange die Studie läuft. Sobald Sie die Studie beenden, werden alle erhobenen Daten anonymisiert. Das bedeutet, dass die Daten nicht mehr Ihrer Person zugeordnet werden können. Die anonymen Daten werden mindestens 10 Jahre lang aufbewahrt.`,
        description: 'Privacy policy 3 demographic text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'Technische Daten',
        description: 'Privacy policy 3 techincal title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Bei der Nutzung von DIRECT werden Nutzungsdaten gespeichert. Diese Daten werden verwendet, um unberechtigte Zugriffsversuche zu erkennen und zu verfolgen. Sie werden auch dazu verwendet, anonyme Zugriffsstatistiken zu erstellen und damit DIRECT zu verbessern.\n\nWenn Sie die DIRECT Website aufrufen, werden automatisch Daten in den Server-Logfiles gespeichert. Dazu gehören: Datum, Uhrzeit, verwendetes Betriebssystem, Eigenschaften des verwendeten Bildschirms und der verwendete Browser.\n\nWenn Sie die DIRECT App auf Ihrem Smartphone nutzen, werden folgende Daten automatisch in Logfiles gespeichert: Die von Ihnen verwendete Plattform (Android oder iOS), die Version der App, das Gerätemodell und die Systemversion.\n\nDarüber hinaus werden folgende Daten gespeichert: Die Größe der übertragenen Datenmenge, ein sogenannter HTTP-Statuscode (z.B. zur Identifizierung einer erfolgreich übertragenen Webseite), die IP-Adresse des zugreifenden Rechners, wobei diese IP-Adresse durch Löschen des letzten Oktetts dieser Adresse anonymisiert wird.\n\nDie Erhebung und Verwendung der in den Logfiles gespeicherten Informationen wird in anonymen Auswertungen zu statistischen Zwecken (z.B. zur Analyse des Nutzungsverhaltens) und damit zur Verbesserung unseres Angebotes genutzt. Da die IP-Adressen wie oben beschrieben anonymisiert werden, ist eine Zuordnung der Auswertungsergebnisse zu einer bestimmten IP-Adresse nicht möglich.\n\nZu Auswertungszwecken werden die Logfiles für das laufende und das vergangene Kalenderjahr gespeichert. Nach Ablauf dieser Zeit werden die Logfiles gelöscht.`,
        description: 'Privacy policy 3 technical text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: 'App-Nutzungsdaten',
        description: 'Privacy policy 3 app title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Diese Daten geben uns Aufschluss darüber, auf welche Weise und wie oft bestimmte Funktionen der App genutzt werden. Dazu sammeln wir Zeitstempel für Aktionen in der App (z. B. den Beginn einer Sitzung oder den Abschluss einer geplanten Aktivität) und Zähler für Aktionen in der App (z. B. die Anzahl der geplanten Aktivitäten oder die Anzahl der an E-Helfer gesendeten Nachrichten).`,
        description: 'Privacy policy 3 app text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: '4. Wer speichert Ihre Daten?',
        description: 'Privacy policy 4 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Die auf diese Weise verarbeiteten Daten werden von der Freien Universität Berlin elektronisch erhoben, genutzt und gespeichert. Eine Weitergabe an Dritte oder eine Nutzung außerhalb der Freien Universität Berlin erfolgt nicht, es sei denn, Sie haben eingewilligt oder wir sind zur Weitergabe gesetzlich verpflichtet.`,
        description: 'Privacy policy 4 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: '5. Wo werden Ihre Daten gespeichert?',
        description: 'Privacy policy 5 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Ihre Daten werden in Deutschland auf Servern im Rechenzentrum der Hetzner Online GmbH gehostet. Dieser Betreiber garantiert die physische Sicherheit der Daten und den Schutz vor unbefugtem Zugriff über das Netz. Hetzner hält sich an ISO 27001, einen strikten Verhaltenskodex, der die Sicherheit von Informationen und Informationstechnologien betrifft.`,
        description: 'Privacy policy 5 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: '6. Wie sind Ihre Daten geschützt?',
        description: 'Privacy policy 6 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Die App kommuniziert mit dem Server über verschlüsselte Verbindungen mit SSL (Secure Socket Layer), was verhindert, dass unbefugte Dritte Ihre Daten lesen können. Sowohl die Server als auch die Datenbanken befinden sich hinter Firewalls, um den Zugang zu beschränken.`,
        description: 'Privacy policy 6 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: '7. Cookies',
        description: 'Privacy policy 7 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Es werden keine Cookies verwendet.`,
        description: 'Privacy policy 7 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: '8. Aktive Komponenten',
        description: 'Privacy policy 8 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Die DIRECT Website verwendet Java- und Javascript-Anwendungen. Sie können Ihren Browser so einstellen, dass er die Ausführung solcher Anwendungen verweigert oder Sie vorher um Bestätigung bittet. Dies kann auch durch frei verfügbare Zusatzprogramme geschehen. Wenn Sie diese Anwendungen ablehnen, können Sie jedoch möglicherweise bestimmte Funktionen der Website nicht mehr nutzen.`,
        description: 'Privacy policy 8 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: `9. Ihre Rechte`,
        description: 'Privacy policy 9 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Am 25. Mai 2018 ist die Europäische Datenschutzverordnung (DSGVO) in Kraft getreten. Die Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten gemäß der DSGVO ist Ihre Einwilligung zur Teilnahme an dieser Studie.\n\nDie DSGVO stärkt die Rechte der Nutzer im Hinblick auf die Verarbeitung personenbezogener Daten. Als Nutzer der Step-by-Step App oder der Website gelten diese Rechte auch für Sie. Dies gilt unabhängig davon, ob Sie in einem Land der Europäischen Union leben oder nicht.`,
        description: 'Privacy policy 9 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: `Auskunft`,
        description: 'Privacy policy 9.1 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Sie haben das Recht, Auskunft über Ihre personenbezogenen Daten zu erhalten und Zugang zu Ihren personenbezogenen Daten und/oder Kopien davon zu verlangen.`,
        description: 'Privacy policy 9.1 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: `Berichtigung, Löschung oder Einschränkung der Verarbeitung`,
        description: 'Privacy policy 9.2 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Sie haben das Recht, die Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.`,
        description: 'Privacy policy 9.2 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: `Widerruf der Einwilligung und Widerspruch gegen die Verarbeitung`,
        description: 'Privacy policy 9.3 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Sie können Ihre Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten jederzeit widerrufen und der Verarbeitung Ihrer Daten widersprechen.`,
        description: 'Privacy policy 9.3 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: `Übertragbarkeit von Daten`,
        description: 'Privacy policy 9.4 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Sie haben das Recht, die Sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und diese Daten einem anderen Verantwortlichen ohne unser Zutun zu übermitteln.`,
        description: 'Privacy policy 9.4 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: `Recht auf Widerspruch`,
        description: 'Privacy policy 9.5 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Sie haben das Recht, sich an die zuständige Datenschutzaufsichtsbehörde zu wenden und dort Beschwerde einzulegen.`,
        description: 'Privacy policy 9.5 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: `10. Änderungen an der Datenschutzpraxis`,
        description: 'Privacy policy 10 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Aufgrund der ständigen Veränderungen im Internet, vor allem der Technik und auch der einschlägigen Rechtslage, behalten wir uns das Recht vor, unsere Datenschutzpraxis von Zeit zu Zeit anzupassen, sofern dies erforderlich wird. Solche Anpassungen unserer Datenschutzpraxis werden dann mit einer Ankündigungsfrist von zwei Kalenderwochen hier auf dieser Seite bekannt gegeben, sofern dies aufgrund der Umstände, die die Anpassung bedingen, möglich bleibt.`,
        description: 'Privacy policy 10 text'
      })
    },
    {
      bold: true,
      msg: intl.formatMessage({
        defaultMessage: `11. Kontakt`,
        description: 'Privacy policy 11 title'
      })
    },
    {
      msg: intl.formatMessage({
        defaultMessage: `Verantwortlich für die Verarbeitung Ihrer Daten ist Prof. Dr. Christine Knaevelsrud.\n\nBei Fragen zum Umgang mit Ihren Daten kontaktieren Sie uns bitte unter: klinpsy-int@psychologie.fu-berlin.de\n\nDen Datenschutzbeauftragten der Freien Universität Berlin erreichen Sie unter:\ndatenschutz@fu-berlin.de`,
        description: 'Privacy policy 11 text'
      })
    }
  ];

  return (
    <Drawer
      onBackgroundPress={() => navigation.goBack()}
      title={intl.formatMessage({
        defaultMessage: 'Privacy policy',
        description: 'Privacy policy drawer title'
      })}
    >
      <ScrollView style={styles.container}>
        {text.map(({ bold, msg }, idx) =>
          bold ? (
            <SemiBoldText key={`privacy-policy-${idx}`}>{msg}</SemiBoldText>
          ) : (
            <MediumText key={`privacy-policy-${idx}`} style={styles.text}>
              {msg}
            </MediumText>
          )
        )}
      </ScrollView>
    </Drawer>
  );
};

export default PrivacyPolicyDrawer;

const styles = StyleSheet.create({
  container: {
    padding: 16
  },
  arrow: {
    width: 30,
    height: 30,
    resizeMode: 'contain'
  },
  text: {
    marginTop: 6,
    marginBottom: 12
  }
});
