import React, { FunctionComponent } from 'react';
import { Image, ImageProps, StyleSheet } from 'react-native';

const Avatar: FunctionComponent<ImageProps> = (props) => {
  return <Image {...props} style={[styles.image, props.style]} />;
};

export default Avatar;

const styles = StyleSheet.create({
  image: {
    height: 24,
    width: 24,
    borderRadius: 12
  }
});
