import { NavigatorScreenParams } from '@react-navigation/native';
import {
  createStackNavigator,
  StackNavigationOptions
} from '@react-navigation/stack';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Image, StyleSheet, View } from 'react-native';

import Colors from '../colors';
import { useAppSelector } from '../store/hooks';
import { WrittenExposureTemplate } from '../store/types';
import AuthenticationNavigator, {
  AuthStackParamList
} from './Authentication/AuthenticationNavigator';
import WelcomeScreen from './Authentication/WelcomeScreen';
import SessionTabNavigator from './Tab/Home/Session/SessionTabNavigator';
import SettingsNavigator from './Tab/Home/SettingsNavigator';
import CognitiveTriangleArchiveScreen from './Tab/Library/CognitiveTriangleArchiveScreen';
import LetterToAFriendArchiveScreen from './Tab/Library/LetterToAFriendArchiveScreen';
import ResponsibilityPieArchiveScreen from './Tab/Library/ResponsibilityPieArchiveScreen';
import SafetyPlanScreen from './Tab/Library/SafetyPlanScreen';
import WrittenExposureArchiveScreen from './Tab/Library/WrittenExposureArchiveScreen';
import TabNavigator, { TabParamList } from './Tab/TabNavigator';

export type AppStackParamList = {
  Welcome: undefined;
  Authentication: NavigatorScreenParams<AuthStackParamList>;
  Tab: NavigatorScreenParams<TabParamList>;
  Session: { sessionEntityId: string };
  CognitiveTriangleArchive: undefined;
  ResponsibilityPieArchive: undefined;
  WrittenExposureArchive: undefined | { template?: WrittenExposureTemplate };
  LetterToAFriendArchive: undefined;
  SafetyPlan: undefined;
  Settings: undefined;
};

const AppStack = createStackNavigator<AppStackParamList>();

const headerOptions: StackNavigationOptions = {
  headerTitleStyle: {
    fontFamily: 'NotoSans-Medium',
    fontSize: 16,
    color: Colors.GREY2,
    alignSelf: 'center'
  },
  headerBackTitleVisible: false,
  headerShown: true,
  headerBackImage: () => (
    <View style={styles.iconContainer}>
      <Image style={styles.icon} source={require('../assets/Close.png')} />
    </View>
  )
};

const AppNavigator: FunctionComponent = () => {
  const user = useAppSelector((state) => state.user);
  const intl = useIntl();

  return (
    <AppStack.Navigator screenOptions={{ headerShown: false }}>
      {!user.fullname && !user.userId ? (
        <>
          <AppStack.Screen name="Welcome" component={WelcomeScreen} />
          <AppStack.Screen
            name="Authentication"
            component={AuthenticationNavigator}
          />
        </>
      ) : (
        <>
          <AppStack.Screen name="Tab" component={TabNavigator} />
          <AppStack.Screen name="Session" component={SessionTabNavigator} />
          <AppStack.Screen
            name="CognitiveTriangleArchive"
            component={CognitiveTriangleArchiveScreen}
            options={{
              ...headerOptions,
              headerTitle: intl.formatMessage({
                defaultMessage: 'Cognitive Triangle',
                description: 'CognitiveTriangleArchiveScreen title'
              })
            }}
          />
          <AppStack.Screen
            name="WrittenExposureArchive"
            component={WrittenExposureArchiveScreen}
            options={{
              ...headerOptions,
              headerTitle: intl.formatMessage({
                defaultMessage: 'Written Exposure',
                description: 'WrittenExposureArchiveScreen title'
              })
            }}
          />
          <AppStack.Screen
            name="LetterToAFriendArchive"
            component={LetterToAFriendArchiveScreen}
            options={{
              ...headerOptions,
              headerTitle: intl.formatMessage({
                defaultMessage: 'Letter to a friend',
                description: 'LetterToAFriendArchiveScreen title'
              })
            }}
          />
          <AppStack.Screen
            name="ResponsibilityPieArchive"
            component={ResponsibilityPieArchiveScreen}
            options={{
              ...headerOptions,
              headerTitle: intl.formatMessage({
                defaultMessage: 'Responsibility Pie',
                description: 'Responsibility Pie Archive Screen title'
              })
            }}
          />
          <AppStack.Screen
            name="SafetyPlan"
            component={SafetyPlanScreen}
            options={{
              ...headerOptions,
              headerTitle: intl.formatMessage({
                defaultMessage: 'Safety Plan',
                description: 'SafetyPlanScreen title'
              })
            }}
          />
          <AppStack.Screen
            name="Settings"
            component={SettingsNavigator}
            options={{
              ...headerOptions,
              headerShown: false,
              headerTitle: intl.formatMessage({
                defaultMessage: 'Settings',
                description: 'SettingsScreen title'
              })
            }}
          />
        </>
      )}
    </AppStack.Navigator>
  );
};

export default AppNavigator;

const styles = StyleSheet.create({
  iconContainer: {
    height: 45,
    width: 45,
    marginLeft: 5,
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    height: 24,
    width: 24
  }
});
