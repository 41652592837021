import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Update
} from '@reduxjs/toolkit';

import { RootState } from '.';
import env from '../env';
import { parseValue, sortFields, parseIfJson } from './../helpers';
import { getUserFlow } from './flow';
import { selectModuleById } from './modules';
import { fetchSession } from './sessions';
import {
  Answer,
  Block,
  Condition,
  ExerciseBlock,
  WithRequired,
  WrittenExposureTemplate
} from './types';

const blocksAdapter = createEntityAdapter<Block>({
  selectId: (block) => block.id
});

const initialState = blocksAdapter.getInitialState({ loading: false });

export const upsertBlock = createAsyncThunk<
  Update<Block>,
  {
    entityId: string;
    changes: Partial<Block>;
  },
  { state: RootState }
>('blocks/upsertBlock', async ({ entityId, changes }, { getState }) => {
  if (!getState().user.userId) {
    return {
      id: entityId,
      changes
    };
  }

  const blockState = getState().blocks.entities[entityId] as Block;

  // API expects string valued answer value so we stringify it here
  const changesWithStringifiedAnswer = Object.entries(changes).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]:
        key === 'answer' && typeof value !== 'string'
          ? JSON.stringify(value)
          : value
    }),
    {}
  );

  const apiRes = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/sessions/${
      blockState.sessionId
    }/modules/${blockState.moduleId}/${blockState.collection}/${
      blockState.blockId
    }`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(changesWithStringifiedAnswer)
    }
  );

  const apiData = await apiRes.json();

  if (!apiRes.ok) {
    throw new Error(
      apiData.code && apiData.message
        ? `${apiData.code}: ${apiData.message}`
        : `${apiRes.status} ${apiRes.statusText}`
    );
  }

  return {
    id: entityId,
    changes: {
      completed: apiData.completed ?? blockState?.completed,
      completedAt: apiData.completedAt ?? blockState?.completedAt,
      answer: apiData.answer ? parseIfJson(apiData.answer) : blockState?.answer
    }
  };
});

export const upsertAllBlocks = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('blocks/upsertAllBlocks', async (_, { getState, dispatch }) => {
  await Promise.allSettled(
    selectAllBlocks(getState()).map((block) => {
      dispatch(
        upsertBlock({
          entityId: block.id,
          changes: {
            answer: block.answer,
            completed: block.completed,
            completedAt: block.completedAt ?? undefined
          }
        })
      );
    })
  );
});

export const blocksSlice = createSlice({
  name: 'blocks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserFlow.fulfilled, (state, action) => {
      blocksAdapter.upsertMany(state, action.payload.blocks);
    });
    builder.addCase(fetchSession.fulfilled, (state, action) => {
      blocksAdapter.upsertMany(state, action.payload.blocks);
    });
    builder.addCase(upsertBlock.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(upsertBlock.fulfilled, (state, action) => {
      state.loading = false;
      blocksAdapter.updateOne(state, action.payload);
    });
    builder.addCase(upsertBlock.rejected, (state, action) => {
      state.loading = false;
    });
  }
});

export const { selectAll: selectAllBlocks, selectById: selectBlockById } =
  blocksAdapter.getSelectors<RootState>((state) => state.blocks);

export const selectBlocksByMultipleIds = (state: RootState, ids: string[]) => {
  return ids
    .map((id) => selectBlockById(state, id))
    .filter((block) => !!block) as Block[];
};

export const selectBlocksByModuleId = (state: RootState, entityId: string) => {
  const module = selectModuleById(state, entityId);
  if (!module) {
    return [];
  }
  return sortFields(
    module.blockIds
      .map((id) => selectBlockById(state, id))
      .filter((block) => !!block)
  ) as Block[];
};

export const selectFilteredBlocksByModuleId = (
  state: RootState,
  entityId: string
) => {
  return selectBlocksByModuleId(state, entityId).filter(
    (block) => !block.condition || checkBlockCondition(state, block.condition)
  );
};

export const selectCurrentFilteredBlocksByModuleId = createSelector(
  [
    (state: RootState, entityId: string) =>
      selectFilteredBlocksByModuleId(state, entityId)
  ],
  (blocks) => {
    const lastCompletedIndex = ((): number => {
      const index = blocks.map((block) => block.completed).lastIndexOf(true);
      if (index === -1) {
        return 0;
      }
      return index + 1 > blocks.length ? blocks.length : index + 1;
    })();
    return blocks.slice(0, lastCompletedIndex + 1);
  }
);

export const selectUnlockedFeatures = createSelector(
  [selectAllBlocks],
  (blocks) =>
    (
      blocks.filter(
        (block) =>
          Array.isArray(block.unlockFeatures) &&
          block.unlockFeatures.length > 0 &&
          block.completed
      ) as WithRequired<Block, 'unlockFeatures'>[]
    )
      .map((block) => block.unlockFeatures)
      .flat()
);

export const selectAllExerciseBlocks = createSelector(
  [selectAllBlocks],
  (blocks) => blocks.filter((block) => 'exercise' in block) as ExerciseBlock[]
);

export const writtenExposureDefaultTemplate: WrittenExposureTemplate = {
  id: 0,
  time: 30,
  instruction: '',
  rating: true,
  skipTopicSelection: false,
  defaultTopic: null
};

// Written exposure templates are included in exercise blocks and unlocked features
export const selectWrittenExposureTemplates = createSelector(
  [selectAllExerciseBlocks, selectUnlockedFeatures],
  (blocks, unlockedFeatures) => {
    const exerciseBlockTemplates = blocks.flatMap(
      (block) => block.writtenExposureTemplate ?? []
    );

    const unlockedFeaturesTemplates = unlockedFeatures.flatMap((feature) =>
      feature.template && 'time' in feature.template // discriminates WrittenExposureTemplate from ExerciseType Union
        ? feature.template
        : []
    );

    return [writtenExposureDefaultTemplate].concat(
      exerciseBlockTemplates,
      unlockedFeaturesTemplates
    );
  }
);

export const checkBlockCondition = (state: RootState, condition: Condition) => {
  const operations: {
    [op: string]: (a: Answer | number, b: string | number) => boolean;
  } = {
    '=': (a, b) => {
      return Array.isArray(a) ? a.includes(b.toString()) : a === b;
    },
    '!=': (a, b) => {
      return Array.isArray(a) ? !a.includes(b.toString()) : a !== b;
    },
    '<': (a, b) => a < b,
    '<=': (a, b) => a <= b,
    '>': (a, b) => a > b,
    '>=': (a, b) => a >= b
  };
  if (!condition[0].length || !condition[1] || !condition[2]) {
    return true;
  }
  const sumOfBlocks: (blocks: Block[]) => number = (blocks) => {
    const answers = blocks.map((block) => {
      return isNaN(Number(block.answer)) ? 0 : Number(block.answer);
    });
    return answers.reduce((a, b) => a + b, 0);
  };

  const conditionBlocks = selectBlocksByMultipleIds(state, condition[0]);
  const compareTarget: Answer | number =
    conditionBlocks.length === 1
      ? parseValue(conditionBlocks[0].answer)
      : sumOfBlocks(conditionBlocks);
  const compareValue: string | number = parseValue(condition[2]);

  // handle invalid datatype/operator combinations
  if (
    Array.isArray(compareTarget) &&
    condition[1] !== '=' &&
    condition[1] !== '!='
  ) {
    return false;
  }

  return operations[condition[1]](compareTarget, compareValue);
};

export default blocksSlice.reducer;
