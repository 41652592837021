import React, { FunctionComponent } from 'react';
import {
  Image,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View
} from 'react-native';

import Colors from '../../../../../colors';
import MediumText from '../../../../../components/MediumText';
import Switch from '../../../../../components/Switch';

interface Props {
  title: string;
  subtitle?: string;
  rtl?: boolean;
  type: 'arrow' | 'switch' | 'danger' | 'none';
  value?: boolean;
  onPress?: () => void;
  hideBorder?: boolean;
  textStyle?: TextStyle | TextStyle[];
  disabled?: boolean;
}

const SettingsMenuItem: FunctionComponent<Props> = (props) => {
  const renderLeftComponent = () => {
    return (
      <View style={{ flexDirection: 'column' }}>
        <MediumText
          style={[
            styles.title,
            props.type === 'danger' && styles.dangerText,
            props.textStyle,
            props.disabled && styles.textDisabled
          ]}
        >
          {props.title}
        </MediumText>

        {props.subtitle && (
          <MediumText style={styles.subtitle}>{props.subtitle}</MediumText>
        )}
      </View>
    );
  };

  const renderRightComponent = () => {
    if (props.type === 'arrow') {
      return (
        <Image
          source={require('../../../../../assets/arrow_right.png')}
          style={[
            { width: 20, height: 20, marginRight: 18 },
            props.rtl && { transform: [{ scaleX: -1 }] }
          ]}
        />
      );
    }
    if (props.type === 'switch') {
      return (
        <View style={{ marginRight: 24 }}>
          <Switch
            value={!!props.value}
            onValueChange={(val) => {
              props.onPress?.();
            }}
          />
        </View>
      );
    }
  };

  return (
    <TouchableOpacity
      disabled={props.disabled}
      onPress={props.onPress}
      style={[styles.container, !props.hideBorder && styles.border]}
    >
      {renderLeftComponent()}
      {!props.disabled && renderRightComponent()}
    </TouchableOpacity>
  );
};

export default SettingsMenuItem;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.WHITE,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10
  },
  textDisabled: {
    color: Colors.GREY4
  },
  title: {
    fontSize: 16
  },
  subtitle: {
    fontSize: 12,
    color: Colors.GREY3
  },
  border: {
    borderBottomWidth: 1,
    borderColor: Colors.GREY5
  },
  dangerText: {
    color: Colors.RED
  }
});
