import { useNavigation } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, TouchableOpacity, ScrollView } from 'react-native';

import Colors from '../../../../colors';
import SemiBoldText from '../../../../components/SemiBoldText';
import useAppWindowDimensions from '../../../../hooks/useAppWindowDimensions';
import {
  deleteExercise,
  selectAllSafetyItemsGroupedBySection
} from '../../../../store/exercises';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ExerciseType, SafetyItemType } from '../../../../store/types';
import { AppStackParamList } from '../../../AppNavigator';
import { RootStackParamList } from '../../../RootNavigator';
import { safetyPlanDebug } from '../LibraryScreen/debug';
import SafetyItemCard from './components/SafetyItemCard';

const SafetyPlanScreen: FunctionComponent = () => {
  const { debugMode } = useAppSelector((state) => state.user);
  const [editMode, setEditMode] = useState(false);
  let itemsMappedBySection = useAppSelector(
    selectAllSafetyItemsGroupedBySection
  );

  if (debugMode) {
    itemsMappedBySection = [...itemsMappedBySection, ...safetyPlanDebug];
  }

  const dispatch = useAppDispatch();
  const { height: windowHeight } = useAppWindowDimensions();
  const navigation =
    useNavigation<
      StackNavigationProp<AppStackParamList & RootStackParamList>
    >();
  const intl = useIntl();

  navigation.setOptions({
    headerRight: () => (
      <TouchableOpacity
        style={{ marginRight: 20 }}
        onPress={() => setEditMode(!editMode)}
      >
        <SemiBoldText>
          {editMode
            ? intl.formatMessage({
                defaultMessage: 'Done',
                description: 'Done text in header bar'
              })
            : intl.formatMessage({
                defaultMessage: 'Edit',
                description: 'Edit text in header bar'
              })}
        </SemiBoldText>
      </TouchableOpacity>
    )
  });

  const handleDelete = (id: string) => {
    navigation.navigate('ConfirmationQuestion', {
      onPressYes: () => dispatch(deleteExercise(id))
    });
  };

  const getAddItemTitle = (type: ExerciseType) => {
    if (type === ExerciseType.SafetyPlanAddressList) {
      return intl.formatMessage({
        defaultMessage: 'Add contact',
        description: 'Add contact in safety plan'
      });
    }
    return intl.formatMessage({
      defaultMessage: 'Add list item',
      description: 'Add list item in safety plan'
    });
  };

  const navigateToInput = (
    type: SafetyItemType,
    cmsName: string,
    section: string
  ) => {
    navigation.navigate('Drawer', {
      screen: 'SafetyPlanInput',
      params: {
        template: {
          cmsName,
          title: section,
          instruction: intl.formatMessage({
            defaultMessage: 'Hello, Please add the information',
            description: 'Introduction text on the safety item input'
          })
        },
        type
      }
    });
  };

  return (
    <>
      <ScrollView style={[styles.container, { height: windowHeight - 50 }]}>
        {itemsMappedBySection.map((val) => {
          const type = val.items[0]?.type as SafetyItemType;
          if (type !== ExerciseType.SafetyPlanAddressList && !type) return;
          return (
            <SafetyItemCard
              items={val.items}
              section={val.section}
              addItemTitle={getAddItemTitle(type)}
              editMode={editMode}
              onDelete={handleDelete}
              onAdd={() =>
                navigateToInput(type, val.items[0]?.cmsName, val.section)
              }
            />
          );
        })}
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 32,
    backgroundColor: Colors.ICEBLUE
  },
  list: {
    height: '100%',
    width: '100%'
  },
  content: {
    paddingTop: 32
  }
});

export default SafetyPlanScreen;
