import React, { FunctionComponent, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { View, StyleSheet, TextInput } from 'react-native';

import KeyboardInput from '../../../../../components/KeyboardInput';
import PillButton from '../../../../../components/PillButton';
import { QuestionBlock } from '../../../../../store/types';

interface PasswordAnswerProps {
  block: QuestionBlock;
  completed: boolean;
  onComplete: () => void;
  setValue: (password: string) => void;
  loading?: boolean;
}

interface FormValues {
  password: string;
  repeatedPassword: string;
}

const PasswordAnswer: FunctionComponent<PasswordAnswerProps> = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const repeatPasswordInput = useRef<TextInput>(null);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<FormValues>();
  const password = useRef('');
  const repeatedPassword = useRef('');
  password.current = watch('password', '');
  repeatedPassword.current = watch('repeatedPassword', '');

  const intl = useIntl();

  if (props.completed) {
    return <PillButton disabled>{'*'.repeat(8)}</PillButton>;
  }

  return (
    <View style={{ width: '100%' }}>
      <View>
        <Controller
          control={control}
          name="password"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                defaultMessage: 'Please enter a password',
                description: 'Password block error label no password'
              })
            },
            minLength: {
              value: 8,
              message: intl.formatMessage({
                defaultMessage: 'Please use at least 8 characters',
                description: 'Password block error label password length'
              })
            },
            pattern: {
              value:
                /^(?=.*?[a-zA-Z\u0621-\u064A])(?=.*?[0-9\u0660-\u0669]).{8,}$/,
              message: intl.formatMessage({
                defaultMessage:
                  'Password not complex enough. Please include at least one letter and one digit.',
                description: 'Password block error label failed validation'
              })
            }
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <KeyboardInput
              value={value}
              onChangeText={(text) => {
                onChange(text);
                props.setValue(text);
              }}
              secureTextEntry={!passwordVisible}
              autoCorrect={false}
              autoCapitalize="none"
              returnKeyType="next"
              autoComplete="password"
              textContentType="password"
              multiline={false}
              placeholder={intl.formatMessage({
                defaultMessage: 'New password',
                description: 'New password input placeholder'
              })}
              style={styles.input}
              containerStyle={{ marginBottom: 12 }}
              onBlur={onBlur}
              onSubmitEditing={() => repeatPasswordInput.current?.focus()}
              hasValidation={!!errors.password?.message}
              errorText={errors.password ? errors.password.message : null}
              showPasswordToggleButton
              onPressPasswordToggleButton={() =>
                setPasswordVisible(!passwordVisible)
              }
            />
          )}
        />
      </View>

      <View>
        <Controller
          control={control}
          name="repeatedPassword"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                defaultMessage: 'Please confirm your password',
                description: 'Password block error label confirm password'
              })
            },
            validate: (val) =>
              val === password.current ||
              intl.formatMessage({
                defaultMessage: "Passwords don't match",
                description: 'Password block error label not matching'
              })
          }}
          render={({ field: { value, onChange, onBlur } }) => (
            <KeyboardInput
              ref={repeatPasswordInput}
              value={value}
              onChangeText={onChange}
              secureTextEntry={!passwordVisible}
              autoCorrect={false}
              autoCapitalize="none"
              returnKeyType="done"
              autoComplete="password"
              textContentType="password"
              multiline={false}
              placeholder={intl.formatMessage({
                defaultMessage: 'Confirm new password',
                description: 'New password confirmation input placeholder'
              })}
              style={styles.input}
              containerStyle={{}}
              onBlur={onBlur}
              onSubmitEditing={handleSubmit(props.onComplete)}
              hasValidation={!!errors.repeatedPassword?.message}
              errorText={
                errors.repeatedPassword ? errors.repeatedPassword.message : null
              }
              showPasswordToggleButton
              onPressPasswordToggleButton={() =>
                setPasswordVisible(!passwordVisible)
              }
            />
          )}
        />
      </View>
      <PillButton
        disabled={!password.current || !repeatedPassword.current}
        style={{ marginTop: 12, alignSelf: 'flex-end' }}
        onPress={handleSubmit(props.onComplete)}
        loading={props.loading}
      >
        <FormattedMessage
          defaultMessage="Save"
          description="Save button text for blocks"
        />
      </PillButton>
    </View>
  );
};

export default PasswordAnswer;

const styles = StyleSheet.create({
  input: {
    height: 32
  }
});
