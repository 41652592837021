import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  EmitterSubscription,
  Keyboard,
  KeyboardEventListener,
  LayoutChangeEvent,
  Platform,
  Pressable,
  ScrollView,
  ScrollViewProps,
  View
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Block as BlockType, Option } from '../../../../../store/types';
import Block from './Block';

interface BlocksListProps extends ScrollViewProps {
  blocks: BlockType[];
  currentIndex: number;
  initialIndex: number;
  goToPrevious: () => void;
  onBlockComplete: (value?: string | Option | Option[] | null) => void;
  disabled: boolean; // if true, user cannot answer questions
  validation: { error: boolean; message: string };
  loading: boolean;
}

const BlocksList: FunctionComponent<BlocksListProps> = (props) => {
  const [viewHeight, setViewHeight] = useState(0);
  const [blocksCoordinates, setBlocksCoordinates] = useState<number[]>([]);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const insets = useSafeAreaInsets();
  const bottomPadding = insets.bottom + 10;
  const editableBlockOffset = 68; // offset applied to keep the prev block answer in view for edit
  const scrollView = useRef<ScrollView>(null);

  useEffect(() => {
    // adjustment scroll to trigger rendering of new blocks
    if (props.currentIndex >= blocksCoordinates.length - 1) {
      setTimeout(
        () =>
          scrollView.current?.scrollTo({
            y: scrollPosition + 1,
            animated: false
          }),
        100
      );
    }
  }, [props.currentIndex, blocksCoordinates.length]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBlock(blocksCoordinates.length - 1);
    }, 500);
  }, [props.currentIndex, blocksCoordinates.length]);

  useEffect(() => {
    // android handles Keyboard avoiding automatically but
    // we still need to scroll back to the initial position manually
    const keyboardDidHide: KeyboardEventListener = () => {
      scrollView.current?.scrollToEnd();
    };
    // adjust scroll position to show save button when opening Keyboard
    const keyboardDidShow: KeyboardEventListener = (event) => {
      scrollView.current?.scrollTo({
        y: scrollPosition + event.endCoordinates.height + 54
      });
    };

    let showSubscription: EmitterSubscription;
    let hideSubscription: EmitterSubscription;

    if (Platform.OS === 'android') {
      showSubscription = Keyboard.addListener(
        'keyboardDidShow',
        keyboardDidShow
      );
      hideSubscription = Keyboard.addListener(
        'keyboardDidHide',
        keyboardDidHide
      );
    }
    return () => {
      if (Platform.OS === 'android') {
        showSubscription.remove();
        hideSubscription.remove();
      }
    };
  }, [props.currentIndex, scrollPosition]);

  const scrollToBlock = (index: number) => {
    scrollView.current?.scrollTo({
      y:
        blocksCoordinates[Math.min(index, blocksCoordinates.length - 1)] -
        bottomPadding -
        editableBlockOffset,
      animated: true
    });
  };

  const handleLayoutChange = useCallback(
    (event: LayoutChangeEvent) => {
      if (viewHeight === 0) {
        setViewHeight(event.nativeEvent.layout.height);
      }
    },
    [viewHeight]
  );

  const isEditableBlock = (index: number) => {
    return (
      !props.disabled && !props.loading && props.currentIndex - 1 === index
    );
  };

  return (
    <View onLayout={handleLayoutChange} style={{ flex: 1 }}>
      {viewHeight > 0 && (
        <ScrollView
          ref={scrollView}
          bounces={false}
          disableScrollViewPanResponder
          disableIntervalMomentum
          showsVerticalScrollIndicator={false}
          decelerationRate={0}
          scrollEventThrottle={16}
          removeClippedSubviews={false}
          keyboardShouldPersistTaps="handled"
          keyboardDismissMode="on-drag"
          onScroll={(e) => {
            setScrollPosition(e.nativeEvent.contentOffset.y);
          }}
        >
          <Pressable
            onPress={Platform.OS !== 'web' ? Keyboard.dismiss : undefined}
          >
            {props.blocks?.map((item, index, itemsArray) => (
              <View
                key={`${item.id}${index}`}
                onLayout={(e) => {
                  blocksCoordinates[index] = e.nativeEvent.layout.y;
                  setBlocksCoordinates(blocksCoordinates);
                }}
                style={{
                  minHeight:
                    index === itemsArray.length - 1
                      ? viewHeight -
                        (index !== 0 ? bottomPadding + editableBlockOffset : 0)
                      : undefined,
                  paddingBottom: bottomPadding,
                  justifyContent: 'flex-end'
                }}
              >
                {isEditableBlock(index) ? (
                  <Pressable onPress={props.goToPrevious}>
                    <Block
                      block={item}
                      validation={props.validation}
                      onComplete={props.onBlockComplete}
                      completed={props.disabled || props.currentIndex !== index}
                      loading={props.loading}
                      editable
                    />
                  </Pressable>
                ) : (
                  <Block
                    block={item}
                    validation={props.validation}
                    onComplete={props.onBlockComplete}
                    completed={props.disabled || props.currentIndex !== index}
                    loading={props.loading}
                    editable={false}
                  />
                )}
              </View>
            ))}
          </Pressable>
        </ScrollView>
      )}
    </View>
  );
};

export default BlocksList;
