export interface StyleCheckProperties {
  marked: boolean;
  selected: boolean;
  timestamp: number;
}

export interface DateObject {
  day: number;
  dateString: string;
  month: number;
  timestamp: number;
  year: number;
}

export enum Direction {
  Left = 'left',
  Right = 'right'
}

export type ArrowDirection = 'left' | 'right';
