import {
  ExerciseType,
  SafetyItem,
  UnlockableFeature
} from '../../../../store/types';

export const cognitiveTriangleWithLetterDebug: UnlockableFeature = {
  id: 8,
  unlockedFeature: 'cognitive-triangle' as ExerciseType,
  file: {
    type: 'image',
    url: 'http://localhost/cms/assets/5fb29c60-5450-4979-912a-aab9f07da735',
    localUrl: null
  },
  template: {
    title: 'Cognitive Triangle (with Letter to a Friend)',
    instructionInitial: 'This is the initial instruction',
    instructionHelpfulApproach: 'This is the helpful approach instruction',
    instructionSummary: 'This is the summary instruction',
    instructionLinkedExercise:
      'This is the instruction that will show up in the letter to a friend',
    linkedExercise: 'letter-to-a-friend' as ExerciseType,
    situations: [
      {
        id: 1,
        text: 'Sit1',
        helpful: {
          thoughts: [
            { id: '2', text: '2', value: '2' },
            { id: '2', text: '1', value: '1' }
          ],
          feelings: [
            { id: '2', text: '2', value: '2' },
            { id: '2', text: '1', value: '1' }
          ],
          behaviors: [
            { id: '2', text: '2', value: '2' },
            { id: '2', text: '1', value: '1' }
          ]
        },
        unhelpful: {
          thoughts: [
            { id: '2', text: '2', value: '2' },
            { id: '2', text: '1', value: '1' }
          ],
          feelings: [
            { id: '2', text: '2', value: '2' },
            { id: '2', text: '1', value: '1' }
          ],
          behaviors: [
            { id: '2', text: '2', value: '2' },
            { id: '2', text: '1', value: '1' }
          ]
        }
      }
    ],
    linkedListExerciseTemplate: null,
    letterToAFriendRating: true
  }
};

export const safetyPlanDebug: { section: string; items: SafetyItem[] }[] = [
  {
    section: 'Emergency',
    items: [
      {
        id: '1',
        createdAt: '',
        section: 'Medical',
        type: ExerciseType.SafetyPlanBasicList,
        cmsName: 'Emergency0',
        name: 'In case of a medical emergency, do this'
      }
    ]
  },
  {
    section: 'Talk to someone',
    items: [
      {
        id: '2',
        createdAt: '',
        section: 'Talk to someone',
        type: ExerciseType.SafetyPlanBasicList,
        cmsName: 'Emergency3',
        name: 'In case you want to talk to someone, do this'
      },
      {
        id: '3',
        createdAt: '',
        section: 'Talk to someone',
        type: ExerciseType.SafetyPlanBasicList,
        cmsName: 'Emergency3',
        name: 'This is another item'
      }
    ]
  },
  {
    section: 'Seek help',
    items: [
      {
        id: '4',
        createdAt: '',
        section: 'Seek help',
        cmsName: 'Emergency1',
        type: ExerciseType.SafetyPlanAddressList,
        name: 'In case you want to talk to someone, do this',
        phoneNumber: '000',
        address: 'Street 1'
      }
    ]
  }
];

export const unlockedFeaturesDebug: UnlockableFeature[] = [
  {
    id: 1,
    unlockedFeature: 'audio' as ExerciseType,
    file: {
      type: 'image',
      url: 'http://localhost/cms/assets/925fe049-fff3-46d7-b760-b6027d52f5bf',
      localUrl: null
    },
    template: {
      title: 'Relax',
      audioName: 'Relax',
      file: {
        type: 'audio',
        localUrl: null,
        url: 'http://localhost/cms/assets/cbbf93db-51bb-4301-a60d-334ebfbe6af2'
      }
    }
  },
  {
    id: 2,
    unlockedFeature: 'lifeline' as ExerciseType,
    file: {
      type: 'image',
      url: 'http://localhost/cms/assets/872f8e33-b7fd-413b-a836-2d73a1e041ef',
      localUrl: null
    }
  },
  {
    id: 3,
    unlockedFeature: 'list' as ExerciseType,
    file: {
      type: 'image',
      url: 'http://localhost/cms/assets/3f22cc6c-7781-4683-a87f-a37c9fffbf11',
      localUrl: null
    },
    template: {
      id: 1,
      title: 'List exercise custom title (simple)',
      instruction: 'cms instruction',
      items: [
        {
          id: '2',
          text: 'cms text1',
          response: 'cms response1',
          blocks: []
        }
      ],
      defaultResponse: 'cms default response',
      defaultBlocks: [
        {
          id: '1',
          type: 'single-select',
          options: [
            {
              id: '1',
              text: '1',
              value: '1'
            },
            {
              id: '2',
              text: '2',
              value: '2'
            }
          ],
          text: 'One'
        },
        {
          id: '2',
          type: 'list-input',
          options: [],
          text: 'List example'
        },
        {
          id: '3',
          type: 'keyboard-input',
          options: [],
          text: 'keyboard example'
        }
      ],
      complexList: false,
      allowCustomItems: true,
      scheduling: false
    }
  },
  {
    id: 3.5,
    unlockedFeature: 'list' as ExerciseType,
    file: {
      type: 'image',
      url: 'http://localhost/cms/assets/3f22cc6c-7781-4683-a87f-a37c9fffbf11',
      localUrl: null
    },
    template: {
      id: 2,
      title: 'List exercise custom title (complex)',
      instruction: 'cms instruction',
      items: [
        {
          id: '2',
          text: 'cms text1',
          response: 'cms response1',
          blocks: []
        }
      ],
      defaultResponse: 'cms default response',
      defaultBlocks: [
        {
          id: '1',
          type: 'single-select',
          options: [
            {
              id: '1',
              text: '1',
              value: '1'
            },
            {
              id: '2',
              text: '2',
              value: '2'
            }
          ],
          text: 'One'
        },
        {
          id: '2',
          type: 'list-input',
          options: [],
          text: 'List example'
        },
        {
          id: '3',
          type: 'keyboard-input',
          options: [],
          text: 'keyboard example'
        }
      ],
      complexList: true,
      allowCustomItems: true,
      scheduling: false
    }
  },
  {
    id: 4,
    unlockedFeature: 'cognitive-triangle' as ExerciseType,
    file: {
      type: 'image',
      url: 'http://localhost/cms/assets/5fb29c60-5450-4979-912a-aab9f07da735',
      localUrl: null
    },
    template: {
      instructionInitial: 'initial',
      instructionHelpfulApproach: 'helpful',
      instructionSummary: 'summary',
      instructionLinkedExercise: 'linked exercise',
      linkedExercise: 'list' as ExerciseType,
      letterToAFriendRating: true,
      linkedListExerciseTemplate: {
        id: 1,
        title: 'Test template',
        instruction: 'cms instruction',
        items: [
          {
            id: '2',
            text: 'cms text1',
            response: 'cms response1',
            blocks: []
          }
        ],
        defaultResponse: 'cms default response',
        defaultBlocks: [
          {
            id: '1',
            type: 'single-select',
            options: [
              {
                id: '1',
                text: '1',
                value: '1'
              },
              {
                id: '2',
                text: '2',
                value: '2'
              }
            ],
            text: 'One'
          },
          {
            id: '2',
            type: 'list-input',
            options: [],
            text: 'List example'
          },
          {
            id: '3',
            type: 'keyboard-input',
            options: [],
            text: 'keyboard example'
          }
        ],
        complexList: false,
        allowCustomItems: true,
        scheduling: false
      },
      situations: [
        {
          id: 1,
          text: 'Sit1',
          helpful: {
            thoughts: [
              { id: '2', text: '2', value: '2' },
              { id: '2', text: '1', value: '1' }
            ],
            feelings: [
              { id: '2', text: '2', value: '2' },
              { id: '2', text: '1', value: '1' }
            ],
            behaviors: [
              { id: '2', text: '2', value: '2' },
              { id: '2', text: '1', value: '1' }
            ]
          },
          unhelpful: {
            thoughts: [
              { id: '2', text: '2', value: '2' },
              { id: '2', text: '1', value: '1' }
            ],
            feelings: [
              { id: '2', text: '2', value: '2' },
              { id: '2', text: '1', value: '1' }
            ],
            behaviors: [
              { id: '2', text: '2', value: '2' },
              { id: '2', text: '1', value: '1' }
            ]
          }
        },
        {
          id: 2,
          text: 'second sit',
          helpful: {
            thoughts: [
              { id: '2', text: '2', value: '2' },
              { id: '2', text: '1', value: '1' }
            ],
            feelings: [],
            behaviors: [
              { id: '2', text: '2', value: '2' },
              { id: '2', text: '1', value: '1' }
            ]
          },
          unhelpful: {
            thoughts: [
              { id: '2', text: '2', value: '2' },
              { id: '2', text: '1', value: '1' }
            ],
            feelings: [],
            behaviors: [
              { id: '2', text: '2', value: '2' },
              { id: '2', text: '1', value: '1' }
            ]
          }
        }
      ]
    }
  },
  {
    id: 5,
    unlockedFeature: 'written-exposure' as ExerciseType,
    file: {
      type: 'image',
      url: 'http://localhost/cms/assets/262f0184-6f2a-470b-84a7-951830abeb4b',
      localUrl: null
    },
    template: {
      id: -1,
      time: 10,
      instruction: 'WRITE',
      rating: true,
      skipTopicSelection: false,
      defaultTopic: null
    }
  },
  {
    id: 6,
    unlockedFeature: 'responsibility-pie' as ExerciseType,
    file: {
      type: 'image',
      url: 'http://localhost/cms/assets/1f1c9498-7f37-4f4e-899b-67276d3b9bbd',
      localUrl: null
    }
  },
  {
    id: 7,
    unlockedFeature: 'checking-in' as ExerciseType,
    file: {
      type: 'image',
      url: 'http://localhost/cms/assets/f301b9af-8308-4af4-bb61-13c67c5503af',
      localUrl: null
    }
  }
];
