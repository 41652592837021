import env from '../env';
import { nullishCoalesce } from '../helpers';
import {
  Asset,
  AudioExerciseTemplate,
  CognitiveTriangleTemplate,
  ExerciseTemplate,
  ListExerciseTemplate,
  SafetyPlanListItemTemplate,
  WrittenExposureTemplate
} from './types';

const sortByCreationDateDsc = (
  a: { createdAt: string },
  b: { createdAt: string }
) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

const getAudioExerciseTemplateFromApiObject = (
  template: any
): AudioExerciseTemplate => {
  return {
    title: template.translations[0]?.title,
    audioName: template.translations[0]?.audio_name,
    file: {
      type: template.translations[0]?.file?.type,
      localUrl: null,
      url: template.translations[0]?.file?.id
        ? `${env.CMS_BASE_URL}/assets/${template.translations[0]?.file?.id}`
        : null
    } as Asset
  };
};

const getCognitiveTriangleTemplateFromApiObject = (
  template: any,
  customInstructions?: any
): CognitiveTriangleTemplate => {
  const situations = template.situations.map(
    (situation: any) => situation.cognitive_triangle_situations_id
  );
  const instructions = template.translations[0];

  return {
    instructionInitial: nullishCoalesce(
      customInstructions?.instruction_initial,
      instructions?.instruction_initial
    ),
    instructionHelpfulApproach: nullishCoalesce(
      customInstructions?.instruction_helpful_approach,
      instructions?.instruction_helpful_approach
    ),
    instructionSummary: nullishCoalesce(
      customInstructions?.instruction_summary,
      instructions?.instruction_summary
    ),
    instructionLinkedExercise: nullishCoalesce(
      customInstructions?.instruction_linked_exercise,
      instructions?.instruction_linked_exercise
    ),
    linkedExercise: template.linked_exercise,
    linkedListExerciseTemplate:
      template.linked_list_exercise_template &&
      getListExerciseTemplateFromApiObject(
        template.linked_list_exercise_template
      ),
    letterToAFriendRating: template.letter_to_a_friend_rating,
    situations: situations.map((situation: any) => ({
      id: situation.id,
      text: nullishCoalesce(
        situation.translations[0]?.text,
        situation.translations[0]?.placeholder
      ),
      helpful: {
        thoughts: situation.helpful_thoughts.map((option: any) => ({
          id: option.answer_options_id.id,
          text: nullishCoalesce(
            option.answer_options_id.translations[0]?.text,
            option.answer_options_id.translations[0]?.placeholder
          ),
          value: option.answer_options_id.value
        })),
        feelings: situation.helpful_feelings.map((option: any) => ({
          id: option.answer_options_id.id,
          text: nullishCoalesce(
            option.answer_options_id.translations[0]?.text,
            option.answer_options_id.translations[0]?.placeholder
          ),
          value: option.answer_options_id.value
        })),
        behaviors: situation.helpful_behaviors.map((option: any) => ({
          id: option.answer_options_id.id,
          text: nullishCoalesce(
            option.answer_options_id.translations[0]?.text,
            option.answer_options_id.translations[0]?.placeholder
          ),
          value: option.answer_options_id.value
        }))
      },
      unhelpful: {
        thoughts: situation.unhelpful_thoughts.map((option: any) => ({
          id: option.answer_options_id.id,
          text: nullishCoalesce(
            option.answer_options_id.translations[0]?.text,
            option.answer_options_id.translations[0]?.placeholder
          ),
          value: option.answer_options_id.value
        })),
        feelings: situation.unhelpful_feelings.map((option: any) => ({
          id: option.answer_options_id.id,
          text: nullishCoalesce(
            option.answer_options_id.translations[0]?.text,
            option.answer_options_id.translations[0]?.placeholder
          ),
          value: option.answer_options_id.value
        })),
        behaviors: situation.unhelpful_behaviors.map((option: any) => ({
          id: option.answer_options_id.id,
          text: nullishCoalesce(
            option.answer_options_id.translations[0]?.text,
            option.answer_options_id.translations[0]?.placeholder
          ),
          value: option.answer_options_id.value
        }))
      }
    }))
  };
};

const getListExerciseTemplateFromApiObject = (
  template: any,
  customInstructions?: any
): ListExerciseTemplate => {
  return {
    id: template.id,
    title: nullishCoalesce(
      template.translations[0]?.title,
      template.translations[0]?.placeholder
    ),
    instruction: nullishCoalesce(
      customInstructions?.instruction,
      template.translations[0]?.instruction,
      template.translations[0]?.placeholder
    ),
    items: template.items
      .map((item: any) => item.list_exercise_items_id)
      .map((item: any) => ({
        id: item.id.toString(),
        text: nullishCoalesce(
          item.translations[0]?.text,
          item.translations[0]?.placeholder
        ),
        response: item.translations[0]?.response,
        blocks: item.blocks
          .map((block: any) => block.list_exercise_blocks_id)
          .map((block: any) => ({
            id: block.id.toString(),
            type: block.type,
            options: block.options.map((option: any) => ({
              id: option.answer_options_id.id,
              text: nullishCoalesce(
                option.answer_options_id.translations[0]?.text,
                option.answer_options_id.translations[0]?.placeholder
              ),
              value: option.answer_options_id.value
            })),
            text: nullishCoalesce(
              block.translations[0]?.text,
              block.translations[0]?.placeholder
            ),
            min: block.min,
            max: block.max,
            interval: block.interval
          }))
      })),
    defaultResponse: nullishCoalesce(
      template.translations[0]?.default_response,
      template.translations[0]?.placeholder
    ),
    defaultBlocks: template.default_blocks
      .map((block: any) => block.list_exercise_blocks_id)
      .map((block: any) => ({
        id: block.id.toString(),
        type: block.type,
        options: block.options.map((option: any) => ({
          id: option.answer_options_id.id,
          text: nullishCoalesce(
            option.answer_options_id.translations[0]?.text,
            option.answer_options_id.translations[0]?.placeholder
          ),
          value: option.answer_options_id.value
        })),
        text: nullishCoalesce(
          block.translations[0]?.text,
          block.translations[0]?.placeholder
        ),
        min: block.min,
        max: block.max,
        interval: block.interval
      })),
    complexList: template.complex_list,
    allowCustomItems: template.allow_custom_items,
    scheduling: template.scheduling
  };
};

const getSafetyPlanListItemTemplateFromApiObject = (
  template: any
): SafetyPlanListItemTemplate => {
  return {
    cmsName: template.name,
    title: nullishCoalesce(
      template.translations[0]?.title,
      template.translations[0]?.placeholder
    ),
    instruction: nullishCoalesce(
      template.translations[0]?.instruction,
      template.translations[0]?.placeholder
    )
  };
};

const getWrittenExposureTemplateFromApiObject = (
  template: any,
  customInstructions?: any
): WrittenExposureTemplate => {
  return {
    id: template.id,
    time: template.time,
    instruction: nullishCoalesce(
      customInstructions?.instruction,
      template.translations[0]?.instruction,
      template.translations[0]?.placeholder
    ),
    rating: template.rating,
    skipTopicSelection: template.skip_topic_selection,
    defaultTopic: template.translations[0]?.default_topic
  };
};

/**
 * Gets the template from a template field that is defined as Many-To-Any in Directus.
 * @param m2aTemplate - CMS m2a template object
 */
const getTemplateFromM2A = (m2aTemplate: any): ExerciseTemplate | undefined => {
  if (!m2aTemplate?.[0]) {
    return undefined;
  }
  switch (m2aTemplate[0].collection) {
    case 'audio_exercises':
      return getAudioExerciseTemplateFromApiObject(m2aTemplate[0].item);
    case 'cognitive_triangle_templates':
      return getCognitiveTriangleTemplateFromApiObject(m2aTemplate[0].item);
    case 'list_exercise_templates':
      return getListExerciseTemplateFromApiObject(m2aTemplate[0].item);
    case 'safety_plan_list_items':
      return getSafetyPlanListItemTemplateFromApiObject(m2aTemplate[0].item);
    case 'written_exposure_templates':
      return getWrittenExposureTemplateFromApiObject(m2aTemplate[0].item);
    default:
      return undefined;
  }
};

/**
 * Gets an Asset object from a Directus file object.
 */
const getAssetFromFile = (file: any): Asset | null => {
  if (!file?.id) {
    return null;
  }
  return {
    type: file.type?.split('/')[0],
    url: `${env.CMS_BASE_URL}/assets/${file?.id}`,
    localUrl: null
  };
};

export {
  sortByCreationDateDsc,
  getAudioExerciseTemplateFromApiObject,
  getCognitiveTriangleTemplateFromApiObject,
  getListExerciseTemplateFromApiObject,
  getSafetyPlanListItemTemplateFromApiObject,
  getWrittenExposureTemplateFromApiObject,
  getTemplateFromM2A,
  getAssetFromFile
};
