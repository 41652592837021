import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';

import Colors from '../../../../colors';
import MediumText from '../../../../components/MediumText';
import PillButton from '../../../../components/PillButton';
import Slider from '../../../../components/Slider';

interface FeelingValue {
  name: string;
  scale: number;
}
interface Props {
  feelingsData: string[];
  selected: FeelingValue[];
  onSelect: (val: FeelingValue[]) => void;
}

const FeelingsSelect: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const getTitleText = (feeling: string) => {
    return intl.formatMessage(
      {
        defaultMessage: 'How {feeling} do you feel?',
        description: 'Slider title on the cognitive triangle screen'
      },
      {
        feeling
      }
    );
  };

  const renderButtons = () => {
    return props.feelingsData?.map((value) => {
      const isSelected = props.selected.some((val) => val.name === value);
      return (
        <PillButton
          key={value}
          style={[styles.baseButton, isSelected && styles.buttonActive]}
          onPress={() => {
            if (isSelected) {
              props.onSelect(
                props.selected.filter((item) => item.name !== value)
              );
            } else {
              props.onSelect([{ name: value, scale: -1 }, ...props.selected]);
            }
          }}
        >
          <MediumText
            style={isSelected ? styles.textActive : styles.textInactive}
          >
            {value}
          </MediumText>
        </PillButton>
      );
    });
  };

  const renderSliders = () => {
    return props.selected.map((feeling) => (
      <View style={{ marginTop: 20 }}>
        <Slider
          title={getTitleText(feeling.name)}
          onSlidingComplete={(sliderValue: number) => {
            const updatedValues = props.selected.map((item) => {
              return item.name === feeling.name
                ? {
                    name: item.name,
                    scale: sliderValue
                  }
                : item;
            });
            props.onSelect(updatedValues);
          }}
          maximumValue={100}
          step={25}
          value={feeling.scale === -1 ? 50 : feeling.scale}
          minimumValue={0}
          leftLabel={intl.formatMessage({
            defaultMessage: 'a little',
            description: 'Slider a little'
          })}
          rightLabel={intl.formatMessage({
            defaultMessage: 'a lot',
            description: 'Slider a lot'
          })}
        />
      </View>
    ));
  };

  return (
    <>
      <View style={styles.buttonsContainer}>{renderButtons()}</View>
      {renderSliders()}
    </>
  );
};
export default FeelingsSelect;

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  baseButton: {
    marginRight: 10,
    marginTop: 10,
    backgroundColor: Colors.WHITE,
    borderWidth: 1,
    borderColor: Colors.BLUE
  },
  textInactive: {
    color: Colors.BLUE
  },
  textActive: {
    color: Colors.WHITE
  },
  buttonActive: {
    backgroundColor: Colors.BLUE,
    borderWidth: 1,
    borderColor: Colors.BLUE
  }
});
