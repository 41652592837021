import { useNavigation } from '@react-navigation/native';
import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  Image
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import Drawer from '../../../components/Drawer';
import MediumText from '../../../components/MediumText';
import Message from '../../../components/Message';
import PillButton from '../../../components/PillButton';
import SemiBoldText from '../../../components/SemiBoldText';
import Slider from '../../../components/Slider';
import Step from '../../../components/Step';
import { useBackHandler } from '../../../hooks/useBackHandler';
import { isRtl } from '../../../lang';
import { saveExercise } from '../../../store/exercises';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectActiveLanguage } from '../../../store/languages';
import { CheckingInAnswer, ExerciseType } from '../../../store/types';

enum CheckingSteps {
  FIRST_QUESTION,
  SECOND_QUESTION
}

const CheckingInScreen: FunctionComponent = () => {
  const rtl = isRtl(useAppSelector(selectActiveLanguage).language);
  const [stepIndex, setStepIndex] = useState(CheckingSteps.FIRST_QUESTION);
  const [exerciseValues, setExerciseValues] = useState<CheckingInAnswer[]>([
    {
      id: '0',
      problem: 'I am constantly worried about my future.',
      impactScale: -1
    },
    {
      id: '1',
      problem: 'I am very lonely',
      impactScale: -1
    }
  ]);

  const intl = useIntl();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const impactValues = [
    intl.formatMessage({
      defaultMessage: 'not at all',
      description: 'Not at all slider value'
    }),
    intl.formatMessage({
      defaultMessage: 'a little',
      description: 'A little slider value'
    }),
    intl.formatMessage({
      defaultMessage: 'moderately',
      description: 'Moderately slider value'
    }),
    intl.formatMessage({
      defaultMessage: 'much',
      description: 'Much slider value'
    }),
    intl.formatMessage({
      defaultMessage: 'very much',
      description: 'Very much slider value'
    })
  ];

  const renderMessages = (question: string) => {
    return (
      <>
        <Message
          component={
            <View style={styles.problemMessage}>
              <MediumText>
                <FormattedMessage
                  defaultMessage="In the beginning you said your biggest problem was:"
                  description="Checking In first message"
                />
              </MediumText>
              <SemiBoldText>{question}</SemiBoldText>
            </View>
          }
          style={{ marginVertical: 20 }}
          containerStyle={styles.messageContainer}
        />
        <Message
          text={intl.formatMessage({
            defaultMessage:
              'How often did this problem affect your behaviour within the last week?',
            description: 'Checking In second message'
          })}
          style={{ marginBottom: 40 }}
          containerStyle={styles.messageContainer}
        />
      </>
    );
  };

  const renderSlider = (exercise: CheckingInAnswer) => {
    const { impactScale, id } = exercise;
    return (
      <Slider
        step={1}
        maximumValue={impactValues.length - 1}
        minimumValue={0}
        onValueChange={(val) =>
          setExerciseValues(
            exerciseValues.map((exercise) =>
              exercise.id === id ? { ...exercise, impactScale: val } : exercise
            )
          )
        }
        value={impactScale === -1 ? 2 : impactScale}
        leftLabel={impactValues[0]}
        rightLabel={impactValues[impactValues.length - 1]}
      />
    );
  };

  const renderImpactText = (impactScale: number) => {
    return (
      <MediumText style={{ height: 40, marginVertical: 30, width: '100%' }}>
        {impactScale !== -1 && (
          <>
            <FormattedMessage defaultMessage="This problem concerned me" />{' '}
            <SemiBoldText>{impactValues[impactScale]}</SemiBoldText>.
          </>
        )}
      </MediumText>
    );
  };

  const renderFirstQuestion = () => {
    const exercise = exerciseValues[0];
    const { impactScale, problem } = exercise;
    return (
      <>
        {renderMessages(problem)}
        {renderSlider(exercise)}
        {renderImpactText(impactScale)}
        <View style={styles.pillButtonContainer}>
          <PillButton
            disabled={impactScale === -1}
            onPress={() => setStepIndex(CheckingSteps.SECOND_QUESTION)}
          >
            <FormattedMessage defaultMessage="Save" description="Save" />
          </PillButton>
        </View>
      </>
    );
  };

  const renderSecondQuestion = () => {
    const exercise = exerciseValues[1];
    const { impactScale, problem } = exercise;
    return (
      <>
        {renderMessages(problem)}
        {renderSlider(exercise)}
        {renderImpactText(impactScale)}
        <View style={styles.pillButtonContainer}>
          <PillButton
            disabled={impactScale === -1}
            onPress={() => {
              dispatch(
                saveExercise({
                  id: uuidv4(),
                  type: ExerciseType.CheckingIn,
                  createdAt: new Date().toISOString(),
                  answers: exerciseValues
                })
              );
              navigation.goBack();
            }}
          >
            <FormattedMessage defaultMessage="Save" description="Save" />
          </PillButton>
        </View>
      </>
    );
  };

  const isFirstScreen = () => {
    return stepIndex <= CheckingSteps.FIRST_QUESTION;
  };

  const regularBackwardNav = () => {
    setStepIndex(stepIndex - 1);
  };

  const { getDrawerIcon } = useBackHandler(
    isFirstScreen,
    regularBackwardNav,
    [stepIndex],
    navigation
  );

  return (
    <Drawer
      leftComponent={getDrawerIcon()}
      onBackgroundPress={() =>
        navigation.navigate('DrawerCloseExercise', {
          closeExercise: () => navigation.goBack()
        })
      }
      title={intl.formatMessage({
        defaultMessage: 'Checking In',
        description: 'Checking In drawer title'
      })}
    >
      <ScrollView style={{ paddingHorizontal: 24, flex: 1 }}>
        <Step current={stepIndex} step={CheckingSteps.FIRST_QUESTION}>
          {renderFirstQuestion()}
        </Step>
        <Step current={stepIndex} step={CheckingSteps.SECOND_QUESTION}>
          {renderSecondQuestion()}
        </Step>
      </ScrollView>
    </Drawer>
  );
};

export default CheckingInScreen;

const styles = StyleSheet.create({
  pillButtonContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20
  },
  problemMessage: {
    paddingHorizontal: 16,
    paddingVertical: 13
  },
  messageContainer: {
    shadowOpacity: 0
  },
  arrow: {
    width: 30,
    height: 30,
    resizeMode: 'contain'
  }
});
