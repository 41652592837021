import { createStackNavigator } from '@react-navigation/stack';
import { MediaTypeOptions } from 'expo-image-picker';
import React, { FunctionComponent } from 'react';

import {
  AudioExerciseTemplate,
  CognitiveTriangleExercise,
  CognitiveTriangleTemplate,
  ConcernsAndBarriersItems,
  ConcernsAndBarriersTemplate,
  LetterToAFriendExercise,
  ListExercise,
  ListExerciseItem,
  ListExerciseTemplate,
  ResponsibilityPieExercise,
  ResponsibilityPieReason,
  SafetyItemType,
  SafetyPlanListItemTemplate,
  WrittenExposureTemplate
} from '../store/types';
import LegalDrawer from './Authentication/LegalDrawer';
import LoginDrawer from './Authentication/LoginScreen/LoginDrawer';
import PrivacyPolicyDrawer from './Authentication/PrivacyPolicyDrawer';
import AudioPlayerScreen from './Tab/Library/AudioPlayerScreen';
import CheckingInScreen from './Tab/Library/CheckingInScreen';
import CognitiveTriangleScreen from './Tab/Library/CognitiveTriangleScreen';
import LetterToAFriendArchiveItemScreen from './Tab/Library/LetterToAFriendArchiveScreen/LetterToAFriendArchiveItemScreen';
import LetterToAFriendScreen from './Tab/Library/LetterToAFriendScreen';
import LifelineScreen from './Tab/Library/LifelineScreen';
import ListExerciseScreen from './Tab/Library/ListExerciseScreen';
import ResponsibilityPieArchiveItemScreen from './Tab/Library/ResponsibilityPieArchiveScreen/ResponsibilityPieArchiveItemScreen';
import ResponsibilityPieScreen from './Tab/Library/ResponsibilityPieScreen';
import SafetyPlanInputScreen from './Tab/Library/SafetyPlanScreen/SafetyPlanInputScreen';
import SimpleListExerciseScreen from './Tab/Library/SimpleListExerciseScreen';
import WrittenExposureArchiveItemScreen from './Tab/Library/WrittenExposureArchiveItemScreen';
import WrittenExposureScreen from './Tab/Library/WrittenExposureScreen';
import CameraScreen from './Tab/Tracker/MoodScreen/CameraScreen';
import MoodTrackerScreen from './Tab/Tracker/MoodScreen/MoodTrackerScreen';

export type DrawerStackParamList = {
  CognitiveTriangle:
    | undefined
    | {
        completedExercise?: CognitiveTriangleExercise;
        onFinish?: () => void;
        template?: CognitiveTriangleTemplate;
      };
  LetterToAFriend: {
    values: LetterToAFriendExercise;
    situation: string;
    instruction: string;
    helpfulApproachInstruction: string;
    rating: boolean;
    onFinish: (values: LetterToAFriendExercise) => void;
    stepIndex?: number;
  };
  LetterToAFriendArchiveItem: {
    helpfulThought: string;
    letter: string;
    date: string;
    files?: string[];
  };
  Lifeline: undefined | { onFinish: () => void };
  WrittenExposure:
    | undefined
    | { onFinish: () => void; template?: WrittenExposureTemplate };
  WrittenExposureArchiveItem: {
    topic: string;
    text: string;
    date: string;
    files?: string[];
  };
  ResponsibilityPieArchiveItem: { reasons: ResponsibilityPieReason[] };
  Calendar: undefined;
  DrawerCloseExercise: {
    closeExercise: () => void;
  };
  CheckingIn: undefined;
  ConcernsAndBarriers:
    | undefined
    | {
        values?: ConcernsAndBarriersItems[];
        onFinish?: () => void;
        template?: ConcernsAndBarriersTemplate;
      };
  AudioPlayer: {
    onFinish?: () => void;
    template: AudioExerciseTemplate;
  };
  ListExercise: {
    onFinish?: (values: ListExercise) => void;
    onBack?: (values: ListExercise) => void;
    template: ListExerciseTemplate;
    instructionLinkedExercise?: string;
    items?: ListExerciseItem[];
    preventSaving?: boolean;
  };
  SimpleListExercise: {
    onFinish?: (values: ListExercise) => void;
    onBack?: (values: ListExercise) => void;
    template: ListExerciseTemplate;
    instructionLinkedExercise?: string;
    items?: ListExerciseItem[];
    preventSaving?: boolean;
  };
  MoodTracker: undefined;
  Camera: {
    setUri: (uri: string) => void;
    mediaTypes?: MediaTypeOptions;
  };
  SafetyPlanInput: {
    template: SafetyPlanListItemTemplate;
    type: SafetyItemType;
    onFinish?: () => void;
  };
  ResponsibilityPie: {
    reasons: ResponsibilityPieReason[];
    instruction: string;
    linkedCognitiveTriangleId?: string;
    onFinish?: (values: ResponsibilityPieExercise) => void;
    onBack?: (values: ResponsibilityPieExercise) => void;
    stepIndex?: number;
  };
  LoginDrawer: undefined | { onFinish?: () => void | Promise<void> };
  LegalDrawer: undefined;
  PrivacyPolicyDrawer: undefined;
};

const DrawerStack = createStackNavigator<DrawerStackParamList>();

const DrawerNavigator: FunctionComponent = () => {
  return (
    <DrawerStack.Navigator
      mode="modal"
      screenOptions={{
        headerShown: false,
        cardStyle: { backgroundColor: 'rgba(0,0,0,0.3)' }
        // TODO: add Drawer animation
      }}
    >
      <DrawerStack.Screen
        name="CognitiveTriangle"
        component={CognitiveTriangleScreen}
      />
      <DrawerStack.Screen name="Lifeline" component={LifelineScreen} />
      <DrawerStack.Screen
        name="WrittenExposure"
        component={WrittenExposureScreen}
      />
      <DrawerStack.Screen
        name="WrittenExposureArchiveItem"
        component={WrittenExposureArchiveItemScreen}
      />
      <DrawerStack.Screen
        name="ResponsibilityPieArchiveItem"
        component={ResponsibilityPieArchiveItemScreen}
      />
      <DrawerStack.Screen
        name="LetterToAFriend"
        component={LetterToAFriendScreen}
      />
      <DrawerStack.Screen
        name="LetterToAFriendArchiveItem"
        component={LetterToAFriendArchiveItemScreen}
      />
      <DrawerStack.Screen name="CheckingIn" component={CheckingInScreen} />
      <DrawerStack.Screen
        name="SimpleListExercise"
        component={SimpleListExerciseScreen}
      />
      <DrawerStack.Screen name="AudioPlayer" component={AudioPlayerScreen} />
      <DrawerStack.Screen name="MoodTracker" component={MoodTrackerScreen} />
      <DrawerStack.Screen name="Camera" component={CameraScreen} />
      <DrawerStack.Screen
        name="SafetyPlanInput"
        component={SafetyPlanInputScreen}
      />
      <DrawerStack.Screen
        name="ResponsibilityPie"
        component={ResponsibilityPieScreen}
      />
      <DrawerStack.Screen name="LoginDrawer" component={LoginDrawer} />
      <DrawerStack.Screen name="ListExercise" component={ListExerciseScreen} />
      <DrawerStack.Screen name="LegalDrawer" component={LegalDrawer} />
      <DrawerStack.Screen
        name="PrivacyPolicyDrawer"
        component={PrivacyPolicyDrawer}
      />
    </DrawerStack.Navigator>
  );
};

export default DrawerNavigator;
