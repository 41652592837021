import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Update
} from '@reduxjs/toolkit';

import { RootState } from '.';
import env from '../env';
import { selectFilteredBlocksByModuleId } from './blocks';
import { getOnboardingFlow, getUserFlow } from './flow';
import { fetchSession } from './sessions';
import { Block, Module } from './types';

const modulesAdapter = createEntityAdapter<Module>({
  selectId: (module) => module.id
});

const initialState = modulesAdapter.getInitialState({ loading: true });

export const upsertModule = createAsyncThunk<
  Update<Module>,
  { entityId: string; changes: Partial<Module> },
  { state: RootState }
>('modules/upsertModule', async ({ entityId, changes }, { getState }) => {
  if (!getState().user.userId) {
    return {
      id: entityId,
      changes
    };
  }

  const moduleState = getState().modules.entities[entityId] as Module;

  const apiRes = await fetch(
    `${env.API_BASE_URL}/users/${getState().user.userId}/sessions/${
      moduleState.sessionId
    }/modules/${moduleState.moduleId}`,
    {
      method: 'post',
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(changes)
    }
  );

  const apiData = await apiRes.json();

  if (!apiRes.ok) {
    throw new Error(
      apiData.code && apiData.message
        ? `${apiData.code}: ${apiData.message}`
        : `${apiRes.status} ${apiRes.statusText}`
    );
  }

  return {
    id: entityId,
    changes: {
      started: apiData.started ?? moduleState?.started,
      completed: apiData.completed ?? moduleState?.completed,
      startedAt: apiData.startedAt ?? moduleState?.startedAt,
      completedAt: apiData.completedAt ?? moduleState?.completedAt
    }
  };
});

export const upsertAllModules = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('modules/upsertAllModules', async (_, { getState, dispatch }) => {
  await Promise.allSettled(
    selectAllModules(getState()).map((module) =>
      dispatch(
        upsertModule({
          entityId: module.id,
          changes: {
            started: module.started,
            startedAt: module.startedAt ?? undefined,
            completed: module.completed,
            completedAt: module.completedAt ?? undefined
          }
        })
      )
    )
  );
});

export const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    updateModule: modulesAdapter.updateOne
  },
  extraReducers: (builder) => {
    builder.addCase(getOnboardingFlow.fulfilled, (state, action) => {
      modulesAdapter.upsertMany(state, action.payload.modules);
    });
    builder.addCase(getUserFlow.fulfilled, (state, action) => {
      modulesAdapter.upsertMany(state, action.payload.modules);
    });
    builder.addCase(fetchSession.fulfilled, (state, action) => {
      modulesAdapter.upsertMany(state, action.payload.modules);
    });
    builder.addCase(upsertModule.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(upsertModule.fulfilled, (state, action) => {
      state.loading = false;
      modulesAdapter.updateOne(state, action.payload);
    });
    builder.addCase(upsertModule.rejected, (state, action) => {
      state.loading = false;
    });
  }
});

export const { selectById: selectModuleById, selectAll: selectAllModules } =
  modulesAdapter.getSelectors<RootState>((state) => state.modules);

export const selectModulesByMultipleIds = (state: RootState, ids: string[]) => {
  return ids
    .map((id) => selectModuleById(state, id))
    .filter((module) => !!module) as Module[];
};

export const selectAllModuleBlocks = (state: RootState) =>
  selectAllModules(state).reduce(
    (prev, module) => ({
      ...prev,
      [module.id]: selectFilteredBlocksByModuleId(state, module.id)
    }),
    {} as { [moduleId: string]: Block[] }
  );

export const selectModuleProgresses = createSelector(
  [selectAllModuleBlocks],
  (blocks) => {
    return Object.keys(blocks).reduce((prev, curr) => {
      const progress =
        (blocks[curr].filter((block) => block.completed).length /
          blocks[curr].length) *
        100;
      return {
        ...prev,
        [curr]: progress
      };
    }, {} as { [id: string]: number });
  }
);

export const { updateModule } = modulesSlice.actions;
export default modulesSlice.reducer;
